import React from 'react'
import { Row, Col } from 'antd'

import { useStateValue } from '../stateProvider/stateProvider'

const headerStyle = {
  fontWeight: 'bold',
  fontSize: '1.75em',
}

const subheaderStyle = {
  fontWeight: 'bold',
}

const ColProps = {
  span: 12,
  offset: 6,
  style: {
    zIndex: 1000,
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)',
  },
}

const fields = {
  fr: {
    mainTitle: 'Info pour dealers',
    subFields: [
      {
        title: 'Dealerapp',
        list: [
          {
            subTitle: 'Manuel dealerapp',
            link:
              'https://docs.google.com/document/d/16b-6t23YOkvRICOhoZDSV4MtgK2eajE1nGThZm5vTj8/edit?usp=sharing',
          },
        ],
      },
      {
        title: 'Leasing',
        list: [
          {
            subTitle: 'Aperçu entreprises leasing',
            link:
              'https://docs.google.com/spreadsheets/d/1iYY5hI8O0FCbe8H3akhxXG1qrvtl8eLXIioNos8EVxc/edit?usp=sharing',
          },
          {
            subTitle: 'Sociétés de leasing',
            link:
              'https://docs.google.com/document/d/1cW0wBXdWRMWYZ_eFwmfxFRNnwLfJbTTc20XFDInU0DI/edit?usp=sharing',
          },
          {
            subTitle: 'Leasing petits entreprises/indépendants',
            link:
              'https://docs.google.com/document/d/1jiGABW7Nxs-PXDBma3tHxdbNFD126UmZqYmBQbf6WB4/edit?usp=sharing',
          },
        ],
      },
      {
        title: 'Après vente',
        list: [
          {
            subTitle: 'Fin de leasing',
            route: '/info-end-of-lease',
          },
          {
            subTitle: 'Assurance, dommage et vol',
            route: '/info-damage',
          },
          {
            subTitle: 'Demande vélo de remplacement',
            link:
              'https://docs.google.com/forms/d/e/1FAIpQLSeFs89Bvu9hyBOQjxuRQZgxcIb-m9CnOIVvAhyY2CLGfQ3XHg/viewform',
          },
        ],
      },
      {
        title: 'Matériel',
        list: [
          {
            subTitle: 'Demande matériel dealers (servicepas, autocollants)',
            link:
              'https://docs.google.com/forms/d/e/1FAIpQLSfQ35rMw1ZbS1dAW1x7UfP2CDBvw5yLaegGdEyFQ3ZxNcX1_A/viewform',
          },
          {
            subTitle: 'Méthode servicepas',
            link:
              'https://docs.google.com/document/d/1qK2fuXPiADz15BCxQtg4Kw5L5Ta_FLNnKwEQ3qzkyrk/edit?usp=sharing',
          },
        ],
      },
    ],
  },
  nl: {
    mainTitle: 'Info voor dealers',
    subFields: [
      {
        title: 'Dealerapp',
        list: [
          {
            subTitle: 'Handleiding dealerapp',
            link:
              'https://sites.google.com/b2bike.be/dealerapphandleiding?usp=sharing',
          },
        ],
      },
      {
        title: 'Leasing',
        list: [
          {
            subTitle: 'Overzicht bedrijven fietsleasing',
            link:
              'https://docs.google.com/spreadsheets/d/1iYY5hI8O0FCbe8H3akhxXG1qrvtl8eLXIioNos8EVxc/edit?usp=sharing',
          },
          {
            subTitle: 'Leasemaatschappijen',
            link:
              'https://docs.google.com/document/d/1qKHAuP_VDtJZxXroJvON6o8pe00ScNGdbl4ZvZLafAA/edit?usp=sharing',
          },
          {
            subTitle: 'Leasing kleine zelfstandigen',
            link:
              'https://docs.google.com/document/d/1c8DSWjWKqBJZS_aV1f0YhuEY8jy46TvZZL0Rs04BuaY/edit?usp=sharing',
          },
        ],
      },
      {
        title: 'Naverkoop',
        list: [
          {
            subTitle: 'Einde lease',
            route: '/info-end-of-lease',
          },
          {
            subTitle: 'Verzekering, schade en diefstal',
            route: '/info-damage',
          },
          {
            subTitle: 'Aanvraag vervangfiets',
            link:
              'https://docs.google.com/forms/d/e/1FAIpQLSdEIXQEQ0vX1A-M4gy_O7ys6pZCABcKA-TmLwrIrHyqT5vK0g/viewform',
          },
        ],
      },
      {
        title: 'Materiaal',
        list: [
          {
            subTitle: 'Aanvraag materiaal dealers (servicepassen, stickers)',
            link:
              'https://docs.google.com/forms/d/e/1FAIpQLScmlUs8gQt04B3oCDzK7XZOg6cXGGptZ_5FbfS1RePlaqIb1Q/viewform',
          },
          {
            subTitle: 'Werkwijze servicepassen',
            link:
              'https://docs.google.com/document/d/1H0eAXwoVFVuONP3zofEH0BqsnWVl-lhAmksfYLonrCE/edit?usp=sharing',
          },
        ],
      },
    ],
  },
}

const Info = (props) => {
  const [{ userDetails }] = useStateValue()

  if (!userDetails) {
    return <></>
  }

  const fieldsByLanguage = fields?.[userDetails?.language ?? 'nl'] ?? {}

  return (
    <Row type="flex" align="middle">
      <Col {...ColProps}>
        <h1 style={headerStyle}>{fieldsByLanguage?.mainTitle}</h1>

        {(fieldsByLanguage?.subFields ?? [])?.map((subField) => (
          <div key={subField?.title}>
            <h3 style={subheaderStyle}>{subField?.title}</h3>
            <ul>
              {(subField?.list ?? [])?.map((item, i) => (
                <li key={i}>
                  <a
                    href={item?.link}
                    onClick={() =>
                      item?.route && props.history.push(item.route)
                    }
                    target={item?.noTarget ? undefined : '_blank'}
                  >
                    {item?.subTitle}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default Info
