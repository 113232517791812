import React, { useEffect, useState } from 'react'
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import PasswordResetPage from './passwordResetPage';
import MfaResetPage from './mfaResetPage';

const ResetPage = props => {
const history = useHistory();

const [currentPage, setCurrentPage] = useState();

useEffect(()=>{
    const queryString = props.location.search.replace("?", "");
    const { mode, oobCode } = QueryString.parse(queryString);
    if(!mode || !oobCode){
        history.push("/");
    }

    switch (mode) {
        case "revertSecondFactorAddition":
            setCurrentPage(<MfaResetPage resetProps={props} />);
            break;
        case "resetPassword":
            setCurrentPage(<PasswordResetPage resetProps={props} />)
            break;
        default:
            history.push("/");
    }

}, []);

// /reset?mode=revertSecondFactorAddition&oobCode=1UzSkPADgnkmMp4CN6SngsuC2JnNFq9h3M6MDvHsHvgAAAF4ffXvKg

return (
    <>
        {currentPage}
    </>
);

}

export default ResetPage;