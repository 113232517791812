import axios from 'axios'
import get from 'lodash.get'
import React from 'react'

import { sendEmailService } from '../../api/apiUrls'
import Filter from './components/filter'
import Icon from './components/wrappedComponents/icon'
import { openNotificationWithIcon } from './components/wrappedComponents/notification'
import { getServiceUrl } from '../../util/util'

// set sorting props for the column that have key: sorting true
const activateSortingProps = (sorting) =>
  sorting && {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  }

// set filter props for the column that have key: filter true
const activateFilterProps = ({
  col,
  handleSearch,
  filterVariables,
  menuItems,
  resetFilter,
}) =>
  col.filter && {
    filterDropdown: (props) => (
      <Filter
        handleSearch={handleSearch}
        filterVariables={filterVariables}
        menuItems={menuItems}
        resetFilter={resetFilter}
        {...props}
        {...col}
      />
    ),
    // If I use my own wrapped Icon from Ant Design the filter does not get visible
    filterIcon: () => {
      const filterIsActive = get(filterVariables, col.filterKey, [])

      return (
        <Icon
          type="filter"
          style={{
            color: filterIsActive?.length > 0 ? 'white' : undefined,
            backgroundColor: filterIsActive?.length > 0 ? '#7cbc20' : undefined,
          }}
        />
      )
    },
    onFilter: (value, record) => {
      const getValue = get(record, col.dataIndex, {})

      return getValue
        ?.toString()
        ?.toLowerCase()
        .includes(value.toLowerCase())
    },
  }

export const overwriteColumnsProps = ({
  columns,
  handleSave,
  menuItems,
  setIsCommentModalVisible,
  handleSearch,
  resetFilter,
  filterVariables,
  setIsUserInfoModalVisible,
}) =>
  columns.map((col) => ({
    ...col,
    // here you can overwrite column props if necessary
    // example: the filter and sorting props of the column are overwritten here
    ...activateFilterProps({
      col,
      handleSearch,
      filterVariables,
      menuItems: menuItems.filter((item) => item.__typename === col.menuType),
      resetFilter,
    }),
    ...activateSortingProps(col.sorting),
    // overwrite cell props
    onCell: (rowData) => ({
      rowData,
      editable: col.editable ?? false,
      dataIndex: col.dataIndex,
      title: col.title,
      component: col.component,
      handleSave,
      required: col.required ?? false,
      menuItems,
      menuType: col.menuType,
      ...(col.component === 'Modal' && {
        onClick: (e) => {
          e.stopPropagation()
          setIsUserInfoModalVisible({
            rowData,
          })
        },
      }),
      ...(col.component === 'ModalForm' && {
        onClick: (e) => {
          e.stopPropagation()
          setIsCommentModalVisible({
            rowData,
            dataIndex: col.dataIndex,
          })
        },
      }),
    }),
  }))

export const sendEmail = async ({
  to,
  cc,
  text,
  html,
  subject,
  headers,
  fromAddress = immatMail,
}) => {
  try {
    const result = await axios.post(
      sendEmailService,
      {
        to,
        cc,
        text,
        subject,
        html,
        fromAddress,
      },
      { headers }
    )

    if (result.status === 200) {
      openNotificationWithIcon({
        type: 'success',
        message: 'De mail is succesvol verzonden.',
      })
    }
  } catch (emailError) {
    console.error('email error: ', emailError)
    openNotificationWithIcon({
      type: 'error',
      message:
        'Er was een probleem tijdens het verzenden van de email. Probeer het later nog eens aub.',
      description: emailError.message,
      duration: 0,
    })

    return
  }
}

export const sendFBUsersEmail = async ({
  to,
  cc,
  text,
  html,
  subject,
  headers,
  fromAddress = immatMail,
  companyId,
  token,
}) => {
  try {
    const url = await getServiceUrl('handleSendFBUsersMail', token)

    const result = await axios.post(
      url,
      {
        to,
        cc,
        text,
        subject,
        html,
        fromAddress,
        companyId,
      },
      { headers }
    )

    if (result.status === 200) {
      openNotificationWithIcon({
        type: 'success',
        message: 'De mail is succesvol verzonden.',
      })
    }
  } catch (emailError) {
    console.error('email error: ', emailError)
    openNotificationWithIcon({
      type: 'error',
      message:
        'Er was een probleem tijdens het verzenden van de emails naar de gerelateerde company users. Probeer het later nog eens aub.',
      description: emailError.message,
      duration: 0,
    })

    return
  }
}

export const getSortOrder = {
  ascend: 'asc',
  descend: 'desc',
}

export const noop = () => undefined

export const templatesPreRegistration = {
  nl:
    "<p>Beste dealer, <br><br> Het framenummer <<frame_number>> is nog niet gepreregistreerd. <br>Kunt u alstublieft de nodige actie ondernemen zodat we de nummerplaat kunnen aanvragen. <br>Laat het ons weten via <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> wanneer de preregistratie klaar is.</p>",
  fr:
    "<p>Cher concessionnaire, <br><br>Le numéro de cadre <<frame_number>> n'a pas encore été pré-enregistré. <br>Pouvez-vous s'il vous plaît faire le nécessaire afin que nous puissions faire la demande de plaque d'immatriculation. <br>Veuillez nous informer à l'adresse <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> lorsque le pré-enregistrement est en ordre.</p>",
  en:
    "<p><p>Dear dealer, <br><br>Framenumber <<frame_number>> still needs a preregistration. <br>Could you please take the necessary action so that we can apply for the license plate. <br>Please keep us informed at <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> when the preregistration has been done.</p>",
}

export const templatesPreRegistrationOlderThenTwoYears = {
  nl:
    "<p>Beste dealer, <br><br> De preregistratie en/of de fiets van het framenummer <<frame_number>> is ouder dan 2 jaar. Om de fiets in te schrijven via de DIV, hebben we ook het volgende nodig: <br> <br>- Een gedateerde en ondertekende verklaring, met stempel en vermelding van het framenummer, dat de fiets nieuw is (uit stock) met 0 km op de teller. <br> <br>Stuur dit document naar <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> en wij doen het nodige om de nummerplaat aan te vragen.",
  fr:
    "<p>Cher dealer, <br><br>Le pré-enregistrement et/ou le vélo du numéro de cadre <<frame_number>> a plus de 2 ans. Afin d'inscrire le vélo par le biais de la DIV, nous avons également besoin du document suivant: <br> <br>- Une déclaration datée et signée, portant le cachet et le numéro du cadre, attestant que le vélo est neuf (de stock) et que le compteur affiche 0 km. <br> <br>Envoyez-nous ce document à <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> et nous ferons le nécessaire pour faire la demande de plaque d'immatriculation.",
  en:
    "<p>Dear dealer, <br><br>The pre-registration and/or the bicycle of the frame number <<frame_number>> is older than 2 years. In order to register the bike through the DIV service, we also require the following: <br> <br>- A dated and signed declaration, stamped and mention of the frame number, stating that the bike is new (from stock) with 0 km mileage. <br> <br>Please send this document to <a href='mailto:<<immat_mail>>'> <<immat_mail>> </a> and we will do the necessary to request the license plate.",
}

export const subjectPreregistration = {
  nl: 'B2Bike - Preregistratie Q<<quotationId>>',
  fr: 'B2Bike - pré-enregistrement Q<<quotationId>>',
  en: 'B2Bike - Preregistration Q<<quotationId>>',
}

export const subjectPreregistrationOlderThanTwoYears = {
  nl: 'B2Bike - Preregistratie Q<<quotationId>> (Ouder dan 2 jaar)',
  fr: 'B2Bike - pré-enregistrement Q<<quotationId>> (Plus de 2 ans)',
  en: 'B2Bike - Preregistration Q<<quotationId>> (Older than 2 years)',
}

/*
 * stringToReplace: "beste <<Dealer_name>>, at <<Dealer_address>>. The id is: <<Dealer_id>>"
 * object: {
 *   Dealer_name: "Cogetix",
 *   Dealer_address: "Stabroek",
 *   Dealer_id: "1"
 * }
 * result: => "beste Cogetix, at Stabroek. The id is: 1"
 */
export const replaceValuesInStringByObject = ({
  stringToReplace,
  dataToChange,
}) => {
  Object.entries(dataToChange).forEach((x) => {
    stringToReplace = stringToReplace.replaceAll(`<<${x[0]}>>`, x[1])
  })

  return stringToReplace
}

export const templatesLicenseNumberSignedup = (dealer) => ({
  nl: `<p>Beste ${
    dealer ? 'dealer' : 'werkgever'
  }, <br><br>De nummerplaat voor Q<<quotation_Id>> is nu succesvol ingeschreven: <<license_number>>.</p>`,
  fr: `<p>Cher ${
    dealer ? 'revendeur' : 'employeur'
  }, <br><br>La plaque d'immatriculation pour Q<<quotation_Id>> a été enregistrée: <<license_number>>.</p>`,
  en: `<p>Dear ${
    dealer ? 'dealer' : 'employer'
  }, <br><br>The license plate for Q<<quotation_Id>> is succesfully registered: <<license_number>>.</p>`,
})

export const templatesLicenseNumberSignedupSubject = {
  nl: 'B2Bike - Nummerplaat Q<<quotation_Id>> is ingeschreven',
  fr: "B2Bike - Plaque d'immatriculation Q<<quotation_Id>> enregistrée",
  en: 'B2Bike - License plate Q<<quotation_Id>> is registered',
}

export const immatMail = 'immat@b2bike.be'
