import React, { useState, useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table, Button, Icon, Modal, Input, Select, Form, Spin, Row, Col, message, Popconfirm } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const TemplateSettings = props => {

  const { Option } = Select;

  const templateModel = {
    name: '',
    file_id: '',
    language_fk: null,
    variant_fk: null,
    order_log_type_fk: null,
    isNew: true
  };

  const [selectedTemplate, setSelectedTemplate] = useState({ ...templateModel })
  const [currentTemplates, setCurrentTemplates] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [orderLogTypes, setOrderLogTypes] = useState(null);
  const [variants, setVariants] = useState(null);
  const [inputModalVisible, setInputModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [working, setWorking] = useState(false);

  const [createTemplate] = useMutation(QUERIES.CREATE_TEMPLATE);
  const [updateTemplate] = useMutation(QUERIES.UPDATE_TEMPLATE);
  const [deleteTemplate] = useMutation(QUERIES.DELETE_TEMPLATE);


  const onMutationComplete = () => {
    message.success('Wijzigingen opgeslagen!');
    refetch()
      .then(res => {
        setCurrentTemplates(res.data.templates);
        setInputModalVisible(false);
        setWorking(false);
      })
  }

  const onMutationFailed = err => {
    message.error('Er ging iets mis');
    setInputModalVisible(false);
    setWorking(false);
    console.error(err);
  }

  const { error, loading, refetch } = useQuery(QUERIES.TEMPLATE_SETTINGS, {
    onCompleted: res => {
      setCurrentTemplates(res.templates);
      setLanguages(res.languages);
      setVariants(res.variants);
      setOrderLogTypes(res.orderLogTypes);
    }
  });

  let searchInput = null;
  const searchColumn = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={'Zoeken'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="filter"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          filter
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: text => (
    //   <Highlighter
    //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //     searchWords={[searchTerm]}
    //     autoEscape
    //     textToHighlight={text.toString()}
    //   />
    // ),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchTerm(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchTerm('');
  };


  const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      render: (text, record) => record.name,
      width: '15%',
      onFilter: (value, record) => record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter: (a, b) => a.name > b.name ? -1 : 1,
      sortDirections: ['descend', 'ascend'],
      ...searchColumn('name')
    },
    {
      title: 'File ID',
      render: record => record.file_id,
      width: '25%'
    },
    {
      title: 'Taal',
      render: record => record.language.name,
      width: '15%'
    },
    {
      title: 'Bestandstype',
      sorter: (a, b) => a.orderLogType.name > b.orderLogType.name ? -1 : 1,
      sortDirections: ['descend', 'ascend'],
      render: record => record.orderLogType.name
    },
    {
      title: 'Variant',
      render: record => record.variant.name
    },
    {
      render: record => {
        return (<span>
          <Button
            shape='circle'
            style={{ marginRight: '8px' }}
            onClick={() => {
              setSelectedTemplate({
                Id: record.Id,
                name: record.name,
                language_fk: record.language.Id,
                order_log_type_fk: record.orderLogType.id,
                file_id: record.file_id,
                variant_fk: record.variant.Id
              });
              setInputModalVisible(true);
            }}
          ><Icon type="edit" /></Button>
            <Popconfirm
            title="Template verwijderen?"
            onConfirm={() => {
              setWorking(true);
              deleteTemplate({ variables: { Id: record.Id } })
                .then(res => {
                  onMutationComplete();
                })
                .catch(err => {
                  onMutationFailed(err);
                })
            }}
            okText="Ja"
            cancelText="Nee"
          >
          <Button
            shape='circle'
            type='danger'
            style={{ marginRight: '8px' }}
          ><Icon type="delete" /></Button>
          </Popconfirm>
            <Button
              shape='circle'
              style={{ marginRight: '8px' }}
              onClick={() => {
                window.open(`https://drive.google.com/file/d/${record.file_id}`, 'blank')
              }}
            ><Icon type="eye" /></Button>
        </span>)
      }
    }
  ];

  if (languages && variants && orderLogTypes && currentTemplates && !working) {
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            setSelectedTemplate({ ...templateModel });
            setInputModalVisible(true);
          }}><Icon type='plus' /> Nieuw
        </Button>
        <Row type='flex' justify='center'>
          <Table
            style={{ width: '80vw', margin: 16 }}
            columns={columns}
            dataSource={currentTemplates}
            rowKey={record => record.Id}
          />
        </Row>
        <Modal
          visible={inputModalVisible}
          destroyOnClose={true}
          cancelText={'Annuleren'}
          onCancel={() => setInputModalVisible(false)}
          onOk={() => {
            {
              setWorking(true);
              if (!!selectedTemplate.isNew) {
                const { isNew, ...rest } = selectedTemplate;
                createTemplate({ variables: { template: rest } })
                  .then(res => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }
              else {
                const { __typename, ...rest } = selectedTemplate;
                updateTemplate({ variables: { template: rest } })
                  .then(res => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }
            }
            // console.log(selectedTemplate);
            // setInputModalVisible(false);
          }}
        >
          <Form>
            <Form.Item label='Naam' required={true}>
              <Input
                defaultValue={selectedTemplate.name}
                onChange={e => {
                  // console.log(e.target.value);
                  const val = DOMPurify.sanitize(e.target.value);
                  setSelectedTemplate(old => {
                    return { ...old, name: val };
                  })
                }}
              />
            </Form.Item>
            <Form.Item label='File ID' required={true}>
              <Input
                defaultValue={selectedTemplate.file_id}
                onChange={e => {
                  // console.log(e.target.value);
                  const val = DOMPurify.sanitize(e.target.value);
                  setSelectedTemplate(old => {
                    return { ...old, file_id: val };
                  })
                }}
              />
            </Form.Item>
            <Form.Item label='Taal' required={true}>
              <Select
                defaultValue={selectedTemplate.language_fk}
                onSelect={val => {
                  setSelectedTemplate(old => {
                    return { ...old, language_fk: val };
                  })

                }}>
                {languages.map((el, index) => {
                  return (
                    <Option value={el.Id} key={index}>{el.name}</Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label='Order log type' required={true}>
              <Select
                defaultValue={selectedTemplate.order_log_type_fk}
                onSelect={val => {
                  setSelectedTemplate(old => {
                    return { ...old, order_log_type_fk: val };
                  })
                }}
              >
                {orderLogTypes.map((el, index) => {
                  return (
                    <Option key={index} value={el.id}>{el.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label='Variant' required={true}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={selectedTemplate.variant_fk}
                onSelect={val => {
                  setSelectedTemplate(old => {
                    return { ...old, variant_fk: val };
                  })
                }}
              >
                {variants.map((el, index) => {
                  return (<Option key={index} value={el.Id}>{el.name}</Option>)
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }

  if (error) {
    console.error(error);
  }

  return (
    <Row type='flex' justify='center'>
      <Col span={8}>
        <Spin size='large' />
      </Col>
    </Row>
  );
};

export default TemplateSettings;