import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { useStateValue } from '../stateProvider/stateProvider';
import { QUERIES } from '../../api/queries';
import { message } from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';

const ErrorHandler =  () => {

  const [{ currentUser, currentDealer, userDetails }] = useStateValue();
  const { strings } = useI18nContext();
  // console.log(userDetails)

  useSubscription(QUERIES.ON_ERROR, {
    onSubscriptionData: (d => {
      if (d.subscriptionData.data.onError.user.email === userDetails.email) {
        if (d.subscriptionData.data.onError.message === 'template not found') {
          return message.error(getLabel(d.subscriptionData.data.onError.message, true, strings));
        }
      }
    })
  })
  
  return(
    <div>
      
    </div>
  )
}

export default ErrorHandler;