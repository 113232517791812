import React, { useState } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery } from '@apollo/react-hooks';
import { Table, Spin, Row, Col } from 'antd';

const BikeTypeSettings = props => {

  const [bikeTypes, setBikeTypes] = useState(null);

  const { loading, error, refetch } = useQuery(QUERIES.BIKE_TYPE_SETTINGS, {
    onCompleted: res => {
        setBikeTypes(res.bikeTypes);
    }
  });

  const columns = [
    {
      title: 'Type',
      render: record => record.name,
      key: record => record.id+"Name",
    },
    {
      title: 'Code',
      render: record => record.code,
      key: record => record.id+"Code",
    }
  ];

  if (!loading && !error && !!bikeTypes) {
    return (
      <div>
        <Row type='flex' justify='center'>
          <Table
            rowKey={record => record.id}
            style={{ width: '80vw', margin: 16 }}
            dataSource={bikeTypes}
            columns={columns}
          />
        </Row>
      </div>
    );
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col span={24}>
          <Spin size='large' />
        </Col>
      </Row>
    </div>
  );

};

export default BikeTypeSettings;