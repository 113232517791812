import React from 'react'
import { Form } from 'antd'
import merge from 'lodash.merge'
import set from 'lodash.set'
import get from 'lodash.get'

import CellComponents from './cellComponents'
import { EditableContext } from '../'
import Tooltip from './wrappedComponents/tooltip'
import { openNotificationWithIcon } from './wrappedComponents/notification'

const threeDots = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '175px',
  minWidth: '100px',
  height: '50px',
  textOverflow: 'ellipsis',
  display: 'table-cell',
}

const getCellComponent = (component, props, ref) => {
  switch (component) {
    case 'Dropdown':
      return <CellComponents.Dropdown ref={ref} {...props} />
    case 'DatePicker':
      return <CellComponents.DatePicker ref={ref} {...props} />
    case 'TextArea':
      return <CellComponents.TextArea ref={ref} {...props} />
    case 'TextField':
    default:
      return <CellComponents.TextField ref={ref} {...props} />
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      ref: React.createRef(),
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing
    this.setState({ editing }, () => {
      if (editing) {
        this.state.ref?.current?.focus()
      }
    })
  }

  getCellChildren = (
    component,
    menuItems = [],
    children,
    rowValueByDataIndex
  ) => {
    switch (component) {
      case 'Dropdown':
        if (menuItems.length > 0) {
          return menuItems.find((x) => x.id === rowValueByDataIndex)?.name || ''
        }
        return children
      case 'DatePicker': {
        const date = new Date(Number(children[2]))

        let updatedChildren = [...children]
        updatedChildren[2] = children[2]
          ? date.toLocaleDateString('nl-BE')
          : null

        return updatedChildren
      }
      default:
        return children
    }
  }

  save = (e) => {
    const { rowData, handleSave, dataIndex, component } = this.props

    // save value for a datepicker
    if (component === 'DatePicker') {
      this.form.setFields(
        set(this.form.getFieldsValue(), dataIndex.split('.')[0], {
          value: e ? new Date(e.format()) : null,
          errors: [],
        })
      )
    }

    // save value for a dropdown
    if (e?.id && e?.name && component !== 'DatePicker') {
      this.form.setFields(
        set(this.form.getFieldsValue(), dataIndex.split('.')[0], {
          value: e?.id,
          errors: [],
        })
      )
    }

    const key = dataIndex
    const keys = key?.split('.') ?? []

    // save value for a textfield or a textarea
    if (
      this.form.getFieldValue(keys[0]) !== undefined &&
      !(e?.id && e?.name) &&
      component !== 'DatePicker'
    ) {
      this.form.setFields(
        set(this.form.getFieldsValue(), dataIndex, {
          value: e?.id ? e : e?.target?.value ?? null,
          errors: !e?.target?.value
            ? [new Error(`${keys[keys.length - 1]} is required`)]
            : [],
        })
      )
    }

    this.form.validateFields((error, values) => {
      const areThereErrors = get(error, `${dataIndex}.errors`, false)

      // When there are errors -> skip all the rest so the user can first make an update to that one
      if (areThereErrors) {
        openNotificationWithIcon({
          type: 'error',
          message: areThereErrors[0]?.message ?? undefined,
          description:
            'Het opslaan is niet gelukt, want het veld is verplicht.',
          duration: 0,
        })
        return
      }
      if (error && error[e?.currentTarget?.id]) {
        openNotificationWithIcon({
          type: 'error',
          message: error[e?.currentTarget?.id] ?? undefined,
          duration: 0,
        })
        return
      }

      const oldValue = get(rowData, dataIndex, undefined)
      const newValue = get(values, dataIndex, undefined)

      // When old and new value is the same there does not need to be a trigger to update
      if (oldValue === newValue) {
        this.toggleEdit()
        return
      }

      this.toggleEdit()
      handleSave(merge(rowData, values))
    })
  }

  renderCell = (form) => {
    this.form = form
    const {
      children,
      dataIndex,
      rowData,
      title,
      required,
      component,
    } = this.props
    const { editing } = this.state

    const menuItemsByType = this.props.menuItems.filter(
      (item) => item.__typename === this.props.menuType
    )

    return editing && !['ModalForm', 'Modal'].includes(component) ? (
      <Form.Item style={{ minWidth: 150 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required,
              message: `${title} is required.`,
            },
          ],
          initialValue: rowData[dataIndex] || children[2],
        })(
          getCellComponent(
            component,
            {
              ...this.props,
              onPressEnter: this.save,
              onBlur: this.save,
              menuItems: this.props.menuItems,
              menuType: this.props.menuType,
              onClickAway: this.toggleEdit,
            },
            this.state.ref
          )
        )}
      </Form.Item>
    ) : (
      <Tooltip
        title={
          component === 'Modal'
            ? "Open de werknemer's gedetailleerde informatie"
            : 'Deze cel kan worden geupdate door erop te klikken'
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            minHeight: '50px',
            height: '100%',
            ...((component === 'ModalForm' ||
              component === 'Modal' ||
              component === 'TextField') &&
              threeDots),
          }}
          onClick={this.toggleEdit}
          id="Cell"
        >
          {this.getCellChildren(
            component,
            menuItemsByType,
            children,
            rowData[dataIndex]
          )}
          <p></p>
        </div>
      </Tooltip>
    )
  }

  render() {
    const {
      editable,
      dataIndex,
      title,
      rowData,
      index,
      handleSave,
      children,
      menuItems,
      menuType,
      ...restProps
    } = this.props
    return (
      <td
        onClick={this.toggleEdit}
        {...(editable && { style: { cursor: 'pointer' } })}
        {...restProps}
      >
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    )
  }
}

export default EditableCell
