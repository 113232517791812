import React, { useEffect, useState, useContext } from 'react';
import fbApp from '../firebase';
import { useStateValue } from '../components/stateProvider/stateProvider';
import axios from 'axios';
import { getServiceUrl } from '../util/util';
/**
 * auth.js
 * Listens to changes in the Firebase auth state and update the currenUser
 * and their details accordingly.
 * Exposes the current user and their details through the react context api.
 */
const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development';
const sessionCookieUrl = env === "production" ? 'https://europe-west1-b2bike-253312.cloudfunctions.net/getSessionCookie': "https://europe-west1-b2bike-253312.cloudfunctions.net/getSessionCookieStaging"

export const AuthContext = React.createContext({ currentUser: null });

export const useAuthContext = () => useContext(AuthContext);


export const AuthProvider = () => {

  // const [tokenRefreshTime, setTokenRefreshTime] = useState(null)
  const [{ currentUser, userDetails, token, idToken }, dispatch] = useStateValue();
  // const [idToken, setIdToken] = useState(null);
  const [cookieTimer, setCookieTimer] = useState(null)

  let refreshTime = null;

  useEffect(() => {
    fbApp.auth().onIdTokenChanged(user => {
      if (cookieTimer) {
        /**
         * Clear the cookie timer if it exists
         */
        clearTimeout(cookieTimer);
      }
      dispatch({ type: 'setCurrentUser', currentUser: user });
      if (!user) {
        refreshTime = null;
      }
      if (user && (!refreshTime || refreshTime < new Date().getTime())) {
        refreshTime = new Date().getTime() + 1500000;

        user.getIdToken(true).then(res => {
          /**
           * Make the user's idToken globally available when it changes
           */
          dispatch({ type: 'setIdToken', newToken: res });
        });
      }
    });
  }, []);

  /**
   * Start getting new session cookies when the idToken updates
   */
  useEffect(() => {
    getSessionCookie();
  }, [idToken])

  /**
   * Gets the sessiontoken with idToken from global state and starts a timer for the next refresh interval
   */
  const getSessionCookie = async () => {
    if (!!cookieTimer) {
      clearTimeout(cookieTimer);

    }
    if (!!idToken) {
      try {
        const cookie = await axios.post(sessionCookieUrl, {}, {
          headers: {
            'authorization': idToken
          }
        });

        dispatch({ type: 'setToken', newToken: cookie.data.token });
        getServiceUrl('manageUsers', cookie.data.token).then(url => {
          axios.post(url,
        { mode: 'READ', include: []},
        {
          headers: {
            Authorization: cookie.data.token
          }
        })
        .then(userRes => {
          setTimeout(() => {dispatch({ type: 'setUserDetails', userDetails: userRes.data.me })}, 3000)
        });
        });
        const timer = setTimeout(() => {
          return getSessionCookie();
        }, 3 * 60 * 1000);
        setCookieTimer(timer);
      } catch (e) {
        console.warn('ID token expired');
      }
    };
    return;

  }

  return null;
};