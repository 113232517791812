import React, { useState, useEffect } from 'react';
import { Spin, Modal, Button, message, Icon } from 'antd';
import { useStateValue } from '../stateProvider/stateProvider';
import { secureFetchFromDrive } from '../../api/driveFetch';
import { downloadFileFromBlob } from '../../util/util';
import { useI18nContext, getLabel } from '../../api/i18nService';

function Modalpopup({ quot, visible, callback, buttonText }) {
    const [{ token, userDetails }] = useStateValue();
    const [contentIframe, setContentIframe] = useState(<Spin />);
    const [blob, setBlob] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const { strings } = useI18nContext();



    async function waitOnFetch() {
        try {
        const file = quot.order.orderLogs.find(el => {
            if (el.orderLogType.code === 'CALCULATIONDOC_SIGNED' && !quot.order.confirmedOrder) {
                return el;
            }
            if (el.orderLogType.code === 'CALCULATIONDOC' && !quot.order.confirmedOrder) {
                return el;
            }
            if (el.orderLogType.code === 'CALCULATIONDOC_DIGITALLY_SIGNED' && !!quot.order.confirmedOrder) {
                return el;
            }
            if (el.orderLogType.code === 'CALCULATIONDOC_SIGNED' && !!quot.order.confirmedOrder) {
                if (!!quot.order.confirmedOrder.canceller_mail && !quot.order.confirmedOrder.confirmer_mail) {
                    return el;
                }
            }
        })
        const res = await secureFetchFromDrive(file.file_id, token);
        const tempBlob = new Blob([res.data], { type: "application/pdf" });
        const uri = window.URL.createObjectURL(tempBlob);
        setBlob(tempBlob);
        setContentIframe(<iframe width="100%" height="600px" src={uri} > </iframe>);
        } catch (err) {
            //No quot file available yet or communication failed
        }
    }

   

    useEffect(() => {
        if (!!quot) {
          waitOnFetch();
        }
    }, [quot]);
  
    if (!!quot) {
        return (
            <div >
                <Modal
                    title={getLabel('Berekening', true, strings)}
                    visible={visible}
                    destroyOnClose={true}
                    width="60%"
                    cancelText={getLabel('Terug', true, strings)}
                    keyboard={false}
                    footer={[
                        <Button
                        type='primary'
                        shape='circle'
                        size='large'
                        loading={!quot.id || downloading}
                        style={{float: 'left'}}
                        onClick={ () => {
                        try {
                            setDownloading(true)
                            downloadFileFromBlob(blob, `Q${quot.id}-${quot.employee.name}.pdf`);
                        }
                        catch (err) {
                            message.error(getLabel('Er ging iets mis', true, strings));
                            console.error(err);
                        }
                        finally {
                            setDownloading(false);
                        }
                        }}
                        >
                        {(quot.id && !downloading) && <Icon type="cloud-download" />}
                        </Button>,
                        <Button key="back" type="secondary" onClick={()=>{callback(false)}} >{getLabel('back', true, strings)}</Button>,
                    ]}
                    closable={false}
                >
                    {contentIframe}
                </Modal>
            </div>
        )
    } else {
        return null
    }
}

export default Modalpopup