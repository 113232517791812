import { Row, Button, Col, Modal, Icon, Menu, Dropdown, Select, Divider } from 'antd';
import { useEffect } from 'react';
import { useStateValue } from '../stateProvider/stateProvider';
import './contact.css';

const fieldStyling = {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', height: '20px'}

const Contact = () => {
    const [{ userDetails }] = useStateValue();

    useEffect(() => {
        console.log(userDetails);
    }, [userDetails]);

    if (!userDetails || !userDetails?.language) {
        return <></>
    }

    if (userDetails?.language === 'fr') {
        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Contact</h1>
                    <h3>Besoin d’aide ? Des questions ? Contactez-nous.</h3>
                    <p>Veuillez garder ces numéros strictement pour votre usage personnel et ne les transmettez PAS aux conducteurs. De cette façon, nous pouvons garder ces lignes libres pour vous autant que possible.</p>

                    {/* Internal sales */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>INTERNAL SALES</b></td>
                            <td><a href='tel:003233751189'><b className='phone-number'><Icon type='phone' /> 03 375 11 93</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Pour des questions concernant:</p></div>
                    <ul>
                        <li><div style={fieldStyling}><p>Un calcul</p> <a href="mailto:offerte@b2bike.be">offerte@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Une offre</p> <a href="mailto:offerte@b2bike.be">offerte@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Une commande</p> <a href="mailto:bestelling@b2bike.be">bestelling@b2bike.be</a></div></li>
                    </ul>

                    {/* After sales */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>AFTER SALES</b></td>
                            <td><a href='tel:03233751190'><b className='phone-number'><Icon type='phone' /> 03 375 11 94</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Pour des questions concernant:</p></div>
                    <ul>
                        <li><div style={fieldStyling}><p>Un sinistre</p> <a href="mailto:schade@b2bike.be">schade@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Une problème technique</p> <a href="mailto:aftersales@b2bike.be">aftersales@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Une fin de leasing</p> <a href="mailto:findelease@b2bike.be">findelease@b2bike.be</a></div></li>
                    </ul>

                    {/* IMMATRICULATIE */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>IMMATRICULATION</b></td>
                            <td><a href='tel:003233751191'><b className='phone-number'><Icon type='phone' /> 03 375 11 95</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Pour des questions concernant:</p> <a href="mailto:immat@b2bike.be">immat@b2bike.be</a></div>
                    <ul>
                        <li>Une inscription</li>
                        <li>Une plaque d’immatriculation</li>
                        <li>E705 ou pré-enregistrement</li>
                    </ul>

                    {/* BOEKHOUDING */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>COMPTABILITÉ</b></td>
                            <td><a href='tel:003233751192'><b className='phone-number'><Icon type='phone' /> 03 375 11 96</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Pour des questions concernant:</p> <a href="mailto:facturen@b2bike.be">facturen@b2bike.be</a></div>
                    <ul>
                        <li>Facturation</li>
                    </ul>

                </Col>
            </Row>
        )
    }

    else {
        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Contact</h1>
                    <h3>Hulp nodig? Vragen? Contacteer ons.</h3>
                    <p>Gelieve deze nummers strikt voor eigen gebruik te houden en NIET aan bestuurders door te geven. Zo kunnen we deze lijnen zoveel mogelijk voor jullie vrij houden.</p>

                    {/* Internal sales */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>INTERNAL SALES</b></td>
                            <td><a href='tel:003233751189'><b className='phone-number'><Icon type='phone' /> 03 375 11 89</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Voor vragen over:</p></div>
                    <ul>
                        <li><div style={fieldStyling}><p>Een berekening</p> <a href="mailto:offerte@b2bike.be">offerte@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Een offerte</p> <a href="mailto:offerte@b2bike.be">offerte@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Een bestelling</p> <a href="mailto:bestelling@b2bike.be">bestelling@b2bike.be</a></div></li>
                    </ul>

                    {/* After sales */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>AFTER SALES</b></td>
                            <td><a href='tel:03233751190'><b className='phone-number'><Icon type='phone' /> 03 375 11 90</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Voor vragen over:</p></div>
                    <ul>
                        <li><div style={fieldStyling}><p>Schade of diefstal</p> <a href="mailto:schade@b2bike.be">schade@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Een herstelling</p> <a href="mailto:aftersales@b2bike.be">aftersales@b2bike.be</a></div></li>
                        <li><div style={fieldStyling}><p>Einde lease</p> <a href="mailto:eindelease@b2bike.be">eindelease@b2bike.be</a></div></li>
                    </ul>

                    {/* IMMATRICULATIE */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>IMMATRICULATIE</b></td>
                            <td><a href='tel:003233751191'><b className='phone-number'><Icon type='phone' /> 03 375 11 91</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Voor vragen over:</p> <a href="mailto:immat@b2bike.be">immat@b2bike.be</a></div>
                    <ul>
                        <li>Een inschrijving</li>
                        <li>Een nummerplaat</li>
                        <li>E705 of preregistratie</li>
                    </ul>

                    {/* BOEKHOUDING */}
                    <table width="50%">
                        <tr>
                            <td width='50%'><b className='department-name'>BOEKHOUDING</b></td>
                            <td><a href='tel:003233751192'><b className='phone-number'><Icon type='phone' /> 03 375 11 92</b></a></td>
                        </tr>
                    </table>
                    <div style={fieldStyling}><p>Voor vragen over:</p> <a href="mailto:facturen@b2bike.be">facturen@b2bike.be</a></div>
                    <ul>
                        <li>Facturatie</li>
                    </ul>

                </Col>
            </Row>
        )
    }
};

export default Contact;