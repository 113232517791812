import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { Link } from 'react-router-dom';
import { Row, Col, Tree, Icon, Button, Input, Spin, Empty, Result, Tag, Select, Tooltip } from 'antd';
import { useAuthContext } from '../../auth/auth';
import { useStateValue } from '../stateProvider/stateProvider';
import { useI18nContext, getLabel } from '../../api/i18nService';
import { render } from 'less';
const { Search } = Input;
const { TreeNode } = Tree;
const Option = Select.Option;
const searchTypes = ["company", "invoice target"];

/**
 * companySearch.js
 * Queries, structures and displays companies in an easily searchable manner.
 */

const CompanySearch = props => {

  const { strings } = useI18nContext();
  const [{ company: companyContext, token, userDetails }, dispatch] = useStateValue();
  const { currentUser } = useAuthContext();
  // const [token, setToken] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchTypeIndex, setSearchTypeIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedNodes, setExpandedNodes] = useState([]);
  let content = null;
  let expanded = useRef([]);
  const _is_mounted = useRef(false);
  const [query, setQuery] = useState(props.customQuery? props.customQuery: QUERIES.COMPANIES_ALL);

  // Resets the company in global state to null - we want a blank slate in this component
  useEffect(() => {
    dispatch({
      type: 'changeCompany',
      newCompany: null
    });
  }, [dispatch]);

  // if (!!currentUser) {
  //   _is_mounted.current && setToken(token);
  // }

  // Cleanup - check whether we are mounted to avoid state updates on unmounted components
  useEffect(() => {
    _is_mounted.current = true;
    return () => {
      _is_mounted.current = false;
    }
  }, [])

  const checkChildrenAreHidden=(company)=>{
    if(company.children){
      const findHiddenChildComps = company.children.find((childComp)=>{
        return childComp.hide==false;
      });
      return !findHiddenChildComps;
    }else return true;
  }

  /* sortCompanies(res)
  Sorts all companies alphabetically and adds child companies to their parent as a child.
  The param res is the graphQL response (this is called when the query completes) and contains
  the unsorted array of companies.
  */
  const sortCompanies = unsortedCompanies => {
    // DELETE ALL CHILDREN THAT ARE RETAINED IN APOLLO CACHE
    unsortedCompanies.forEach(el => {
      delete el.children;
    });
    // Sort alphabetically here so that children will later be alphabetically sorted too
    unsortedCompanies.sort((a, b) => {
      return ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0);
    });

    // Loop through all companies
    unsortedCompanies.forEach(el => {
      // If the company has a parent, we find it
      if (el.company_parent_fk) {
        const parent = unsortedCompanies.find(obj => {
          return obj.id === el.company_parent_fk;
        });
        // ... and add the company to the children of its parent
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push({ ...el });
        // Flag the original company as redundant (don't want it, but can't delete it yet)
        el.redundant = true;
      }
    });
    const sortedCompanies = [];

    /* checkChildrenAndPush(children, comp)
      This is a tricky one. Basically copies a children array found on a redundant parent
      to its non-redundant parent (which is itself a child). Recursively checks grandchildren as well.
    */ 
    const checkChildrenAndPush = (children, comp) => {
      children.forEach(child => {
        if (child.id === comp.id) {
          child.children = comp.children;
          return;
        }
        else if (child.children) {
          checkChildrenAndPush(child.children, comp);
        }
      });
    }
    
  if (!!userDetails.companyIds){
    return null
  }
  
    // Loop through the companies again and check run checkChildrenAndPush on them
    unsortedCompanies.forEach(el => {
      if (el.children) {
        unsortedCompanies.forEach(el2 => {
          if (el2.redundant) {
            checkChildrenAndPush(el.children, el2)
          }
        });
      }
      /* All redundant (grand-)children are now added to their parent, the redundant 
        companies can be disregarded, the rest is our result.
      */
      if (!el.redundant) {
        sortedCompanies.push(el);
      }
    })
    // Remove hidden companies w/ hidden children
    const finalResult = sortedCompanies.filter(el => {
      if (!props.showHidden && el.hide && checkChildrenAreHidden(el)) {
        return false;
      }
      else return true;
    });
    // Prevent setting the state if unmounted
    _is_mounted.current && setCompanies(finalResult);

    // You made it to the end of sortCompanies... You deserve a break!
  }

  /* Recursive function that displays all children of a company, then calls itself again with each child as param, and so on...
    This is to support deeply nested company structures
  */

  const checkHighlight=(item)=>{
    if(searchTerm.trim() !== ''){
      switch (searchTypeIndex) {
        case 0:
          return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        case 1:
          return item.invoice_target?item.invoice_target.name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
        default:
          return false;
      }
    }
    return false;
  }

  const checkChildrenAndMap = company => {
    if (company.children) {
      return company.children.map((item, index) => {
        return (
          <TreeNode title={<CompanyRow item={item} token={token} callback={props.callback} showHidden={props.showHidden} settingsSource={props.settingsSource}
            higlight={checkHighlight(item)} />} key={item.id} style={{ padding: '8px', margin: '8px' }}>
            {checkChildrenAndMap(item)}
          </TreeNode>
        )
      })
    }
  }

  /* Recursive function that looks for the searchTerm (component state) in the 
    children of the param. If found, it pushes the param into the expandedArray and returns true.
    Else it keeps looking in the children of each child by calling itself.
  */
  const searchChildren = item => {
    let found = false;
    if (item.children) {
      item.children.some(child => {
        if (child.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          found = true;
          expanded.current.push(item.id);
          return true;
        }
        else {
          found = searchChildren(child);
          if (found) {
            expanded.current.push(item.id);
            return true;
          }
          return false;
        }
      })
    }
    return found;
  }

  /* filterCompanies()
    Loops thourgh the mapped children and adds them to the filteredCompanies array (component state).
    Uses the searchChildren function to do the same for all children of every company. 
    Every company that contains a child (or grandchild) that contains the searchterm
    will be added to the expanded array (component state) so that these children are also instantly
    visible.
  */
  const filterCompanies = useCallback(() => {
    if (searchTerm.trim() === '') {
      setFilteredCompanies(companies);
      setExpandedNodes([]);
    }
    else {
      expanded.current = [];
      let resultSet = [];
      companies.forEach(item => {
        if (checkHighlight(item)) { //item.name.toLowerCase().includes(searchTerm.toLowerCase())
          resultSet.push(item);
        }
        if (searchChildren(item)) {
          if (resultSet.indexOf(item) < 0) {
            resultSet.push(item);
            expanded.current.push(item.id);
          }
        }
      })
      setExpandedNodes(expanded.current);
      setFilteredCompanies(resultSet);
    }
  }, [companies, searchTerm, searchTypeIndex])


  // Refilters the companies when any of the dependencies change
  useEffect(() => {
    filterCompanies()
  }, [companies, filterCompanies, searchTerm, searchTypeIndex])

  // Perform the query and call sortCompanies on completed
  const { loading, error, data } = useQuery(query, {
    variables: { size: 9999 },
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      sortCompanies(res.companies);
    }
  });

  if (error) {
    console.error(error);
    content = (
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  }

  else if (loading) {
    content = (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    )
  }

  if (data && filteredCompanies.length > 0) {
    content = (
      <Row type='flex' justify='center' gutter={8}>
        <Tree
          style={{ width: '50vw' }}
          blockNode={true}
          switcherIcon={<Icon type="down" />}
          showIcon={false}
          expandedKeys={expandedNodes}
          onExpand={expArray => {
            setExpandedNodes([...expArray])
          }}
        >
          {filteredCompanies.map((item, index) => {
            return (
              <TreeNode
                title={
                  <CompanyRow item={item} disableKPMG={props.disableKPMG} token={token} callback={props.callback} showHidden={props.showHidden} settingsSource={props.settingsSource}
                    higlight={checkHighlight(item)} />
                }
                key={item.id} style={{ padding: '8px', margin: '8px' }}>
                {checkChildrenAndMap(item)}
              </TreeNode>
            )
          })}
        </Tree>
      </Row>)
  }

  else if (data && filterCompanies.length === 0) {
    content = (
      <Row type='flex' justify='center'>
        <Empty description={getLabel('nothing found', true, strings)} />
      </Row>
    );
  }

  const selectBefore=()=>{
    return (
    <Select defaultValue={getLabel(searchTypes[searchTypeIndex], true, strings)} style={{ width: 80 }} onChange={val=>setSearchTypeIndex(val)}>
      {searchTypes.map((item, index)=>
        <Option value={index} key={`searchType_${index}`}>{getLabel(item, true, strings)}</Option>
      )}
    </Select>
    )};

  return (
    <div>
      <Row type='flex' justify="center" gutter={16}>
        <Col span={8}>
          <Search
            addonBefore={props.settingsSource?selectBefore():(null)}
            onSearch={val => { setSearchTerm(val) }}
            enterButton
            size='large'
            placeholder={getLabel(searchTypes[searchTypeIndex], true, strings)} />
        </Col>
      </Row>
      {content}
    </div>
  );
}

/**
 * CompanyRow
 * Inner UI Component representing a company row (pretty self-documenting...)
 */
const checkHasOwnCalculationSheet=(settingsSource, companyItem)=>{
  // if((!!settingsSource && !!companyItem.calculation_sheet && !!companyItem.parentCompany && !!companyItem.parentCompany.calculation_sheet && companyItem.calculation_sheet != companyItem.parentCompany.calculation_sheet) ){
  //   return (
  //     <Tag color="green">S</Tag>
  //   );
  // }
  if (!settingsSource) {
    return;
  }
  if (!companyItem.calculation_sheet) {
    return;
  }
  if (companyItem.parentCompany) {
    if (companyItem.parentCompany.calculation_sheet === companyItem.calculation_sheet) {
      return;
    }
  }
  return (
        <Tag color="green">S</Tag>
      );
}

const checkFip = (companyItem) => {
  if (!!companyItem.fip) {
    return (<Tag color="purple">FIP</Tag>)
  }
}

const CompanyRow = props => {
  const { strings } = useI18nContext();
  const isDisabled = () => {
    return (!props.showHidden && props.item.hide) || (props.disableKPMG && !!props.item.kpmg_integration_partner)
  }
  return (
    <Tooltip title={(props.disableKPMG && !!props.item.kpmg_integration_partner)? getLabel('employee should use kpmg portal', true, strings): ""} >
    <Row type='flex' justify='space-between' align='middle' gutter={8}
      style={{ padding: '4px', borderRadius: '5px', 
      boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.1)', 
      backgroundColor: (props.item.hide || isDisabled())? 'rgba(0,0,0,0)' : (props.higlight ? '#edfad9' : '#fefefe'),
      cursor: isDisabled() && 'not-allowed'
    }}
      onClick={() => {
        if  (isDisabled()) {
          return;
        }
        props.callback(props.item)
      }}>
      <Col span={16} >
    <h3 style={{ margin: '0px' }}>{props.item.name} {checkHasOwnCalculationSheet(props.settingsSource, props.item)} {checkFip(props.item)}</h3>
      </Col>
      <Col span={6} >
        <h5 style={{ margin: '0px', color: '#a1a1a1', textOverflow: 'ellipsis', overflow: 'hidden' }}>{props?.item?.invoice_target?.name}</h5>
      </Col>
    </Row>
    </Tooltip>
  )
}

export default CompanySearch;