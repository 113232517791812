import React, { useState, useEffect, useRef } from 'react';
import DealerManagement from '../dealerManagement/dealerManagement';
import { useStateValue } from '../stateProvider/stateProvider';

const UserManagement = props => {

    const [{ currentUser, userDetails }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: 'setCurrentEmployersPage', page: 'userManagement' })
    }, [])

    return(
        <>
        <DealerManagement onEmployersPortal={true} />
        </>
    );
}

export default UserManagement;