import React, { useState, useEffect } from 'react';
import { useQuery, useSubscription, useLazyQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { useStateValue } from '../stateProvider/stateProvider';
import { Spin, Row, Col, Button, Icon, Switch, Modal, Empty } from 'antd';
import QuotationCard from '../quotationCard/quotationCard';
import QuotationNew from '../quotationNew/quotationNew';
import { useI18nContext, getLabel } from '../../api/i18nService';
import Bugsnag from '@bugsnag/js'


const QuotationOveriew = props => {
  const { strings } = useI18nContext();
  const [quotations, setQuotations] = useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const [newQuotationVisible, setNewQuotationVisible] = useState(false);
  const [{dealerIndex, currentDealer, currentUser, userDetails, tempQuotation, tempQuotationFields}, dispatch] = useStateValue();
  const [selectedQuotationFields, setSelectedQuotationFields] = useState(null);
  const [selectedBikeDetails, setSelectedBikeDetails] = useState(null);

  const [getCompany, { loading: companyLoading, data: companyData}] = useLazyQuery(QUERIES.COMPANY_INFO_FICHE)

  useEffect(() => {
    // console.log('current user: ', userDetails);
    if (!!tempQuotation && !!tempQuotationFields) {
      if (tempQuotation.employee_fk === props.match.params.employeeId) {
        setNewQuotationVisible(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log('company data in: ', companyData);
  // }, [companyData])

  const { loading, error, data, refetch, startPolling } = useQuery(QUERIES.QUOTATIONS_AND_ORDERS_FOR_EMPLOYEE, {
    variables: {
      employeeId: props.match.params.employeeId,
      dealerId: currentDealer? currentDealer.id: null
    },
    fetchPolicy: 'network-only',
    // pollInterval: 500,
    onCompleted: data => {
      // console.log('[qoutationOverview] - polled');
      if (!data) {
        // console.log('[qoutationOverview] - no data obj in res');
        return;
      }
      // startPolling(5000)
      setQuotations(data.employee.quotations);
    }
  });

  /*
  useSubscription(quotationCreatedSub, {
    onSubscriptionData: data => {
      const d = data.subscriptionData.data.quotationCreated || data.subscriptionData.data.quotationCreatedTest;
      console.log('q create sub', d)
      if (d.employee_fk === props.match.params.employeeId) {
        refetch().then(res => {
          setQuotations(res.data.employee.quotations);
        })
      }
    }
  });

  useSubscription(quotationUpdatedSub, {
    onSubscriptionData: subData => {
      if (quotations) {
        const d = subData.subscriptionData.data.quotationUpdated || subData.subscriptionData.data.quotationUpdatedTest;
        console.log('q update sub', d)
        const subId = d.id;
        if (quotations.find(el => { return el.id === subId })) {
          refetch().then(res => {
            setQuotations(res.data.employee.quotations);
          })
        }
      }
    }
  });
*/

  useEffect(() => {
    if (data && data.employee) {
      // console.log('Data changed', data);
      if (data.employee.quotations) {
        setQuotations(data.employee.quotations);
      }
      if (data.employee.company_fk) {
        // console.log('EmployeeFk: ', data.employee.company_fk);
        getCompany({variables: {id: data.employee.company_fk}})
        dispatch({
          type: 'changeCompany',
          newCompany: { id: data.employee.company_fk }
        });
      }
    }
  }, [dispatch, data]);

  const newQuotationModal = () => {
    if (data && companyData) {
      return (
        <Modal
          width={720}
          visible={newQuotationVisible}
          footer={null}
          closable={false}
          destroyOnClose={true}
        >
          <QuotationNew companyId={data.employee.company_fk}
            speedPedelecInfo={{ ...data.employee, quotations: null }}
            invoiceTargetInfo={{ ...data.employee, ...companyData.company }}
            employeeId={data.employee.id}
            onClose={() => { setNewQuotationVisible(false) }}
            onSave={(res) => {
              // console.log('[quotationOverview] - onSave New - res: ', res);
              refetch().then(quoteRes => {
                // console.log('[quotationOverview] - onSave New - res: ', quoteRes);
                setNewQuotationVisible(false);
                setQuotations(quoteRes.data.employee.quotations);
              })
            }}
            fields={selectedQuotationFields}
            bikeDetails={selectedBikeDetails}
            history={props.history} />
        </Modal>
      )
    }
  };

  let content = null;

  if (!!userDetails.companyIds){
    return null
  }
  
  if (error) {
    console.error(error);
    content = (<h1>{getLabel('error', true, strings)}</h1>);
  }

  if (loading) {
    content = (
      <Row type='flex' justify='center' gutter={16}>
        <Col style={{ marginTop: '128px' }} span={1}>
          <Spin size='large'></Spin>
        </Col>
      </Row>);
  }
  if (data && quotations && companyData) {
    if (quotations.length === 0) {
      content = (
        <Empty
        style={{marginTop:'128px'}} description={getLabel('no quotations', true, strings)}></Empty>
      );
    }
    else {
      content = (
        <Row type='flex' justify='center' gutter={8} style={{ padding: '0px 2vw', marginTop: '16px' }}>
          {quotations.map((item, index) => {
            // console.log('companyData: ', companyData);
            if (!showInactive && !item.active) return null;
            return (<Col key={index} span={8}>
              <QuotationCard
                key={item.id}
                company={companyData.company}
                id={item.id}
                highlight={props.match.params.quotationId ? (props.match.params.quotationId === item.id) : false}
                onDuplicate={(fields, details) => {
                  // console.log('QuotationOverview - onDuplicate', fields);
                  setSelectedQuotationFields(fields);
                  setSelectedBikeDetails(details);
                  setNewQuotationVisible(true);
                }}
              />
            </Col>)
          })}
        </Row>
      );
    }
  }

  return (
    <div>
      <Row type='flex' justify='space-between'>
        <Button onClick={evt => {
          props.history.goBack();
        }}><Icon type='left' /></Button>
        <Switch
          checkedChildren={getLabel('alle offertes', true, strings)}
          unCheckedChildren={getLabel('active', true, strings)}
          checked={showInactive}
          onChange={
            checked => {
              setShowInactive(checked);
            }
          }
        />
      </Row>
      {content}
      {newQuotationModal()}
      <Button
        disabled={!data || data?.employee?.company?.hide}
        type="primary" shape="circle" icon="plus"
        size='large' style={{
          position: 'fixed', bottom: '32px', right: '32px',
          height: '64px', width: '64px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
        }}
        onClick={() => {
          setSelectedQuotationFields(null);
          setSelectedBikeDetails(null);
          setNewQuotationVisible(true);
        }} />
    </div>
  )
}

export default QuotationOveriew;