import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useStateValue } from '../components/stateProvider/stateProvider';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { concat, split } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { InMemoryCache, ApolloLink } from 'apollo-boost';
import { apiHttpUrlLocal, apiHttpUrlProd, apiHttpUrlDev, apiHttpUrlStaging, apiWsUrl } from './apiUrls';
import { createHttpLink } from 'apollo-link-http';
import { BatchHttpLink } from "apollo-link-batch-http";

export const ApolloWrapper = ({ children }) => {
  // const apiUrl = apiHttpUrl;
  const [{ token, idToken }] = useStateValue();
  const [client, setClient] = useState(null);
  const [apolloLink, setAppoloLink] = useState(null);
  const [hasSessionToken, setHasSessionToken] = useState(false)

  useEffect(() => {
    /**
     * Recreate the apolloclient when the user's idToken changes
     */
     createApolloClient();
}, [idToken]);

useEffect(() => {
  /**
   * Recreate client if the session token is available for the first time
   */
  if (!!token && !hasSessionToken) {
    if (localStorage.getItem('sessionToken')) {
      createApolloClient();
      setHasSessionToken(true);
    }
  }
}, [token])

const createApolloClient = () => {
   let wsLink = new WebSocketLink({
    uri: apiWsUrl,
    options: {
      reconnect: false
    }
  });
  let envHeader = (process.env.REACT_APP_ENV === 'staging'? 'test': 'prod');
  let apiUrl = null;
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      apiUrl = apiHttpUrlDev;
      break;
    case 'staging':
      apiUrl = apiHttpUrlStaging;
      break;
    case 'production':
      apiUrl = apiHttpUrlProd;
      break;

  }
  if (!process.env.REACT_APP_ENV) {
    envHeader = 'dev';
    apiUrl = apiHttpUrlLocal;
    // envHeader = 'prod';
  }
  // Create HTTP link for all other GraphQL requests
  let httpLink = createHttpLink({
    uri: apiUrl,
  });

  // Create a dynamic link that picks ws or HTTP depending on the nature of the query
  let link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );
  /**
   * Middleware that adds the headers on a per-request basis
   */
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: localStorage.getItem('sessionToken'),
        env: envHeader
      }
    });
  
    return forward(operation);
  })

  /**
   * Create the actual apolloClient with split link (ws + http) and middleware
   */
  const c = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, link),
  });
  setClient(c);
}

  if (!client) {
    /**
     * Dummy client for default un-authenticated requests (e.g. login page)
     */
    return (<ApolloProvider client={new ApolloClient({
      link: createHttpLink({
        uri: '',
        fetch: () => {
          return new Promise((resolve, reject) => {
            return resolve({text: ''});
          })
        }
      }),
      cache: new InMemoryCache()
    })}>
      {children}
    </ApolloProvider>
  )
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};