import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message, Select, Result, Spin } from 'antd';
import { getLabel, useI18nContext } from '../../api/i18nService';
import Title from 'antd/lib/typography/Title';
import { validateEmail } from '../../util/util';
import axios from 'axios';
import { registerDealerService } from '../../api/apiUrls';
import DOMPurify from 'dompurify';
const { Option } = Select;

const Register = () => {
  const {strings} = useI18nContext();
  const [email, setEmail] = useState('');
  const [dealerName, setDealerName] = useState('');
  const [dealerPhone, setDealerPhone] = useState('');
  const [dealerLocation, setDealerLocation] = useState('');
  const [dealerLanguage, setDealerLanguage] = useState('');
  const [hasRegistered, setHasRegistered] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const registerDealer = async e => {
    e.preventDefault();
    if (validateEmail(email)) {
      setIsWorking(true);
      try {
        await axios.post(registerDealerService, { dealer: {
          dealerName: dealerName,
          dealerLocation: dealerLocation,
          email: email,
          dealerPhone: dealerPhone,
          dealerLanguage: dealerLanguage
        }});
        setHasRegistered(true);
      }
      catch (emailError) {
        return message.error(getLabel('Aanvraag tot registratie mislukt', true, strings));
      }
      setIsWorking(false);
    }
    else {
      return message.error(getLabel('email invalid', true, strings));
    }
  }

  let content = null;
    if (!hasRegistered && !isWorking) {
      content = (
        <Form onSubmit={e => {registerDealer(e)}}>
            <Form.Item label={getLabel('email', true, strings)}>
            <Input placeholder={getLabel('email', true, strings)}
            onChange={e => setEmail(DOMPurify.sanitize(e.target.value))}
            value={email}
            />
            </Form.Item>
            <Form.Item label={getLabel('dealer name', true, strings)}>
            <Input placeholder={getLabel('dealer name', true, strings)}
            onChange={e => setDealerName(DOMPurify.sanitize(e.target.value))}
            value={dealerName}
            />
            </Form.Item>
            <Form.Item label={getLabel('tel', true, strings)}>
            <Input placeholder={getLabel('tel', true, strings)}
            onChange={e => setDealerPhone(DOMPurify.sanitize(e.target.value))}
            value={dealerPhone}
            />
            </Form.Item>
            <Form.Item label={getLabel('city', true, strings)}>
            <Input placeholder={getLabel('city', true, strings)}
            onChange={e => setDealerLocation(DOMPurify.sanitize(e.target.value))}
            value={dealerLocation}
            />
            </Form.Item>
            <Form.Item label={getLabel('language', true, strings)}>
              <Select value={dealerLanguage} onChange={val => {setDealerLanguage(DOMPurify.sanitize(val))}}>
                <Option value='nl'>Nederlands</Option>
                <Option value='fr'>Français</Option>
              </Select>
            </Form.Item>
            <Button type='primary' htmlType='submit' loading={false} 
                disabled={(email === '') || (dealerName === '')} style={{float: 'right'}} >
                  {getLabel('register', true, strings)}
                </Button>
          </Form>
      );
    }
    else if (hasRegistered && !isWorking) {
      content = (
        <Result
        status="success"
        title={getLabel('thank you', true, strings)}
        subTitle={getLabel('your request will be processed', true, strings)}
      />
      );
    }

    if (isWorking) {
      content = (
        <Row type='flex' justify='center'>
          <Spin size='large'/>
        </Row>
      )
    }

    return (
      <Row type="flex" justify="space-around" gutter={16} style={{padding: '16px', height: '85vh'}}>
         <Col span={10}>
            {/* <img src='/res/img/login-img.jpg' style={{width: '100%', height: '100%'}} alt=''/> */}
            <div style={{height: '100%', width: '100%', 
            backgroundImage: 'url(/res/img/login_img.jpg)',
            backgroundRepeat: 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center'}}></div>
          </Col>
        <Col span={10}>
        <Title level={3}>{getLabel('register', true, strings)}</Title>
          {content}
        </Col>
      </Row>
    );
}

export default Register;