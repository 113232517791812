import React, { useState } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table, Spin, Row, Col, Popconfirm, Button, Icon, message, Modal, Form, Input } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const EmployeeStatuteSettings = props => {

  const [employeeStatutes, setEmployeeStatutes] = useState(null);
  const [working, setWorking] = useState(false);
  const [selectedEmployeeStatute, setSelectedEmployeeStatute] = useState(null);
  const [inputModalVisible, setInputModalVisible] = useState(false);

  const [createEmployeeStatute] = useMutation(QUERIES.CREATE_EMPLOYEE_STATUTES);
  const [updateEmployeeStatute] = useMutation(QUERIES.UPDATE_EMPLOYEE_STATUTES);

  const { loading, error, refetch } = useQuery(QUERIES.EMPLOYEE_STATUTES, {
    onCompleted: res => {
      setEmployeeStatutes(res.employeeStatutes);
    }
  });

  const employeeStatuteModel = {
    name: '',
    code: '',
    isNew: true
  };

  const onMutationComplete = () => {
    message.success('Wijzigingen opgeslagen!');
    refetch()
      .then(res => {
        setEmployeeStatutes(res.data.employeeStatutes);
        setInputModalVisible(false);
        setWorking(false);
      })
  }

  const onMutationFailed = err => {
    message.error('Er ging iets mis');
    setInputModalVisible(false);
    setWorking(false);
    console.error(err);
  }

  const columns = [
    {
      title: 'Statuut',
      render: record => record.name,
      key: record => record.id+"Name",
    },
    {
      title: 'Code',
      render: record => record.code,
      key: record => record.id+"Code",
    },
    {
      render: record => {
        return(
            <span>
              <Button
              shape='circle'
              style={{ marginRight: '8px' }}
              onClick={() => {
                setSelectedEmployeeStatute(record);
                setInputModalVisible(true);
              }}
            ><Icon type="edit" /></Button>
            </span>
        )
      }
    }
  ];

  if (!loading && !error && !!employeeStatutes && !working) {
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            setSelectedEmployeeStatute({ ...employeeStatuteModel });
            setInputModalVisible(true);
          }}><Icon type='plus' /> Nieuw
        </Button>

        <Row type='flex' justify='center'>
          <Table
            rowKey={record => record.id}
            style={{ width: '80vw', margin: 16 }}
            dataSource={employeeStatutes}
            columns={columns}
          />
        </Row>

        <Modal
          visible={inputModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setWorking(true);
            if (!!selectedEmployeeStatute.isNew) {
              const { isNew, ...rest } = selectedEmployeeStatute;
              createEmployeeStatute({ variables: { employeeStatute: rest } })
                .then(res => {
                  onMutationComplete();
                })
                .catch(err => {
                  onMutationFailed(err);
                })
            }
            else {
              const { __typename, ...rest } = selectedEmployeeStatute;
              updateEmployeeStatute({ variables: { employeeStatute: rest } })
                .then(res => {
                  onMutationComplete();
                })
                .catch(err => {
                  onMutationFailed(err);
                })
            }
          }}
          onCancel={() => {
            setInputModalVisible(false);
          }}
          cancelText={'Annuleren'}
        >
          {selectedEmployeeStatute && (
            <Form>
              <Form.Item required={true} label='Naam'>
                <Input
                  defaultValue={selectedEmployeeStatute.name}
                  onChange={e => {
                    const val = DOMPurify.sanitize(e.target.value);
                    setSelectedEmployeeStatute(old => {
                      return { ...old, name: val };
                    });
                  }} />
              </Form.Item>
              <Form.Item required={true} label='Code'>
                <Input
                  defaultValue={selectedEmployeeStatute.code}
                  onChange={e => {
                    const val = DOMPurify.sanitize(e.target.value);
                    // console.log(val);
                    setSelectedEmployeeStatute(old => {
                      return { ...old, code: val };
                    });
                  }} />
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>
    );
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col span={24}>
          <Spin size='large' />
        </Col>
      </Row>
    </div>
  );

};

export default EmployeeStatuteSettings;