import axios from 'axios';
import { getServiceUrl } from '../util/util';
// import { fetchFromDrive } from './apiUrls';

/**
 * driveFetch.js
 * Small service that consumes the fetchFromDrive microService.
 * 
 * **** NOTE ****
 * Please use secureFetchFromDrive whenever possible, as FetchFromDrive (regular) performs 
 * a GET-request an send the accesstoken in clear text
 */

// Via GET (use if all else fails)
const FetchFromDrive = (id, token) => {
  return getServiceUrl('fetchFromDrive', token).then(url => {
    return `${url}?fileId=${id}&auth=${token}`;
  })
};

// Via POST with access token in header (Strongly recommended)
export const secureFetchFromDrive = async (fileId, token) => {
  const url = await getServiceUrl('fetchFromDrive', token);
  let result = null
  try {
    result = await axios.post(url, { fileId: fileId }, {
      responseType: 'arraybuffer',
      headers: {
        'authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With'
      }
    });
    return result;
  }
  catch (err) {
    console.error("Error fetching from Drive: ", err);
  }
  finally {
    return result;
  }
}

export default FetchFromDrive;