import React from "react";
import { Input } from "antd";

import { noop } from "../../utils";
import Tooltip from "./tooltip";

const TextField = ({
  onChange = noop,
  onBlur = noop,
  disabled = false,
  style = { minWidth: "auto" },
  size = "large",
  tooltipTitle = undefined,
  ...props
}) => (
  <Tooltip title={tooltipTitle}>
    <Input
      id={props.id}
      allowClear={props.allowClear}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      defaultValue={props.defaultValue}
      disabled={disabled}
      min={props.min}
      max={props.max}
      step={props.step}
      type={props.type}
      placeholder={props.placeholder}
      size={size}
      {...props}
    />
  </Tooltip>
);

export default TextField;
