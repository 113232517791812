import React, { useState, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond/dist/filepond.min.css";
import { Select, Card, Row, Spin, Result, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { useI18nContext, getLabel } from '../../api/i18nService';
import { titleCase } from '../../util/util';
const { Option } = Select;

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

/**
 * 
 * @param {option: Number, locked: Boolean, acceptedFileTypes: Array<String>, onFileChanged: function(file)} props 
 * 
 */

const FileUploader = props => {
  const [documentTypes, setDocumentTypes] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const locked = props.locked ? props.locked : false;
  const [value, setValue] = useState({ type: null, file: {} })

  const { strings } = useI18nContext();
  // let value =  {type: null, file: {}};

  const { loading, error, data } = useQuery(QUERIES.ORDER_LOG_TYPES, {
    onCompleted: res => {
      setDocumentTypes(res.orderLogTypes);
    }
  });

  useEffect(() => {
    if (props.customDocType) {
      setSelectedDocumentType(props.customDocType);
    }
  }, []);

  useEffect(() => {
    // console.log('FileUploader: first effect hook runs');
    if (!props.customDocType) {
      let docType = null;
    if (documentTypes) {
      if (props.option) {
        docType = documentTypes.find(el => {
          return el.code === props.option;
        })
      }
      if (!docType) {
        docType = documentTypes[0];
      }
    }
    setSelectedDocumentType(docType);
    }
  }, [documentTypes]);

  useEffect(() => {
    // console.log('FileUploader: ', selectedDocumentType);
    setValue(old => {
      return { ...old, type: selectedDocumentType };
    })
  }, [selectedDocumentType]);

  useEffect(() => {
    if (value.type) {
      props.onFileChanged(value);
    }
  }, [value, props]);

  if (error) {
    return (
      <Result
    status="error"
    title={getLabel('something went wrong', true, strings)}
    extra={
      <Link to='/'>
      <Button type="primary" key="console">
        <Icon type='home'/>
      </Button>
      </Link>
    }
  />
    );
  }

  if ((!loading && documentTypes && selectedDocumentType) || (!!props.customDocType && selectedDocumentType)) {
    // console.log(titleCase(selectedDocumentType.name).trim());
    return (
      <div style={{ width: '300px', textAlign: 'center' }}>
        <Card title={getLabel(titleCase(selectedDocumentType.name).trim(), true, strings)}>
          {(documentTypes && !props.customDocType) && (<Select style={{ margin: '8px' }}
            defaultValue={selectedDocumentType.code}
            disabled={locked}
            onChange={type => {
              setSelectedDocumentType(documentTypes.find(el => {
                return el.code === type;
              }));
              // props.onFileChanged({...value, type: type});
            }}>
            {documentTypes.map((opt, index) => {
              // console.log(getLabel(titleCase(opt.name), true, strings));
              return <Option value={opt.code} key={index}>{getLabel(titleCase(opt.name), true, strings)}</Option>
            })}
          </Select>)}
          <FilePond
            allowMultiple={false}
            allowFileTypeValidation={props.acceptedFileTypes ? true : false}
            acceptedFileTypes={props.acceptedFileTypes && props.acceptedFileTypes}
            // allowFileSizeValidation={true}
            maxFileSize='10MB'
            labelMaxFileSizeExceeded={getLabel('File too large', true, strings)}
            labelMaxFileSize='Max 10MB'
            onaddfile={
              (error, file) => {
                if (error) return console.error(error);
                //props.onFileChanged({...value, file: file});
                setValue({ ...value, file: file });
              }
            }
            onremovefile={
              () => {
                //props.onFileChanged({...value, file: null});
                setValue({ ...value, file: null });
              }
            }
            fileValidateTypeLabelExpectedTypesMap={{ 'application/pdf': 'pdf', 'image/*': '.jpg / .png' }}
            labelFileTypeNotAllowed={`Fout bestandstype`}
            fileValidateTypeLabelExpectedTypes={`Enkel ${props.acceptedFileTypes[0]}`}
          />
        </Card>
      </div>
    )
  }
  return (
    <Row type='flex' justify='center'>
      <Spin style={{ marginTop: '64px' }} />
    </Row>
  )

}

export default FileUploader;