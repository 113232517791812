import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Card, Input, Select, Form, DatePicker, Spin, Avatar,
  Button, Icon, Popconfirm, message, Modal, Result, Typography, Tooltip
} from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { QUERIES } from '../../api/queries';
import { useAuthContext } from "../../auth/auth";
import { useStateValue } from '../stateProvider/stateProvider';
import { useI18nContext, getLabel } from '../../api/i18nService';
import * as qs from 'qs';
import { validateEmail } from '../../util/util';
import CompanySearch from '../companySearch/companySearch';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const { Option } = Select;
const { Title } = Typography;
const infoCodeKey = 'infoCodeKey';

// Define a default employee
let defaultEmployee = {
  id: null,
  company_fk: null,
  employee_statute_fk: null,
  employee_title_fk: null,
  language_fk: '1',
  first_name: "",
  last_name: "",
  email: "",
  tel: "",
  employee_number: "",
  street: "",
  house_number: "",
  national_register: "",
  comment_private: "",
  comment_public: "",
  city: "",
  zip_code: "",
  custom_properties: {},
  company: {
    employeeStatutes: [{
      id: null,
      name: '',
      code: '',
    }],
    id: null,
    name: '',
    info: '',
    logo_url: null,
    custom_employee_properties: []
  },
  drivers_license_date: null,
  date_of_birth: null
};

/**
 * employeeDetail.js
 * Component for creating, displaying and editing employee.
 * Query parameters are allowed as entry point for external applications
 */

const EmployeeDetail = props => {

  const { strings } = useI18nContext();
  let content = null;
  // const [token, setToken] = useState('');
  // const { currentUser, userDetails } = useAuthContext();
  // if (!!currentUser) {
  //   currentUser.getIdToken(true).then(userToken => {
  //     setToken(userToken);
  //   });
  // }
  const [{ token, currentUser, userDetails }] = useStateValue();
  const [employee, setEmployee] = useState(defaultEmployee);
  // Define a default company
  const [company, setCompany] = useState({
    id: 0,
    name: '',
    info: '',
    employeeStatutes: [{
      id: null,
      name: '',
      code: '',
    }],
    invoice_target: {
      id: null,
      name: null
    },
    custom_employee_properties: []
  });
  const [isNew, setIsNew] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const originalEmployee = useRef(null);
  const userForm = useRef(null);
  const [showCompanySelector, setShowCompanySelector] = useState(false)
  let companies = null;
  const [{ company: companyContext, dealerIndex, currentDealer }, dispatch] = useStateValue();
  const dateFormat = 'DD/MM/YYYY';

  useEffect(() => {
    if (!props.match.params.companyId) {
      return props.history.push('/')
    }
  }, [])

  useEffect(() => {
    return function cleanup() {
      if (props.history.location.pathname.indexOf('/employee/') < 0) {
        message.destroy()
      }
    };
  });

  useEffect(() => {
    if (company) {
      dispatch({
        type: 'changeCompany',
        newCompany: company
      })
    }
  }, [company, dispatch])

  useEffect(() => {
    if (!employee.employee_statute_fk && !!company.employeeStatutes[0].id) {
      setEmployee(old => {
        return {... old, employee_statute_fk: company.employeeStatutes[0].id}
      })
    }
  }, [employee, company]);

  const getBackRoute = () => {
    if (!props.match.params.companyId) {
      return `/`;
    }
    return `/employees/${props.match.params.companyId}/`;
  }

  const checkRequiredFields = (employeeToCheck) => {
    if (!employeeToCheck.date_of_birth) {
      return false;
    }
    if (company.invoice_target.name === 'Axus') {
      if (
        !employeeToCheck.street
        || !employeeToCheck.house_number
        || !employeeToCheck.zip_code
        || !employeeToCheck.city) {
        return false;
      }
    }
    return true;
  }

  const checkCustomProps = (employeeToCheck) => {
    const requiredCustomProperties = employeeToCheck.company.custom_employee_properties.filter((property)=>property.required==true);
    let requiredPropsValid = true;
    for(const requiredProp of requiredCustomProperties){
      if(!employeeToCheck.custom_properties[requiredProp.name] || employeeToCheck.custom_properties[requiredProp.name]==='') {
          requiredPropsValid = false;
          break;
        }
    }
        return requiredPropsValid;
    // console.log(employeeToCheck);
    // if (employeeToCheck.company.custom_employee_properties) {
    //   if (employeeToCheck.company.custom_employee_properties.length > 0) {
    //     for (let pr in employeeToCheck.company.custom_employee_properties) {
    //       console
    //       if (pr.required) {
    //         console.log('gets here')
    //         if (!employeeToCheck.custom_properties) {
    //           console.log('no props')
    //           return false;
    //         }
    //         if (!employeeToCheck.custom_properties[pr.name]) {
    //           console.log('props incomplete')
    //           return false;
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log('all good')
    // return true
  }

  // Validate mandatory fields manually in order to disable the save button
  // TODO: trim() mandatory strings
  useEffect(() => {
    const requiredCustomProperties = employee.company.custom_employee_properties.filter((property)=>property.required==true);
    let requiredPropsValid = true;
    for(const requiredProp of requiredCustomProperties){
      if(!employee.custom_properties[requiredProp.name] || employee.custom_properties[requiredProp.name]==='') {
          requiredPropsValid = false;
          break;
        }
    }
    
    if (!employee.first_name || employee.first_name === ''
      || !employee.last_name || employee.last_name === ''
      || !employee.email || employee.email === ''
      || !employee.tel || employee.tel === ''
      || !employee.employee_statute_fk || employee.employee_statute_fk === ''
      || !employee.language_fk || !employee.date_of_birth
      || !employee.street || employee.street === ''
      || !employee.house_number || employee.house_number === ''
      || !employee.zip_code || employee.zip_code === ''
      || !employee.city || employee.city === ''
      || !requiredPropsValid) {
      setIsValid(false);
    }
    else {
      setIsValid(true);
    }
  }, [employee]);

  const handleSave = e => {
    e.preventDefault();
    saveEmployee();
  }

  const checkFieldRequiredForCompany = (field, company) => {
    if (!!company.employee_required_fields) {
      return JSON.parse(company.employee_required_fields).includes(field);
    }
    return false;
  }

  const checkRequiredFieldsForCompany = (emp, comp) => {
    let isClear = true;
    if (!!comp.employee_required_fields) {
      for (let field of JSON.parse(comp.employee_required_fields)) {
        if (!emp[field] || emp[field === '']) {
          isClear = false;
          break;
        }
      }
    }
    return isClear;
  }

  const saveEmployee = (callback = null) => {
    // console.log('employee at save: ', !!employee.company.custom_employee_properties);
    if (!validateEmail(employee.email)) {
      message.error(getLabel('email invalid', true, strings));
    }
    let mutatedEmployee = { ...employee };
    if (mutatedEmployee.__typename) {
      delete mutatedEmployee.__typename
    }
    //Set non required and ampty (null) custom properties to empty value so it's included in the stored json string.
    if(mutatedEmployee.company.custom_employee_properties){
      if (mutatedEmployee.company.custom_employee_properties.length <= 0) {
        // console.log('no custom props for company, deleting obj');
        delete mutatedEmployee.custom_properties
      }
      else {
        for(const property of mutatedEmployee.company.custom_employee_properties){
          if(!mutatedEmployee.custom_properties[property.name])
            mutatedEmployee.custom_properties[property.name] = "";
        }
      }
    }
    else {
      // console.log('no custom props for company, deleting obj');
      delete mutatedEmployee.custom_properties
    }
    delete mutatedEmployee.company;
    if (mutatedEmployee.date_of_birth) {
      mutatedEmployee.date_of_birth = employee.date_of_birth.toISOString();
    }
    if (mutatedEmployee.drivers_license_date) {
      mutatedEmployee.drivers_license_date = employee.drivers_license_date.toISOString();
    }
    if(mutatedEmployee.custom_properties && Object.keys(mutatedEmployee.custom_properties).length>0) {
      mutatedEmployee.custom_properties = JSON.stringify(mutatedEmployee.custom_properties);
    }
    setIsWorking(true)
    if (mutatedEmployee.id === null) {
      createEmployee({ variables: { employee: mutatedEmployee } }).then((res, err) => {
        if (err) {
          console.error(err);
          setIsWorking(false);
          return message.error(getLabel('something went wrong', true, strings));
        }
        if (res) {
          message.success(getLabel('employee', true, strings) + ' ' + getLabel('created', true, strings));
          props.history.push('/');
          props.history.push('/employee/' + res.data.createEmployee.id + '/' + res.data.createEmployee.company.id);
          // message.info({
          //   content: getLabel('Werknemer code verzonden', true, strings), 
          //   duration: 0,
          //   key: infoCodeKey
          // });
        }
      })
      .catch(err => {
        setIsWorking(false);
        if (err.message === `GraphQL error: Email already in use`) {
          return message.error(getLabel('email in use', true, strings));
        }
        return message.error(getLabel('something went wrong', true, strings));
      })
    }
    else {
      updateEmployee({ variables: { employee: mutatedEmployee } }).then((res, err) => {
        if (err) {
          console.error(err);
          setIsWorking(false);
          return message.error(getLabel('something went wrong', true, strings));
        }
        if (res) {
          props.history.replace('/employee/' + res.data.updateEmployee.id + '/' + res.data.updateEmployee.company.id);
          originalEmployee.current = { ...employee }
          message.success(getLabel('employee', true, strings) + ' ' + getLabel('modified', false, strings));
          setIsWorking(false);
          setHasChanges(false);
        }
      });
    }
  }
  // Create Employee query with update function to update cached
  const [createEmployee] = useMutation(QUERIES.CREATE_EMPLOYEE);
  const [updateEmployee] = useMutation(QUERIES.UPDATE_EMPLOYEE);

  // Callback function for new Employee data
  const onQueryComplete = data => {
    if (data.companies) {
      companies = data.companies;
    }
    let defaultCompany = null;
    if (props.match.params.companyId) {
      defaultCompany = companies.find(item => {
        return item.id === props.match.params.companyId;
      });
    }
    else {
      defaultCompany = { ...company };
    }
    // Entry point for external applications
    if (props.location.pathname === '/ext/employee') {
      const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
      setEmployee(old => {
        return {
          ...old,
          first_name: params.first_name || '',
          last_name: params.last_name || '',
          email: params.email || '',
          tel: params.tel || '',
          street: params.street || '',
          house_number: params.house_number || '',
          zip_code: params.zip_code || '',
          city: params.city || '',
          company_fk: defaultCompany.id,
          company: defaultCompany
        }
      })
    }
    // For a new employee
    if (props.match.params.id === 'new') {
      defaultCompany["custom_employee_properties"] = defaultCompany.custom_employee_properties? JSON.parse(defaultCompany.custom_employee_properties) : [];
      if (props.match.params.companyId) {
        setEmployee(old => {
          return { ...old, company_fk: defaultCompany.id, company: defaultCompany }
        });
        setCompany(defaultCompany);
        return;
      }
      setEmployee(old => {
        return { ...old, company_fk: data.companies[0].id }
      })
      return;
    }
    // For existing employees
    if (data.employee || data.createdEmployee || data.mutatedEmployee) {
      const fetchedEmployee = data.employee || data.createdEmployee
      originalEmployee.current = null
      setCompany(fetchedEmployee.company);
      setEmployee({
        ...fetchedEmployee,
        date_of_birth: fetchedEmployee.date_of_birth ? new Date(parseInt(fetchedEmployee.date_of_birth)) : null,
        drivers_license_date: fetchedEmployee.drivers_license_date ? new Date(parseInt(fetchedEmployee.drivers_license_date)) : null,
        company: {...fetchedEmployee.company, custom_employee_properties: fetchedEmployee.company.custom_employee_properties? JSON.parse(fetchedEmployee.company.custom_employee_properties) : []},
        custom_properties: fetchedEmployee.custom_properties ? JSON.parse(fetchedEmployee.custom_properties) : {}
      });
      originalEmployee.current = {
        ...fetchedEmployee,
        date_of_birth: fetchedEmployee.date_of_birth ? new Date(parseInt(fetchedEmployee.date_of_birth)) : null,
        drivers_license_date: fetchedEmployee.drivers_license_date ? new Date(parseInt(fetchedEmployee.drivers_license_date)) : null
      };
      setIsNew(false);
      setHasChanges(false);
    }
    setIsWorking(false);
  }
  // Fetch the employee (if provided) and all companies
  const { loading, error, data, refetch } = useQuery(QUERIES.COMPANY_ALL_AND_EMPLOYEE, {
    variables: {
      employeeId: props.match.params.id || 'new',
      dealerId: currentDealer ? currentDealer.id : null
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onQueryComplete(data);
    }
  });

  // Check whether the employee has changes and the save button should be visible
  useEffect(() => {
    if (originalEmployee.current === null || JSON.stringify(originalEmployee.current) === JSON.stringify(defaultEmployee) || !originalEmployee.current.custom_properties || typeof originalEmployee.current.custom_properties == "string") {
      originalEmployee.current = { ...employee };
    }
    if (JSON.stringify(employee) !== JSON.stringify(originalEmployee.current)) {
      setHasChanges(true);
    }
    else {
      setHasChanges(false);
    }
  }, [employee, originalEmployee, data])

  if (!!userDetails.companyIds){
    return null
  }
  
  if (loading) {
    content = (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    )
  }

  if (isWorking) {
    content = (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    )
  }

  if (error) {
    console.error(error);
    content = (
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  }

  // Show the complete Employee form
  if (!isWorking && data && employee && company) {
    content = (
      <Form ref={userForm} id="userForm" onSubmit={handleSave}>
        <Row type="flex" justify="center">
          <Col span={8}>
            <Card title={getLabel('general', true, strings)}>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('first name', true, strings)} required={true}>
                    <Input placeholder={getLabel('first name', true, strings)} value={employee.first_name} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, first_name: val }
                      })
                    }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('last name', true, strings)} required={true}>
                    <Input placeholder={getLabel('last name', true, strings)} value={employee.last_name} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, last_name: val }
                      })
                    }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('email', true, strings)} required={true}>
                    <Input placeholder={getLabel('email', true, strings)} value={employee.email} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, email: val.trim()}
                      })
                    }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('tel', true, strings)} required={true}>
                    <Input placeholder={getLabel('tel', true, strings)} value={employee.tel} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, tel: val }
                      })
                    }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('Titel', true, strings)} required={checkFieldRequiredForCompany('employee_title_fk', employee.company)}>
                    <Select defaultValue={employee.employee_title_fk}
                      value={employee.employee_title_fk}
                      onChange={newValue => {
                        setEmployee(old => {
                          return { ...old, employee_title_fk: newValue };
                        })
                      }}>
                      {data.employeeTitles.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>{getLabel(item.name, true, strings)}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('statute', true, strings)} required={true}>
                    <Select
                      // defaultValue={employee.employee_statute_fk? employee.employee_statute_fk: company.employeeStatutes[0].id}
                      value={employee.employee_statute_fk ? employee.employee_statute_fk : company.employeeStatutes[0].id}
                      onChange={newValue => {
                        setEmployee(old => {
                          return { ...old, employee_statute_fk: newValue };
                        });
                      }}>
                      {company.employeeStatutes.map((item, index) => {
                        return (
                          <Option value={item.id} key={index}>{getLabel(item.name, true, strings)}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('date of birth', true, strings)} required={true}>
                    <DatePicker value={employee.date_of_birth ? moment(employee.date_of_birth, dateFormat) : null}
                      format={dateFormat} onChange={(date, dateString) => {
                        setEmployee(old => {
                          return { ...old, date_of_birth: date };
                        });
                      }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('language', true, strings)} required={true}>
                    <Select defaultValue={employee.language_fk}
                      value={employee.language_fk}
                      onChange={newValue => {
                        setEmployee(old => {
                          return { ...old, language_fk: newValue };
                        })
                      }}>
                      {data.languages.map((item, index) => {
                        return <Option key={index} value={item.Id}>{getLabel(item.name, true, strings)}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title={getLabel('employer', true, strings)}>
              <Row type="flex" justify="left" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('company', true, strings)}>
                    <Button onClick={() => { setShowCompanySelector(true) }}>{company.name || getLabel('company', true, strings)}</Button>
                    <Modal
                      visible={showCompanySelector}
                      width={720}
                      footer={null}
                      onCancel={() => {
                        setShowCompanySelector(false);
                      }}
                      destroyOnClose={true}>
                      <CompanySearch disableKPMG={true} callback={comp => {
                        const newComp = data.companies.find(el => {
                          return el.id === comp.id;
                        });
                        let newStatute = newComp.employeeStatutes.find(el => {
                          return el.id === employee.employee_statute_fk;
                        });
                        setCompany(newComp);
                        setEmployee(old => {
                          return { ...old, company_fk: comp.id,
                            company: {...newComp, custom_employee_properties: newComp.custom_employee_properties? JSON.parse(newComp.custom_employee_properties) : []},
                            custom_properties: {},
                            employee_statute_fk: newStatute ? newStatute.id : newComp.employeeStatutes[0].id };
                        });
                        setShowCompanySelector(false);
                      }} />
                    </Modal>
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('employee number', true, strings)} required={checkFieldRequiredForCompany('employee_number', employee.company)}>
                    <Input placeholder={getLabel('employee number', true, strings)} value={employee.employee_number} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, employee_number: val }
                      })
                    }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('comment public', true, strings)} required={checkFieldRequiredForCompany('comment_public', employee.company)}>
                    <Input placeholder={getLabel('comment public', true, strings)} value={employee.comment_public} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, comment_public: val }
                      })
                    }} />
                  </Form.Item>
                </Col>
              </Row>
              {!!employee.company.custom_employee_properties && employee.company.custom_employee_properties.map((property, i)=>{
                if(i%2!=0)
                  return;
                
                const propItems = employee.company.custom_employee_properties[i+1]?[property, employee.company.custom_employee_properties[i+1]]:[property];
                  return (
                    <Row type="flex" justify="center" gutter={16}>
                    {propItems.map(propItem=>{
                       return(<Col span={12}>
                         <Form.Item label={getLabel(propItem.name, true, strings)} required={propItem.required}>
                            <Input type={propItem.type} placeholder={getLabel(propItem.name, true, strings)} value={employee.custom_properties[propItem.name]} onChange={e => {
                            const val = DOMPurify.sanitize(e.target.value);
                            setEmployee(old => {
                                return { ...old, custom_properties: {...old.custom_properties, [propItem.name]: val } }
                              })
                            }} />
                          </Form.Item>
                    </Col>)})}
                  </Row>
                )
              })}
            </Card>
          </Col>
          <Col span={8}>
          <Card style={{paddingTop: 55}}>
            <Row type="flex" justify="center" gutter={16}>
              <Col span={18}>
                <Form.Item label={getLabel('address', true, strings)} required={true}>
                    <Input placeholder={getLabel('address', true, strings)} value={employee.street}
                      onChange={e => {
                        const val = DOMPurify.sanitize(e.target.value);
                        setEmployee(old => {
                          return { ...old, street: val }
                        });
                      }} />
                  </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={getLabel('house number', true, strings)} required={true}>
                    <Input placeholder={getLabel('house number', true, strings)} value={employee.house_number}
                      onChange={e => {
                        const val = DOMPurify.sanitize(e.target.value);
                        setEmployee(old => {
                          return { ...old, house_number: val }
                        });
                      }} />
                  </Form.Item>
              </Col>
            </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('zip code', true, strings)} required={true}>
                    <Input placeholder={getLabel('zip code', true, strings)} value={employee.zip_code} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, zip_code: val }
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('city', true, strings)} required={true}>
                    <Input placeholder={getLabel('city', true, strings)} value={employee.city} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, city: val }
                      });
                    }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title={getLabel('mandatory fields speed pedelec', true, strings)}>
              <Row type="flex" justify="center" gutter={16}>
                <Col span={12}>
                  <Form.Item label={getLabel('drivers license date', true, strings)} required={checkFieldRequiredForCompany('drivers_license_date', employee.company)}>
                    <DatePicker value={employee.drivers_license_date ? moment(employee.drivers_license_date, dateFormat) : employee.drivers_license_date}
                      format={dateFormat} onChange={(date, dateString) => {
                        setEmployee(old => {
                          return { ...old, drivers_license_date: date };
                        })
                      }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={getLabel('national register', true, strings)} required={checkFieldRequiredForCompany('national_register', employee.company)}>
                    <Input placeholder={getLabel('national register', true, strings)} value={employee.national_register} onChange={e => {
                      const val = DOMPurify.sanitize(e.target.value);
                      setEmployee(old => {
                        return { ...old, national_register: val }
                      });
                    }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {!!company.id && (
              <Card>
                {company.logo_url ? <img src={company.logo_url || null} alt='company logo' style={{ maxHeight: '100px', maxWidth: '100px' }} />
                : <Avatar size='large'>{company.name[0]}</Avatar>}
                <iframe style={{ height: '256px' }} srcDoc={company.info || null} title='company info' />
              </Card>
            )}
          </Col>
        </Row>
      </Form>
    )
  }

  return (
    <div>
      {(!isWorking && data && company) && (<Title level={2} type='secondary'>{getLabel((employee.id ? 'edit employee' : 'werknemer aanmaken'), true, strings)}</Title>)}
      <Row type='flex' justify='space-between'
        style={{ position: 'fixed', width: '90%' }}
      >
        {(!isNew && !hasChanges) ? (
          <Tooltip title={getLabel('go back', true, strings)}>
            <Link to={getBackRoute()}>
              <Button><Icon type='left' /></Button>
            </Link>
          </Tooltip>) :
          (
            <Tooltip title={getLabel('go back', true, strings)}>
              <Popconfirm
                placement="bottomRight"
                title={`${getLabel('discard changes', true, strings)} ${getLabel('and', false, strings)} ${getLabel('go back', false, strings)}?`}
                okText={getLabel('ja', true, strings)}
                cancelText={getLabel('nee', true, strings)}
                onConfirm={() => { props.history.push(`/employees/${props.match.params.companyId}` || getBackRoute()) }}
              >
                <Link to={getBackRoute()}>
                  <Button><Icon type='left' /></Button>
                </Link>
              </Popconfirm>
            </Tooltip>)
        }
        <span>
          {((isNew || hasChanges) && !isWorking) && (
            <Button type="primary" size="large" shape="circle"
              style={{ margin: '0px 8px' }}
              htmlType="submit"
              form="userForm"
              disabled={!isValid || !checkRequiredFieldsForCompany(employee, employee.company)}
            >
              <Icon type="save" />
            </Button>
          )}
          {(!isNew && !hasChanges) &&
            <Tooltip title={getLabel('proceed', true, strings)}>
              <Button 
              disabled={!isValid || !checkCustomProps(originalEmployee.current) || !checkRequiredFieldsForCompany(originalEmployee.current, originalEmployee.current.company)}
              onClick={() => {
                if (!checkRequiredFields(employee)) {
                  return message.error(getLabel('ALD fields', true, strings) + ' ' + getLabel('missing', false, strings));
                }
                props.history.push('/quotations/' + employee.id)
              }}><Icon type='right' /></Button>
            </Tooltip>}
          {(checkRequiredFields(employee) && hasChanges && isValid && !isNew) && (
            <Tooltip title={getLabel('proceed', true, strings)}>
              <Popconfirm
              disabled={!checkCustomProps(originalEmployee.current) || !checkRequiredFieldsForCompany(originalEmployee.current, originalEmployee.current.company)}
                placement="bottomRight"
                title={`${getLabel('discard changes', true, strings)} ${getLabel('and', false, strings)} ${getLabel('proceed', false, strings)}?`}
                okText={getLabel('ja', true, strings)}
                cancelText={getLabel('nee', true, strings)}
                onConfirm={() => {
                  if (!checkRequiredFields(originalEmployee.current)) {
                    return message.error(getLabel('ALD fields', true, strings) + ' ' + getLabel('missing', false, strings));
                  }
                  else {
                    props.history.push('/quotations/' + employee.id);
                  }
                }}
              >
                <Button disabled={!checkCustomProps(originalEmployee.current)}><Icon type='right' /></Button>
              </Popconfirm>
            </Tooltip>
          )}
        </span>
      </Row>
      {content}
    </div>

  );
};

export default EmployeeDetail;