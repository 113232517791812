import React, { useEffect } from 'react';
import { Tabs, Icon } from 'antd';
import { useStateValue } from '../stateProvider/stateProvider';
import { getLabel, useI18nContext } from '../../api/i18nService';
import DealerSettings from './dealerSettings';
import CompanySettings from './companySettings';
import TemplateSettings from './templateSettings';
import VariantSettings from './variantSettings';
import BikeBrandSettings from './bikeBrandSettings';
import FieldTypeSettings  from './fieldTypeSettings'
import {  ControlOutlined } from '@ant-design/icons';
import BikeTypeSettings from './bikeTypeSettings';
import OrderLogTypeSettings from './orderLogTypeSettings';
import EmployeeTitleSettings from './employeeTitleSettings';
import EmployeeStatuteSettings from './employeeStatuteSettings';
import LanguageSettings from './languageSettings';
import OrderStatusSettings from './orderStatusSettings';
import WebhookAttemptSettings from './webhookAttemptSettings';

const Settings = props => {
  const { TabPane } = Tabs;
  const [{ currentUser, userDetails }] = useStateValue();

  if (!userDetails.superUser) {
    return null;
  }

  return (
    <div>
      <h1>Instellingen</h1>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <Icon type="shop" />
              Dealers
        </span>
          }
          key="1"
        >
          <DealerSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="cluster" />
              Bedrijven
        </span>
          }
          key="2"
        >
          <CompanySettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="snippets" />
              Templates
          </span>
          }
          key="3"
        >
          <TemplateSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="diff" />
              Variants
          </span>
          }
          key="4"
        >
          <VariantSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="tags" />
              Merken
          </span>
          }
          key="5"
        >
          <BikeBrandSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <ControlOutlined />
              Field Types
            
          </span>
          }
          key="6"
        >
          <FieldTypeSettings/>
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="file-text" />
              Order Log Types
          </span>
          }
          key="7"
        >
          <OrderLogTypeSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="idcard" />
              Aansprekingen
          </span>
          }
          key="8"
        >
          <EmployeeTitleSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="user" />
              Statuten
          </span>
          }
          key="9"
        >
          <EmployeeStatuteSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="notification" />
              Taal
          </span>
          }
          key="10"
        >
          <LanguageSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="book" />
              Fiets types
          </span>
          }
          key="11"
        >
          <BikeTypeSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="switcher" />
              Order statusen
          </span>
          }
          key="12"
        >
          <OrderStatusSettings />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="link" />
              Webhook attempts
          </span>
          }
          key="13"
        >
          <WebhookAttemptSettings />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Settings;