import React, { useEffect, useState } from 'react'
import {Row, Col, Input, message, Form, Button, Icon, Popover, Alert} from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import * as firebase from 'firebase/app';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const strengthStyle={
  lineHeight: 0,
  marginLeft: 25
}

const PasswordResetPage = props => {
    const history = useHistory();
    const { strings } = useI18nContext();
    const auth = firebase.auth();

    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [requestEmail, setRequestEmail] = useState('');
    const [emailRequestLoading, setEmailRequestLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionCode, setActionCode] = useState(null);
    const [userMail, setUserMail] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    //pwd checks
    const characterCheck = newPassword.match(/^.{8,}$/);
    const numberCheck = newPassword.match(/^(?=.*\d)/);
    const specialCharCheck = newPassword.match(/^(?=.*[!@#$%^&])/);
    const caseCheck = newPassword.match(/^(?=.*[A-Z])/);

    useEffect(()=>{
        setLoading(true);
        const queryString = props.resetProps.location.search.replace("?", "");
        const { mode, oobCode } = QueryString.parse(queryString);
        if(!mode || !oobCode || mode!="resetPassword"){
            history.push("/");
        }

        auth.verifyPasswordResetCode(oobCode).then((email)=>{
            setActionCode(oobCode);
            setUserMail(email);
        }).catch((err)=>{
            if(['auth/expired-action-code', 'auth/invalid-action-code'].includes(err.code))
                setShowErrorComponent(true);
            else
                history.push("/");
        }).finally(()=> setLoading(false));
    }, []);

    const checkPasswordIsAvailable=async()=>{
        try{
            const {data} = await axios.post((process.env.REACT_APP_ENV === 'production'?"https://europe-west1-b2bike-253312.cloudfunctions.net/hashPw": "https://europe-west1-b2bike-253312.cloudfunctions.net/hashPwStaging"), {
                candidate: newPassword
            }, {
                headers: {
                    rscode: actionCode
                }
            });

            return data.result=="ok";
        }catch(err){
            return false;
        }
    }

    const submitPassword=async(e)=>{
        e.preventDefault();
        setLoading(true);

        //Check code/mail availability
        if(!userMail || !actionCode){
            history.push("/");
            return;
        }
        //Check if match
        if(newPassword!=newPasswordConfirmation){
            setLoading(false);
            return message.warning(getLabel('Wachtwoorden komen niet overeen', true, strings));
        }
        //Check if strong enough
        if(!newPassword.match(/^(?=.*\d)(?=.*[!@#$%^&]).{8,}$/) || !newPassword.match(/^(?=.*[A-Z])/)){
            setLoading(false);
            return message.warning(getLabel('Nieuw wachtwoord voldoet niet aan de voorwaarden', true, strings));
        }
        //Check password availability
        if(!await checkPasswordIsAvailable()){
            setLoading(false);
            return message.warning(getLabel('Nieuw wachtwoord is reeds eerder gebruikt', true, strings));
        }
        

        auth.confirmPasswordReset(actionCode, newPassword).then(()=>{
            auth.signInWithEmailAndPassword(userMail, newPassword).then(()=>{
                history.push("/");
            }).catch(()=>{
                return message.warning(getLabel('Paswoord bevestigd maar kon niet inloggen', true, strings));
            });
        }).catch(()=>{
            return message.warning(getLabel('Kon paswoord niet bevestigen. Probeer later opnieuw', true, strings));
        }).finally(()=>setLoading(false));
    }

    const specialCharContent = (
        <div>
        <p style={{textAlign: "center"}}>{"!@#$%^&".split('').join(' ')}</p>
        </div>
    );

    const validateEmail = (email)=> {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleRequestButton = () => {
        setEmailRequestLoading(true);
        axios.post('https://europe-west1-b2bike-253312.cloudfunctions.net/passwordResetHandler', {
            email: requestEmail,
            type: 'reset'
        }).then(() => {
            message.success(getLabel('Succesvol verzonden Je kan deze pagina verlaten en je e-mail inbox raadplegen', true, strings));
        }).catch(_ => {
            message.error(getLabel('Kon de nieuwe code niet verzenden, kijk je e-mail inbox na of neem contact op', true, strings));
        }).finally(() => {
            setEmailRequestLoading(false);
        });
    }

    return (
    <div>
        <h2>{getLabel('Wachtwoord instellen', true, strings)}</h2>
        <div>
            <Row type='flex' justify='center'>
            <Col span={8}>
                {showErrorComponent && <Alert
                    message={getLabel("Kan wachtwoord niet instellen", true, strings)}
                    description={
                        <>
                            <hr style={{opacity: 0.25}} />
                            <p>{getLabel("Vul hieronder je e-mailadres in om een nieuwe reset-code te ontvangen", true, strings)}</p>
                            <Input placeholder={getLabel('email', true, strings)} onChange={(e) => {
                                const val = e.target.value;
                                setRequestEmail(val);
                            }}></Input>
                            <Button type="primary" style={{marginTop: 15}} loading={emailRequestLoading} disabled={!validateEmail(requestEmail) || emailRequestLoading} onClick={handleRequestButton}>{getLabel('Code aanvragen', true, strings)}</Button>
                        </>
                    }
                    type="error"
                    showIcon
                    style={{marginBottom: 30, marginTop: 50}}
                />}
                <Form onSubmit={submitPassword} disabled={true} style={{opacity: showErrorComponent ? 0.30 : 1}}>
                    <Form.Item label={getLabel('Nieuw wachtwoord', true, strings)} required={true}>
                    <p style={{...strengthStyle, color: characterCheck?"Green":"Red"}}>{characterCheck?<Icon type="check" />:<Icon type="close" />} {getLabel('Bevat minstens 8 karakters', true, strings)}</p>
                    <p style={{...strengthStyle, color: numberCheck?"Green":"Red"}}>{numberCheck?<Icon type="check" />:<Icon type="close" />} {getLabel('Bevat minstens één cijfer', true, strings)}</p>
                    <p style={{...strengthStyle, color: specialCharCheck?"Green":"Red"}}>{specialCharCheck?<Icon type="check" />:<Icon type="close" />} {getLabel('Bevat minstens één speciaal karakter', true, strings)}<Popover content={specialCharContent} title="Speciale karakters"><Icon style={{color: "#000000", marginLeft: 5}} type="info-circle" /></Popover></p>
                    <p style={{...strengthStyle, color: caseCheck?"Green":"Red"}}>{caseCheck?<Icon type="check" />:<Icon type="close" />} {getLabel('Bevat minstens één hoofdletter', true, strings)}</p>
                        <Input.Password disabled={showErrorComponent} onChange={(e) => {
                        const val = e.target.value;
                        setNewPassword(val);
                        }}/>
                    </Form.Item>
                    <Form.Item label={getLabel('Bevestig nieuw wachtwoord', true, strings)} required={true}>
                        <Input.Password disabled={showErrorComponent} onChange={(e) => {
                        const val = e.target.value;
                        setNewPasswordConfirmation(val);
                        }}/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!newPassword || !newPasswordConfirmation || loading || showErrorComponent} loading={loading}>
                    {getLabel('Bevestigen', true, strings)}
                    </Button>
                </Form>
            </Col>
            </Row>
        </div>
    </div>);
}

export default PasswordResetPage;