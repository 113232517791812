import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Spin, Input, Row, Col, Tabs, Result, InputNumber, Avatar, Button, Icon, Tooltip, message, Alert } from 'antd';
import { getLabel, useI18nContext } from '../../api/i18nService';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { useStateValue } from '../stateProvider/stateProvider';
import EmployeeCard from '../employeeCard/employeeCard';

const { Search } = Input;

const { TabPane } = Tabs;

const EmployeeSearchBarcode = props => {
  const history = useHistory();

  const { strings } = useI18nContext();
  const dummyTerm = props.match.params.companyId ? '' : 'dùmmy$e@rch';
  const [company, setCompany] = useState(null);
  const [surname, setSurname] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [tempBarcode, setTempBarcode] = useState(null);
  const [searchTerm, setSearchTerm] = useState(dummyTerm);
  const [codeResults, setCodeResults] = useState(null);
  const [nameResults, setNameResults] = useState(null);
  const [barcodeContent, setBarcodeContent] = useState(<div></div>);
  const [nameContent, setNameContent] = useState(<div></div>);
  const emailInput = useRef();
  const surnameInput = useRef();
  const nameInput = useRef();
  const barcodeInput = useRef();

  const [{ token, currentUser, userDetails }] = useStateValue();
  
  const { loading, error, data, refetch } = useQuery(QUERIES.COMPANY_INFO_FICHE, {
    variables: { id: props.match.params.companyId},
    fetchPolicy: 'network-only',
    onCompleted: (data => {
      setCompany(data.company)
    })
  });

  // Define the graphQL query based on whether a company id is provided
  useEffect(() => {
    if (!!barcode && barcode != null && barcode.length > 0) {
      getEmployeeByCode({
        variables: { code: barcode, company_fk: props.match.params.companyId },
        fetchPolicy: 'cache-and-network'
        
      })
    }
  }, [barcode])

  useEffect(()=>{
    if (codeResults != null){
      if (codeResults[0] != null ){
        setBarcodeContent(props.match.params.companyId? (
          <Row style={{ marginTop: '16px', zIndex:'1000' }} type='flex' gutter={16} justify={(codeResults.length > 0) ? 'start' : 'center'} id='resultRow'>
            {(codeResults.length > 0 ? codeResults.map((el, index) => (
              <Col span={8} key={index}>
                <Link to={'/employee/' + el.id + '/' + props.match.params.companyId}>
                  <EmployeeCard shouldRenderLogo={codeResults.length < 100 && !props.match.params.companyId} employee={el} fbToken={token} />
                </Link>
              </Col>
            )) : (<Col span={8}>
              <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{searchTerm === '' ? ('') : getLabel('employee not found', true, strings)}</h1>
            </Col>))}
          </Row>
        ):(
            <Row style={{ marginTop: '16px' }} type='flex' gutter={16} justify={(searchTerm !== dummyTerm && codeResults.length > 0) ? 'start' : 'center'}>
              {searchTerm === dummyTerm ? (<Col span={1}>
                <Icon type='search' style={{ fontSize: '72px', marginTop: '128px' }} />
              </Col>) : (codeResults.length > 0 ? codeResults.map((el, index) => (
                <Col span={8} key={index}>
                  <Link to={'/employee/' + el.id}>
                    <EmployeeCard shouldRenderLogo={codeResults.length < 100} employee={el} fbToken={token} />
                  </Link>
                </Col>
              )) : (<Col span={8}>
                <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{getLabel('employee not found', true, strings)}</h1>
              </Col>))}
            </Row>
          )
        )
      }else{
        setBarcode(null);
        message.warn('Geen werknemers gevonden');
        setBarcodeContent(null);
      }
    }
  },[codeResults])

  useEffect(()=>{
    if ( nameResults != null){
      if (nameResults[0] != null ){
        setNameContent(props.match.params.companyId? (
          <Row style={{ marginTop: '16px', zIndex:'1000' }} type='flex' gutter={16} justify={(nameResults.length > 0) ? 'start' : 'center'} id='resultRow'>
            {(nameResults.length > 0 ? nameResults.map((el, index) => (
              <Col span={8} key={index}>
                <Link to={'/employee/' + el.id + '/' + props.match.params.companyId}>
                  <EmployeeCard shouldRenderLogo={nameResults.length < 100 && !props.match.params.companyId} employee={el} fbToken={token} />
                </Link>
              </Col>
            )) : (<Col span={8}>
              <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{searchTerm === '' ? ('') : getLabel('employee not found', true, strings)}</h1>
            </Col>))}
          </Row>
        ):(
            <Row style={{ marginTop: '16px' }} type='flex' gutter={16} justify={(searchTerm !== dummyTerm && nameResults.length > 0) ? 'start' : 'center'}>
              {searchTerm === dummyTerm ? (<Col span={1}>
                <Icon type='search' style={{ fontSize: '72px', marginTop: '128px' }} />
              </Col>) : (nameResults.length > 0 ? nameResults.map((el, index) => (
                <Col span={8} key={index}>
                  <Link to={'/employee/' + el.id}>
                    <EmployeeCard shouldRenderLogo={nameResults.length < 100} employee={el} fbToken={token} />
                  </Link>
                </Col>
              )) : (<Col span={8}>
                <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{getLabel('employee not found', true, strings)}</h1>
              </Col>))}
            </Row>
          )
        )
      }else{
      message.warn('Geen werknemers gevonden');
      setNameContent(null);

      }
    }
  },[nameResults])



  if (!props.match.params.companyId){
    history.push("/");
  }

  const [getEmployeeByCode, { loadingEmployeeByCode, errorEmployeeByCode }] = useLazyQuery(QUERIES.EMPLOYEE_BY_ACCESS_CODE, {
    onCompleted: (data) => {
      setCodeResults([data.employeeByAccessCode])
    },
    onError: (err)=>{
      message.warn('Geen werknemers gevonden');

    }
  });

  const [getEmployeeByName, { loadingEmployeeByName, errorEmployeeByName }] = useLazyQuery(QUERIES.EMPLOYEE_BY_NAME_AND_EMAIL, {
    onCompleted: (data) => {

      setNameResults([data.employeeByNameAndEmail])
    },
    onError: (err)=>{
      message.warn('Geen werknemers gevonden');
      setNameContent(null)
    }
  });

  if (!!userDetails.companyIds) {
    return null
  }

  if (loadingEmployeeByName ) {
    setNameContent(
      <Row type='flex' justify='center' gutter={16}>
        <Col style={{ marginTop: '128px' }} span={1}>
          <Spin size='large'></Spin>
        </Col>
      </Row>
    );
  }
  else if (errorEmployeeByCode) {
    setBarcodeContent(
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  } else if (errorEmployeeByName){
    setNameContent(
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  }else if (loadingEmployeeByCode){
    setBarcodeContent(
      <Row type='flex' justify='center' gutter={16}>
        <Col style={{ marginTop: '128px' }} span={1}>
          <Spin size='large'></Spin>
        </Col>
      </Row>
    );
  }

  function verifyBarcode() {
    if (!tempBarcode || tempBarcode === '' || tempBarcode?.length <= 0) {
      message.warn('No barcode entered')
      return;
    } else {
      setBarcode(tempBarcode);
    }
  }

  function setNull(){
    setBarcode(null);
    setSurname(null);
    setName(null);
    setEmail(null);
    setCodeResults(null);
    setNameResults(null);
    setBarcodeContent(null);
    setNameContent(null);
    if(emailInput.current){
      emailInput.current.state.value = null;
    }
    if(surnameInput.current){
      surnameInput.current.state.value = null;
    }
    if(nameInput.current){
      nameInput.current.state.value = null;
    }
    if(barcodeInput.current){
      barcodeInput.current.state.value = null;
    }
  }
  
  return (
    <>
      <Row type='flex' justify='space-between'>
        <Link to='/'>
          <Button><Icon type='left' /></Button>
        </Link>
      </Row>
      {(props.match.params.companyId && company) && (

        <Row type='flex' justify='space-around' style={{ maxWidth: '35vw', margin: '14px auto' }}>
          <Col span={20}><h2 style={{ textAlign: 'left' }}>{company.name}</h2></Col>
          <Col span={4}> {company.logo_url ?
            <img className='image' src={company.logo_url} alt='logo' style={{ maxHeight: '50px', maxWidth: '100px' }} />
            : <Avatar size='large'>{company.name[0]}</Avatar>}</Col>
        </Row>
      )
      }
      <div style={{ maxWidth: '1000px', margin: '1em auto' }}>
        <h2 style={{marginBottom: 10, textAlign: "center", margin: 15}}>{getLabel('Werknemer opzoeken of toevoegen', true, strings)}</h2>
        <Tabs defaultActiveKey="2" tabBarExtraContent={<Tooltip title={company?.kpmg_integration_partner? getLabel('employee should use kpmg portal', true, strings) : getLabel('new employee', true, strings)} >
          <Button disabled={!!company?.kpmg_integration_partner} size='large' type='primary'>
            <Link to={props.match.params.companyId ? `/employee/new/${props.match.params.companyId}` : '/employee/new'}>
              <Icon type='plus' /> {getLabel('Werknemer toevoegen', true, strings)}
            </Link>
          </Button>

        </Tooltip>} onChange={() => { 
          setNull();
        }} >
          <TabPane tab={getLabel('Zoeken via barcode', true, strings)} key="1" >
            <div >
              <Row type='flex' justify="center" gutter={16}>
                <Col span={12}>
                {getLabel('employee access code info', true, strings)}
                  <Tooltip title={getLabel('search barcode', true, strings)}>
                    <Row>
                      <Input
                      ref={barcodeInput}
                        id="barcodeInput"
                        style={{ width: '80%' }}
                        placeholder='Barcode'
                        onChange={e => setTempBarcode(e.target.value)}
                        onPressEnter={(e) => {
                            verifyBarcode()
                        }}
                        allowClear
                      />
                      <Button type='primary' disabled={!tempBarcode} onClick={(e) => {verifyBarcode()}} style={{ marginTop: '1em' }}>
                        <Icon type='search' />
                      </Button>
                    </Row>
                  </Tooltip>
                </Col>
              </Row>
              <div id='contentDiv'>
                {barcodeContent}
              </div>
            </div>
          </TabPane>
          <TabPane tab={getLabel('Zoeken via naam + e-mail', true, strings)} key="2"  >
            <div>
              <Row type='flex' justify="center" gutter={16}>
                <Col span={12}>
                <p>{getLabel('employee name and mail info', true, strings)}</p>
                  <Input
                    ref={nameInput}
                    id="nameInput"
                    style={{ marginTop: '1em' }}
                    size='large'
                    onChange={e => setName(e.target.value)}
                    placeholder='Voornaam' 
                    allowClear/>
                  <Input
                    ref={surnameInput}
                    id="surnameInput"
                    style={{ marginTop: '1em' }}
                    size='large'
                    onChange={e => setSurname(e.target.value)}
                    placeholder='Achternaam' 
                    allowClear/>
                  <Input
                    ref={emailInput}
                    id="emailInput"
                    style={{ marginTop: '1em' }}
                    size='large'
                    onChange={e => setEmail(e.target.value)}
                    placeholder='E-Mail' 
                    allowClear/>
                  <Button type='primary' disabled={!name || !surname || !email}onClick={() => {
                   
                    getEmployeeByName({
                      variables: { first_name: name, last_name: surname, email: email, company_fk: props.match.params.companyId },
                      fetchPolicy: 'cache-and-network'
                    })
                  }}
                    style={{ marginTop: '1em' }}>
                    <Icon type='search' />
                            {getLabel('search employee', true, strings)}
                    </Button>
                </Col>

              </Row>
              {nameContent}
            </div>        
            </TabPane>
        </Tabs>

      </div>
    </>
  );
};

export default EmployeeSearchBarcode