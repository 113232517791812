import React from "react";
import { Modal, Form, Input } from "antd";

const ModalForm = Form.create({ name: "custom_modal" })(
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        form,
        value,
        footer,
        title,
        formField,
      } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title={title}
          okText="Save"
          onCancel={onCancel}
          footer={footer}
        >
          <Form layout="vertical">
            <Form.Item>
              {getFieldDecorator(formField, { initialValue: value })(
                <Input.TextArea rows={10} />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default ModalForm;
