import React, { useEffect, useState } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
// import OwnTable from './table';
import {
    Table,
    Button,
    Icon,
    Modal,
    Input,
    message,
    Form,
    Spin,
    Row,
    Col,
    Popconfirm,
    Select
} from 'antd';
const Option = {Select};

const FieldTypeSettings = () => {

    const [fieldTypeData, setData] = useState('');
    const [dataTypes, setDataTypes] = useState(null);
    const [state, setState] = useState({})
    const [code, setCode] = useState('');
    const [naam, setName] = useState('');
    const [dataTypeFK, setDataTypeFK] = useState('1');

    useEffect(() => {
        setState({ ModalText: 'Content of the modal', visible: false, confirmLoading: false })

    }, []);

    const { loading, error, refetch } = useQuery(QUERIES.GET_FIELD_TYPE, {
        onCompleted: res => {
            res.fieldTypes.forEach(data => {
                data.key = data.id + "test" + (data.id - 1)
            });
            setDataTypes(res.dataTypes);
            // console.log(res.dataTypes);
            setData(res.fieldTypes);
        }
    });
    const [createFieldType] = useMutation(QUERIES.CREATE_FIELD_TYPES);

    // const [deleteFieldType] = useMutation(QUERIES.DELETE_FIELD_TYPES);

    const showModal = () => {
        setState({ visible: true });
    };

    const handleOk = () => {



        setState({ visible: false, confirmLoading: false });


        createFieldType({
            variables: {
                fieldType: {
                    name: naam,
                    code: code,
                    data_type_fk: dataTypeFK,
                    is_multivalue: dataTypeFK === "3"
                }
            }
        }).then(res => {
            onMutationComplete();
        }).catch(err => {
            console.error(err);
        })
    };

    const onMutationComplete = () => {
        message.success('Wijzigingen opgeslagen!');
        refetch().then(res => {
            // var temparray = []
            // temparray.push(res.data.fieldTypes)
            setData(res.data.fieldTypes);
            setState({ visible: false, confirmLoading: false });
        })
    }

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setState({ visible: false });
    };

    const visible = state.visible;
    const confirmLoading = state.confirmLoading;

    const columns = [
        {
            title: 'Name',
            dataIndex: '',
            render: (text, record) => {
                return record.name;
            }
        },
        {
            title: 'code',
            dataIndex: '',
            render: (text, record) => {
                return record.code;
            }
        },
        {
            title: 'Data type',
            dataIndex: '',
            render: (text, record) => {
                return record.dataType.name;
            }
        }

    ];

    if (!!fieldTypeData) {
        return (

            <div>
                <Button
                    type='primary'
                    onClick={showModal}
                ><Icon type='plus' /> Nieuw
            </Button>

                {/* {fieldTypeData.map((data) => <OwnTable data={data} columns = {columns}/>)} */}
                <Row type='flex' justify='center'>
                    <Table
                        columns={columns}
                        dataSource={fieldTypeData}
                        style={{ width: '80vw', margin: 16 }}
                    />
                </Row>
                <div>

                    <Modal
                        title="Nieuw Field Type"
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        cancelText={'Annuleren'}
                        onCancel={handleCancel}>
                        <div className="modalBody">
                            <Form>
                                <Form.Item required={true} label='Naam'>
                                    <Input
                                        onChange={e => {
                                            setName(DOMPurify.sanitize(e.target.value));
                                        }} />
                                </Form.Item>
                                <Form.Item required={true} label='Code'>
                                    <Input
                                        onChange={e => {
                                            setCode(DOMPurify.sanitize(e.target.value));
                                        }} />
                                
                                </Form.Item>
                                <Form.Item required={true} label='Data Type'>
                                    <Select
                                    value={dataTypeFK}
                                    onChange={val => {
                                        setDataTypeFK(val);
                                    }}
                                    >
                                        {dataTypes.map((el, index) => {
                                            return (<Option key={index} value={el.id}>{el.name}</Option>)
                                        })}
                                    </Select>
                                
                                </Form.Item>
                            </Form>

                        </div>

                    </Modal>
                </div>

            </div>
        )
    }

    return (
        <div>
            <h1>Geen data </h1>
        </div>
    )

}

export default FieldTypeSettings