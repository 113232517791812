import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useAuthContext } from '../../auth/auth';
import { QUERIES } from '../../api/queries';
import { Select, List, Button, Modal, Form, Input, Checkbox, message, Row, Col, Spin, Icon, Card, Tabs } from 'antd';
import axios from 'axios';
import fbApp from '../../firebase';
import { reverseString, getServiceUrl } from '../../util/util';
import { useStateValue } from '../stateProvider/stateProvider';
import { getLabel, useI18nContext } from '../../api/i18nService';
import DealerTab from './dealerTab';
import CompanyTab from './companyTab';
import UserModal from './userModal';
const { Option } = Select;

/**
 * dealerManagement.js
 * Component used to manage dealers and users linked to dealers
 * Only B2Bike backoffice personel (superUsers) should have access to this
 */

const DealerManagement = props => {
  const TabPane = Tabs.TabPane;

  const { strings } = useI18nContext();

  // Define a simple model for a user, reflects the structure in Firebase
  const userModel = {
    first_name: '',
    last_name: '',
    email: '',
    dealerIds: [],
    companyIds: [],
    admin: false,
    language: 'nl',
    superUser: false
  };

  // const [allDealers, setAllDealers] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [createNewUserVisible, setCreateNewUserVisible] = useState(false);
  const [currentModalType, setCurrentModalType] = useState();
  const [newUser, setNewUser] = useState({ ...userModel });
  const [editMode, setEditMode] = useState(false);
  const [{ userDetails, currentUser, userDealers, currentDealer, token }, dispatch] = useStateValue();
  const [working, setWorking] = useState(false);
  const [userManagement, setUserManagement] = useState(null);
  const [isFromEmployersPortal, setIsFromEmployersPortal] = useState(false);
  // const { userDetails } = useAuthContext();

  const [updateCompany] = useMutation(QUERIES.UPDATE_COMPANY);

  // Query all dealers from api
  // const { loading, error, data } = useQuery(QUERIES.DEALER_ALL, {
  //   onCompleted: res => {
  //     setAllDealers(res.dealers);
  //   }
  // });

  const fetchAllUsers = async () => {
    if (!!userManagement) {
      const userRes = await axios.post(userManagement, 
        {mode: 'READ', include: ['all']},
        {
          headers: {
            Authorization: token
          }
        }
        );
        setAllUsers(userRes.data.allUsers);
    }
  }

  // Create listener to firebase users
  useEffect(() => {
    fetchAllUsers();
  }, [userManagement]);

  useEffect(() => {
    getServiceUrl('manageUsers', token).then(url => {
      setUserManagement(url);
    })
  }, []);

  useEffect(() => {
    if (!!selectedDealer) {
      onDealerChange(selectedDealer.id)
    }
  }, [allUsers]);

  useEffect(() => {
    setSelectedDealer(currentDealer);
  }, []);

  //Source detection
  useEffect(() => {
    setIsFromEmployersPortal((!!props && !!props.onEmployersPortal));
  }, []);

  // Redirect to home if you are not a superUser or admin
  if (!userDetails.admin && !userDetails.superUser) {
    return (<Redirect to={'/'} />)
  }

  // Save a user via userManagement micro service
  const saveUser = async (user) => {
    user.dealerIds = user.dealerIds ? user.dealerIds : [];
    user.companyIds = user.companyIds ? user.companyIds : [];

    setWorking(true);
    let success = true;
    try {
      const res = await axios.post(userManagement, { ...user, mode: editMode ? 'EDIT' : 'CREATE' }, {
        headers: {
          'Authorization': token
        }
      });
      if (res.data.error) {
        message.error(res.data.error);
        console.error(res.data.error);
        return;
      }
      if(!!user.isPortaluser && user.companyIds.length > 0 && !!userDetails.superUser) {
        //set portal_user property to true as this is the first user
        await updateCompany({variables: {
          company: {
            id: user.companyIds[0],
            portal_user: true
          }
        }});
      }
    }
    catch (err) {
      message.error('Fout');
      console.error(JSON.stringify(err));
      success = false;
    }
    finally {
      if(!!success) {
        if (editMode) {
          message.success('User bijgewerkt!');
        }
        else {
          message.success('User aangemaakt!');
        }
      }

      setNewUser({ ...userModel });
      setWorking(false);
      fetchAllUsers();
    }
  }

  const deleteUser = async (user) => {
    user.dealerIds = user.dealerIds ? user.dealerIds : [];
    user.companyIds = user.companyIds ? user.companyIds : [];

    setWorking(true);
    try {
      const res = await axios.post(userManagement, { ...user, mode: 'DELETE' }, {
        headers: {
          'Authorization': token
        }
      });
      if (res.data.error) {
        message.error(res.data.error);
        console.error(res.data.error);
        return;
      }
      if(!!user.isPortaluser && !!user.companyIds && user.companyIds.length > 0 && !!userDetails.superUser) {
        //set portal_user property to false as this was the last user
        await updateCompany({variables: {
          company: {
            id: user.companyIds[0],
            portal_user: false
          }
        }});
      }
      message.success('User verwijderd');
    }
    catch (err) {
      message.error('Fout');
      console.error(JSON.stringify(err));
    }
    finally {
      setNewUser({ ...userModel });
      setWorking(false);
      fetchAllUsers();
    }
  }

  // Callback function for dealer Select (searchable)
  const onDealerChange = value => {
    setSelectedDealer(userDealers.find(el => {
      return el.id === value;
    }));
    const fu = allUsers.filter(el => {
      if (!el.dealerIds) {
        return false;
      }
      return el.dealerIds.indexOf(parseInt(value)) >= 0;
    });
    setFilteredUsers(fu);
  }

  // console.log(userDealers);

  /*if (!!userDetails.companyIds){
    return null
  }*/

  if (!userDetails.admin && !userDetails.superUser) {
    return null;
  }

  // else if (error) {
  //   console.error(error);
  //   return (
  //     <Result
  //       status="error"
  //       title={'Er ging iets mis'}
  //     />
  //   );
  // }




  if (((!!userDealers && !!selectedDealer) || !!isFromEmployersPortal) && userManagement) {
    return (
      <div>
        <div style={{ position: 'fixed', height: '100vh', width: '100vw', backgroundColor: 'rgba(255, 255, 255, .8)', display: working ? 'block' : 'none', zIndex: 128 }}>
          <Row type='flex' justify='center' style={{ marginTop: '256px' }}>
            <Spin size='large'></Spin>
          </Row>
        </div>
        <h2 style={{ marginBottom: 10 }}>{getLabel('gebruikersbeheer', true, strings)}</h2>


        {
          // First check for superUser
          userDetails.superUser ? (
            <Tabs defaultActiveKey="1" onChange={null}>
              <TabPane tab="Company beheer" key="1">
                <CompanyTab userDetails={userDetails} currentDealer={currentDealer} onDealerChange={onDealerChange} userDealers={userDealers} selectedDealer={selectedDealer} setEditMode={setEditMode} setNewUser={setNewUser} userModel={userModel} setCreateNewUserVisible={setCreateNewUserVisible} filteredUsers={filteredUsers} deleteUser={deleteUser} allUsers={allUsers} setCurrentModalType={setCurrentModalType} />
              </TabPane>
              <TabPane tab="Dealer beheer" key="2">
                <DealerTab userDetails={userDetails} currentDealer={currentDealer} onDealerChange={onDealerChange} userDealers={userDealers} selectedDealer={selectedDealer} setEditMode={setEditMode} setNewUser={setNewUser} userModel={userModel} setCreateNewUserVisible={setCreateNewUserVisible} filteredUsers={filteredUsers} deleteUser={deleteUser} newUser={newUser} saveUser={saveUser} editMode={editMode} createNewUserVisible={createNewUserVisible} setCurrentModalType={setCurrentModalType} />
              </TabPane>
            </Tabs>
          ) :
            (
              <Tabs defaultActiveKey="1" onChange={null}>
                {
                  // If not superUser, check for origin
                  isFromEmployersPortal ?
                    (
                      <TabPane tab="Company beheer" key="1">
                        <CompanyTab userDetails={userDetails} currentDealer={currentDealer} onDealerChange={onDealerChange} userDealers={userDealers} selectedDealer={selectedDealer} setEditMode={setEditMode} setNewUser={setNewUser} userModel={userModel} setCreateNewUserVisible={setCreateNewUserVisible} filteredUsers={filteredUsers} deleteUser={deleteUser} allUsers={allUsers} setCurrentModalType={setCurrentModalType} />
                      </TabPane>
                    ) : userDetails.admin && (
                      <TabPane tab="Dealer beheer" key="1">
                        <DealerTab userDetails={userDetails} currentDealer={currentDealer} onDealerChange={onDealerChange} userDealers={userDealers} selectedDealer={selectedDealer} setEditMode={setEditMode} setNewUser={setNewUser} userModel={userModel} setCreateNewUserVisible={setCreateNewUserVisible} filteredUsers={filteredUsers} deleteUser={deleteUser} newUser={newUser} saveUser={saveUser} editMode={editMode} createNewUserVisible={createNewUserVisible} setCurrentModalType={setCurrentModalType} />
                      </TabPane>
                    )}
              </Tabs>
            )
        }

        <UserModal createNewUserVisible={createNewUserVisible} setCreateNewUserVisible={setCreateNewUserVisible} saveUser={saveUser} newUser={newUser} setNewUser={setNewUser} userModel={userModel} setEditMode={setEditMode} editMode={editMode} userDetails={userDetails} currentModalType={currentModalType} />
      </div >
    );
  }

  return null;
}

export default DealerManagement;