import ActionCell from './components/actionCell';
import Button from './components/wrappedComponents/button';
import React from "react";


// Make sure that when you set the filter on true (in the table configuration),
// you provide a filterKey and this needs to be the same as the filter key you provided on query level
// So this can be automatically updated in the query
// PS: don't forget to add the variables to the query too in the API!
export const columns = (customProps, props) => [
  {
    title: 'Offerte',
    dataIndex: 'bike.order.quotation_fk',
    editable: false,
    filter: true,
    filterComponent: 'TextField',
    filterKey: 'quotation_fk',
    sorting: false,
    render: (text, record) => (
      <Button
        onClick={() => {
          props.history.push(
            '/quotations/' +
              record.bike.employee.id +
              '/' +
              record.bike.order.quotation.id
          )
        }}
        style={{ width: '100%' }}
      >
        Q{text}
      </Button>
    ),
  },
  {
    title: 'Lease Co',
    dataIndex: 'bike.employee.company.invoice_target.name',
    editable: false,
    filter: true,
    filterComponent: 'TextField',
    filterKey: 'invoice_target',
    sorting: false,
  },
  {
    title: 'Dealer',
    dataIndex: 'bike.order.quotation.dealer.name',
    editable: false,
    filter: false,
    sorting: false,
  },
  {
    title: 'Werknemer',
    dataIndex: 'bike.employee.name',
    editable: true,
    filter: true,
    filterComponent: 'TextField',
    filterKey: 'employee_name',
    sorting: false,
    component: 'Modal',
  },
  {
    title: 'Framenummer',
    dataIndex: 'bike.frame_number',
    editable: true,
    filter: true,
    filterComponent: 'TextField',
    filterKey: 'bike_frame_number',
    sorting: false,
    required: true,
    component: 'TextField',
  },
  {
    title: 'Modulo',
    dataIndex: 'bike.control_number',
    editable: true,
    filter: false,
    sorting: false,
    required: true,
    component: 'TextField',
  },
  {
    title: 'Veld X12',
    dataIndex: 'bike.barcode_x12',
    editable: true,
    filter: false,
    sorting: false,
    required: false,
    component: 'TextField',
  },
  {
    title: 'Inschrijving',
    dataIndex: 'license_plate_registration_type_fk',
    editable: true,
    filter: false,
    sorting: false,
    required: true,
    component: 'Dropdown',
    menuType: 'LicensePlateRegistrationType',
  },
  {
    title: 'Datum inschrijving',
    dataIndex: 'registration_date',
    editable: true,
    filter: false,
    sorting: true,
    required: false,
    component: 'DatePicker',
  },
  {
    title: 'Nummerplaat',
    dataIndex: 'bike.license_number',
    editable: true,
    filter: true,
    filterComponent: 'TextField',
    filterKey: 'bike_license_number',
    sorting: false,
    required: true,
    component: 'TextField',
  },
  {
    title: 'Datum verzending nummerplaat',
    dataIndex: 'license_plate_sent_to_dealer_date',
    editable: true,
    filter: false,
    sorting: true,
    required: false,
    component: 'DatePicker',
  },
  {
    title: 'Commentaar',
    dataIndex: 'comment',
    editable: true,
    filter: false,
    sorting: false,
    required: false,
    component: 'ModalForm',
  },
  {
    title: 'Status',
    dataIndex: 'license_plate_request_status_fk',
    editable: true,
    filter: true,
    filterComponent: 'MultiSelect',
    filterKey: 'license_plate_request_statuses',
    sorting: true,
    required: true,
    component: 'Dropdown',
    menuType: 'LicensePlateRequestStatus',
  },
  {
    title: 'Acties',
    render: (rowData) => <ActionCell {...customProps} rowData={rowData} />,
    filter: false,
    sorting: false,
    editable: false,
    required: false,
  },
]
