import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Button,
  Col,
  Modal,
  Icon,
  Menu,
  Dropdown,
  Select,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import fbApp from "../../firebase";
import {
  useStateValue,
  backofficeDealer,
} from "../stateProvider/stateProvider";
import CompanyInfoCard from "../companyInfoCard/companyInfoCard";
import { getLabel, useI18nContext } from "../../api/i18nService";
import { QUERIES } from "../../api/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import axios from "axios";
const { Option } = Select;

const Header = (props) => {
  const { strings } = useI18nContext();
  const [
    {
      currentUser,
      userDetails,
      company: companyContext,
      token,
      allDealers,
      dealerIndex,
      userDealers,
    },
    dispatch,
  ] = useStateValue();
  const [showModal, setShowModal] = useState(false);

  const [collectDealers] = useLazyQuery(QUERIES.DEALER_ALL_RAW, {
    fetchPolicy: "no-cache",
    onCompleted: ({ dealers }) => {
      dispatch({
        type: "setAllDealers",
        dealers: dealers,
      });
    },
  });

  useEffect(() => {
    collectDealers();
  }, [token]);

  useEffect(() => {
    // console.log('all dealers changed!')
    let myDealers = [];
    // if (userDealers.length > 0) {
    //   return;
    // }
    if (!!allDealers && !!userDetails) {
      if (userDetails.superUser) {
        // let defaultIndex = allDealers.indexOf(allDealers.find(el => parseInt(el.id) === parseInt(userDetails.dealerIds[0])));
        // dispatch({ type: 'setDealerIndex', newIndex: defaultIndex });
        dispatch({ type: "setUserDealers", dealers: allDealers });
        if (allDealers.indexOf(backofficeDealer < 0)) {
          allDealers.push(backofficeDealer);
        }
        dispatch({
          type: "setDealerIndex",
          newIndex: allDealers.indexOf(
            allDealers.find((el) => el.id === backofficeDealer.id)
          ),
        });
      } else if (!!userDetails.dealerIds) {
        userDetails.dealerIds.forEach((el) => {
          let d = allDealers.find((dealer) => {
            return parseInt(dealer.id) === parseInt(el);
          });
          if (!!d) {
            myDealers.push(d);
          }
        });
        const dIndex = localStorage.getItem("dealerIndex")
          ? Number(localStorage.getItem("dealerIndex"))
          : 0;
        dispatch({ type: "setUserDealers", dealers: myDealers });
        dispatch({ type: "setDealerIndex", newIndex: dIndex });
        // setUserDealers(myDealers);
      }
    }
  }, [allDealers, userDetails, currentUser]);

  useEffect(() => {
    // if (userDetails.superUser && dealerIndex === -1) {
    //   dispatch({ type: 'setCurrentDealer', newDealer: {
    //     name: 'BACKOFFICE',
    //     id: '-1'
    //   } });
    //   return;
    // }
    dispatch({ type: "setCurrentDealer", newDealer: userDealers[dealerIndex] });
  }, [dealerIndex, currentUser, userDealers]);

  const signOut = async (evt) => {
    // dispatch({
    //   type: 'changeRequestedRoute',
    //   newRoute: '/login'
    // });
    // dispatch({
    //   type: 'changeCompany',
    //   newCompany: null
    // });
    // dispatch({
    //   type: 'setAllDealers',
    //   dealers: null
    // });
    // dispatch({
    //   type: 'setDealerIndex',
    //   newIndex: 0
    // });
    // dispatch({
    //   type: 'setCurrentDealer',
    //   newDealer: null
    // });
    // dispatch({ type: 'setUserDealers', dealers: [] });
    await fbApp.auth().signOut();
    dispatch({ type: "logout" });
    setTimeout(() => {
      window.location.replace("/login");
    }, 30);
    // return <Redirect to='/login' />
  };

  const accountMenu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/my-account">{getLabel("my account", true, strings)}</Link>
      </Menu.Item>
      {(userDetails.superUser || userDetails.admin) && (
        <Menu.Item key="1">
          <Link to="/dealer-management">
            {getLabel("Gebruikersbeheer", true, strings)}
          </Link>
        </Menu.Item>
      )}
      {userDetails.admin && !userDetails.superUser && (
        <Menu.Item key="1">
          <Link to="/dealer-info">
            {getLabel("menu item dealer info", true, strings)}
          </Link>
        </Menu.Item>
      )}
      {userDetails.superUser && (
        <Menu.Item key="1">
          <Link to="/dealer-info-backoffice">
            {getLabel("menu item dealer info", true, strings)}
          </Link>
        </Menu.Item>
      )}
      {userDetails.superUser && (
        <Menu.Item>
          <Link to="/employees">Werknemers</Link>
        </Menu.Item>
      )}
      {/* {userDetails.superUser && (
        <Menu.Item>
          <Link to='/bike-management'>Fietsen</Link>
        </Menu.Item>
      )} */}
      {userDetails.superUser && (
        <Menu.Item>
          <Link to="/settings">
            <Icon type="setting" /> Instellingen
          </Link>
        </Menu.Item>
      )}
      {!!userDetails.companyIds && (
        <Menu.Item>
          <Link to="/employersportal">
            <Icon type="setting" /> employersPortal
          </Link>
        </Menu.Item>
      )}
      {userDetails.superUser && (
        <Menu.Item>
          <Link to="/service-pass-management">
            <Icon type="credit-card" /> Service Pass Beheer
          </Link>
        </Menu.Item>
      )}
      {userDetails.superUser && (
        <Menu.Item>
          <Link to="/license-plates-requests">Nummerplaat tool</Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="2">
        <Button
          type="dashed"
          style={{ margin: "0px 8px", height: "4vh" }}
          onClick={(evt) => signOut(evt)}
        >
          {getLabel("log out", true, strings)}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const dealerDropdown = useRef(null);

  const content = (innerElements) => {
    return (
      <>
        <div>
          {/* {strings && (
          <Row type='flex' align="middle" style={{
            position: 'fixed',
            zIndex: 2000,
            width: '100vw', height: '24px', padding: '0px 8px',
            backgroundColor: '#F5222D',
          }}>
            <Col span={24}>
              <h4 style={{color: 'white', textAlign: 'center'}}>{getLabel('downtime-banner', true, strings)}</h4>
            </Col>
            </Row>
        )} */}
          <Row type='flex' align="middle" style={{
            position: 'fixed',
            zIndex: 1000,
            width: '100vw', height: '8vh', padding: '0px 8px',
            backgroundColor: 'white',
            boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
          }}>
            <Col span={12}>
              <Link to={!!userDetails.companyIds ? '/employersportal' : '/'}><img src='/res/img/B2Bike_logo.jpg' alt='' style={{ height: '4vh' }} /></Link>
              <Button type='primary' size='large'>
                <Link to={!!userDetails.companyIds ? '/employersportal' : '/'}><Icon type='home' /></Link>
              </Button>
              {userDetails.dealerIds ? userDetails.dealerIds.length > 0 && (<>
                <Button style={{ marginLeft: 8 }} type='ghost'>
                  <Link to={'/info'}>{getLabel('info voor dealers', true, strings)}</Link>
                </Button>
              </>) 
              : <></>
              }
              {userDetails.superUser && 
              <Button>
                <Link to={window.location.pathname.includes('employersportal') ? '/': '/employersportal'}>{window.location.pathname.includes('employersportal')? "Terug naar Dealers": "Naar Werkgeversportaal"}</Link>
                </Button>}
            </Col>
            {innerElements}
          </Row>
          {/* <Row type='flex' align="middle" 
      visible={!!currentUser}
      style={{
          // position: 'fixed',
          marginTop: '8vh',
          zIndex: 1000,
          width: '100vw', height: '8vh', padding: '0px 8px',
          backgroundColor: 'white',
          boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
        }}>
        <h4 style={{color: 'red'}}>{getLabel('banner upload issues', true, strings)}</h4>
        </Row> */}
        </div>
      </>
    );
  };

  if (
    !currentUser ||
    !userDetails ||
    window.location.href.includes("/employersportal")
  ) {
    return content(null);
  }
  return content(
    <Col span={12}>
      <Modal
        visible={showModal}
        width={720}
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}
        destroyOnClose={true}
      >
        <CompanyInfoCard company={companyContext} token={token} />
      </Modal>
      {/* {(!!userDetails.dealerIds && !!userDealers) && ( */}
      <Row type="flex" justify="end">
        {!!userDetails.dealerIds &&
          !!userDealers &&
          userDetails.dealerIds.length > 0 &&
          userDealers.length > 0 && (
            <Select
              ref={dealerDropdown}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={dealerIndex}
              style={{ width: "15vw", margin: "0px 8px", height: "4vh" }}
              disabled={userDealers.length === 1}
              onChange={(val) => {
                dispatch({
                  type: "setDealerIndex",
                  newIndex: val,
                });
              }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    // onMouseDown={e => e.preventDefault()}
                    onClick={() => {
                      dispatch({
                        type: "setDealerIndex",
                        newIndex: userDealers.indexOf(backofficeDealer),
                      });
                      dealerDropdown.current.blur();
                    }}
                  >
                    {userDetails.superUser && (
                      <p style={{ margin: "4px 8px", cursor: "pointer" }}>
                        <b>{backofficeDealer.name}</b>
                      </p>
                    )}
                  </div>
                </div>
              )}
            >
              {userDealers.map((el, index) => {
                return (
                  <Option value={index} key={index}>
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          )}
        {companyContext != null && currentUser && (
          <Button
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <Icon type="info-circle" />
          </Button>
        )}
        {userDetails.dealerIds && userDetails.dealerIds.length > 0 && (
          <Button type="default" style={{ margin: "0px 8px", height: "4vh" }}>
            <Link to="/quotations">
              {userDetails.superUser
                ? "Offertes"
                : getLabel("my quotations", true, strings)}
            </Link>
          </Button>
        )}
        {userDetails.dealerIds && userDetails.dealerIds.length > 0 && (
          <Button type="default" style={{ margin: "0px 8px", height: "4vh" }}>
            <Link to="/contact"><Icon type="phone" /> {getLabel("contact us", true, strings)}</Link>
          </Button>
        )}
        <Dropdown overlay={accountMenu}>
          <a className="ant-dropdown-link" href="#">
            <Icon style={{ fontSize: "16pt" }} type="menu" />
          </a>
        </Dropdown>
      </Row>
    </Col>
  );
};

export default Header;
