import { message, Modal, Spin, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { givacarApiUrlBase } from './servicePassActions';
import { useI18nContext, getLabel } from '../../api/i18nService';


const ServicePassTransactions = props => {

    const { strings } = useI18nContext();

    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    useEffect(()=>{
        setTransactions([]);
        if(!!props.visible&&!!props.barcode)
            collectTransactions(props.barcode);
    }, [props.visible]);

    const onClose=()=>{
        setLoading(false);
        setTransactions([]);
        props.setVisible(false);
    }

    const onCollectFail=()=>{
        message.warn(getLabel('Probleem bij het ophalen van de transacties', true, strings));
        onClose();
    }

    const collectTransactions=async(barcode)=>{
        setLoading(true);
        try{
            const { data: {ResultCode, Transactions } } = await axios({
                method: 'post',
                url: givacarApiUrlBase + '/getTransactionsForCard',
                data: { barcode: barcode },
                headers: {
                    authorization: localStorage.getItem('sessionToken')
                }
            });

            if(!!ResultCode&&ResultCode[0]=="0" &&!!Transactions){
                setTransactions(Transactions[0]);
            }else{
                onCollectFail();
            }
        }catch(err){
            onCollectFail();
        }
        setLoading(false);
    }

    const cols = [
        {
            title: getLabel('Transactie', true, strings),
            dataIndex: 'Transactie',
            key: 'Transactie',
            render: (txt, record)=> record.TransactionId[0]
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            render: (txt, record)=> record.Type[0]
        },
        {
            title: getLabel('dealer', true, strings),
            dataIndex: 'Retailer',
            key: 'Retailer',
            render: (txt, record)=> record.Retailer[0].Name? record.Retailer[0].Name[0]: 'Ongekend'
        },
        {
            title: getLabel('value', true, strings),
            dataIndex: 'Waarde',
            key: 'Waarde',
            render: (txt, record)=> `€ ${(parseInt(record.Value[0])/100).toFixed(2)}`
        },
        {
            title: getLabel('date', true, strings),
            dataIndex: 'Datum',
            key: 'Datum',
            render: (txt, record)=> record.TransactionHeader[0].TransactionTime[0].replace("T", " ")
        },
    ];

    return(
        <Modal
            style={{marginTop: '20vh', minWidth: '75vw'}}
            zIndex={1000}
            visible={props.visible}
            destroyOnClose={true}
            onCancel={() => onClose()}
            closable={true}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            cancelText={getLabel('back', true, strings)}
            title={`${getLabel('Transacties van', true, strings)}: ${props.barcode}`}>

            <Spin spinning={loading} style={{width: "100%", marginTop: 25, marginBottom: 25}} />

            {!loading&&
                <Table columns={cols} dataSource={transactions.GiftCardTransaction} />
            }
        </Modal>
    );
}

export default ServicePassTransactions;