import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Table, Spin, Input, Icon, Button, Row, Col, Checkbox, Switch, Select, message, Empty } from 'antd';
import { useStateValue, backofficeDealer } from '../stateProvider/stateProvider';
import Column from 'antd/lib/table/Column';
import { Link, Redirect } from 'react-router-dom';
import { QUERIES } from '../../api/queries';
import moment from 'moment';
import { useAuthContext } from '../../auth/auth';
import { useI18nContext, getLabel } from '../../api/i18nService';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

function QuotationList(props) {
  const { strings } = useI18nContext();
  moment.locale('nl');

  const getSessionObject = (key, original)=>{
    const sessionVars = JSON.parse(sessionStorage.getItem(key));
    if(sessionVars != null)
      return sessionVars;
    else
      return original;
  };

  // const { currentUser, userDetails } = useAuthContext();
  const [{ dealerIndex, currentDealer, allDealers, currentUser, userDetails, token }, dispatch] = useStateValue();
  const [query, setQuery] = useState(QUERIES.QUOTATIONS_ALL_FOR_DEALER_QUERY);
  const [queryVariables, setQueryVariables] = useState(getSessionObject("queryVariables", { id: currentDealer ? currentDealer.id : null }));
  const [showAllDealers, setShowAllDealers] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [searchTerm, setSearchTerm] = useState(getSessionObject("searchTerm", ''));
  const [sortDirections, setSortDirections] = useState(getSessionObject("sortDirections" ,{}));
  const [activeQuotations, setActiveQuotations] = useState([]);
  const [updateQuotation] = useMutation(QUERIES.UPDATE_QUOTATION);
  // const [{ company: companyContext }, dispatch] = useStateValue();
  const [quotations, setQuotations] = useState([]);
  const [queryOffset, setQueryOffset] = useState(1);
  const [queryOffsetForUI, setQueryOffsetForUI] = useState(1);
  const [selectedQuotations, setSelectedQuotations] = useState(null);
  const querySize = 1000;
  const { Option } = Select;

  const [getQuotations, { loading, error, data}] = useLazyQuery(query, {
    variables: queryVariables,
      // fetchPolicy: 'cache-and-network',
      fetchPolicy: 'no-cache',
      onCompleted: queryRes => {
        // console.log(queryRes, queryVariables);
        onQueryComplete(queryRes);
      }
  });
  useEffect(()=>{
    getQuotations();
  }, []);

  /*const { loading, error, data, refetch } = useQuery(query,
    {
      variables: queryVariables,
      // fetchPolicy: 'cache-and-network',
      fetchPolicy: 'no-cache',
      onCompleted: queryRes => {
        // console.log(queryRes, queryVariables);
        onQueryComplete(queryRes)
      }
    },
  );*/

  const { loading:orderStatusesLoading } = useQuery(QUERIES.ORDER_STATUSES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: queryRes => {
        if(queryRes.orderStatuses&&queryRes.orderStatuses.length>0);
          setOrderStatuses(queryRes.orderStatuses);
      }
    },
  );

  const onQueryComplete = queryRes => {
    // if (!queryRes) {
    //   console.log('queryRes in oncomplete:', queryRes);
    //   return;
    // }
        if (queryRes.dealer) {
          // console.log('queryRes has dealer');
          setQuotations(queryRes.dealer.quotations);
        }
        else if (queryRes.quotations) {
          // console.log('queryRes has quotations')
          setQuotations(queryRes.quotations);
        }
        else {
          // console.error('No quotations in res?');
        }
  };

  useEffect(() => {
    setActiveQuotations(quotations.filter(el => !!el.active));
    // console.log(quotations.filter(el => !!el.active));
  }, [quotations])

  useEffect(() => {
    if (!!currentDealer && token) {
      if (currentDealer.id === backofficeDealer.id) {
        // console.log('setting query...');
        setQuery(QUERIES.QUOTATIONS_ALL_QUERY);
        setShowAllDealers(true);
        setQueryVariables(old => {
          if(old.filter != null)
            delete old.filter.dealer_fk;
          return { ...old, limit: 10, page: queryOffset, filter: { ...old.filter, active: activeOnly } };
          });
      }
      else {
        setQuery(QUERIES.QUOTATIONS_ALL_QUERY);
        setShowAllDealers(false);
        setQueryVariables(old =>{
          return { ...old, limit: 10, page: queryOffset, filter: { ...old.filter,  active: activeOnly, dealer_fk: currentDealer.id }};
        });
        }
    }
  }, [currentDealer, queryOffset, activeOnly])

  useEffect(() => {
    // console.log('Current query variables', queryVariables);
    getQuotations();

    sessionStorage.setItem("queryVariables", JSON.stringify(queryVariables));
  }, [queryVariables]);

  useEffect(() => {
    dispatch({
      type: 'changeCompany',
      newCompany: null
    });
  }, [dispatch]);

  //Session based store filter & sorting
  useEffect(()=>{
    // console.log("Storing new searchTerms: ", searchTerm);
    sessionStorage.setItem("searchTerm", JSON.stringify(searchTerm));
  }, [searchTerm]);

  useEffect(()=>{
    sessionStorage.setItem("sortDirections", JSON.stringify(sortDirections));
  }, [sortDirections]);


  if (!!userDetails.companyIds){
    return null
  }
  
  let searchInput = null;
  const searchColumn = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex=="order_status"?
          <Select loading={orderStatusesLoading} disabled={orderStatusesLoading} defaultValue={searchTerm[dataIndex]} style={{ width: 188, marginBottom: 8, display: 'block' }} onChange={(val)=>setSelectedKeys(val?[val]:[])}>
          {orderStatuses.map((item)=> {
             return <Option value={item.name} key={`status_option_${item.index}`}>{getLabel(item.code.toLowerCase(), true, strings)}</Option>
          })
          }
        </Select>
        :
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={'Zoeken'}
            defaultValue={searchTerm[dataIndex]}
            // value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        }
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="filter"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {getLabel('filter', true, strings)}
        </Button>
        <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      // console.log(dataIndex);
      // console.log(value, record);

      if (record[dataIndex] || record.employee[dataIndex] || record.order.orderStatus["name"]) {
        // console.log('filterValue: ', value);

        if (dataIndex === 'quotation_number') {
          let checkedValue = value;
          if (value.toLowerCase()[0] !== 'q') {
            checkedValue = `Q${value}`;
          }
          setQueryVariables(old => {
            return { ...old, filter: { ...old.filter, quotation_number: checkedValue } }
          })
          return;
        }

        if(dataIndex == "company"){
            setQueryVariables(old => {
              return { ...old, filter: { ...old.filter, company_name: value } }
            })
            return;
        }

        if(dataIndex == "employee"){
            setQueryVariables(old => {
              return { ...old, filter: { ...old.filter, employee_name: value } }
            })
            return;
        }

        if(dataIndex == "order_status"){
          setQueryVariables(old => {
            return { ...old, filter: { ...old.filter, order_status_name: value } }
          })
          return;
      }

        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible&&searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: text => (
    //   <Highlighter
    //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //     searchWords={[searchTerm]}
    //     autoEscape
    //     textToHighlight={text.toString()}
    //   />
    // ),
  });

  const getFilterKey = (dataIndex)=>{
    switch (dataIndex) {
      case "order_status":
        return "order_status_name";
      case "company":
        return "company_name";
      case "quotation_number":
        return "quotation_number";
      case "employee":
        return "employee_name";
      default:
        return "";
    }
  }

  const getAPISearchKey=(key)=>{
    switch (key) {
      case "employee":
        return "employee.name";
      case "company":
        return "company.name";
      case "quotation_number":
        return "quotation_number";
      case "order_status":
        return "order_status.name";
      case "quotation_date":
        return "quotation_date";
    }
  }

  const handleSort = (sort)=>{
    //Check if we really sorted (instead of filtering).
    if(Object.keys(sort).length === 0)
      return;

    const key = sort.columnKey;
    const API_KEY = getAPISearchKey(key);

    let order = sort.order;
    if(order == "ascend")
      order = "asc";
    else if(order == "descend")
      order = "desc";
    else if(order == null){
      setQueryVariables(old => {
        delete old.sort;
        return  { ...old }
      });
      setSortDirections({
        [key]: sort.order
      });
      getQuotations();
      return;
    }
    
    setQueryVariables(old => {
      return { ...old, sort: {
        field: API_KEY,
        direction: order
      } }
    });
    setSortDirections({
      [key]: sort.order
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (!!showAllDealers) {
      setQueryOffset(1);
    }

    setSearchTerm({
      ...searchTerm,
      [dataIndex]: selectedKeys[0]
    });

    const filterKey = getFilterKey(dataIndex);
    if (!quotations || quotations.length <= 0 && filterKey != "") {
      setQueryVariables(old => {
        return { ...old, filter: { ...old.filter, [filterKey]: selectedKeys[0] } }
      })
      getQuotations();
    }
  };

  const handleNoQuotationsReset = ()=>{
    setQueryVariables(old => {
      if(!showAllDealers)
        old.filter = {  active: activeOnly, dealer_fk: currentDealer.id };
      else
        old.filter = {  active: activeOnly };
      return {...old}
    });
    setSearchTerm('');
    getQuotations();
  }

  const handleReset = (clearFilters, dataIndex) => {

    clearFilters();
    //if (!!showAllDealers) {
      if (dataIndex === "company") {
        setQueryVariables(old => {
          delete old.filter.company_name;
          return {...old};
        });
      }
      if (dataIndex === "employee") {
        setQueryVariables(old => {
          delete old.filter.employee_name;
          return {...old};
        })
      }
      if (dataIndex === "quotation_number") {
        setQueryVariables(old => {
          delete old.filter.quotation_number;
          return {...old};
        })
      }
      if (dataIndex === "order_status") {
        setQueryVariables(old => {
          delete old.filter.order_status_name;
          return {...old};
        })
      }
      // setQueryVariables(old => ({...old, filter: {active: activeOnly}}));
      setSearchTerm(old => {
        delete old[dataIndex];
        return {...old};
      });
    //}
    setQueryOffset(1);
    getQuotations();
    // setSearchTerm('');
  };

  // const [updateQuotation] = useMutation(QUERIES.UPDATE_QUOTATION);

  const allDealerToggle = (
    
      <Row type='flex' justify='start'>
        {/* <Checkbox checked={!showAllDealers}
          onChange={e => {
            const val = e.target.checked;
            console.log(val);
            setShowAllDealers(!val);
          }}
        >Enkel huidige dealer</Checkbox> */}
        { (
          <span style={{ marginLeft: '16px', display: 'inline-flex' }}>
            <Button
              disabled={queryOffset <= 1}
              onClick={() => {
                const offset = queryOffset - 1;
                setQueryOffset(offset);
                setQueryOffsetForUI(offset);
              }}
            >
              Vorige
          </Button>
            {/* <h4>Pagina {queryOffset} geladen ({(queryOffset * querySize) - querySize} ~ {querySize * queryOffset})</h4> */}

            <Input style={{ margin: '0px  16px', width: '128px' }} type='Number'
            value={queryOffsetForUI}
            onKeyPress={
              e => {
                if(e.key == 'Enter'){
                  const val = DOMPurify.sanitize(e.target.value);
                  if (val > 0) {
                    setQueryOffset(parseInt(val));
                    setQueryOffsetForUI(parseInt(val));
                  }
                }
              }
            }
            onBlur={(e)=>{
              setQueryOffset(parseInt(e.target.value));
            }}
            onChange={
              e=>{
                setQueryOffsetForUI(parseInt(e.target.value));
              }
            }
            ></Input>
            <Button
              onClick={() => {
                const offset = queryOffset + 1;
                setQueryOffset(offset);
                setQueryOffsetForUI(offset);
              }}
            >
              Volgende
          </Button>
          </span>
        )}
      </Row>
    
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      if (selectedRows.length > 0) {
        setSelectedQuotations(selectedRows);
      }
      else {
        setSelectedQuotations(null);
      }
    }
  };

  if (loading) {
    return (<Row type='flex' justify='center' gutter={16}>
      <Col style={{ marginTop: '128px' }} span={1}>
        <Spin size='large'></Spin>
      </Col>
    </Row>)
  }

  if (error) {
    console.error(error.message);
    return <p>Error: {error.message}</p>
  }

  else {
    if (!data || !quotations) {
      return null;
    }
    // console.log(data);
    /*if (!quotations || quotations.length <= 0) {
      return (<div>
        {allDealerToggle}
        <h1>No quotation found</h1>
      </div>)
    }*/
    quotations.forEach(el => {
      el.employee.fullName = el.employee.first_name + ' ' + el.employee.last_name;
    });
    // console.log(data.quotations);
    return (
      <div>
        {allDealerToggle}
        <Row type='flex' justify='center'>
          <Table
          pagination={false}
          onChange={(pag, filt, sort)=>{
            handleSort(sort);
          }} 
          locale = {{
            emptyText: (<Empty description={
              <span>
                {getLabel('Geen quotations gevonden volgens de ingestelde filters', true, strings)}.<br/>

                {getLabel('Probeer de filters manueel of in bulk (via de onderstaande knop) te herstellen', true, strings)}.<br/>

                <Button type="primary" onClick={handleNoQuotationsReset} >RESET</Button>
              </span>
            }/>)
          }}
            rowSelection={rowSelection}
            dataSource={activeOnly ? activeQuotations : quotations}
            style={{ width: '80vw', margin: 16 }} rowKey={record => record.id}>
            <Column
              title={getLabel('quotation number', true, strings)}
              dataIndex='quotation_number'
              render={(text, record) => {
                return (
                  // <Link to={"/quotations/" + record.employee.id + '/' + record.id}>{record.quotation_number}</Link>
                  <Button type='primary' size='large' onClick={() => {
                    // dispatch({ type: 'setDealerIndex', newIndex: allDealers.indexOf(allDealers.find(el => el.id == record.dealer.id)) });
                    // return <Redirect to={"/quotations/" + record.employee.id + '/' + record.id}/>
                    props.history.push("/quotations/" + record.employee.id + '/' + record.id);
                  }}>{record.quotation_number}</Button>
                );
              }
              }
              sorter={true}
              sortOrder={sortDirections.quotation_number}
              sortDirections={['ascend', 'descend']}
              {...searchColumn('quotation_number')}
            />
            <Column
              title={getLabel('bike type', true, strings)}
              dataIndex=""
              render={record => (
                <p>{getLabel(record.bikeType.name, true, strings)}</p>
              )}
            />
            <Column
              title={getLabel('deliverability', true, strings)}
              dataIndex=""
              render={record => (
                <p>{record.deliverability}</p>
              )}
            />
            <Column
              title={getLabel('employee', true, strings)}
              dataIndex={'employee'}
              render={(text, record) => (
                <p>{record.employee.first_name} {record.employee.last_name}</p>
              )}
              sorter={true}
              sortOrder={sortDirections.employee}
              sortDirections={['ascend', 'descend']}
              {...searchColumn('employee')}
            />
            <Column
              title={getLabel('company', true, strings)}
              dataIndex={'company'}
              render={(text, record) => (
                <p>{record?.company?.name}</p>
              )}
              sorter={true}
              sortOrder={sortDirections.company}
              sortDirections={['ascend', 'descend']}
              {...searchColumn('company')}
            />
            <Column
              title={getLabel('order status', true, strings)}
              dataIndex=""
              render={record => (
                <p>{record.order ? (record.order.orderStatus && getLabel(record.order.orderStatus.code.toLowerCase(), true, strings)) : getLabel('no order', true, strings)}</p>
                )}
              sorter={true}
              sortOrder={sortDirections.order_status}
              sortDirections={['ascend', 'descend']}
              {...searchColumn('order_status')}
              key="order_status"
            />
            <Column
              title={getLabel('quotation date', true, strings)}
              dataIndex=""
              render={record => (
                <p>{moment(Number(record.quotation_date)).format('DD/MM/YYYY')}</p>
              )}
              sorter={true}
              sortOrder={sortDirections.quotation_date}
              sortDirections={['ascend', 'descend']}
              key="quotation_date"
            />
            <Column
              title={getLabel('invoice target', true, strings)}
              dataIndex=""
              render={record => (
                <h5 style={{ overflow: 'hidden', textOverflow: 'ellipsis', color: '#a1a1a1' }}>{record?.company?.invoice_target?.name}</h5>
              )}
            />
            <Column
              title={(
                <Checkbox
                  defaultChecked={activeOnly}
                  onChange={e => {
                    const checked = e.target.checked;
                    // console.log(activeOnly)
                    setActiveOnly(checked);
                  }}
                >
                  {getLabel('active', true, strings)}
                </Checkbox>

              )}
              dataIndex=""
              render={record => (
                <Switch
                  defaultChecked={record.active}
                  onChange={e => {
                    // record.active = e;
                    // TODO: mutate record
                    updateQuotation({variables: {quotation: {id: record.id, active: e}}})
                    .then(res => {
                      getQuotations()
                      .then(res => {
                        onQueryComplete(res.data);
                      })
                    })
                    .catch(err => {
                      console.error(err);
                      message.error('Er ging iets mis...');
                    })
                    setQuotations(old => {
                      record.active = e;
                      old[old.indexOf(record)].active = e;
                      return old;
                    })
                    if (!!selectedQuotations) {
                      // let updatedQuotations = [];
                      // selectedQuotations.forEach((el, index) => {
                      //   el.active = e;
                      //   updatedQuotations.push(el);
                      //   console.log(e);
                      //   // TODO: mutate el
                      // })
                      // setSelectedQuotations(updatedQuotations);
                      let theseQuotes = [...quotations];
                      selectedQuotations.forEach(el => {
                        theseQuotes[theseQuotes.indexOf(el)].active = e;
                        // console.log(theseQuotes[theseQuotes.indexOf(el)])
                      });
                      setQuotations(theseQuotes);
                    }
                  }}
                />
              )}
            />
            {userDetails.superUser && (
              <Column
                title='Dealer'
                render={record => (
                  <Select
                    style={{ width: '256px' }}
                    defaultValue={record.dealer ? record.dealer.id : null}
                    onChange={val => {
                      updateQuotation({ variables: { quotation: { id: record.id, dealer_fk: val } } })
                        .then(res => {
                          message.success('offerte gewijzigd');
                          // refetch();
                        })
                        .catch(err => {
                          console.error('QuotationList - updateQuotation', err);
                          message.error('Er ging iets mis...');
                        })
                    }}
                  >
                    {allDealers.map((el, index) => {
                      return <Option value={el.id} key={index}>{el.name}</Option>
                    })}
                  </Select>
                )}
              />
            )}
          </Table>
        </Row>
        {showAllDealers}

      </div>
      
    )
  }
}

export default QuotationList;