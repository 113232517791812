import React, { useEffect, useState } from 'react';
import { QUERIES } from '../../api/queries';
import { useStateValue } from '../stateProvider/stateProvider';
import { useLazyQuery } from '@apollo/react-hooks';
import { Table, Row, Button, Icon, Modal, message, Input, Select } from 'antd';
import { getDateString, getDestinationName, getServiceUrl } from '../../util/util';
import axios from 'axios';
import Column from 'antd/lib/table/Column';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
const Option = Select.Option;

const WebhookAttemptSettings = props => {

  const [{ token }, dispatch] = useStateValue();
  const [webhookAttempts, setWebhookAttempts] = useState([]);
  const [modalData, setModalData] = useState({
    title: "",
    content: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [webhookOptions, setWebhookOptions] = useState(null);
  const [queryVars, setQueryVars] = useState({
    page: 1,
    size: 10,
    filter: {
      success: null,
      quotation_number: null
    }
  });
  const [totalAttempts, setTotalAttempts] = useState(null);

  const [getWebhookAttempts, { loading, error }] = useLazyQuery(QUERIES.WEBHOOK_ATTEMPTS, {
    variables: queryVars,
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      onQueryComplete(res);
    }
  });

  const onQueryComplete = queryRes => {
    const attempts = queryRes.webhookAttempts;
    setWebhookOptions(queryRes.webhooks);
    setWebhookAttempts(attempts);
    if(!totalAttempts && !!queryRes && !!queryRes.webhookAttempts && queryRes.webhookAttempts.length > 0 && Number(queryRes.webhookAttempts[0].id) > 5000){
      setTotalAttempts(Number(queryRes.webhookAttempts[0].id));
    }
  }

  useEffect(()=>{
    getWebhookAttempts();
  }, []);

  useEffect(()=>{
    getWebhookAttempts();
  }, [queryVars]);

  useEffect( () => () => console.log("unmount"), [] );

  let searchInput = null;
  const searchColumn = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex != "success"? <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={'Zoeken'}
          // defaultValue={selectedKeys[0]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />:
        <Select defaultValue={null} style={{ width: 120 }} value={selectedKeys[0]} onChange={(val) => setSelectedKeys(val ? [DOMPurify.sanitize(val)] : [])} allowClear style={{ width: 188, marginBottom: 8, display: 'block' }}>
          <Option value={true}>Ok</Option>
          <Option value={false}>Niet Ok</Option>
        </Select>
        }
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="filter"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {'filter'}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return true;
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible && !!searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    console.log(selectedKeys);
    if (dataIndex === 'success') {
      let value = selectedKeys[0] === 'true'
      setQueryVars((old) => {
        return {...old, filter: {...old.filter, [dataIndex]: value}}
      });
    }
    else {
      setQueryVars((old) => {
        return {...old, filter: {...old.filter, [dataIndex]: selectedKeys[0]}}
      });
    }
  };

  const handleReset = clearFilters => {
    clearFilters();
  };


  const retryWebhook = async (webhookAttempt) => {
    try {
      message.info('Opnieuw triggeren...');
      const webhook = webhookOptions.find(el => el.id === webhookAttempt.webhook_fk);

      //handleWebhookPublish
      const handleWebhookPublishUrl = await getServiceUrl('handleWebhookPublish', token);
      const webhookRes = await axios.post(handleWebhookPublishUrl, {
        orderId: webhookAttempt.order_fk,
        dealId: null,
        webhooks: JSON.stringify([{
          id: webhook.id,
          url: webhook.url,
          invoice_target: webhook.invoice_target,
          webhook_format_fk: webhook.webhook_format_fk,
          filter: webhook.filter,
          deal_status_fk: webhook.deal_status_fk
        }])
      });
      message.success('Webhook opnieuw getriggerd!');
    }
    catch (err) {
      console.error(err);
      message.error('Er ging iets mis...');
    }
  };

  const onEyeClick = (record) => {
    const tempContentObj = JSON.parse(record.data);
    const tempContent = JSON.stringify(tempContentObj, undefined, 2);

    setModalData({
      title: "Webhook attempt: #" + record.id,
      content: tempContent
    })
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  }

  const getStatusString = (code) => {
    if (code == '200')
      return "Ok";

    return "Niet ok"
  };

  const changePagination = (page) => {
    setQueryVars(old=> {
      return {...old, page}
    });
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Table
          loading={loading}
          rowKey={record => record.id}
          style={{width: 1000, maxWidth: 1000, minWidth: 1000}}
          pagination={{
            defaultCurrent:1,
            current: queryVars.page,
            pageSize: 10,
            total: totalAttempts || 50000,
            onChange: (page)=>changePagination(page)
          }}
          dataSource={!!webhookAttempts? webhookAttempts: []}>
          <Column
            title='Status code'
            render={record => (<p style={{ color: String(record.status_code) === '200' ? '#7cbc20' : 'red' }}>{getStatusString(record.status_code)}</p>)}
            key={record => record.id + "status_code"}
            {...searchColumn('success')}
          />
          <Column
            title='Destination'
            render={record => (<p>{getDestinationName(record.url)}</p>)}
            key={record => record.id + "destination"}
          />
          <Column
            title='Quotation Nr'
            render={(text, record) => (<p>{record.quotation_number}</p>)}
            key={record => record.id + "quotationNr"}
            dataIndex='quotation_number'
            {...searchColumn('quotation_number')}
          />
          <Column
            title='Response'
            render={record => record.response}
            key={record => record.id + "response"}
          />
          <Column
            title={'Datum'}
            render={record => getDateString(record.date)}
            key={record => record.id + "date"}
          />
          <Column
            title={'Data'}
            render={(record) => {
              return (
                <>
                  <Button
                    shape='circle'
                    style={{ marginRight: '0px' }}
                    onClick={() => {
                      onEyeClick(record);
                    }}
                  ><Icon type="eye-o" /></Button>
                </>
              )
            }}
            key={record => record.id + "data"}
          />
          <Column
            title=''
            render={(record) => {
              return (
                <>
                  <Button
                    shape='circle'
                    style={{ marginRight: '0px' }}
                    onClick={() => {
                      retryWebhook(record)
                      // console.log(record)
                    }}
                  ><Icon type="redo" /></Button>
                </>
              )
            }}
            key={record => record.id + "retry"}
          />
        </Table>
      </Row>

      <Modal
        title={modalData.title}
        visible={showModal}
        onOk={closeModal}
        onCancel={closeModal}
        cancelText={'Annuleren'}
        footer={[
          <Button key="ok" size="large" onClick={closeModal}>OK</Button>,
        ]}
      >
        <br />
        <pre>{modalData.content}</pre>
        <br />
      </Modal>
    </div>
  );
};

export default WebhookAttemptSettings;