import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Modal, Table, Spin, Input, Icon, Button, Row, Col, List, Switch, Select, message, Empty, Dropdown, Menu, Tooltip, Tabs, Card, Popconfirm } from 'antd';
import { useStateValue, backofficeDealer } from '../stateProvider/stateProvider';
import { QUERIES } from '../../api/queries';
import { useI18nContext, getLabel } from '../../api/i18nService';
import moment from 'moment';
import fbApp from '../../firebase';
import { Link } from 'react-router-dom';

const Inbox = () => {
    const { strings } = useI18nContext();
    const [{ userDetails, currentUser }, dispatch] = useStateValue();
    const [notifications, setNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        dispatch({type: 'setCurrentEmployersPage', page: 'inbox'})
    }, [])

    useEffect(() => {
        if (!!currentUser) {
            const dbRef = fbApp.database().ref(`/notifications/${currentUser.uid}`);
            dbRef.on('value', (ss) => {
                if (!ss.val()) {
                    return;
                }
                const data = ss.val();
                setNotifications(Object.keys(data).map(key => {
                    const obj = { ...data[key], id: key };
                    if (!!obj.actionParams) {
                        for (let k of Object.keys(obj.actionParams)) {
                            obj.message = obj.message.replace(`<<${k}>>`, obj.actionParams[k]);
                        }   
                    }
                    return obj;
                }));
            })
        }
    }, [currentUser]);

    const markAsRead = async (item) => {
        fbApp.database().ref(`/notifications/${currentUser.uid}/${item.id}/readAt`).set(new Date().getTime());
    }

    const deleteNotification = async (item) => {
        setSelectedNotification(null);
        fbApp.database().ref(`/notifications/${currentUser.uid}/${item.id}`).set(null);
        if (notifications.length === 1) {
            setNotifications([]);
        }
    }

    const getNotificationDescription = (item) => {
        switch (item.action) {
            case 'goToQuotation':
                return (
                    <Row>
                        <Col span={6}>
                            <h3>Offerte</h3>
                            <h3>Werknemer</h3>
                        </Col>
                        <Col span={18}>
                            <h3>Q{item.actionParams.quotationId}</h3>
                            <h3>{item.actionParams.employeeName || 'Ongekend'}</h3>
                        </Col>
                    </Row>
                );
                break;
            default:
                return (
                    <Empty />
                )
        }
    }

    const getNotificationActions = (item) => {
        switch (item.action) {
            case 'goToQuotation':
                return [
                    <Popconfirm
                    title={`${getLabel('Bericht verwijderen', true, strings)}?`}
                    onConfirm={() => {
                        deleteNotification(item);
                    }}
                    >
                        <Button type="danger" icon="delete">
                            {getLabel('Bericht verwijderen', true, strings)}
                        </Button>
                    </Popconfirm>,
                    <Button type="primary" icon="file-search">
                        <Link to={`/employersportal/quotations/${item.actionParams? item.actionParams.quotationId: ''}`} style={{ color: 'white' }}>{getLabel('Raadplegen', true, strings)}</Link>
                    </Button>
                ]
                break;
            default:
                return (
                    <Empty />
                )
        }
    }

    return (
        <>
            <h1>{getLabel('Inbox', true, strings)}</h1>
            <Row>
                <Col span={10}>
                    <div style={{ width: '80%', height: '80vh' }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={notifications}
                            renderItem={item => (
                                <List.Item>
                                    <Card
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            markAsRead(item);
                                            setSelectedNotification({ ...item });
                                        }}>
                                        <Row>
                                            <Col span={20}>
                                                <h2>{item.message}</h2>
                                            </Col>
                                            <Col span={4}>
                                                {!item.readAt && (
                                                    <Icon type="mail" theme="twoTone" style={{ width: '100%', height: 'auto', fontSize: '24pt' }} twoToneColor='#f5222d' />
                                                )}
                                            </Col>
                                        </Row>
                                        <p>{new Date(item.createdAt).toLocaleString('nl-be')}</p>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
                <Col span={14}>
                    <div style={{ width: '80%', height: '80vh' }}>
                        {!selectedNotification && (<Empty />)}
                        {!!selectedNotification && (
                            <Card title={selectedNotification.message} actions={getNotificationActions(selectedNotification)}>
                                <p>{new Date(selectedNotification.createdAt).toLocaleString('nl-be')}</p>
                                <h5>{getNotificationDescription(selectedNotification)}</h5>
                            </Card>
                        )}
                    </div>

                </Col>
            </Row>
        </>
    )
}


export default Inbox
