import Axios from 'axios';
import { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";

import 'react-toastify/dist/ReactToastify.css';
import './util.css';


const { toast } = require("react-toastify");

const toastConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined
};

export const showInfoToast = (msg) => {
  toast.info(msg, toastConfig);
}

export const showErrorToast = (msg) => {
  toast.error(msg, toastConfig);
}

export const getServiceUrl = async (serviceName, token) => {
  let url = '';
  try {
    const response = await Axios({
      method: 'post',
      url: 'https://europe-west1-b2bike-253312.cloudfunctions.net/getServiceUrl',
      data: {
        service: serviceName,
        env: process.env.REACT_APP_ENV || 'development',
        user: {
          accessToken: token
        }
      }
    });
    url = response.data.serviceUrl;
  }
  catch (err) {
    console.error(err);
  }
  finally {
    return url;
  }
};

// TODO: Only case first char of entire string
export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const compareObjects = (a, b) => {
  // console.log('Util - Comparing objs: ', a, b);
  Object.keys(a).forEach(el => {
    if (a[el] !== b[el]) {
      return false;
    }
  });
  return true;
}

export const reverseString = str => {
  return str.split('').reverse().join('');
}

export const downloadFileFromBlob = (blob, fileName) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
};

export const getDateString = (millisString) => {
  const date = new Date(parseInt(millisString));

  const dateStr = `0${date.getDate()}`.slice(-2);
  const monthStr = `0${date.getMonth() + 1}`.slice(-2);
  const yearStr = date.getFullYear();
  const hourStr = `0${date.getHours()}`.slice(-2);
  const minStr = `0${date.getMinutes()}`.slice(-2);
  const secStr = `0${date.getSeconds()}`.slice(-2);

  return `${dateStr}-${monthStr}-${yearStr} ${hourStr}:${minStr}:${secStr}`;
}


export const getDestinationName = (url) => {
  const keys = ["adsoft", "ALD", "VAB"]
  for (const key of keys) {
    if (url.toLowerCase().includes(key.toLowerCase()))
      return key;
  }
  return url;
};

export const flattenObj = (object, prefix = '') => {
  return Object.keys(object).reduce((prev, element) => {
    return object[element] && typeof object[element] == 'object' && !Array.isArray(element)
      ? { ...prev, ...flattenObj(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
  }, {})
}

export const differenceOfTwoArrays = (arrayOne, arrayTwo) => {
  const temp = [];

  arrayOne = arrayOne.toString().split(',').map(Number);
  arrayTwo = arrayTwo.toString().split(',').map(Number);

  for (const i in arrayOne) {
    if (arrayTwo.indexOf(arrayOne[i]) === -1)
      temp.push(arrayOne[i]);
  }

  for (const i in arrayTwo) {
    if (arrayOne.indexOf(arrayTwo[i]) === -1)
      temp.push(arrayTwo[i]);
  }

  return temp.sort((a, b) => a - b);
}

// ! validation functions 
export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true
  }
  else {
    return false
  }
}

export const checkForPhoneValidation = (value) => {
  if (!value) return false

  return (
    value?.length >= 9 &&
    value?.length <= 19 &&
    (isValidPhoneNumber(value.toString()) ||
      isPossiblePhoneNumber(value.toString()) ||
      /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(value.toString()))
  )
}

export const checkWebsiteLink = (value) => {
  if (!value || value === '' || value.includes('https://')) {
    return value
  } else {
    return 'https://' + value
  }
}

export const replaceMultipleItemsInString = (value) => {
  const items = {
    '/': '',
    '.': '',
    '(': '',
    ')': '',
  }

  Object.keys(items).forEach((key) => {
    value = value.replaceAll(key, items[key])
  })

  return value
}

export const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

  return !!urlPattern.test(urlString);
}

// ! This is for validation of the postal codes for different countries, the values in this array are valid
// ! Belgium, The Netherlands and Germany are it for now 
export const validCountryCodes = ["BE", "NL", "DE"]
