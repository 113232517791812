import React from 'react'
import { Badge as AntBadge } from 'antd'

const Badge = (props) => (
  <AntBadge
    {...props}
    style={{
      backgroundColor: '#7cbc20',
      cursor: 'pointer',
      fontWeight: 'bold',
    }}
  />
)

export default Badge
