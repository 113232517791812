import React, { useState, useEffect } from 'react';
import { useStateValue } from '../stateProvider/stateProvider';
import { Modal, Input, List, Col, Row, Button, Icon } from 'antd';

const { Search } = Input;

const SearchDealer = props => {
  const [visible, setVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState(props.name || '');
  const [filteredDealers, setFilteredDealers] = useState([]);
  const [{allDealers}] = useStateValue();

  useEffect(() => {
    setFilteredDealers(allDealers.filter(el => {
      return el.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
    }));
  }, [searchTerm]);

  return (
    <Modal
      destroyOnClose={true}
      footer={false}
      visible={visible}
      onCancel={() => {
        props.onClose();
        setVisible(false);
      }}
    >
      <h3>Selecteer Dealer</h3>
      <Search
      defaultValue={searchTerm}
      onSearch={val => {
        setSearchTerm(val);
      }}
      />
      <List>
        <h4>Ongeregistreerde dealer:</h4>
        <List.Item key={'dummy'}>
            <List.Item.Meta
              style={{ width: '100%' }}
              description={<Row type='flex' gutter={8} style={{ width: '100%' }}>
                <Col span={20}>
                  {searchTerm}
                </Col>
                <Col span={4}>
                  <Button
                    onClick={() => {
                      props.onSelect({ dealer: null, dealerName: searchTerm});
                      setVisible(false);
                      props.onClose();
                    }}
                    shape='circle'><Icon type="check" /></Button>
                </Col>
              </Row>}
            />

          </List.Item>
          <h4>Geregistreerde dealers:</h4>
        {filteredDealers.map((el, index) => {
          return (
            <List.Item key={index}>
            <List.Item.Meta
              style={{ width: '100%' }}
              description={<Row type='flex' gutter={8} style={{ width: '100%' }}>
                <Col span={20}>
                  {el.name}
                </Col>
                <Col span={4}>
                  <Button
                    onClick={() => {
                      props.onSelect({ dealer: el});
                      setVisible(false);
                      props.onClose();
                    }}
                    shape='circle'><Icon type="check" /></Button>
                </Col>
              </Row>}
            />

          </List.Item>
          )
        })}
      </List>
    </Modal>
  )
};

export default SearchDealer;