import React from "react";
import { Alert as AntAlert } from "antd";

import { noop } from "../../utils";

const Alert = ({
  message = "Provide message",
  type = "error",
  showIcon = true,
  onClose = noop,
  ...props
}) => (
  <AntAlert
    message={message}
    type={type}
    description={props.description}
    showIcon={showIcon}
    closable
    onClose={onClose}
  />
);

export default Alert;
