import fbApp from '../firebase';
import React, { useEffect, useState, useContext } from 'react';
import { useStateValue } from '../components/stateProvider/stateProvider';
import axios from 'axios';
import { getServiceUrl } from '../util/util';

/**
 * i18nService.js
 * Aaaah, le français...
 * Uses the React Context Api to provide translations across the app
 * 
 * ***** For daily use: ***** 
 * -> import { useI18nContext, getLabel } from 'here'
 * -> const { strings } = useI18nContext();
 * -> getLabel('MY_CODE', true, strings); // 'Mijn code'
 */

export const I18nContext = React.createContext({ strings: null });
export const useI18nContext = () => useContext(I18nContext);

/**
 * getLabel - returns a string of text in the current language.
 * @param {String} code - Key of the text
 * @param {boolean} toUpperCase - Should the first letter be caps (title case)?
 * @param {Object} strings - Object mapping keys (code) to text (return value) - Exported by useI18nContext()
 */
export const getLabel = (code, toUpperCase, strings) => {
  if (strings && code) {
    let term = strings[code];
    if (!term) {
      // If there is no text for the given code, return the code itself
      term = code;
    }
    if (toUpperCase) {
      return (term.charAt(0).toUpperCase() + term.slice(1));
    }
    return (term);
  };
}

/**
 * I18nProvider
 * React component that provides the i18n context
 * @dependency AuthContext
 */
export const I18nProvider = ({ children }) => {

  const [{ currentUser, token }, dispatch] = useStateValue();
  const [strings, setStrings] = useState(null);
  const langCodesArr = ["nl", "fr", "en", "de"];
  const [langStringsArr, setLangStringsArr] = useState([]);
  const [langStringsObj, setLangStringsObj] = useState({});
  const [langCode, setLangCode] = useState('nl');

  // Check the user's language whenever the currentUser changes and update accordingly
  useEffect(() => {
    let lang = null;
    if (currentUser !== null && token !== null) {
      getServiceUrl('manageUsers', token).then(url => {
        axios.post(url,
          { mode: 'READ', include: [] },
          {
            headers: {
              Authorization: token
            }
          }
        ).then(users => {
          getStrings(users.data.me.language.toLowerCase());
        })
      })
    }
    else {
      getStrings(lang);
    }
  }, [currentUser, token]);

  //Get all language lists for the language settings page
  useEffect(() => {
    getAllLangStrings();
  }, [])

  /* Gets the dictionary from Firebase based on the users language. 
  Defaults to the browser language or dutch in no language is provided.
  */
  const getStrings = (lang) => {
    const browserLang = navigator.language.substr(0, 2) || navigator.userLanguage.substr(0, 2);
    if (!lang) {
      if (browserLang === 'fr' || browserLang === 'nl' || browserLang === 'en' || browserLang === 'de') {
        lang = browserLang;
      }
      else {
        lang = 'nl';
      }
    }
    fbApp.database().ref('/i18n/' + lang + '/').once('value').then(snapshot => {
      setStrings(snapshot.val());
    }).catch(err => {
      console.error("i18Service - error: ", err);
    });
  }

  const getAllLangStrings = async () => {
    let languageKeys = [];

    //Get all translations
    for (const code of langCodesArr) {
      await fbApp.database().ref('/i18n/' + code + '/').once('value').then(snapshot => {
        const resVal = snapshot.val();
        let newObj = langStringsObj;
        //Turn strings object into an array for further table use
        newObj[code] = Object.keys(resVal).map((key) => {
          languageKeys.push(key);
          return [key, resVal[key]];
        });
        setLangStringsObj(newObj);
      }).catch(err => {
        console.error("i18Service - error: ", err);
      });
    }

    //Get all unique translation keys
    languageKeys = Array.from(new Set(languageKeys));

    let tempTransArr = [];
    //Get all translations for every unique key
    for (const key of languageKeys) {
      let tempTransItem = [];
      tempTransItem.push(key);
      //Search for translation within each language
      for (const code of langCodesArr) {
        const translatedValue = langStringsObj[code].find((item) => {
          return item[0] == key;
        });

        if (translatedValue == null)
          tempTransItem.push("");
        else
          tempTransItem.push(translatedValue[1]);
      }
      tempTransArr.push(tempTransItem);
    }

    setLangStringsArr(tempTransArr);
  }

  const updateTranslationString = (langCode, stringKey, newValue, callBack, callBackErr) => {
    fbApp.database().ref(`/i18n/${langCode}/${stringKey}`).set(newValue).then(() => callBack()).catch((err) => callBackErr());
  }

  return (
    <I18nContext.Provider value={{ strings, langStringsArr, langCodesArr, langCode, updateTranslationString, getAllLangStrings }}>
      {children}
    </I18nContext.Provider>
  );
}
