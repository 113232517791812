import { notification } from "antd";

export const openNotificationWithIcon = (props) => {
  notification[props.type]({
    duration: props.duration ?? 3,
    message: props.message,
    description: props.description,
    style: getStyleByNotificationType[props.type],
  });
};

const successStyle = {
  backgroundColor: "#f6ffed",
  border: "1px solid #b7eb8f",
};

const infoStyle = {
  backgroundColor: "#e6f7ff",
  border: "1px solid #91d5ff",
};

const warningStyle = {
  backgroundColor: "#fffbe6",
  border: "1px solid #ffe58f",
};

const errorStyle = {
  backgroundColor: "#fff1f0",
  border: "1px solid #ffa39e",
};

const getStyleByNotificationType = {
  success: successStyle,
  info: infoStyle,
  warning: warningStyle,
  error: errorStyle,
};
