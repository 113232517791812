import React, { useEffect } from 'react';
import { Row, Button, Col, Modal, Icon, Menu, Dropdown, Select, Divider } from 'antd';
import { useStateValue } from '../stateProvider/stateProvider';


const InfoEndOfLease = (props) => {
    const [{ dealerIndex, currentDealer, allDealers, currentUser, userDetails, token }, dispatch] = useStateValue();

    if (!userDetails) {
        return (
            <p></p>
        )
    }
    if (userDetails.language === 'fr') {
        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        zIndex: 1000,
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Information reprise vélo</h1>
                    <p>Voici vous trouverez le flux pour vérifier les vélos qui vous sont apportés lors de la résiliation – anticipée ou non – du contrat.</p>

                    <p>Un vélo en fin de contrat doit être restitué dans un état propre, en bon état et utilisable avec tous les éléments qui étaient présents lors de la livraison d’origine (y compris tous les documents, les clés, le chargeur etc.). Sauf accord contraire, cette opération a lieu dans le magasin qui a initialement livré le vélo. L’utilisateur est responsable du retrait de tous les objets personnels attachés au vélo. Si le vélo n’est pas propre, des frais de nettoyage peuvent être facturés. En cas de dommages inacceptables, de manque d’entretien, de défectuosité ou de pièces et accessoires manquants, un devis sera établi et envoyé à l’utilisateur (ou à l’employeur).</p>

                    <p>Nous pouvons nous attendre à ce qu’un vélo soit retourné dans un état correct. Les dommages normaux causés par l’utilisateur sont acceptés. Les petites éraflures sur le cadre dues aux fermetures éclair, par exemple les dommages à la peinture du porte-bagages dus à l’utilisation de sacoches, etc. sont acceptées. Quand le dommage est le résultat d’une chute, d’une collision, etc., ce dommage doit encore être déclaré à l’assureur. Si le dommage n’est pas accepté, la réparation sera facturée à l’utilisateur.</p>

                    <p>Vous pouvez télécharger le <b>document de reprise du vélo</b> ici: <a href='https://docs.google.com/document/d/1xsxdWZEEe8aYjmJmfa76U4ZNwDGJ4p7F3DiikHtMyHc/edit?usp=sharing' target='_blank'>Document reprise du vélo</a></p>

                    <p>Veuillez le remplir et nous le renvoyer, accompagné d’une photo du vélo.</p>

                    <p>En tant que vélociste, vous êtes bien sûr le mieux placé pour juger si des pièces doivent être remplacées en raison de leur usure ou de leur détérioration. Vous trouverez également ici un <b>guide pour le contrôle de fin de leasing</b> qui détaille les différents points: <a href='https://docs.google.com/document/d/1uXnwlqFbiKn1QvXztdcAFsg2dswe1KHvNrv-wJp1hRE/edit?usp=sharing' target='_blank'>Guide de reprise des vélos en fin de contrat</a></p>

                    <p>Pour cette procédure, vous pouvez nous envoyer une facture de 25,00 EUR hors 6% de TVA avec comme référence reprise vélo.</p>
                </Col>
            </Row >
        )
    }
    else {
        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        zIndex: 1000,
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Info Inlevering Leasefiets</h1>
                    <p>Beste dealer,</p>

                    <p>Hierbij vind je de werkwijze om fietsen te controleren die worden ingeleverd bij – al dan niet vervroegde – contractbeëindiging en dit, behoudens anders afgesproken, in de winkel die de fiets oorspronkelijk geleverd heeft. Dit is van toepassing op alle fietsen die zijn aangekocht via B2Bike en nadien zijn ondergebracht bij de verschillende leasemaatschappijen.</p>

                    <p>Een einde-contract fiets dient in propere, goede en bruikbare staat teruggeleverd te worden met alle elementen die bij de nieuwe levering aanwezig waren (incl. alle originele documenten, sleutels, lader, toebehoren, enz.). De gebruiker is verantwoordelijk voor het verwijderen van alle persoonlijke items die op en aan de fiets zijn bevestigd. We mogen verwachten dat een fiets in correcte toestand ingeleverd wordt, normale gebruiksschade wordt aanvaard. Kleine krasjes op het frame door ritssluitingen bv. lakschade op bagagedrager door gebruik van fietstassen enz. worden aanvaard.</p>

                    <p>Bij onaanvaardbare schade, gebrek aan onderhoud, eventuele defecten of ontbrekende onderdelen en toebehoren zal een bestek worden opgevraagd en door B2Bike aan de gebruiker of werkgever overgemaakt worden.
                    <br/>In het geval dat de schade het gevolg is van een val, een aanrijding enz. dient deze schade alsnog aangegeven te worden bij de verzekeraar. Indien de schade niet aanvaard wordt zal de herstelling aan de bestuurder aangerekend worden.</p>

                    <p>De eindcontrole dient te gebeuren aan de hand van het innamedocument en  in samenspraak met de bestuurder. Voor de bestuurder is dit tevens een bevestiging van inlevering.</p>

                    <p>Het <b>innamedocument</b> vind je hier: <a href='https://docs.google.com/document/d/1lnSjyY5lWzOUC0W70kUvMUhPrTTDJ6xT0aw5Kqerv_I/edit?usp=sharing' target='_blank'>Document inname fiets</a> Gelieve ons dit ingevuld terug te bezorgen aan eindelease@b2bike.be, graag ook met een foto van de fiets.</p>

                    <p>Als fietsenhandelaar ben je uiteraard het best geplaatst om te oordelen of er onderdelen al dan niet aan vervanging toe zijn door slijtage of beschadiging. Hierbij vind je ook een <b>leidraad controle einde lease</b> die iets gedetailleerder ingaat op de verschillende items: <a href='https://docs.google.com/document/d/1MoPLbiZwiTfKOXTAMD92bJU3Bfg4-nDx17DvzCDcruM/' target='_blank'>Richtlijnen inname fiets</a></p>

                    <p>Voor de innameprocedure mag je ons een factuur van 25,00 EUR excl. 6% BTW overmaken met als referentie “Terugname leasefiets Naam bestuurder” en dit samen met het innamedocument.</p>
                    
                    <p>Alvast bedankt voor jullie service!
                    <br/>B2Bike Team</p>
                </Col>
            </Row >
        )
    }
};

export default InfoEndOfLease;