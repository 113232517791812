import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery  } from '@apollo/react-hooks';
import {Modal, Table, Spin, Input, Icon, Button, Row, Col, Checkbox, Switch, Select, message, Empty, Dropdown, Menu, Tooltip, Tabs, Card } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useStateValue, backofficeDealer } from '../stateProvider/stateProvider';
import Column from 'antd/lib/table/Column';
import { QUERIES } from '../../api/queries';
import moment from 'moment';
import './employersPage.css';
import { useI18nContext, getLabel } from '../../api/i18nService';

function CostCenters() {
  const { strings } = useI18nContext();
  const [isNameModalVisible, setIsNameModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  moment.locale('nl');
  const [{  currentUser, userDetails }, dispatch] = useStateValue();

  // variables for API requests 
  const [queryVariables, setQueryVariables] = useState({company_ids:null});
  const [costcenterQueryVariables, setCostcenterQueryVariables] = useState({company_ids:null});
  const [queryCompByIdVariables, setQueryCompByIdVariables] = useState({company_ids:null});

  // API queries 
  const [query, setQuery] = useState(QUERIES.QUOTATIONS_FOR_COMPANY_AND_ORDER_STATUS);
  const [costcenterQuery, setCostcenterQuery] = useState(QUERIES.COSTCENTERS_FOR_COMPANYS);
  const [CompaniesByIdQuery, setGetCompaniesByIdQuery] = useState(QUERIES.COMPANIES_BY_ID);

  // API mutations
  const [deleteCostcenter] = useMutation(QUERIES.DELETE_COST_CENTER);
  const [updateCostcenter] = useMutation(QUERIES.UPDATE_COST_CENTER);
  const [createCostcenter] = useMutation(QUERIES.CREATE_COST_CENTER);

  const [costcentersForCompany, setCostcentersForCompany] = useState(null);
  const [userCompanies, setUserCompanies] = useState(null);
  const [companyObject, setCompanyObject] = useState({company: {name: getLabel('Geen bedrijf geselecteerd', false, strings)}});
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);


  let nameModalInputField;
  let createModalInputField;

  const [spinner, setSpinner] = useState(<Spin />);

  // const [tlDeal, getTlDeal] = useState(QUERIES.GET_TEAMLEADER_DEAL);

  const [getCompaniesById, { loading: compLoading}] = useLazyQuery(CompaniesByIdQuery, {
    variables: {filter: queryCompByIdVariables},
    fetchPolicy: 'no-cache',
    onCompleted: queryRes => {
      setUserCompanies(queryRes.companiesById)
    }
  });

  const [getCostcentersForCompany, { loading: costcenterLoading}] = useLazyQuery(costcenterQuery, {
    variables: {filter: costcenterQueryVariables},
    fetchPolicy: 'no-cache',
    onCompleted: queryRes => {
      if (!!queryRes) {
       setCostcentersForCompany(queryRes)
      }
    }
  });

  useEffect(() => {
    dispatch({type: 'setCurrentEmployersPage', page: 'costcenters'})
}, [])
  
  useEffect(() => {
    setQueryVariables({company_ids:userDetails.companyIds});
    setQueryCompByIdVariables({company_ids:userDetails.companyIds})

  }, [userDetails])


  useEffect(()=>{
    getCompaniesById();
  }, [queryCompByIdVariables])

  useEffect(()=>{
    if (!!companyObject){ 
      setCostcenterQueryVariables({company_ids:[companyObject.company.id]})
      getCostcentersForCompany()
    }
  }, [companyObject])

 
    const showNameModal = () => {
        setIsNameModalVisible(true);
      };
      
      const handleNameOk = async () => { 
          try{
          await updateCostcenter({ variables: { costcenter: {id: selectedCostCenter.id, name: nameModalInputField, company_fk: companyObject.company.id} } });
          message.info(getLabel(`Kostenplaats bijgewerkt`, true, strings));
          getCostcentersForCompany()
          }catch (cErr) {
            console.error(cErr);
            message.error(getLabel(`Kan kostenplaats niet bijwerken`, true, strings));
          }
        setIsNameModalVisible(false);
      };
      
      const handleNameCancel = () => {
        setIsNameModalVisible(false);
      };
      
      const showCreateModal = () => {
        setIsCreateModalVisible(true);
      };
      
      const handleCreateOk = async () => {  
        try {
          await createCostcenter({ variables: { costcenter: {name: createModalInputField, company_fk: companyObject.company.id}} });
          message.info(getLabel(`Kostenplaats aangemaakt`, true, strings));
          getCostcentersForCompany()
        }
        catch (cErr) {
          console.error(cErr);
          if(costcenterQueryVariables.companyIds == null){
            message.error(getLabel(`Geen bedrijf geselecteerd`, false, strings));
          }else{
            message.error(getLabel(`Kan kostenplaats niet aanmaken`, true, strings));
          }
        }
        setIsCreateModalVisible(false);
      };
      
      const handleCreateCancel = () => {
        setIsCreateModalVisible(false);
      };
      
    if (costcenterLoading ){
        return (<div className="spinner">{spinner}</div>)
    }
      
        
    const costCenterMenu = () => (
        <Menu>
          {
          userCompanies? (userCompanies.map((cc)=>{
            return(<Menu.Item key={cc.id} onClick={() => {setCompanyObject({company: cc})}}>
                {cc.name}
              </Menu.Item>
              )
            })):('') 
          }
        </Menu>
      );

   return(
       <>
            <div style={{margin: '2em auto', width: '80%'}}>
                <div style={{display:'flex', flexDirection: 'row'}}>
                <h1 style={{marginRight: '1em'}}>{getLabel('Kostenplaatsen voor', true, strings)} </h1> 
                <Dropdown overlay={costCenterMenu()} trigger={['click']} >
                    <Button>
                    { companyObject.company.name} <DownOutlined />
                    </Button>
                </Dropdown>
                </div>
                
                <div style={{ border: '1px solid #ddd', padding: '2em 0', background: '#fff' }}>
                {costcenterLoading?(
                    <Row type='flex' justify='center' gutter={16}>
                    <Col style={{ marginTop: '128px' }} span={1}>
                        <Spin size='large'></Spin>
                    </Col>
                    </Row>):(
                <Row type='flex' justify='center'>
                <Table
                pagination={false}
                // onChange={(pag, filt, sort)=>{
                //   handleSort(sort);
                // }} 
                dataSource={costcentersForCompany? costcentersForCompany.costcenters: []}
                style={{ width: '80vw', margin: 16 }} 
                rowKey={record => record.id}>

                <Column
                title="Naam"
                dataIndex=""
                render={record => (
                    <p>{record.name}</p>
                )}
                />
                

                <Column
                
                dataIndex=""
                render={record => (
                    <div>
                    <Button
                    type="secondary" shape="circle" icon="edit"
                    size='small' style={{
                        margin:'0 1em',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
                    }}
                    onClick={()=>{
                        setSelectedCostCenter(record)
                        showNameModal(true);
                    }} 
                    />
                    {/*  */}
                    <Button
                    type="primary" shape="circle" icon="delete" type='danger'
                    size='small' style={{
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
                    }}
                    onClick={async () => {
                        try{
                        await deleteCostcenter({ variables: { id: record.id } });
                        await getCostcentersForCompany();
                        message.info(getLabel(`Kostenplaats verwijderd`, true, strings));
                        }catch (cErr) {
                        console.error(cErr);
                        message.error(getLabel(`Kan kostenplaats niet verwijderen`, true, strings));
                        }
                    }} 
                    />
                    </div>
                    
                
                )}
                />
                                
                </Table>
                <Button
                    type="primary" shape="circle" icon="plus"
                    size='large' style={{
                    height: '48px', width: '48px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
                    }}
                    onClick={() => {
                    showCreateModal()
                    }} 
                />
                </Row>
                )}

                </div>
            </div>
            <Modal title={getLabel("Naam veranderen", true, strings)} visible={isNameModalVisible} onPressEnter={handleNameOk} onOk={handleNameOk} onCancel={handleNameCancel}>
            <Input placeholder={getLabel('Naam', true, strings)} defaultValue={selectedCostCenter? selectedCostCenter.name: ''} onChange={(e)=>{nameModalInputField= e.target.value}}></Input>
            </Modal>
            <Modal title={getLabel("Kostenplaats aanmaken", true, strings)} visible={isCreateModalVisible} onPressEnter={handleCreateOk} onOk={handleCreateOk} onCancel={handleCreateCancel}>
            <Input placeholder={getLabel('Naam', true, strings)}  onChange={(e)=>{createModalInputField = e.target.value}}></Input>
            </Modal>
         </>
    )
}

export default CostCenters
