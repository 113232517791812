import React, { useEffect } from "react";
import {
  StateProvider,
  reducer,
  initialState,
} from "../stateProvider/stateProvider";
import "./App.css";
import QuotationList from "../quotationList/quotationList";
import QuotationOverview from "../quotationOverview/quotationOverview";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import moment from "moment";
import Login from "../login/login";
import { AuthProvider } from "../../auth/auth";
import PrivateRoute from "../../auth/privateRoute";
import Home from "../home/home";
import Header from "../header/header";
import EmployersPortalNavigation from "../employersPortalNavigation/employersPortalNavigation";
import { Layout } from "antd";
import EmployeeDetail from "../employeeDetail/employeeDetail";
import EmployeeSearchBarcode from "../employeeSearch/employeeSearchBarcode";
import EmployeeSearch from "../employeeSearch/employeeSearch";
import { I18nProvider } from "../../api/i18nService";
import Register from "../register/register";
import ResetPage from "../resetPage/resetPage";
import MyAccount from "../myAccount/myAccount";
import DealerManagement from "../dealerManagement/dealerManagement";
import DealerInformation from "../dealerInfo";
import DealerInformationBackoffice from "../dealerInfoBackoffice";
import Settings from "../settings/settings";
import { ApolloWrapper } from "../../api/apolloWrapper";
import ErrorHandler from "../errorHandler/errorHandler";
import ServicePassManagement from "../servicePassManagement/servicePassManagement";
import EmployeeAccess from "../employeeAccess/employeeAccess";
import EmployersPortal from "../employersPortal/quotations";
import CostCenters from "../employersPortal/costcenters";
import Dashboard from "../employersPortal/dashboard";
import Inbox from "../employersPortal/inbox";
import InfoEndOfLease from "../info/infoEndOfLease";
import Info from "../info/info";
import { ToastContainer } from "react-toastify";
import InfoDamage from "../info/infoDamage";
import UserManagement from "../employersPortal/userManagement";
import LicensePlates from "../licensePlates";
import Contact from "../contact/contact";

/**
 * App.js
 * Entry point for the application.
 * Sets up a few global contexts: AppolloProvider, I18nProvider and StateProvider (using
 * the initialState and reducer defined here).
 */

function App() {
  moment.locale("nl");

  useEffect(() => {
    /**
     * Cleanup local storage up front, but keep what we need
     */
    const dealerIndex = localStorage.getItem("dealerIndex");
    localStorage.clear();
    if (!!dealerIndex) {
      localStorage.setItem("dealerIndex", dealerIndex);
    }
  }, []);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <I18nProvider langCode="fr">
        <AuthProvider />
        <ApolloWrapper>
          <div className="App" style={{ overflowY: "hidden" }}>
            <Router>
              <Layout>
                <Header />
                <EmployersPortalNavigation />
                <ErrorHandler />
                <div
                  style={{
                    height: "100vh",
                    padding: "0px 16px",
                    paddingTop: "10vh",
                    overflowY: "scroll",
                    maxWidth: "100vw",
                  }}
                >
                  <Switch>
                    <PrivateRoute path="/" exact component={Home} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/reset" exact component={ResetPage} />
                    <Route
                      path="/employee-access"
                      exact
                      component={EmployeeAccess}
                    />
                    <PrivateRoute
                      path="/quotations"
                      exact
                      component={QuotationList}
                    />
                    <PrivateRoute
                      path="/quotations/:employeeId/:quotationId?"
                      component={QuotationOverview}
                    />
                    <PrivateRoute
                      path="/employee/:id/:companyId?"
                      component={EmployeeDetail}
                    />
                    <PrivateRoute
                      path="/ext/employee/"
                      exact
                      component={EmployeeDetail}
                    />
                    <PrivateRoute
                      path="/employees/:companyId"
                      component={EmployeeSearchBarcode}
                    />
                    <PrivateRoute
                      path="/employees"
                      component={EmployeeSearch}
                    />
                    <PrivateRoute path="/my-account" component={MyAccount} />
                    <PrivateRoute
                      path="/dealer-management"
                      component={DealerManagement}
                    />
                    <PrivateRoute
                      path="/dealer-info"
                      component={DealerInformation}
                    />
                    <PrivateRoute
                      path="/dealer-info-backoffice"
                      component={DealerInformationBackoffice}
                    />
                    <PrivateRoute path="/settings" component={Settings} />
                    <PrivateRoute
                      path="/service-pass-management"
                      component={ServicePassManagement}
                    />
                    <PrivateRoute
                      path="/employersportal/quotations/:quotationId?"
                      exact
                      component={EmployersPortal}
                    />
                    <PrivateRoute
                      exact
                      path="/employersportal/costcenters"
                      component={CostCenters}
                    />
                    <PrivateRoute
                      exact
                      path="/employersportal/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path="/employersportal"
                      component={Inbox}
                    />
                    <PrivateRoute
                      exact
                      path="/employersportal/my-account"
                      component={MyAccount}
                    />
                    <PrivateRoute
                      exact
                      path="/employersportal/user-management"
                      component={UserManagement}
                    />
                    <PrivateRoute
                      exact
                      path="/info"
                      component={Info}
                    />
                    <PrivateRoute
                      exact
                      path="/info-end-of-lease"
                      component={InfoEndOfLease}
                    />
                    <PrivateRoute
                      exact
                      path="/info-damage"
                      component={InfoDamage}
                    />
                    <PrivateRoute
                      exact
                      path="/license-plates-requests"
                      component={LicensePlates}
                    />
                    <PrivateRoute
                      path="/contact"
                      component={Contact}
                    />
                  </Switch>
                </div>
              </Layout>
            </Router>
          </div>
        </ApolloWrapper>
      </I18nProvider>
      <ToastContainer />
    </StateProvider>
  );
}

export default App;
