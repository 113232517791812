import { gql } from 'apollo-boost'

/**
 * queries.js
 * Exports all GraphQL queries used by the app.
 * Since we use the ApolloClient React lib, most of these
 * are gql objects. A few queries are exported as strings
 * for use in requests through axios where the Apollo context
 * is not accessible.
 */

export const QUERIES = {
  QUOTATIONS_ALL_FOR_DEALER_QUERY: gql`
    query Dealer($id: ID!) {
      dealer(id: $id) {
        id
        quotations {
          id
          dealer {
            id
          }
          company {
            id
            name
            logo_url
            company_parent_fk
            invoice_target {
              id
              tl_id_v1
              tl_id_v2
              name
              address
            }
          }
          total_vat_excluded
          quotation_number
          active
          employee {
            id
            first_name
            last_name
            company {
              id
              name
              invoice_target {
                id
                tl_id_v1
                tl_id_v2
                name
                address
              }
            }
          }
          order {
            id
            orderStatus {
              id
              name
              code
            }
          }
          deliverability
          bikeType {
            id
            name
            code
          }
          costcenter
          quotation_date
        }
      }
    }
  `,
  // FIX_ME: limit should be null as soon as null returns all (API story)
  QUOTATIONS_ALL_QUERY: gql`
    query Quotations(
      $limit: Int
      $page: Int
      $filter: QuotationFilter
      $sort: QuotationSort
    ) {
      quotations(limit: $limit, page: $page, filter: $filter, sort: $sort) {
        id
        total_vat_excluded
        quotation_number
        active
        costcenter
        company {
          id
          name
          logo_url
          company_parent_fk
          invoice_target {
              id
              tl_id_v1
              tl_id_v2
              name
              address
          }
        }
        employee {
          id
          first_name
          last_name
          company {
            id
            name
            invoice_target {
              id
              tl_id_v1
              tl_id_v2
              name
              address
            }
          }
        }
        order {
          id
          orderStatus {
            id
            name
            code
          }
        }
        deliverability
        bikeType {
          id
          name
          code
        }
        quotation_date
        dealer {
          id
          name
        }
        quotationFields {
          id
          value
          field_fk
          field {
            id
            name
            fieldType {
              id
              dataType {
                id
                code
              }
            }
          }
        }
      }
    }
  `,

  //   QUOTATION_BY_ID: gql`
  // query Quotation($id: ID!) {
  //   quotation(id: $id) {
  //     id
  //     dealer {
  //       id
  //       name
  //     }
  //   employee {
  //     id
  //     first_name
  //     last_name
  //     company {
  //       id
  //       name
  //       invoice_target {
  //          id
  //          tl_id_v1
  //          tl_id_v2
  //          name
  //          address
  //        }
  //     }
  //   }
  //   quotationFields {
  //     id
  //     value
  //     field_fk
  //     field {
  //       id
  //       name
  //       fieldType {
  //         id
  //         dataType {
  //           id
  //           code
  //         }
  //       }
  //     }
  //   }
  //   order {
  //     id
  //     generated_id
  //     deal {
  //       id
  //     }
  //     orderStatus {
  //       id
  //       name
  //       code
  //       index
  //     }
  //     orderLogs {
  //       id
  //       description
  //       file_id
  //     }
  //     bike {
  //       id
  //       brand
  //       model
  //       comment
  //       license_number
  //       frame_number
  //       sticker_number
  //       key_number_1
  //       key_number_2
  //       accu_number
  //       b2bike_servicepas
  //       bikeBrand {
  //         id
  //       }
  //       bikeType {
  //         id
  //       }
  //     }
  //   }
  //   bike_type_fk
  //   bikeType {
  //     id
  //     name
  //   }
  //   bike_brand_fk
  //   quotation_number
  //   calculation_sheet
  //   deliverability
  //   lease_amount
  //   lease_amount_vat
  //   salary_swap
  //   residual_value
  //   insurance_amount
  //   maintenance_amount
  //   assistance_amount
  //   warranty_grant
  //   total_vat_excluded
  //   total_vat_included
  //   quotation_source
  //   quotation_date
  //   active
  //   warning_message
  //   flexout_1
  //   flexout_2
  //   flexout_3
  //   flexout_4
  //   flexout_5
  //   flexout_6
  //   flexout_7
  //   }
  //   orderLogTypes(size: 9999) {
  //     id
  //     code
  //   }
  // }
  // `,

  QUOTATIONS_FOR_COMPANY_AND_ORDER_STATUS: gql`
    query Quotations($filter: QuotationFilter, $limit: Int, $page: Int, $sort: QuotationSort) {
      quotations(filter: $filter, limit: $limit, page: $page, sort: $sort) {
        id
        active
        quotation_number
        total_vat_included
        quotation_date
        salary_swap
        lease_amount_vat
        earliest_delivery_date
        costcenter
        company {
            id
            name
            logo_url
            company_parent_fk
            costcenters {
              id
              name
            }
          }
        quotationFields {
          field {
            fieldType {
              code
            }
          }
          value
        }
        bikeType {
          name
        }
        order {
          start_date
          id
          bike {
            license_number
          }
          deal_status_fk
          orderLogs {
            id
            file_id
            description
            hidden
          }
          deal {
            id
            title
            currentPhase {
              name
            }
            estimated_value {
              amount
            }
            custom_fields {
              definition {
                id
                label
              }
              value
            }
          }
          orderStatus {
            name
            code
          }
          confirmedOrder {
            id
            confirmed_by
            confirmer_mail
            canceller_mail
            cancelled
            cancelled_reason
            confirmed_reason
          }
          orderLogs {
            orderLogType {
              code
            }
            file_id
          }
        }
        employee {
          id
          name
          employee_number
        }
      }
    }
  `,

  QUOTATIONS_FOR_COMPANY_AND_ORDER_STATUS_FOR_EXPORT: gql`
query Quotations($filter: QuotationFilter, $limit: Int, $page: Int, $sort: QuotationSort) {
  quotations(filter: $filter, limit: $limit, page: $page, sort: $sort) {
    active
    quotation_number
    total_vat_included
    quotation_date
    salary_swap
    earliest_delivery_date
    costcenter
    bikeType {
      name
    }
    order {
      start_date
      bike {
        license_number
        sticker_number
      }
      orderStatus {
        name
      }
    }
    employee {
      name
      employee_number
      company {
        name
      }
    }
  }
}
`,

  QUOTATIONS_FOR_DASHBOARD: gql`
    query Quotations($filter: QuotationFilter, $limit: Int, $page: Int) {
      quotations(filter: $filter, limit: $limit, page: $page) {
        id
        active
        quotation_number
        total_vat_included
        quotation_date
        salary_swap
        earliest_delivery_date
        costcenter
        bikeType {
          id
          name
        }
        order {
          id
          order_status_fk
        }
        employee {
          id
          name
          company {
            id
            name
            logo_url
            costcenters {
              id
              name
            }
          }
        }
      }
      orderStatuses {
        id
        name
        code
      }
      bikeTypes {
        id
        name
        code
      }
    }
  `,

  QUOTATION_BY_ID: gql`
    query Quotation($id: ID!) {
      quotation(id: $id) {
        id
        earliest_delivery_date
        test
        costcenter
        company {
          id
          name
          hide
          fip
        }
        dealer {
          id
          name
        }
        employee {
          id
          email
          first_name
          last_name
          language {
            code
          }
          employeeStatute{
            name
          }  
        }
        quotationFields {
          id
          value
          field_fk
          field {
            id
            name
            fieldType {
              id
              code
              dataType {
                id
                code
              }
            }
          }
        }
        order {
          id
          generated_id
          deal_id
          deal_id_v2
          order_status_fk
          deal_status_fk
          orderStatus {
            id
            name
            code
            index
          }
          confirmedOrder {
            id
            confirmed_by
            cancelled_reason
          }
          deal {
            id
            title
            custom_fields {
              definition {
                id
                label
              }
              value
            }
          }
          orderLogs {
            id
            order_log_type_fk
            order_fk
            description
            file_id
            log_date
            hidden
          }
          bike {
            id
            brand
            model
            comment
            control_number
            license_number
            frame_number
            sticker_number
            key_number_1
            key_number_2
            accu_number
            barcode_x12
            b2bike_servicepas
            bikeBrand {
              id
            }
            bikeType {
              id
            }
          }
        }
        bike_type_fk
        bikeType {
          id
          name
        }
        bike_brand_fk
        quotation_number
        calculation_sheet
        deliverability
        lease_amount
        lease_amount_vat
        salary_swap
        residual_value
        insurance_amount
        maintenance_amount
        assistance_amount
        warranty_grant
        total_vat_excluded
        total_vat_included
        quotation_source
        quotation_date
        active
        warning_message
        flexout_1
        flexout_2
        flexout_3
        flexout_4
        flexout_5
        flexout_6
        flexout_7
      }
      orderLogTypes(size: 9999) {
        id
        code
      }
    }
  `,

  ORDER_BY_ID: gql`
    query Order($id: ID!) {
      order(id: $id) {
        id
        deal_id
        quotation_fk
      }
    }
  `,

  DEALER_BY_ID: gql`
    query Dealer($id: ID!) {
      dealer(id: $id) {
        id
        name
      }
    }
  `,

  CREATE_DEALER_HAS_BIKE_BRAND: gql`
    mutation CreateDealerHasBikeBrand($dealerHasBikeBrand: DealerHasBikeBrandInput!) {
      createDealerHasBikeBrand(dealerHasBikeBrand: $dealerHasBikeBrand) {
        id
        dealer_fk
        bike_brand_fk
      }
    }
  `,

  DELETE_DEALER_HAS_BIKE_BRAND: gql`
    mutation DeleteDealerHasBikeBrand($id: ID) {
      deleteDealerHasBikeBrand(id: $id) {
        id
        dealer_fk
        bike_brand_fk
      }
    }
  `,

  DEALER_HAS_BIKE_BRANDS_BY_DEALER_ID: gql`
    query DealerHasBikeBrandsByDealerId($dealerId: ID!) {
      dealerHasBikeBrands(size: 9999, dealerId: $dealerId) {
        id
        dealer_fk
        bike_brand_fk
      }
    }
  `,

  DEALER_ALL: gql`
    query {
      dealers(limit: 9999) {
        id
        name
        street
        zip_code
        city
        email
        language
        contact
        tel
        user
        account_manager
        active
        vat_id
        official_registration_name
        tel_backoffice
        website_url
        email_customers
        internal_use_only
        bikeBrands {
          id
          name
        }
      }
    }
  `,

  DEALER_ALL_RAW: gql`
    query {
      dealers(limit: 9999) {
        id
        name
        street
        zip_code
        city
        email
        language
        contact
        tel
        user
        account_manager
        active
        vat_id
        official_registration_name
        tel_backoffice
        website_url
        email_customers
        internal_use_only
        bikeBrands {
          id
          name
        }
      }
    }
  `,

  COMPANY_ALL_AND_EMPLOYEE: gql`
    query($employeeId: ID!, $dealerId: ID!) {
      companies(size: 9999) {
        id
        name
        info
        logo_url
        custom_employee_properties
        employee_required_fields
        costcenters {
          id
          name
        }
        employeeStatutes {
          id
          name
          code
        }
        invoice_target {
          id
          tl_id_v1
          tl_id_v2
          name
          address
        }
      }
      employeeTitles {
        id
        code
        name
      }
      languages {
        Id
        name
        code
      }
      employee(id: $employeeId, dealerId: $dealerId) {
        id
        company_fk
        employee_statute_fk
        employee_title_fk
        language_fk
        first_name
        last_name
        tel
        email
        employee_number
        street
        house_number
        national_register
        comment_private
        comment_public
        city
        zip_code
        drivers_license_date
        date_of_birth
        custom_properties
        company {
          costcenters {
            id
            name
          }
          employeeStatutes {
            id
            name
            code
          }
          invoice_target {
            id
            tl_id_v1
            tl_id_v2
            name
            address
          }
          id
          name
          info
          logo_url
          custom_employee_properties
          employee_required_fields
        }
      }
    }
  `,

  COMPANY_LOGOS_ALL: gql`
    query Companies($size: Int) {
      companies(size: $size) {
        id
        logo_url
      }
    }
  `,

  BIKE_BRANDS_ALL: gql`
    query BikeBrands {
      bikeBrands(size: 9999) {
        id
        name
      }
    }
  `,
  // EMPLOYEE_ALL_BASIC_INFO: gql`
  // query Employees($dealerId: ID!) {
  //   employees(size: 9999, dealerId: $dealerId) {
  //     id
  //     first_name
  //     last_name
  //     company {
  //       name
  //       logo_id
  //     }
  //     quotations {
  //       id
  //     }
  //   }
  // }
  // `,

  //   QUOTATIONS_AND_ORDERS_FOR_EMPLOYEE: gql`
  // query Quotations($dealerId: ID!, $employeeId: ID!) {
  //   employee(id: $employeeId, dealerId: $dealerId) {
  //     id
  //     street
  //     zip_code
  //     city
  //     drivers_license_date
  //     date_of_birth
  //     national_register
  //     quotations {
  //       id
  //       active
  //     }
  //     company_fk
  //     company {
  //       id
  //       invoice_target {
  //          id
  //          tl_id_v1
  //          tl_id_v2
  //          name
  //          address
  //        }
  //     }
  //   }
  // }
  // `,

  QUOTATIONS_AND_ORDERS_FOR_EMPLOYEE: gql`
    query Quotations($dealerId: ID!, $employeeId: ID!) {
      employee(id: $employeeId, dealerId: $dealerId) {
        id
        street
        house_number
        zip_code
        city
        drivers_license_date
        date_of_birth
        national_register
        quotations {
          id
          active
          costcenter
        }
        company_fk
        company {
          id
          hide
        }
      }
    }
  `,

  EMPLOYEE_ALL_BASIC_INFO: gql`
    query Employees($dealerId: ID!) {
      employees(size: 9999, dealerId: $dealerId) {
        id
        first_name
        last_name
        company {
          id
          name
          logo_url
        }
      }
    }
  `,

  LANGUAGES: gql`
    query {
      languages {
        Id
        name
      }
    }
  `,

  // EMPLOYEE_SEARCH: gql`
  // query SearchEmployees($search: String!) {
  //   searchEmployees(search: $search) {
  //     id
  //     company_fk
  //     employee_statute_fk
  //     employee_title_fk
  //     language_fk
  //     first_name
  //     last_name
  //     tel
  //     email
  //     employee_number
  //     street
  //     national_register
  //     comment_private
  //     comment_public
  //     city
  //     zip_code
  //     drivers_license_date
  //     company {
  //       costcenters {
  //         id
  //         name
  //       }
  //       id
  //       name
  //       info
  //       logo_id
  //     }
  //   }
  // }
  // `,

  EMPLOYEE_SEARCH: gql`
    query SearchEmployees($search: String!) {
      searchEmployees(search: $search) {
        id
        company_fk
        first_name
        last_name
        name
        company {
          id
          name
          info
          logo_url
        }
        bike {
          id
          model
        }
      }
    }
  `,

  EMPLOYEE_BY_NAME_AND_EMAIL: gql`
    query employeeByNameAndEmail(
      $first_name: String!
      $last_name: String!
      $email: String!
      $company_fk: ID!
    ) {
      employeeByNameAndEmail(
        first_name: $first_name
        last_name: $last_name
        email: $email
        company_fk: $company_fk
      ) {
        id
        first_name
        last_name
        employee_access_code
        company {
          id
          logo_url
          info
          name
        }
      }
    }
  `,

  EMPLOYEE_BY_ACCESS_CODE: gql`
    query employeeByAccessCode($code: String!, $company_fk: ID!) {
      employeeByAccessCode(code: $code, company_fk: $company_fk) {
        id
        first_name
        last_name
        employee_access_code
        company {
          id
          logo_url
          info
          name
        }
      }
    }
  `,
  QUOTATIONS_FOR_EMPLOYEE_BY_DEALER: gql`
    query Quotations($employeeId: ID!, $dealerId: ID!) {
      employee(id: $employeeId, dealerId: $dealerId) {
        id
        quotations {
          id
        }
      }
    }
  `,

  COMPANIES_ALL: gql`
    query Companies($size: Int) {
      companies(size: $size) {
        id
        fip
        name
        company_parent_fk
        logo_url
        hide
        custom_employee_properties
        portal_user
        kpmg_integration_partner {
          id
        }
        invoice_target {
          id
          tl_id_v1
          tl_id_v2
          name
          address
        }
        language {
          name
          code
        }
      }
    }
  `,

  COMPANIES_ALL_FOR_SETTINGS: gql`
    query Companies($size: Int) {
      companies(size: $size) {
        id
        fip
        name
        company_parent_fk
        contact
        language_fk
        email
        tel
        info
        comment_public
        comment_private
        disclaimer_calculation
        employee_required_fields
        folder_id
        calculation_sheet
        variant_fk
        logo_url
        hide
        customer_id
        invoice_target {
          id
          tl_id_v1
          tl_id_v2
          name
          address
        }
        parentCompany {
          id
          name
          calculation_sheet
        }
        employee_access_code
      }
    }
  `,

  INVOICE_TARGETS_BY_NAME: gql`
    query($filter: InvoiceTargetInput!) {
      invoiceTargets(filter: $filter) {
        id
        tl_id_v1
        name
      }
    }
  `,

  COMPANY_FIELDS_AND_FIELDS_TYPES: gql`
    query($id: ID!) {
      company(id: $id) {
        id
        fields {
          id
          name
          description
          index
          hasQuotationFields
          fieldValues {
            id
            field_fk
            value
          }
          fieldType {
            id
            name
            is_default
            is_multivalue
            dataType {
              id
              code
            }
          }
        }
      }
      fieldTypes(page: 1, size: 999) {
        id
        name
        code
        is_default
        is_multivalue
        dataType {
          id
          code
        }
      }
    }
  `,

  COMPANY_INFO_FICHE: gql`
    query Company($id: ID!) {
      company(id: $id) {
        id
        name
        logo_url
        info
        fip
        kpmg_integration_partner {
          id
        }
        invoice_target {
          id
          tl_id_v1
          tl_id_v2
          name
          address
          license_plate_auto_request
        }
      }
    }
  `,

  COSTCENTERS_FOR_COMPANY: gql`
    query Company($id: ID!) {
      company(id: $id) {
        id
        costcenters {
          id
          name
          company_fk
        }
      }
    }
  `,

  COSTCENTERS_FOR_COMPANYS: gql`
    query Company($filter: CostcenterFilter) {
      costcenters(page: 1, size: 9999, filter: $filter) {
        id
        name
      }
    }
  `,

  COMPANIES_BY_ID: gql`
    query Company($filter: CompanyFilter) {
      companiesById(page: 1, size: 9999, filter: $filter) {
        id
        name
        portal_user
        company_parent_fk
      }
    }
  `,

  STATUTES_FOR_COMPANY_AND_STATUTES: gql`
    query Company($id: ID!, $page: Int, $size: Int) {
      company(id: $id) {
        id
        employeeStatutes {
          id
          code
          name
        }
      }
      companyHasEmployeeStatutesByCompanyId(id: $id) {
        id
        employee_statute_fk
      }
      employeeStatutes(page: $page, size: $size) {
        id
        name
        code
      }
    }
  `,

  EMPLOYEES_FOR_COMPANY: gql`
    query Company($id: ID!) {
      company(id: $id) {
        id
        logo_url
        info
        name
        employees {
          id
          first_name
          last_name
        }
      }
    }
  `,

  COMPANY_FIELDS_AND_BIKES: gql`
    query Company($id: ID!) {
      company(id: $id) {
        id
        calculation_sheet
        folder_id
        fields {
          id
          index
          description
          fieldType {
            id
            name
            code
            dataType {
              id
              code
            }
          }
          fieldValues {
            id
            value
          }
        }
      }
      bikeTypes {
        id
        name
        code
      }
      bikeBrands(size: 9999) {
        id
        name
      }
    }
  `,

  ORDER_LOG_TYPES: gql`
    query {
      orderLogTypes {
        id
        name
        code
      }
    }
  `,

  GET_FIELD_TYPE: gql`
    query {
      fieldTypes(page: 1, size: 9999) {
        id
        name
        code
        is_default
        is_multivalue
        dataType {
          id
          name
          code
        }
      }
      dataTypes(page: 1, size: 9999) {
        id
        name
        code
      }
    }
  `,

  CREATE_FIELD_TYPES: gql`
    mutation CreateFieldType($fieldType: FieldTypeInput!) {
      createFieldType(fieldType: $fieldType) {
        name
        code
      }
    }
  `,

  UPDATE_FIELD_TYPES: gql`
    mutation UpdateFieldType($fieldType: FieldTypeInput!) {
      updateFieldType(fieldType: $fieldType) {
        id
        name
        code
      }
    }
  `,

  DELETE_FIELD_TYPES: gql`
    mutation DeleteFieldType($id: ID!) {
      deleteFieldType(id: $id) {
        Id
      }
    }
  `,

  GET_TEAMLEADER_DEAL: gql`
    query getTeamleaderDeal($id: ID!) {
      deal(id: $id) {
        id
        title
        estimated_value {
          amount
        }
      }
    }
  `,

  // SERVICE_PASSES: gql`
  //   query ServicePasses($page: Int, $limit: Int) {
  //     servicePasses(page: $page, limit: $limit) {
  //       id
  //       barcode
  //       starting_amount
  //       remaining_amount
  //       used_amount
  //       emptied_at
  //       employee_name
  //       employee {
  //         id
  //         name
  //       }
  //     }
  //   }
  // `,

  // SERVICE_PASSES: gql`
  //   query ServicePasses($page: Int, $size: Int) {
  //     servicePasses(page: $page, size: $size) {
  //       id
  //       barcode
  //       dealer_fk
  //       bike_fk
  //       starting_amount
  //       remaining_amount
  //       used_amount
  //       emptied_at
  //       employee_name
  //       dealer_name
  //       dealer {
  //         id
  //         name
  //       }
  //       bike {
  //         id
  //         model
  //         employee {
  //           id
  //           name
  //           company {
  //             id
  //             name
  //           }
  //         }
  //       }
  //     }
  //   }
  // `,

  SERVICE_PASSES: gql`
    query ServicePasses(
      $page: Int
      $size: Int
      $filter: FilterServicePass
      $sort: ServicePassSort
    ) {
      servicePasses(page: $page, size: $size, filter: $filter, sort: $sort) {
        data {
          id
          barcode
          status
          dealer_fk
          bike_fk
          starting_amount
          remaining_amount
          used_amount
          emptied_at
          employee_name
          dealer_name
          first_in_pack
          comment
          dealer {
            id
            name
          }
          bike {
            id
            order {
              id
              quotation_fk
            }
            bikeType {
              id
              name
            }
            employee {
              id
              name
              company {
                id
                name
                invoice_target {
                  id
                  tl_id_v1
                  tl_id_v2
                  name
                  address
                }
              }
            }
          }
        }
        csvAsBase64
      }
    }
  `,

  SERVICE_PASS_NEXT_HUNDRED: gql`
    query ServicePassNextHundred($id: ID!) {
      servicePassNextHundred(id: $id) {
        data {
          id
          barcode
          dealer_fk
          bike_fk
          starting_amount
          remaining_amount
          used_amount
          emptied_at
          employee_name
          dealer_name
          first_in_pack
          comment
          dealer {
            id
            name
          }
          bike {
            id
            order {
              id
              quotation_fk
            }
            bikeType {
              id
              name
            }
            employee {
              id
              name
              company {
                id
                name
                invoice_target {
                  id
                  tl_id_v1
                  tl_id_v2
                  name
                  address
                }
              }
            }
          }
        }
        csvAsBase64
      }
    }
  `,

  SERVICE_PASS_BY_PACK: gql`
    query servicePassesByPack($firstInPack: String!) {
      servicePassesByPack(firstInPack: $firstInPack) {
        data {
          id
          barcode
          dealer_fk
          bike_fk
          starting_amount
          remaining_amount
          used_amount
          emptied_at
          employee_name
          dealer_name
          comment
          first_in_pack
          dealer {
            id
            name
          }
          bike {
            id
            order {
              id
              quotation_fk
            }
            bikeType {
              id
              name
            }
            employee {
              id
              name
              company {
                id
                name
                invoice_target {
                  id
                  tl_id_v1
                  tl_id_v2
                  name
                  address
                }
              }
            }
          }
        }
      }
    }
  `,

  EMPLOYEE_FOR_SERVICE_PASS: gql`
    query SearchEmployees($search: String!) {
      searchEmployees(search: $search) {
        id
        company_fk
        first_name
        last_name
        name
        company {
          id
          name
          invoice_target {
            id
            tl_id_v1
            tl_id_v2
            name
            address
          }
        }
        bike {
          id
          model
          bikeBrand {
            name
          }
          bikeType {
            id
            name
          }
          order {
            id
            quotation_fk
          }
        }
      }
    }
  `,

  SERVICE_PASS_BASE_64: gql`
    query {
      servicePasses {
        data {
          id
        }
        csvAsBase64
      }
    }
  `,

  TEMPLATE_SETTINGS: gql`
    query {
      templates(size: 9999) {
        Id
        name
        file_id
        language {
          Id
          name
        }
        orderLogType {
          id
          name
        }
        variant {
          Id
          name
        }
      }
      variants(size: 9999) {
        Id
        name
      }
      languages {
        Id
        name
      }
      orderLogTypes {
        id
        name
      }
    }
  `,

  VARIANT_SETTINGS: gql`
    query {
      variants(page: 1, size: 9999) {
        Id
        name
        code
      }
    }
  `,

  BIKE_BRAND_SETTINGS: gql`
    query {
      bikeBrands(page: 1, size: 9999) {
        id
        name
        code
        hidden_public_website
      }
    }
  `,

  BIKE_TYPE_SETTINGS: gql`
    query {
      bikeTypes(page: 1, size: 9999) {
        id
        name
        code
      }
    }
  `,

  BIKES_ALL: gql`
    query {
      bikes(page: 1, size: 999) {
        id
        model
        employee {
          id
          name
          company {
            id
            name
          }
        }
      }
    }
  `,

  BIKE_DETAIL: gql`
    query BikeDetail($id: ID!) {
      bike(id: $id) {
        id
        bike_brand_fk
        bike_type_fk
        model
        comment
        control_number
        license_number
        frame_number
        sticker_number
        key_number_1
        key_number_2
        accu_number
        b2bike_servicepas
      }
      bikeBrands(page: 1, size: 9999) {
        id
        name
        code
      }
      bikeTypes {
        id
        name
      }
    }
  `,

  ENTERPRISE_ALL: gql`
    query {
      enterprises(number: 1, size: 99) {
        id
        name
      }
    }
  `,

  LICENSE_PLATE_REQUESTS_INFO: gql`
    query LicensePlatesInformation(
      $page: Int
      $size: Int
      $filter: LicensePlateRequestFilter
      $sort: LicensePlateRequestSort
    ) {
      licensePlateRequestStatuses(page: 1, size: 99) {
        id
        name
      }
      licensePlateRegistrationTypes(page: 1, size: 99) {
        id
        name
      }
      licensePlateRequests(
        page: $page
        size: $size
        filter: $filter
        sort: $sort
      ) {
        id
        registration_date
        license_plate_sent_to_dealer_date
        comment
        license_plate_registration_type_fk
        bike_fk
        license_plate_request_status_fk
        license_plate_registration_type {
          id
          name
        }
        license_plate_request_status {
          id
          name
        }
        bike {
          id
          employee_fk
          order_fk
          license_number
          frame_number
          control_number
          barcode_x12
          employee {
            id
            name
            email
            tel
            first_name
            last_name
            national_register
            street
            house_number
            city
            address
            date_of_birth
            language {
              name
              code
            }
            company {
              id
              name
              email
              invoice_target {
                id
                name
                tl_id_v2
              }
            }
          }
          order {
            id
            quotation_fk
            quotation {
              id
              quotation_number
              dealer {
                id
                name
                email
                language
              }
            }
            orderLogs {
              id
              order_log_type_fk
              file_id
            }
          }
        }
      }
    }
  `,

  // COMPANY_FIELDS_AND_QUOTATION: gql`
  //   query ($companyId: ID!, $quotationId: ID!) {
  //     company(id: $id) {
  //       id
  //       fields {
  //         id
  //         index
  //         description
  //         fieldType {
  //           id
  //           name
  //           code
  //           dataType {
  //             code
  //           }
  //         }
  //         fieldValues {
  //           id
  //           value
  //         }
  //       }
  //     }
  //   }
  // `,

  /**
   * MUTATIONS START HERE
   */

  UPDATE_LICENSE_PLATE_REQUEST: gql`
    mutation licensePlateRequest(
      $licensePlateRequest: LicensePlateRequestInput!
    ) {
      updateLicensePlateRequest(licensePlateRequest: $licensePlateRequest) {
        id
      }
    }
  `,

  CREATE_LICENSE_PLATE_REQUEST: gql`
    mutation licensePlateRequest(
      $licensePlateRequest: LicensePlateRequestInput!
    ) {
      createLicensePlateRequest(licensePlateRequest: $licensePlateRequest) {
        id
        registration_date
        license_plate_sent_to_dealer_date
        comment
        license_plate_registration_type_fk
        bike_fk
        license_plate_request_status_fk
      }
    }
  `,

  CREATE_EMPLOYEE: gql`
    mutation CreateEmployee($employee: EmployeeInput!) {
      createEmployee(employee: $employee) {
        id
        first_name
        last_name
        company {
          id
          name
          logo_url
        }
      }
    }
  `,

  UPDATE_EMPLOYEE: gql`
    mutation UpdateEmployee($employee: EmployeeInput!) {
      updateEmployee(employee: $employee) {
        id
        company_fk
        employee_statute_fk
        employee_title_fk
        language_fk
        first_name
        last_name
        tel
        email
        employee_number
        street
        house_number
        national_register
        comment_private
        comment_public
        city
        zip_code
        drivers_license_date
        date_of_birth
        company {
          employeeStatutes {
            id
            name
            code
          }
          id
          name
          info
          logo_url
        }
      }
    }
  `,

  CREATE_QUOTATION: gql`
    mutation CreateQuotation(
      $quotation: QuotationInput!
      $quotationFields: [QuotationFieldInput]!
    ) {
      createQuotation(
        quotation: $quotation
        quotationFields: $quotationFields
      ) {
        id
      }
    }
  `,

  UPDATE_QUOTATION: gql`
    mutation UpdateQuotation($quotation: QuotationInput!) {
      updateQuotation(quotation: $quotation) {
        id
      }
    }
  `,

  UPDATE_QUOTATION_EMPLOYER_DETAIL: gql`
    mutation UpdateQuotation($quotation: QuotationInput!) {
      updateQuotation(quotation: $quotation) {
        id
        active
        quotation_number
        total_vat_included
        quotation_date
        salary_swap
        earliest_delivery_date
        costcenter
        bikeType {
          name
        }
        order {
          id
          deal_status_fk
          deal {
            id
            title
            currentPhase {
              name
            }
            estimated_value {
              amount
            }
            custom_fields {
              definition {
                id
                label
              }
              value
            }
          }
          orderStatus {
            name
            code
          }
          confirmedOrder {
            id
            confirmed_by
            confirmer_mail
            canceller_mail
            cancelled
            cancelled_reason
            confirmed_reason
          }
          orderLogs {
            orderLogType {
              code
            }
            file_id
          }
        }
        employee {
          id
          name
          company {
            id
            name
            logo_url
            costcenters {
              id
              name
            }
          }
        }
      }
    }
  `,

  CREATE_COMPANY: gql`
    mutation CreateCompany(
      $company: CompanyInput!
      $logo: String
      $make_sheet: Boolean
    ) {
      createCompany(company: $company, logo: $logo, make_sheet: $make_sheet) {
        id
      }
    }
  `,

  UPDATE_COMPANY: gql`
    mutation UpdateCompany(
      $company: CompanyInput!
      $logo: String
      $regenerate_sheet: Boolean
    ) {
      updateCompany(
        company: $company
        logo: $logo
        regenerate_sheet: $regenerate_sheet
      ) {
        id
      }
    }
  `,

  CREATE_ORDER: gql`
    mutation CreateOrder($order: OrderInput!) {
      createOrder(order: $order) {
        id
      }
    }
  `,

  UPDATE_ORDER: gql`
    mutation UpdateOrder($order: OrderInput!, $deliveryDate: Int) {
      updateOrder(order: $order, deliveryDate: $deliveryDate) {
        id
      }
    }
  `,

  CREATE_ORDER_LOG: gql`
    mutation CreateOrderLog($orderLog: OrderLogInput!, $document: String) {
      createOrderLog(orderLog: $orderLog, document: $document) {
        id
        file_id
      }
    }
  `,

  UPDATE_ORDER_LOG: gql`
    mutation updateOrderLog($orderLog: OrderLogInput!) {
      updateOrderLog(orderLog: $orderLog) {
        id
        file_id
      }
    }
  `,

  UPDATE_BIKE: gql`
    mutation UpdateBike($bike: BikeInput!) {
      updateBike(bike: $bike) {
        id
      }
    }
  `,

  CREATE_SERVICE_PASS: gql`
    mutation CreateServicePass($servicePass: ServicePassInput!) {
      createServicePass(servicePass: $servicePass) {
        id
        barcode
        dealer_fk
        bike_fk
        starting_amount
        remaining_amount
        used_amount
        emptied_at
        employee_name
        dealer_name
        comment
        dealer {
          id
          name
        }
        bike {
          id
          model
          employee {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
    }
  `,

  UPDATE_SERVICE_PASS: gql`
    mutation UpdateServicePass($servicePass: ServicePassInput!) {
      updateServicePass(servicePass: $servicePass) {
        id
        barcode
      }
    }
  `,

  CREATE_VARIANT: gql`
    mutation CreateVariant($variant: VariantInput!) {
      createVariant(variant: $variant) {
        Id
        name
        code
      }
    }
  `,

  UPDATE_VARIANT: gql`
    mutation UpdateVariant($variant: VariantInput!) {
      updateVariant(variant: $variant) {
        Id
        name
        code
      }
    }
  `,

  DELETE_VARIANT: gql`
    mutation DeleteVariant($id: ID!) {
      deleteVariant(id: $id) {
        Id
      }
    }
  `,

  CREATE_FIELD: gql`
    mutation CreateField($field: FieldInput!) {
      createField(field: $field) {
        id
      }
    }
  `,

  UPDATE_FIELD: gql`
    mutation UpdateField($field: FieldInput!) {
      updateField(field: $field) {
        id
      }
    }
  `,

  CREATE_FIELD_VALUE: gql`
    mutation CreateFieldValue($fieldValue: FieldValueInput!) {
      createFieldValue(fieldValue: $fieldValue) {
        id
      }
    }
  `,

  UPDATE_FIELD_VALUE: gql`
    mutation UpdateFieldValue($fieldValue: FieldValueInput!) {
      updateFieldValue(fieldValue: $fieldValue) {
        id
      }
    }
  `,

  CREATE_COMPANY_HAS_EMPLOYEE_STATUTE: gql`
    mutation CreateCompanyHasEmployeeStatute(
      $companyHasEmployeeStatute: CompanyHasEmployeeStatuteInput!
    ) {
      createCompanyHasEmployeeStatute(
        companyHasEmployeeStatute: $companyHasEmployeeStatute
      ) {
        id
      }
    }
  `,

  DELETE_COMPANY_HAS_EMPLOYEE_STATUTE: gql`
    mutation DeleteCompanyHasEmployeeStatute($id: ID!) {
      deleteCompanyHasEmployeeStatute(id: $id) {
        id
      }
    }
  `,

  CREATE_COST_CENTER: gql`
    mutation CreateCostcenter($costcenter: CostcenterInput!) {
      createCostcenter(costcenter: $costcenter) {
        id
      }
    }
  `,

  UPDATE_COST_CENTER: gql`
    mutation UpdateCostcenter($costcenter: CostcenterInput!) {
      updateCostcenter(costcenter: $costcenter) {
        id
      }
    }
  `,

  DELETE_COST_CENTER: gql`
    mutation DeleteCostcenter($id: ID!) {
      deleteCostcenter(id: $id) {
        id
      }
    }
  `,

  CREATE_BIKE_BRAND: gql`
    mutation CreateBikeBrand($bikeBrand: BikeBrandInput!) {
      createBikeBrand(bikeBrand: $bikeBrand) {
        id
        name
        code
      }
    }
  `,

  CREATE_BIKE: gql`
    mutation CreateBike($bike: BikeInput!) {
      createBike(bike: $bike) {
        id
      }
    }
  `,

  UPDATE_BIKE_BRAND: gql`
    mutation UpdateBikeBrand($bikeBrand: BikeBrandInput!) {
      updateBikeBrand(bikeBrand: $bikeBrand) {
        id
        name
        code
      }
    }
  `,

  DELETE_BIKE_BRAND: gql`
    mutation DeleteBikeBrand($id: ID!) {
      deleteBikeBrand(id: $id) {
        id
        name
        code
      }
    }
  `,

  DELETE_ORDER: gql`
    mutation DeleteOrder($id: ID!) {
      deleteOrder(id: $id) {
        id
      }
    }
  `,

  CREATE_TEMPLATE: gql`
    mutation CreateTemplate($template: TemplateInput!) {
      createTemplate(template: $template) {
        Id
      }
    }
  `,

  UPDATE_TEMPLATE: gql`
    mutation UpdateTemplate($template: TemplateInput!) {
      updateTemplate(template: $template) {
        Id
      }
    }
  `,

  DELETE_TEMPLATE: gql`
    mutation DeleteTemplate($Id: ID!) {
      deleteTemplate(id: $Id) {
        Id
      }
    }
  `,

  CREATE_DEALER: gql`
    mutation CreateDealer($dealer: DealerInput!) {
      createDealer(dealer: $dealer) {
        id
      }
    }
  `,
  UPDATE_DEALER: gql`
    mutation UpdateDealer($dealer: DealerInput!) {
      updateDealer(dealer: $dealer) {
        id
      }
    }
  `,

  DELETE_DEALER_WITH_QUOTATION_CHECK: gql`
    mutation DeleteDealerWithQuotationCheck($id: ID!) {
      deleteDealerWithQuotationCheck(id: $id) {
        id
      }
    }
  `,

  EMPLOYEE_TITLES: gql`
    query {
      employeeTitles(page: 1, size: 9999) {
        id
        name
        code
      }
    }
  `,

  CREATE_EMPLOYEE_TITLE: gql`
    mutation CreateEmployeeTitle($employeeTitle: EmployeeTitleInput!) {
      createEmployeeTitle(employeeTitle: $employeeTitle) {
        id
      }
    }
  `,

  UPDATE_EMPLOYEE_TITLE: gql`
    mutation UpdateEmployeeTitle($employeeTitle: EmployeeTitleInput!) {
      updateEmployeeTitle(employeeTitle: $employeeTitle) {
        id
      }
    }
  `,

  DELETE_EMPLOYEE_TITLE: gql`
    mutation DeleteEmployeeTitle($id: ID!) {
      deleteEmployeeTitle(id: $id) {
        id
      }
    }
  `,

  EMPLOYEE_STATUTES: gql`
    query {
      employeeStatutes(page: 1, size: 9999) {
        id
        name
        code
      }
    }
  `,

  CREATE_EMPLOYEE_STATUTES: gql`
    mutation CreateEmployeeStatute($employeeStatute: EmployeeStatuteInput!) {
      createEmployeeStatute(employeeStatute: $employeeStatute) {
        id
      }
    }
  `,

  UPDATE_EMPLOYEE_STATUTES: gql`
    mutation UpdateEmployeeStatute($employeeStatute: EmployeeStatuteInput!) {
      updateEmployeeStatute(employeeStatute: $employeeStatute) {
        id
      }
    }
  `,

  DELETE_EMPLOYEE_STATUTES: gql`
    mutation DeleteEmployeeStatute($id: ID!) {
      deleteEmployeeStatute(id: $id) {
        id
      }
    }
  `,

  ORDER_STATUSES: gql`
    query {
      orderStatuses(page: 1) {
        id
        code
        name
        index
      }
    }
  `,
  WEBHOOK_ATTEMPTS: gql`
    query WebhookAttempts(
      $page: Int
      $size: Int
      $filter: WebhookAttemptFilter
    ) {
      webhookAttempts(page: $page, size: $size, filter: $filter) {
        id
        webhook_fk
        order_fk
        status_code
        date
        response
        data
        url
        quotation_number
      }
      webhooks {
        id
        url
        invoice_target
        webhook_format_fk
        filter
        deal_status_fk
      }
    }
  `,
  WEBHOOK_ATTEMPTS_BY_QUOTATION_ID: gql`
    query WebhookAttemptsByQuotationId($id: ID!) {
      webhookAttemptsByQuotationId(id: $id) {
        id
        webhook_fk
        status_code
        response
        date
        url
      }
    }
  `,

  CREATE_CONFIRMED_ORDER: gql`
    mutation CreateConfirmedOrder($confirmedOrder: ConfirmedOrderInput!) {
      createConfirmedOrder(confirmedOrder: $confirmedOrder) {
        id
      }
    }
  `,

  UPDATE_CONFIRMED_ORDER: gql`
    mutation UpdateConfirmedOrder(
      $confirmedOrder: ConfirmedOrderInput!
      $reason: ReasonToCancelConfirmedOrder!
    ) {
      updateConfirmedOrder(
        confirmedOrder: $confirmedOrder
        reasonToCancelConfirmedOrder: $reason
      ) {
        id
      }
    }
  `,

  UPDATE_DEAL: gql`
    mutation UpdateDeal($deal: DealInput!) {
      updateDeal(deal: $deal) {
        id
      }
    }
  `,
  UPDATE_DEAL_PHASE: gql`
    mutation UpdateDealPhase($deal: DealInput!, $phase: DealPhaseInput!) {
      updateDealPhase(deal: $deal, phase: $phase) {
        id
      }
    }
  `,
  UPDATE_DEAL_CUSTOM_FIELDS: gql`
    mutation UpdateDealCustomFields($deal: DealInput!) {
      updateDealCustomFields(deal: $deal) {
        id
      }
    }
  `,

  /**
   * SUBSCRIPTIONS START HERE
   */

  TEST_SUBSCRIPTION: gql`
    subscription {
      postAdded {
        author
        comment
      }
    }
  `,

  QUOTATION_UPDATED: gql`
    subscription {
      quotationUpdated {
        id
      }
    }
  `,

  QUOTATION_CREATED: gql`
    subscription {
      quotationCreated {
        id
        employee_fk
      }
    }
  `,

  ORDER_UPDATED: gql`
    subscription {
      orderUpdated {
        id
        quotation_fk
      }
    }
  `,

  ORDER_LOG_UPDATED: gql`
    subscription {
      orderLogUpdated {
        id
        order_fk
        order_log_type_fk
        file_id
      }
    }
  `,

  ORDER_LOG_CREATED: gql`
    subscription {
      orderLogCreated {
        id
        order_fk
      }
    }
  `,
  ON_ERROR: gql`
    subscription {
      onError {
        user {
          email
        }
        dealer {
          id
        }
        employee {
          id
        }
        quotation {
          id
        }
        company {
          id
        }
        message
        createdAt
      }
    }
  `,
  DELETE_FIELD: gql`
    mutation DeleteField($id: ID!) {
      deleteField(id: $id) {
        id
      }
    }
  `,
  DELETE_FIELD_VALUE: gql`
    mutation DeleteFieldValue($id: ID!) {
      deleteFieldValue(id: $id) {
        id
      }
    }
  `,
  DELETE_COMPANY_CALCULATION_SHEET: gql`
    mutation DeleteCompanyCalculationSheet($id: ID!) {
      deleteCompanyCalculationSheet(id: $id) {
        id
        calculation_sheet
      }
    }
  `,
  DELETE_COMPANY: gql`
    mutation DeleteCompany($id: ID!) {
      deleteCompany(id: $id) {
        id
        name
      }
    }
  `,
}
