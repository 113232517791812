import React from "react";
import { Select } from "antd";

import { noop } from "../../utils";
import Tooltip from "./tooltip";

const Dropdown = ({
  onChange = noop,
  options = [],
  size = "large",
  mode = "default",
  ...props
}) => (
  <Tooltip title={props.tooltipTitle}>
    <Select
      showSearch={props.showSearch}
      autoFocus={props.autoFocus}
      allowClear={props.allowClear}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      style={props.style}
      placeholder={props.placeholder}
      onChange={onChange}
      size={size}
      mode={mode}
      {...props}
    >
      {options.map((item) => (
        <Select.Option key={item.key} value={item.value}>
          {item?.name ?? item?.value}
        </Select.Option>
      ))}
    </Select>
  </Tooltip>
);

export default Dropdown;
