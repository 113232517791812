import React, { useState, useEffect } from 'react';
import { useI18nContext, getLabel } from '../../api/i18nService';
import { Input, InputNumber, Button, Select, Form, Row, Col, Typography, Checkbox, Icon, Avatar } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const Option = Select.Option;
const { Text } = Typography;

const EmployeeAccessForm = ({loading, company, dealers, createErrorMsg, submitForm, selectedDealer, setSelectedDealer, setName, setMail, setEmployeeStatute, setBikeType, bikeTypes}) => {

    const { strings } = useI18nContext();

    //Information form
    const [fields, setFields] = useState(company.fields.sort((a, b) => a.index - b.index));
    const [quotationFields, setQuotationFields] = useState(null);
    const [statutes, setStatues] = useState(company.statutes);
    const [quotation, setQuotation] = useState({
        dealer_fk: "",
        employee_fk: "",
        deliverability: '',
        quotation_source: 'DealerAppV2',
        quotation_date: new Date().toISOString(),
        active: true,
        bike_type_fk: null,
        bike_brand_fk: null
      });

    //Send mail info
    const [sendToEmployee, setSendToEmployee] = useState(false);
    const [sendToDealer , setSendToDealer ] = useState(false);

    //styling
    const style = {
        hr: {
            width: "30%",
            maxWidth: "300px",
            margin: "auto",
            marginTop: "25px",
            marginBottom: "25px",
            border: "0.5px solid lightGray"
        }
    }

    const setUserInfo=(type, info)=>{
        switch (type) {
          case "name":
            setName(info);
            break;
          case "mail":
            setMail(info);
            break;
        }
    }

    const createDealerSelect=()=>{
        return(
            <>
            <Select
                showSearch
                style={{ width: 300 , marginBottom: "5px"}}
                placeholder={getLabel('dealer', true, strings)}
                optionFilterProp="children"
                defaultValue = {dealers[0].id}
                onChange={val => {
                    setQuotation(old => { return { ...old, dealer_fk: val } });
                    setSelectedDealer(dealers.find((el)=>{
                      return el.id == val;
                    }));
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {dealers.map((dealer)=>{
                    return (<Option value={dealer.id} key={dealer.id}>{`${dealer.name? dealer.name: "/"} - ${dealer.zip_code? dealer.zip_code : "/"} - ${dealer.city? dealer.city : "/"}`}</Option>)
                    })
                }
            </Select>
            <p style={{color:"lightGrey"}}>{getLabel('Je kan via het invulveld zoeken op: dealer naam, postcode of locatie', true, strings)}.</p>
            </>
        )
    }

    const createStatuteSelect = () => {
      return (
      <>
      <Select
      style={{width: 300, marginBottom: '5px'}}
      placeholder={getLabel('statute', true, strings)}
      defaultValue={statutes[0].id}
      onChange={val => {
        setEmployeeStatute(statutes.find(el => el.id === val));
      }}
      >
        {statutes.map(el => {
          return (<Option value={el.id} key={el.id}>{getLabel(el.name.toLowerCase().replace(/\./g, ''), true, strings)}</Option>)
        })}
      </Select>
      </>
      )
    }

    const createBikeTypeSelect = () => {
      return (
        <>
        <Select
        style={{width: 300, marginBottom: '5px'}}
        placeholder={getLabel('bike type', true, strings)}
        defaultValue={bikeTypes? bikeTypes[0].id: ''}
        onChange={val => {
          setBikeType(bikeTypes.find(el => el.id === val));
        }}
        >
          {bikeTypes.map(el => {
            return (<Option value={el.id} key={el.id}>{getLabel(el.name, true, strings)}</Option>)
          })}
        </Select>
        </>
      )
    }

    const findQuotationField = id => {
      return quotationFields.find(el => {
        return el.fieldId === id;
      });
    }
  
    // Setup the quotaion fields based on the company fields.
    const createQuotationFields = fieldArray => {
      let quoteFields = [];
      if (!company.fields) {
        fieldArray.forEach(item => {
          quoteFields.push({ field_fk: item.id, value: (item.fieldValues.length > 0) ? item.fieldValues[0].value : '' });
        });
      }
      else {
        quoteFields = company.fields.map(f => {
          if(f.code == "LIST")
            f.value = f.fieldValues[0].value;

          delete f.id;
          delete f.__typename;
          return f;
        });
      }
      setQuotationFields(quoteFields);
    }

    useEffect(()=>{
      createQuotationFields(company.fields);
    }, [])

    const mapField = (field) => {
      if(quotationFields){
        switch (field.code) {
          case 'TEXT':
            return (<Input
              defaultValue={findQuotationField(field.fieldId).value}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setQuotationFields(old => {
                  const oldField = findQuotationField(field.fieldId);
                  old[old.indexOf(oldField)] = { ...oldField, value: val };
                  return old;
                })
              }}
              placeholder={!!field.description ? getLabel(field.description.toLowerCase().replace(/\./g, ''), true, strings) : getLabel(field.fieldTypeName.toLowerCase().replace(/\./g, ''), true, strings)}
              />);
        case 'NUMBER':
            return (
              <span>€
            <InputNumber
                  style={{ marginLeft: '8px' }}
                  min={0}
                  defaultValue={findQuotationField(field.fieldId).value || 0}
                  precision={2}
                  decimalSeparator=","
                  onChange={e => {
                    setQuotationFields(old => {
                      const oldField = findQuotationField(field.fieldId);
                      old[old.indexOf(oldField)] = { ...oldField, value: DOMPurify.sanitize(e) || 0 };
                      return old;
                    })
                  }}
                  step={0.01} />
              </span>
            );
          case 'LIST':
            return (
              <Select
                defaultValue={field.fieldValues[0].value}
                onChange={e => {
                  setQuotationFields(old => {
                    const oldField = findQuotationField(field.fieldId);
                    old[old.indexOf(oldField)] = { ...oldField, value: e };
                    return old;
                  })
                }}
              >
                {field.fieldValues.map((item, index) => {
                  return (<Option key={index} value={item.value}>{getLabel(item.value.toLowerCase().replace(/\./g, ''), true, strings)}</Option>);
                })}
              </Select>
            );
          default:
            return null;
        }
      }
      };

    const createForm=()=>{
        return (
            <Form>
                <Row type="flex" justify="center" title="Nieuwe offerte" gutter={8}>
                {/*<Col span={12}>

                    <Form.Item label={getLabel('deliverability', true, strings)} required={true}>
                        <Input
                            defaultValue={quotation.deliverability}
                            placeholder={getLabel('Stock winkel, Stock leverancier', true, strings) + ', ...'}
                            onChange={e => {
                            const val = e.target.value;
                            setQuotation(old => {
                                return { ...old, deliverability: val };
                            })
                            }} />
                    </Form.Item>
                    <Form.Item label={getLabel('bike type', true, strings)} required={true}>
                    <Select
                        defaultValue={quotation.bike_type_fk != null ? sortedBikeTypes.find(el => { return el.id === quotation.bike_type_fk }).id : null}
                        onChange={val => {
                        setQuotation(old => { return { ...old, bike_type_fk: val } });
                        }}
                    >
                        {sortedBikeTypes.map((item, index) => {
                        return (<Option
                            key={index}
                            value={item.id}
                        >{getLabel(item.name, true, strings)}</Option>)
                        })}
                    </Select>
                    </Form.Item>
                    <Form.Item label={getLabel('bike brand', true, strings)} required={true}>
                    <Select defaultValue={quotation.bike_brand_fk ? quotation.bike_brand_fk : null}
                        showSearch
                        optionFilterProp='children'
                        onChange={val => {
                        setQuotation(old => { return { ...old, bike_brand_fk: val } });
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {data.bikeBrands.map((item, index) => {
                        return (<Option
                            key={index}
                            value={item.id}
                        >{item.name}</Option>)
                        })}
                    </Select>
                    </Form.Item>
                </Col>*/}
                <Col span={12}>
              <h5>{getLabel('bike type', true, strings)}</h5>
                  {createBikeTypeSelect()}
                    {fields.map((item, key) => {
                    return (
                        <Form.Item key={key} label={getLabel(item.fieldTypeName.toLowerCase().replace(/\./g, ''), true, strings)}>
                        {item.description && <Text style={{ display: 'block', fontSize: '8pt', lineHeight: '10pt' }} type='secondary'>{getLabel(item.description.replace(/\./g, ''), true, strings)}</Text>}
                        {mapField(item)}
                        </Form.Item>
                    )
                    })}

                </Col>
                </Row>
            </Form>
        );
    }

    const onSubmitClick=()=>{
        //null checking w/ waring message?

        //Manipulations
        let newQuotation = quotation;
        for(const item of quotationFields){
          newQuotation[item.fieldName] = item.value;
        };

        //Pass data
        submitForm(quotation, quotationFields, sendToEmployee, sendToDealer);
    }

    return (
        <div style={{
            width: "80%",
            maxWidth: "1250px",
            margin: "auto",
            marginTop: "30px",
            marginBottom: "30px",
            padding: "1%"
        }}>


              <div style={{width:"100%", overflow: "auto"}}>
                <div style={{float: "left"}}>
                {company.logo_url ?
                    <img src={company.logo_url} style={{maxWidth: '128px', maxHeight: '128px', marginBottom:"25px"}} />
                    :
                    <Avatar size='large' style={{ maxWidth: '128px', maxHeight: '128px', marginBottom: '25px' }}>{company.name}</Avatar>
                }

                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="team" style={{marginRight: 10}}/>
                    {company && company.name? company.name:"/"}</p>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="mail" style={{marginRight: 10}}/>
                    {company && company.email? company.email:"/"}</p>
                </div>
              </div>
              <hr style={style.hr} />


            <h4 style={{marginBottom: "25px"}}>{getLabel('Algemene informatie', true, strings)}:</h4>

            <Row type="flex" justify="center" title="Nieuwe offerte" gutter={8}>
            <Col span={12}>
            <h5>{getLabel('Volledige naam', true, strings)}:</h5>
            <Input
              style={{marginBottom:"20px", width: 300}}
              placeholder={getLabel('Volledige naam', true, strings)}
              onChange={e => {
                setUserInfo("name" ,DOMPurify.sanitize(e.target.value));
              }}
            />

            <h5>{getLabel('E-mail', true, strings)}:</h5>
            <Input
              style={{marginBottom:"20px", width: 300}}
              placeholder={getLabel('E-mail', true, strings)}
              onChange={e => {
                setUserInfo("mail" ,DOMPurify.sanitize(e.target.value));
              }}
            />
            <h5>{getLabel('statute', true, strings)}</h5>
            {createStatuteSelect()}

            <h5>{getLabel('Selecteer een dealer', true, strings)}:</h5>
            {createDealerSelect()}

            </Col>
            </Row>

            <hr style={style.hr} />

            <h4>{getLabel('Vul onderstaande velden in', true, strings)}:</h4>
            {createForm()}

            <Row type="flex" justify="center" title="Nieuwe offerte" gutter={8}>
              <Col>
                <Checkbox onChange={event => setSendToEmployee(event.target.checked)}>{getLabel('Send email to me', true, strings)}</Checkbox>
              </Col>

            </Row>

            <Button style={{marginTop: "30px", marginBottom: "50px", display: "block", float:"right"}} onClick={onSubmitClick} loading={loading}>{getLabel('Bereken', true, strings)}</Button>
        </div>
    )
}

export default EmployeeAccessForm;