import React from 'react'
import { Modal as AntModal } from 'antd'

const Modal = ({ title = 'Modal title', visible = false, ...props }) => (
  <AntModal
    title={title}
    visible={visible}
    width={750}
    footer={null}
    {...props}
  >
    {props.content}
  </AntModal>
)

export default Modal
