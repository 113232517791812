import React, { useEffect } from 'react';
import { Row, Button, Col, Modal, Icon, Menu, Dropdown, Select, Divider } from 'antd';
import { useStateValue } from '../stateProvider/stateProvider';
import './infoDamage.css';

const theadStyle = { backgroundColor: '#7CBC1F', color: 'white', height: '64px' };


const InfoDamage = (props) => {
    const [{ dealerIndex, currentDealer, allDealers, currentUser, userDetails, token }, dispatch] = useStateValue();

    if (!userDetails) {
        return (
            <p></p>
        )
    }
    if (userDetails.language === 'fr') {
        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Résumé dommage</h1>
                    <div class=""><div class="">
                        <div class="table-2">
                            <table width="100%">
                                <thead style={theadStyle}>
                                    <tr>
                                        <th align="left"></th>
                                        <th align="left">@ (cc:schade@b2bike.be)</th>
                                        <th align="left">Vol: clés</th>
                                        <th align="left">Franchise: en tort</th>
                                        <th align="left">Vol / perte total &gt; nouveau vélo?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td align="left">B2Bike</td>
                                        <td align="left">info@equinoxinsurance.be</td>
                                        <td align="left">Photo</td>
                                        <td align="left">€ 25 (à payer dans le magasin)</td>
                                        <td align="left">Dans le contrat existant</td>
                                    </tr>
                                    <tr>
                                        <td align="left">J&amp;T</td>
                                        <td align="left">info@equinoxinsurance.be</td>
                                        <td align="left">Photo</td>
                                        <td align="left">€ 25 (à payer dans le magasin)</td>
                                        <td align="left">Dans le contrat existant</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Alphabet</td>
                                        <td align="left">insurance@alphabet.be</td>
                                        <td align="left">Mail</td>
                                        <td align="left">35 / 150</td>
                                        <td align="left">Fin du contrat</td>
                                    </tr>
                                    <tr>
                                        <td align="left">ALD/Axus</td>
                                        <td align="left">damage@aldautomotive.com</td>
                                        <td align="left">Mail</td>
                                        <td align="left">€ 75</td>
                                        <td align="left">Contrat existant</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Arval</td>
                                        <td align="left">insurance.be@arval.be</td>
                                        <td align="left">Mail</td>
                                        <td align="left">indiqué sur le contrat de leasing</td>
                                        <td align="left">Fin du contrat</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Dieteren</td>
                                        <td align="left">dieteren@pv.be</td>
                                        <td align="left">Mail</td>
                                        <td align="left">Dégât: € 150, Vol: € 500</td>
                                        <td align="left">Fin du contrat</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div class="fusion-clearfix"></div></div></div>
                    <div class="fusion-layout-column fusion_builder_column fusion-builder-column-6 fusion_builder_column_2_3 2_3 fusion-two-third" ><div class="fusion-column-wrapper fusion-flex-column-wrapper-legacy" ><div class="fusion-sep-clear"></div><div class="fusion-separator fusion-full-width-sep" ></div><div class="fusion-sep-clear"></div><div class="fusion-text fusion-text-2"><h2 data-fontsize="28" data-lineheight="33.88px" class="fusion-responsive-typography-calculated" ><h4>Par société de leasing</h4></h2>
                        <p><b>Alphabet</b></p>
                        <ul>
                            <li><a href="https://docs.google.com/document/d/1rnOS7ICFNb3hP0V-HMMzhSI_20J7jypxeVtb4uWpXvI/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Alphabet</a></li>
                        </ul>
                        <p><b>ALD/Axus</b></p>
                        <ul>
                            <li>Assurance via Safebike (B2Bike)
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1P9z9lwiP0-rgEAVl1pSlHXeJ5NXmnaTT/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Safebike</a></li>
                                </ul>
                            </li>
                            <li>Assurance via Axus/ALD
                                <ul>
                                    <li><a href="https://drive.google.com/a/b2bike.be/file/d/10J1QXKwqQN_l2UpTUrEOR-YEWSUXWq3i/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre ALD</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/open?id=1B-_ekfmm5JcrhTdPMLDpCUhpjrgAnPaS" target="_blank" rel="noopener noreferrer">Formulaire dossier de sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>Arval</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1fsdPXRP0oL6xbXU0iq74TM0TqM-wy75a/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Arval</a></li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>B2Bike (aankoop of eigen lease)</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1P9z9lwiP0-rgEAVl1pSlHXeJ5NXmnaTT/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Safebike</a></li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSeFs89Bvu9hyBOQjxuRQZgxcIb-m9CnOIVvAhyY2CLGfQ3XHg/viewform" target="_blank" rel="noopener noreferrer">Info vélo de remplacement</a></li>
                        </ul>
                        <p><b>Directlease</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1P9z9lwiP0-rgEAVl1pSlHXeJ5NXmnaTT/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Safebike</a></li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSeFs89Bvu9hyBOQjxuRQZgxcIb-m9CnOIVvAhyY2CLGfQ3XHg/viewform" target="_blank" rel="noopener noreferrer">Info vélo de remplacement</a></li>
                        </ul>
                        <p><b>J&amp;T</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1P9z9lwiP0-rgEAVl1pSlHXeJ5NXmnaTT/view?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre Safebike</a></li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSeFs89Bvu9hyBOQjxuRQZgxcIb-m9CnOIVvAhyY2CLGfQ3XHg/viewform" target="_blank" rel="noopener noreferrer">Info vélo de remplacement</a></li>
                        </ul>
                        <p><b>VDFIN</b></p>
                        <ul>
                            <li><a href="https://docs.google.com/document/d/14Cnq1ONKISYm_Y2U12Ra8dJU7sceuwaj_qZEqX7gyyI/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Flux en cas de sinistre VDFIN</a></li>
                            <li>Garantie e-bike
                                <ul>
                                    <li><a href="https://docs.google.com/document/d/1u138O0CnroDyC26lULpBA2KpY3gD7THEyQmnESdkGnk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Déclaration sinistre</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1PPgNWUFRef9IUeYiDVzNjA9K9gpYUrIU/view?usp=sharing" target="_blank" rel="noopener noreferrer">Modèle de mail déclaration sinistre</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h3 data-fontsize="18" data-lineheight="30.06px" class="fusion-responsive-typography-calculated"></h3>
                        <h3 data-fontsize="18" data-lineheight="30.06px" class="fusion-responsive-typography-calculated"></h3>
                    </div><div class="fusion-clearfix"></div></div></div>
                </Col>
            </Row >
        )
    }
    else {
        const reportFormTheftDamage = <ul>
            <li><a href="https://www.b2bikeclaims.be/" target="_blank" rel="noopener noreferrer">Aangifte formulier Schade/Diefstal</a></li>
        </ul>

        return (
            <Row type='flex' align='middle'>
                <Col span={12} offset={6}
                    style={{
                        padding: '20px',
                        backgroundColor: 'white',
                        boxShadow: ' 0px 5px 5px 0px rgba(0,0,0,0.25)'
                    }}>
                    <h1>Info verzekeringen</h1>
                    <p>Heb je een schade of diefstal dossier, check de leasemaatschappij en verzekering van het bedrijf. Alle fietsen hebben een omniumverzekering, behalve in de dossiers waar de verzekering optioneel is. Dit kan voorvallen bij leasemaatschappij ALD of B2Bike (aankoop of leasing).</p>

                    <p>Weet je de leasemaatschappij niet dan kun je dit vinden in <a href='https://docs.google.com/spreadsheets/d/1iYY5hI8O0FCbe8H3akhxXG1qrvtl8eLXIioNos8EVxc/edit?usp=sharing' target='_blank'>dit overzicht van de bedrijven.</a></p>
                    <p>Kijk met volgende stappen na of er een verzekering is:</p>
                    <ul>
                        <li> Check kolom leasemaatschappij</li>
                        <li> Check kolom verzekering: verzekering verplicht of optioneel? Welke verzekering?</li>
                        <li>Indien optioneel: check of de klant de verzekering heeft genomen</li>
                        <li>In de Dealapp: ‘mijn offertes’</li>
                        <ol>
                            <li>Zoek klant op familienaam</li>
                            <li>Check de offerte van de geleverde fiets</li>
                            <li>Indien bij verzekeringsbedrag niet ‘0’ staat, dan is er een verzekering</li>
                        </ol>
                    </ul>
                    <p>Bij twijfel of vragen over verzekeringen contacteer ons op: 03/295 92 58 of schade@b2bike.be</p>
                    <br />
                    <h2>Schadeflow overview</h2>
                    <table>
                        <thead style={theadStyle}>
                            <tr>
                                <th align="left"></th>
                                <th align="left">@<br />
                                    (cc: schade @b2bike.be)</th>
                                <th align="left">Diefstal: sleutels</th>
                                <th align="left">Vrijstelling in eigen schade</th>
                                <th align="left">Diefstal/TV &gt; nieuwe fiets?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="left">B2Bike</td>
                                <td align="left">info@equinoxinsurance.be</td>
                                <td align="left">Foto</td>
                                <td align="left">€ 25 (direct afrekenen met bestuurder)</td>
                                <td align="left">In bestaand contract</td>
                            </tr>
                            <tr>
                                <td align="left">J&amp;T</td>
                                <td align="left">info@equinoxinsurance.be</td>
                                <td align="left">Foto</td>
                                <td align="left">€ 25 (direct afrekenen met bestuurder)</td>
                                <td align="left">In bestaand contract + facturatie J&amp;T &lt;&gt; B2Bike</td>
                            </tr>
                            <tr>
                                <td align="left">Alphabet</td>
                                <td align="left">insurance@alphabet.be</td>
                                <td align="left">Mail</td>
                                <td align="left">35/150</td>
                                <td align="left">Contract stopt</td>
                            </tr>
                            <tr>
                                <td align="left">ALD/Axus</td>
                                <td align="left">damage@aldautomotive.com</td>
                                <td align="left">Mail</td>
                                <td align="left">€ 75</td>
                                <td align="left">Contract loopt door</td>
                            </tr>
                            <tr>
                                <td align="left">Arval</td>
                                <td align="left">insurance.be@arval.be</td>
                                <td align="left">Mail</td>
                                <td align="left">staat op lease-offerte</td>
                                <td align="left">Contract stopt</td>
                            </tr>
                            <tr>
                                <td align="left">Dieteren</td>
                                <td align="left">dieteren@pv.be</td>
                                <td align="left">Mail</td>
                                <td align="left">Eigen schade: €150, diefstal: €500</td>
                                <td align="left">Contract stopt</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <div class="fusion-text fusion-text-3"><h2><span>Per leasemaatschappij</span></h2>
                        <p><b>Alphabet</b></p>
                        <ul>
                            <li><a href="https://docs.google.com/document/d/1wkICEDVBTUDwBcJBTJliGm85swPwtaz6dDrkX5e29Wc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Schadeflow Alphabet</a></li>
                        </ul>
                        <p><b>ALD/Axus</b></p>
                        <ul>
                            <li><span>Verzekering via Safebike (B2Bike)</span>
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1U1cv1yejcIWolgkyzLgmaOPNDqb-RFEA/view?usp=sharing" target="_blank" rel="noopener noreferrer">Schadeflow Safebike</a></li>
                                    {reportFormTheftDamage}
                                </ul>
                            </li>
                            <li><span>Verzekering via Axus/ALD</span>
                                <ul>
                                    <li><a href="https://drive.google.com/open?id=1o_8PEFzuqwuXsD7EUOWSRRu69FFXkzfa" target="_blank" rel="noopener noreferrer">Schadeflow ALD</a>
                                        <ul>
                                            <li>Aangifte fiets <a href="https://drive.google.com/open?id=1Q000q5Fbc5RCkApnHond2uyMbp4zAT9X" target="_blank" rel="noopener noreferrer">schadeformulier</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>E-bike garantie
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>Arval</b></p>
                        <ul>
                            <li ><a href="https://drive.google.com/file/d/1628jWqO2kAqlB1M1eOTZHlAYfy_xCPyJ/view?usp=sharing" target="_blank" rel="noopener">Schadeflow Arval</a></li>
                            <li >E-bike garantie</li>
                            <li >
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>B2Bike (aankoop of eigen lease)</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1U1cv1yejcIWolgkyzLgmaOPNDqb-RFEA/view?usp=sharing" target="_blank" rel="noopener noreferrer"><span>Schadeflow Safebike</span></a></li>
                            {reportFormTheftDamage}
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdEIXQEQ0vX1A-M4gy_O7ys6pZCABcKA-TmLwrIrHyqT5vK0g/viewform" target="_blank" rel="noopener noreferrer"><span>Info vervangfiets</span></a></li>
                            <li>E-bike garantie
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>Directlease</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1U1cv1yejcIWolgkyzLgmaOPNDqb-RFEA/view?usp=sharing" target="_blank" rel="noopener noreferrer"><span>Schadeflow Safebike</span></a></li>
                            {reportFormTheftDamage}
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdEIXQEQ0vX1A-M4gy_O7ys6pZCABcKA-TmLwrIrHyqT5vK0g/viewform" target="_blank" rel="noopener noreferrer"><span>Info vervangfiets</span></a></li>
                            <li>E-bike garantie
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>J&amp;T</b></p>
                        <ul>
                            <li><a href="https://drive.google.com/file/d/1U1cv1yejcIWolgkyzLgmaOPNDqb-RFEA/view?usp=sharing" target="_blank" rel="noopener noreferrer"><span>Schadeflow Safebike</span></a></li>
                            {reportFormTheftDamage}
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdEIXQEQ0vX1A-M4gy_O7ys6pZCABcKA-TmLwrIrHyqT5vK0g/viewform" target="_blank" rel="noopener noreferrer"><span>Info vervangfiets</span></a></li>
                            <li>E-bike garantie
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><b>VDFIN</b></p>
                        <ul>
                            <li ><a href="https://docs.google.com/document/d/1iIalWuF0YSQrPBsQyW9fiBGs0xYM3Vamq7_8x6P1jUI/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Schadeflow VDFIN</a></li>
                            <li>E-bike garantie
                                <ul>
                                    <li><a href="https://drive.google.com/file/d/1IH3F5TzYLmgtxZ8B5KNVMjSYlPUsINBv/view?usp=sharing" target="_blank" rel="noopener noreferrer">Reparatiemelding flow</a>
                                        <ul>
                                            <li><a href="https://drive.google.com/file/d/1Sm3IR9PbthwQSeK2EshZtiges6ziO-e5/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mail template schademelding</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h3 ></h3>
                        <h4 ></h4>
                        <h3></h3>
                    </div>
                </Col>
            </Row >
        )
    }
};

export default InfoDamage;