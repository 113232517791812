/**
 * apiUrls.js
 * Provides global access to urls for the api and microservices
 */

// API
export const apiHttpUrlProd = 'https://api-xqwnf6cjkq-ew.a.run.app/'
export const apiHttpUrlStaging = 'https://api-staging-xqwnf6cjkq-ew.a.run.app'
export const apiHttpUrlDev = 'https://api-development-xqwnf6cjkq-ew.a.run.app'
export const apiHttpUrlLocal = 'http://localhost:8080'

export const apiWsUrl = 'wss://b2bike-253312.appspot.com/graphql'

// TODO: deze ook nog weghalen en vervangen met die van getServiceUrl (andere branch mee opnemen)
// MicroServices
export const registerDealerService =
  'https://europe-west1-b2bike-253312.cloudfunctions.net/registerDealer'
export const sendEmailService =
  'https://europe-west1-b2bike-253312.cloudfunctions.net/handleSendEmail'

export const bugsnagApiKey = 'fe08cba31e7f2d405782bdab51a17810'
