import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Row, Spin, Modal, Typography } from 'antd'
import CompanySearch from '../companySearch/companySearch'
import CompanyInfoCard from '../companyInfoCard/companyInfoCard'
import { useStateValue } from '../stateProvider/stateProvider'
import { getLabel, useI18nContext } from '../../api/i18nService'

const { Title } = Typography

const Home = () => {
  const [token, setToken] = useState('')
  const [showCompanyModal, setShowCompanyModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(false)
  const [loading, setLoading] = useState(true)
  const [{ userDetails }] = useStateValue()
  const { strings } = useI18nContext()

  useEffect(() => {
    if (selectedCompany) {
      setShowCompanyModal(true)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (userDetails.email) {
      setLoading(false)
    }
  }, [userDetails])

  if (loading) {
    return <Loader />
  }

  if (loading === false) {
    return !!userDetails.companyIds && !userDetails.dealerIds ? (
      <Redirect to="/employersportal" />
    ) : (
      <div>
        <Title level={2} type="secondary">
          {getLabel('new quotation', true, strings)}
        </Title>
        <Modal
          visible={showCompanyModal}
          width={760}
          footer={null}
          onCancel={() => {
            setShowCompanyModal(false)
          }}
          destroyOnClose={true}
        >
          <CompanyInfoCard
            company={selectedCompany}
            token={token}
            showNextButton={true}
            nextRoute={`employees/${selectedCompany.id}`}
            setCompany={true}
          />
        </Modal>
        {userDetails.dealerIds && userDetails.dealerIds.length > 0 && (
          <CompanySearch
            callback={(comp) => {
              setShowCompanyModal(true)
              setSelectedCompany(comp)
            }}
          />
        )}
      </div>
    )
  }
}

const Loader = ({ style = { marginTop: 128 } }) => (
  <Row type="flex" justify="center">
    <Spin size="large" style={style} />
  </Row>
)

export default Home
