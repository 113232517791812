import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Modal, Table, Spin, Input, Icon, Button, Row, Col, Checkbox, Switch, Select, message, Empty, Dropdown, Menu, Tooltip, Tabs, Card } from 'antd';
import { useStateValue, backofficeDealer } from '../stateProvider/stateProvider';
import { QUERIES } from '../../api/queries';
import { useI18nContext, getLabel } from '../../api/i18nService';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import moment from 'moment';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  RadialChart,
  DiscreteColorLegend
} from 'react-vis';

const COLORS = ['#EAEFDB', '#D2E0A8', '#B9DA58', '#95C120', '#64CF3E', '#1EAF7D', '#169B9B', '#338398', '#0E3944', '#F2F5ED']

function Dashboard() {

  const [{ currentUser, userDetails }, dispatch] = useStateValue();
  const [quotations, setQuotations] = useState(null);
  const [orderStatuses, setOrderStatuses] = useState(null);
  const [quotationsPerStatus, setQuotationsPerStatus] = useState(null)
  const [bikeTypes, setBikeTypes] = useState(null);
  const [quotationsPerBikeType, setQuotationsPerBikeType] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [quotationsPerCompany, setQuotationsPerCompany] = useState(null);

  const [tooltip, setTooltip] = useState({ visible: false, content: '' })

  const {strings} = useI18nContext();

  const [getQuotations] = useLazyQuery(QUERIES.QUOTATIONS_FOR_DASHBOARD, {
    fetchPolicy: 'network-only',
    onCompleted: (quotRes => {
      if (!!quotRes) {
        if (!!quotRes.quotations) {
          setQuotations(quotRes.quotations);
          setOrderStatuses(quotRes.orderStatuses);
          setBikeTypes(quotRes.bikeTypes);
        }
      }
    })
  });

  const [getCompanies, companiesRes] = useLazyQuery(QUERIES.COMPANIES_BY_ID, {
    variables: { filter: { company_ids: userDetails.companyIds } },
    fetchPolicy: 'network-only',
    onCompleted: (d => {
      if (!!d.companiesById) {
        setCompanies([...d.companiesById])
      }
    })
  })

  useEffect(() => {
    dispatch({ type: 'setCurrentEmployersPage', page: 'dashboard' })
  }, [])

  useEffect(() => {
    if (!!userDetails) {
      getQuotations({
        variables: {
          limit: 9999,
          filter: { company_ids: userDetails.companyIds }
        }
      })
    };
    getCompanies();
  }, [userDetails])

  useEffect(() => {
    if (!!quotations && !!orderStatuses && bikeTypes && companies) {
      const totalCount = quotations.filter(el => !!el.order).length;

      // Set orders per status
      const countPerStatus = [];
      for (let os of orderStatuses) {
        if (os.code !== 'TESTPURPOSES') {
          const thisEl = {
            x: getLabel(os.code.toLowerCase(), true, strings),
            color: COLORS[orderStatuses.indexOf(os)],
            y: quotations.filter(el => {
              if (!!el.order) {
                return el.order.order_status_fk === os.id;
              }
            }).length
          }
          countPerStatus.push(thisEl);
        }
      }
      setQuotationsPerStatus(countPerStatus);

      // Set orders per bike type
      const countPerBikeType = [];
      for (let bt of bikeTypes) {
        const count = quotations.filter(el => {
          if (!!el.order) {
            return el.bikeType.id == bt.id;
          }
        }).length;
          countPerBikeType.push({
            angle: (count / totalCount) * 360,
            color: COLORS[bikeTypes.indexOf(bt)],
            label: bt.name,
            count: count
          });
      }
      setQuotationsPerBikeType(countPerBikeType);

      // Set orders per company
      const countPerCompany = [];
      for (let c of companies) {
        const count = quotations.filter(el => {
          if (!!el.order) {
            return el.employee.company.id === c.id;
          }
        }).length;
        countPerCompany.push({
          angle: (count / totalCount) * 360,
            color: COLORS[companies.indexOf(c)],
            label: c.name,
            count: count
        });
      }
      setQuotationsPerCompany(countPerCompany);
    }
  }, [quotations, orderStatuses, bikeTypes, companies])

  return (
    <>
    <h1>{getLabel('dashboards', true, strings)}</h1>
      <Row type='flex' justify='space-around'>
        {quotationsPerStatus? (
          <Col className='dashboard-panel'>
          <h2>{getLabel('Statusverdeling', true, strings)}</h2>
          <XYPlot
            xType="ordinal"
            width={600}
            height={300}
            // margin={{ bottom: 110 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis tickLabelAngle={-45} tickFormat={v => ''} />
            <YAxis />
            <VerticalBarSeries
              colorType="literal"
              data={quotationsPerStatus}
              // color='#7CBC1F'
              style={{ rx: 5, width: 10 }}
              // onNearestX={((value, {event}) => {
              //   if (tooltip.content !== `${value.x}: ${value.y}`) {
              //     setTooltip(old => {return {visible: true, content: `${value.x}: ${value.y}`}})
              //     setTimeout(() => {
              //       setTooltip({visible: false, content: ''})
              //     }, 4000)
              //   }
              // })}
              onValueMouseOver={(event) => {
                if (tooltip.content !== `${event.x}: ${event.y}`) {
                  setTooltip({ content: `${event.x}: ${event.y}`, visible: true })
                }
              }}
              onSeriesMouseOut={(event) => {
                setTooltip({ visible: false, content: '' })
              }}
            />
          </XYPlot>
          {/* <DiscreteColorLegend 
        orientation="horizontal"
        items={quotationsPerStatus? quotationsPerStatus.map(el => {return {...el, title: el.x, strokeWidth: 18}}): []}
        /> */}
          {quotationsPerStatus && (
            quotationsPerStatus.map((el, i) => (
              <Row type='flex'>
                <Col>
                  <span
                    style={{
                      margin: '2px',
                      height: '16px',
                      width: '16px',
                      backgroundColor: el.color,
                      borderRadius: '50%',
                      display: 'inline-block'
                    }}
                  ></span>
                </Col>
                 <Col>
                 <span>{el.x}</span>
                </Col>
              </Row>
            )))}
        </Col>
        ): (
          <Col className='dashboard-panel'>
          <h2>{getLabel('Statusverdeling', true, strings)}</h2>
          <div style={{textAlign: 'center', marginTop: '64px'}}>
          <Spin size='large'/>
          </div>
          </Col>
        )}
        {quotationsPerBikeType? (
          <Col className='dashboard-panel'>
          <h2>{getLabel('bike type', true, strings)}</h2>
          <RadialChart
            showLabels={false}
            colorType="literal"
            width={300}
            height={300}
            data={quotationsPerBikeType}
            onValueMouseOver={(datapoint) => {
              setTooltip({visible: true, content: `${getLabel(datapoint.label, true, strings)}: ${datapoint.count}`});
            }}
            onValueMouseOut={() => {
              setTooltip({visible: false, content: ''})
            }}
          />
          {
            quotationsPerBikeType.map((el, i) => (
              <Row type='flex'>
                <Col>
                  <span
                    style={{
                      margin: '2px',
                      height: '16px',
                      width: '16px',
                      backgroundColor: el.color,
                      borderRadius: '50%',
                      display: 'inline-block'
                    }}
                  ></span>
                </Col>
                 <Col>
                 <span>{getLabel(el.label, true, strings)}</span>
                </Col>
              </Row>
            ))}
        </Col>
        ): (
          <Col className='dashboard-panel'>
          <h2>{getLabel('bike type', true, strings)}</h2>
          <div style={{textAlign: 'center', marginTop: '64px'}}>
          <Spin size='large'/>
          </div>
          </Col>
        )}
        {!!quotationsPerCompany?
        (
          
          <Col className='dashboard-panel' style={{display: (companies && companies.length > 1)? 'block': 'none'}}>
          <h2>{getLabel('companies', true, strings)}</h2>
          <RadialChart
            showLabels={false}
            colorType="literal"
            width={300}
            height={300}
            data={quotationsPerCompany}
            onValueMouseOver={(datapoint) => {
              setTooltip({visible: true, content: `${datapoint.label}: ${datapoint.count}`});
            }}
            onValueMouseOut={() => {
              setTooltip({visible: false, content: ''})
            }}
          />
          {
            quotationsPerCompany.map((el, i) => (
              <Row type='flex'>
                <Col>
                  <span
                    style={{
                      margin: '2px',
                      height: '16px',
                      width: '16px',
                      backgroundColor: el.color,
                      borderRadius: '50%',
                      display: 'inline-block'
                    }}
                  ></span>
                </Col>
                 <Col>
                 <span>{el.label}</span>
                </Col>
              </Row>
            ))}
        </Col>
        ):
        (
          <Col className='dashboard-panel' style={{display: (companies && companies.length > 1)? 'block': 'none'}}>
          <h2>{getLabel('companies', true, strings)}</h2>
          <div style={{textAlign: 'center', marginTop: '64px'}}>
          <Spin size='large'/>
          </div>
          </Col>
        )
        }
      </Row>
      <MouseTooltip
        visible={tooltip.visible}
        offsetX={15}
        offsetY={10}
      >
        <span style={{
          backgroundColor: 'rgba(0, 0, 0, .6)',
          borderRadius: '8px',
          padding: '4px',
          color: '#F0F2F5'
        }}>{tooltip.content}</span>
      </MouseTooltip>
    </>

  )
}

export default Dashboard
