import React, { useState } from 'react';
import { Row, Col, Spin, Table, Input, Checkbox, Tooltip, Button, Icon, Modal, Form, Select, message, Popconfirm } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useStateValue } from '../stateProvider/stateProvider';
import { QUERIES } from '../../api/queries';
import Column from 'antd/lib/table/Column';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const DealerSettings = props => {
  const [{ currentUser, userDetails }, dispatch] = useStateValue();
  const [dealers, setDealers] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedDealers, setSelectedDealers] = useState(null);
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false)
  const { Option } = Select;

  const { error, loading, data, refetch } = useQuery(QUERIES.DEALER_ALL, {
    onCompleted: res => {
      // console.log(res.dealers);
      setDealers(res.dealers);
    }
  });

  const dealerModel = {
    name: '',
    street: '',
    city: '',
    zip_code: '',
    tel: '',
    email: '',
    contact: '',
    language: '',
    account_manager: '',
    user: '',
    isNew: true,
    active: true
  };

  const [createDealer] = useMutation(QUERIES.CREATE_DEALER);
  const [updateDealer] = useMutation(QUERIES.UPDATE_DEALER);
  const [deleteDealer] = useMutation(QUERIES.DELETE_DEALER_WITH_QUOTATION_CHECK);

  const onMutationComplete = () => {
    setSelectedDealers(null);
    setShowDetailPanel(false)
    rowSelection.selectedRows = null
    refetch()
      .then(res => {
        setDealers(res.data.dealers);
        dispatch({
          type: 'setAllDealers',
          dealers: res.data.dealers
        });
        setIsWorking(false);
        message.success('Wijzigingen doorgevoerd!');
        setLoadingTable(false)
      });
  };

  const onMutationFailed = (err, IsFromDeleteMutation = false) => {
    setIsWorking(false);
    setLoadingTable(false)
    if (IsFromDeleteMutation)
      message.error('Deze dealer heeft offertes.');
    else {
      console.error(err);
      message.error('Er ging iets mis...');
    }
  };

  let searchInput = null;
  const searchColumn = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={'Zoeken'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Zoeken
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (record[dataIndex].__typename === 'Employee') {
          return record[dataIndex].fullName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        }
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    setSelectedDealers(null)
    rowSelection.selectedRows = null

    confirm();
    setSearchTerm(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    setSelectedDealers(null);
    rowSelection.selectedRows = null;
    clearFilters();
    setSearchTerm('');
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDealers(selectedRows);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (loading) {
    return (<Row type='flex' justify='center' gutter={16}>
      <Col style={{ marginTop: '128px' }} span={1}>
        <Spin size='large'></Spin>
      </Col>
    </Row>)
  }
  if (error) {
    console.error(error.message);
    return <p>Error: {error.message}</p>
  }
  if (data && dealers) {
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            setSelectedDealer({ ...dealerModel });
            setShowDetailPanel(true);
          }}>
          <Icon type='plus' /> Nieuw
        </Button>
        <Button
          style={{ margin: '1em', display: (!!selectedDealers ? (selectedDealers.length > 0 ? 'inline' : 'none') : 'none') }}
          type='primary'
          onClick={() => {
            if (selectedDealers) {
              for (let dealer of selectedDealers) {
                dealer.active = true;
                const { __typename, ...rest } = dealer;

                updateDealer({ variables: { dealer: rest } })
                  .then(() => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }
            }
          }}>
          <Icon type='check' /> Geselecteerde dealers op actief zetten
        </Button>
        <Button
          type='primary'
          style={{ display: (!!selectedDealers ? (selectedDealers.length > 0 ? 'inline' : 'none') : 'none') }}
          onClick={() => {
            if (selectedDealers) {
              for (let dealer of selectedDealers) {
                dealer.active = false;
                const { __typename, ...rest } = dealer;

                updateDealer({ variables: { dealer: rest } })
                  .then(() => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }
            }

          }}>
          <Icon type='close' /> Geselecteerde dealers op inactief zetten
        </Button>
        <Row type='flex' justify='center'>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            loading={loadingTable}
            dataSource={dealers}
            style={{ width: '80vw', margin: 16 }}
            rowKey={record => record.id}>
            <Column
              title='Naam'
              dataIndex='name'
              render={(text, record) => (<Button
                type='link'
                onClick={() => {
                  setSelectedDealer(record);
                  setShowDetailPanel(true);
                }}
              >
                {text}
              </Button>)}
              sorter={(a, b) => a.name < b.name ? -1 : 1}
              sortDirections={['ascend', 'descend']}
              {...searchColumn('name')}
            />
            <Column
              title='Plaats'
              dataIndex='city'
              render={(text, record) => (<h4>{text}</h4>)}
            />
            <Column
              render={record => {
                return (
                  <span>
                    <Popconfirm
                      title={`Dealer ${record.name} verwijderen?`}
                      onConfirm={() => {
                        deleteDealer({ variables: { id: record.id } })
                          .then(res => onMutationComplete(res))
                          .catch(err => onMutationFailed(err, true));
                      }}
                    >
                      <Button
                        shape='circle'
                        type='danger'
                        style={{ marginRight: '8px' }}

                      ><Icon type="delete" /></Button>
                    </Popconfirm>
                  </span>
                );
              }}
            />
            <Column
              title='Intern gebruik'
              render={record => (
                <Tooltip title="Enkel voor intern gebruik">
                  <Checkbox checked={record?.internal_use_only ?? false} onChange={(e) => {
                    setLoadingTable(true)
                    const { bikeBrands, __typename, ...rest } = record
                    updateDealer({ variables: { dealer: { ...rest, internal_use_only: e.target?.checked ?? false } } })
                      .then(() => {
                        onMutationComplete();
                      })
                      .catch(err => {
                        onMutationFailed(err);
                      })
                  }} />
                </Tooltip>
              )}
            />
            <Column
              title='Actief?'
              dataIndex='active'
              render={(text, record) => {
                if (record.active) {
                  return (<Icon type="check" />)
                } else {
                  return (<Icon type="close" />)
                }
              }}
            />
          </Table>
          {selectedDealer && (
            <Modal
              onOk={() => {
                setIsWorking(true);
                if (selectedDealer.isNew) {
                  const { isNew, ...rest } = selectedDealer;
                  createDealer({ variables: { dealer: rest } })
                    .then(() => {
                      onMutationComplete();
                    })
                    .catch(err => {
                      onMutationFailed(err);
                    })
                }
                if (selectedDealer.__typename) {
                  const { __typename, ...rest } = selectedDealer;
                  delete rest["bikeBrands"] // Delete bikebrands from array to save dealer
                  updateDealer({ variables: { dealer: rest } })
                    .then(() => {
                      onMutationComplete();
                    })
                    .catch(err => {
                      onMutationFailed(err);
                    })
                }
              }}
              visible={showDetailPanel}
              onCancel={() => setShowDetailPanel(false)}
            >
              <Form>
                <Row type='flex' justify='center' gutter={16}>
                  <Col span={12}>
                    <Form.Item label='Naam'>
                      <Input
                        value={selectedDealer.name}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, name: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Contact'>
                      <Input
                        value={selectedDealer.contact}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, contact: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Taal'>
                      <Select
                        defaultValue={selectedDealer.language}
                        onChange={e => {
                          setSelectedDealer(old => {
                            return { ...old, language: e };
                          })
                        }}
                      >
                        <Option key='nl' value='nl'>nl</Option>
                        <Option key='fr' value='fr'>fr</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label='Account Manager'>
                      <Input
                        value={selectedDealer.account_manager}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, account_manager: val }
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='E-mail'>
                      <Input
                        value={selectedDealer.email}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, email: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Telefoon'>
                      <Input
                        value={selectedDealer.tel}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, tel: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Straat & nummer'>
                      <Input
                        value={selectedDealer.street}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, street: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Gemeente'>
                      <Input
                        value={selectedDealer.city}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, city: val }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Postcode'>
                      <Input
                        value={selectedDealer.zip_code}
                        onChange={e => {
                          let val = DOMPurify.sanitize(e.target.value);
                          setSelectedDealer(old => {
                            return { ...old, zip_code: val }
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          )}
        </Row>
      </div>
    )
  }
  return (null);
}

export default DealerSettings;