import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { Dropdown, Menu, Modal, Spin, List, Row, Col, Button, Icon, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Search } = Input;

const SearchEmployee = props => {
  const [visible, setVisible] = useState(props.visible || true);
  const [bikeDropdownVisible, setBikeDropdownVisible] = useState(false);
  const [employees, setEmployees] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { loading, error, refetch } = useQuery(QUERIES.EMPLOYEE_FOR_SERVICE_PASS, {
    variables: { search: searchTerm },
    onCompleted: res => {
      setEmployees(res.searchEmployees.filter(el => {
        if (!!el.bike) {
          return el.bike.length > 0;
        }
        return false;
      }));
    }
  });

  useEffect(() => {
    if (props.name) {
      setSearchTerm(props.name);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      return props.onClose();
    }
  }, [visible]);

  const menu = () => {
    const el = selectedEmployee;
    if (!el) return;
    return (
      <Menu>
        <Menu.Item disabled>
          ID   - Merk
      </Menu.Item>
        {el.bike.map((b) => {
          return (<Menu.Item key={b.id} onClick={() => {
            props.onSelect({ employee: el, bike: b });
            setBikeDropdownVisible(false);
            setVisible(false);
            props.onClose();

          }}>
            {'Q' + b.order.quotation_fk + ' - ' + b.bikeBrand.name}
          </Menu.Item>
          )
        })
        }
      </Menu>
    )
  };



  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={() => { setVisible(false) }}
      destroyOnClose={true}
    >
      {false && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '64px' }}><Spin size='large' /></div>
      )}
      {true && (
        <div>
          <Search
            defaultValue={searchTerm}
            onSearch={(e) => {
              setSearchTerm(e);
              refetch({ variables: { search: e } });
            }}
          />
          <List
            loading={loading}
          >
            <List.Item key={'dummy'}>
              <List.Item.Meta
                title={<b>{searchTerm}</b>}
                description={<Row type='flex' gutter={8} style={{ width: '100%' }}>
                  <Col span={20}>
                    Ongelinkt
            </Col>
                  <Col span={4}>
                    <Button
                      shape='circle'
                      onClick={() => {
                        props.onSelect({ employeeName: searchTerm, employee: null });
                        setVisible(false);
                        props.onClose();
                      }}
                    ><Icon type="check" /></Button>
                  </Col>
                </Row>}
              />
            </List.Item>
            {employees && employees.map((el, index) => {
              return (
                // <div>
                <List.Item key={index}>
                  <Modal
                    visible={bikeDropdownVisible && !!selectedEmployee}
                    footer={false}
                    onCancel={() => { setBikeDropdownVisible(false) }}
                    destroyOnClose={true}
                  >
                    {false && (
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '64px' }}><Spin size='large' /></div>
                    )}
                    {true && (
                      <div>
                        <h5>Meerdere fietsen gevonden: </h5>
                        <Dropdown overlay={menu()} trigger={['click']} >
                          <Button>
                            {'Fiets kiezen'} <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                    )}
                  </Modal>
                  <List.Item.Meta
                    style={{ width: '100%' }}
                    title={<b>{`${el.first_name} ${el.last_name}`}</b>}
                    description={<Row type='flex' gutter={8} style={{ width: '100%' }}>
                      <Col span={20}>
                        {el.company.name}
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            if (el.bike.length > 1) {
                              setSelectedEmployee({ ...el })
                              setBikeDropdownVisible(true)
                            } else {
                              props.onSelect({ employee: el, bike: el.bike[0] });
                              setVisible(false);
                              props.onClose();
                            }

                          }}
                          shape='circle'><Icon type="check" /></Button>
                      </Col>
                    </Row>}
                  />

                </List.Item>
                // </div>

              );
            })}
          </List>
        </div>
      )}
    </Modal>
  )

};

export default SearchEmployee;

