import React, { useState, useEffect } from 'react';
import { Select, List, Button, Modal, Form, Input, Checkbox, message, Row, Col, Spin, Icon, Card, Tabs } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const { Option } = Select;

export const MODAL_TYPE_ENUM = {
    DEALER: "Dealer",
    COMPANY: "Company"
}


const UserModal=(props)=>{
    const { createNewUserVisible, setCreateNewUserVisible, saveUser, newUser, setNewUser, userModel, setEditMode, editMode, userDetails, currentModalType  } = props

    return(
        <Modal
            visible={createNewUserVisible}
            onOk={() => {
                setCreateNewUserVisible(false);
                saveUser(newUser);
            }}
            onCancel={() => {
                setCreateNewUserVisible(false);
                setNewUser({ ...userModel });
                setEditMode(false);
            }}
            cancelText={'Annuleren'}
            >
            <Form>
                <Form.Item label='Voornaam' placeholder='Voornaam'>
                <Input
                    value={newUser.first_name}
                    onChange={e => {
                    let val = DOMPurify.sanitize(e.target.value)
                    setNewUser(old => {
                        return { ...old, first_name: val };
                    })
                    }} />
                </Form.Item>
                <Form.Item label='Familienaam' placeholder='Familienaam'>
                <Input
                    value={newUser.last_name}
                    onChange={e => {
                    let val = DOMPurify.sanitize(e.target.value)
                    setNewUser(old => {
                        return { ...old, last_name: val };
                    })
                    }} />
                </Form.Item>
                <Form.Item label='E-mailadres' placeholder='E-mailadres'>
                <Input
                    disabled={editMode}
                    value={newUser.email}
                    onChange={e => {
                    let val = DOMPurify.sanitize(e.target.value)
                    setNewUser(old => {
                        return { ...old, email: val };
                    })
                    }} />
                </Form.Item>
                <Form.Item label='Language'>
                <Select
                    value={newUser.language}
                    onChange={val => {
                    setNewUser(old => {
                        return { ...old, language: val };
                    })
                    }}
                >
                    <Option value='nl'>NL</Option>
                    <Option value='fr'>FR</Option>
                    <Option value='de'>DE</Option>
                    <Option value='en'>EN</Option>
                </Select>
                </Form.Item>
                <Form.Item label={`${currentModalType} Admin`}> 
                <Checkbox
                    disabled={newUser.email === userDetails.email}
                    checked={newUser.admin}
                    value={newUser.admin}
                    onChange={(e) => {
                    let val = e.target.checked;
                    setNewUser(old => {
                        return { ...old, admin: val };
                    })
                    }}
                />
                </Form.Item>
                {
                    !!userDetails.superUser? (<Form.Item label={`Backoffice`} visible={`${userDetails.superUser}`}>
                    <Checkbox 
                    disabled={newUser.email === userDetails.email}
                    checked={newUser.superUser}
                    value={newUser.superUser}
                    onChange={newValue => {
                        setNewUser(old => {
                        return { ...old, superUser: newValue.target.checked };
                      })
                    }}
                    />
                  </Form.Item>):(
                      ''
                  )
                }
                  

            </Form>
            </Modal>
    )
}

export default UserModal;