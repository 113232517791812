import React, { useState, useEffect } from 'react';
import { Input, Button, message } from 'antd';
import Axios from 'axios';
import { useI18nContext, getLabel } from '../../api/i18nService';
import EmployeeAccessForm from './employeeAccessForm';
import EmployeeAccessResult from './employeeAccessResult';
import DOMPurify from 'dompurify';
import { getServiceUrl } from '../../util/util';

const EmployeeAccess = (props) => {

    const { strings } = useI18nContext();

    const createErrorMsg = (msg) => {
        message.error(msg);
    }

    //General
    const [name, setName] = useState("");
    const setUserName = (name) => {
        setName(name);
    }
    const [mail, setMail] = useState("");
    const setUserMail = (mail) => {
        setMail(mail);
    }
    const [employeeStatute, setEmployeeStatute] = useState(null);

    //Dealers
    const [dealers, setDealers] = useState([]);
    const [selectedDealer, setSelectedDealer] = useState(null);
    const setDealer = (dealer) => {
        setSelectedDealer(dealer);
    }

    //Acces code handling
    const [showAccessCodeFields, setShowAccessCodeFields] = useState(true);
    const [accesCodeInput, setAccesCodeInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState(null);

    //Res
    const [postData, setPostData] = useState(null);
    const [calculation, setCalculation] = useState(null);

    // BikeTypes
    const [bikeTypes, setBikeTypes] = useState(null)
    const [selectedBikeType, setSelectedBikeType] = useState(null);
    const setBikeType = val => {
        setSelectedBikeType(val);
    }

    useEffect(() => {
        //setAccesCodeInput("123");

        //Collect dealers
        setLoading(true);
        // Axios.get(endpoints.receiveAllDealersEndpoint).then(({ data: dealers }) => {
        //     dealers.sort((a, b) => {
        //         if (a.zip_code < b.zip_code) {
        //             return -1;
        //         }
        //         if (a.zip_code > b.zip_code) {
        //             return 1;
        //         }
        //         return 0;
        //     });
        //     setLoading(false);
        //     setDealers(dealers);
        //     setSelectedDealer(dealers[0]);
        // }).catch((err) => {
        //     setLoading(false);
        //     createErrorMsg(err.message);
        // });
        try {
            getDealers();
            getBikeTypes();
        }
        catch (err) {
            createErrorMsg(err.message);
        }
        finally {
            setLoading(false);
        }
    }, []);

    const getDealers = async () => {
        const url = await getServiceUrl('getDealers', null);
        const res = await Axios.get(url);
        const dealers = res.data;
        dealers.sort((a, b) => {
            if (a.zip_code < b.zip_code) {
                return -1;
            }
            if (a.zip_code > b.zip_code) {
                return 1;
            }
            return 0;
        });
        setDealers(dealers);
        setSelectedDealer(dealers[0]);
    }
    const getBikeTypes = async () => {
        const url = await getServiceUrl('getBikeTypes', null);
        const res = await Axios.get(url);
        const bts = res.data;
        const tempArray = [];
        for (let l in bts.lenght) {
            tempArray.push(null);
        }
        bts.forEach(el => {
            switch (el.code) {
                case 'MECHANIC_BIKE':
                    tempArray[0] = el;
                    break;
                case 'ELEC_BIKE':
                    tempArray[1] = el;
                    break;
                case 'SPEED_PEDELEC':
                    tempArray[2] = el;
                    break;
                case 'E-STEP':
                    tempArray[3] = el;
                    break;

            }
        });
        setBikeTypes(tempArray);
        setSelectedBikeType(tempArray[0]);
    }

    /*useEffect(() => {
        if(accesCodeInput == "123")
        checkAccessCode();
    }, [accesCodeInput]);*/

    const updateAccessCodeInput = (e) => {
        setAccesCodeInput(DOMPurify.sanitize(e.target.value));
    }

    const checkAccessCode = async () => {

        //Empty check
        if (accesCodeInput == null || accesCodeInput == "") {
            createErrorMsg(getLabel('Gelieve een access code in te vullen', true, strings));
            setLoading(false);
            setShowAccessCodeFields(true);
            return;
        }

        //collect company data based on the code
        setLoading(true);
        const url = await getServiceUrl('getCompanyByAccessCode', null);
        Axios.post(url, {
            "accessCode": accesCodeInput
        }).then(({ data }) => {
            try {
                setEmployeeStatute(data.statutes[0])
                setCompany(data);
                setShowAccessCodeFields(false);
                setLoading(false);
            } catch (err) {
                createErrorMsg(err.message);
                setLoading(false);
                setShowAccessCodeFields(true);
            }
        }).catch((err) => {
            createErrorMsg(getLabel('Controleer je access code', true, strings));
            setLoading(false);
            setShowAccessCodeFields(true);
        });
    }

    const createCalculation = async (quotation, quotationFields, sendToEmployee, sendToDealer) => {
        setLoading(true);

        //Manips
        let data = {
            company: {
                name: company.name,
                folderId: company.folder_id,
                calculationSheetId: company.calculation_sheet
            },
            fields: [

            ],
            employee: {
                name,
                email: mail,
                statute: employeeStatute
            },
            bikeType: selectedBikeType,
            dealer: selectedDealer,
            sendToEmployee,
            sendToDealer,
        }

        for (const item of quotationFields) {
            const field = {
                index: item.index,
                name: item.fieldName,
                value: item.value,
                code: item.code
            }
            data.fields.push(field);
        }
        setPostData(data);

        // Requesting
        // console.log(data);
        const url = await getServiceUrl('calculateWithoutStore', null);
        Axios.post(url,
            data
        ).then(((res) => {

            
            setCalculation(res.data);
            setLoading(false);
        
            
        })).catch((err) => {
            createErrorMsg(err.message);
            setLoading(false);
        });
    }

    return (
        <>
            {showAccessCodeFields &&
                <div style={{ width: "50%", maxWidth: "550px", margin: "auto", marginTop: "30px" }}>
                    <h4>{getLabel('Verifieer jezelf via uw access code', true, strings)}:</h4>
                    <Input placeholder="Access code" onChange={updateAccessCodeInput} onKeyPress={e => {
                        if (e.key == 'Enter')
                            checkAccessCode();
                    }
                    } />
                    <Button style={{ marginTop: "10px" }} onClick={checkAccessCode} loading={loading}>{getLabel('Valideer code', true, strings)}</Button>
                </div>
            }

            {company != null && !showAccessCodeFields &&
                <EmployeeAccessForm
                    loading={loading}
                    company={company}
                    dealers={dealers}
                    bikeTypes={bikeTypes}
                    createErrorMsg={createErrorMsg}
                    submitForm={createCalculation}
                    selectedDealer={selectedDealer}
                    setSelectedDealer={setDealer}
                    setName={setUserName}
                    setMail={setUserMail}
                    setEmployeeStatute={val => {
                        setEmployeeStatute(val);
                    }}
                    setBikeType={setBikeType}
                />
            }

            {calculation &&
                !showAccessCodeFields &&
                <EmployeeAccessResult
                    quotation={calculation}
                    postData={postData}
                    company={company}
                    dealer={selectedDealer}
                    name={name}
                    mail={mail}
                />
            }

        </>
    )
}

export default EmployeeAccess;