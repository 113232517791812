import React, { useState, useEffect, useRef } from 'react';
import { Steps, Spin, Modal, Button, Divider, Menu, Popconfirm, Tooltip, Icon, Row, Col, DatePicker, AutoComplete, Input, Form } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import { useStateValue } from '../stateProvider/stateProvider';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import * as publiceIp from 'public-ip';
import PdfModalPopup from './pdfModalpopup.js';
import DocumentsViewerPopup from './documentsViewerPopup.js';
import './employersPage.css';
import moment from 'moment';
import { useI18nContext, getLabel } from '../../api/i18nService';
import ServicePassTransactions from '../servicePassManagement/servicePassTransactions';
import FormItem from 'antd/lib/form/FormItem';

const { confirm } = Modal;
const { Step } = Steps;
const dateFormat = 'DD/MM/YYYY';

const dealPhaseNames = ["Offerte verzonden", "In onderhandeling", 'Geweigerd'];

function QuotationDetailModal({ quotation, setModalVisible, refetchCallback, currentStatus }) {

    const { strings } = useI18nContext();

    const [{ token, userDetails }] = useStateValue();
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [okButtonDisabled, setOkButtonDisabled] = useState(false);
    const [comment, setComment] = useState('/');
    const [quot, setQuot] = useState(quotation);
    const [servicepass, setServicePass] = useState(null);
    // const [description, setDescription] = useState('')
    const [updateDealPhase] = useMutation(QUERIES.UPDATE_DEAL_PHASE);
    const [employeeUpdating, setEmployeeUpdating] = useState(false);
    // const [description, setDescription] = useState('')
    const [createConfirmedOrder] = useMutation(QUERIES.CREATE_CONFIRMED_ORDER);
    const [updateConfirmedOrder] = useMutation(QUERIES.UPDATE_CONFIRMED_ORDER);
    const [updateOrder] = useMutation(QUERIES.UPDATE_ORDER);
    const [updateEmployee] = useMutation(QUERIES.UPDATE_EMPLOYEE, {
        onCompleted: (data) => {
            setEmployeeUpdating(false)
            setQuot(old => {
                return { ...old, employee: { ...employee } }
            })
        }
    });

    const [getServicePass] = useLazyQuery(QUERIES.SERVICE_PASSES, {
        onCompleted: passes => {
            const passArray = passes.servicePasses.data;
            setServicePass(passArray[0]);
        }
    });

    const updateQuotationQuery = QUERIES.UPDATE_QUOTATION_EMPLOYER_DETAIL;

    const [pdfModalVisible, setPdfModalVisible] = useState(false);
    const [documentsModalVisible, setDocumentsModalVisible] = useState(false);

    const [earliestDate, setEarliestDate] = useState(null);
    const [currentCostcenter, setCurrentCostcenter] = useState(null);
    const [refreshNeeded, setRefreshNeeded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [showTransactionsModal, setShowTransactionsModal] = useState(false);
    const [employee, setEmployee] = useState(null);
    const formSubmitRef = useRef();
    const [formChangeStatus, setFormChangeStatus] = useState({
        EMPLOYEE_NR: false,
        COST_CENTER: false,
        DELIVERY_DATE: false
    });
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        //console.log(quotation);
        setQuot(quotation);
        if (!!quotation.earliest_delivery_date) {
            setEarliestDate(quotation.earliest_delivery_date);
        }
        getServicePass({ variables: { size: 10, page: 1, filter: { quotation_number: `${quotation.id}` } } })
    }, [quotation]);

    useEffect(() => {
        if (!!quot) {
            setCurrentCostcenter(quot.costcenter);
            setButtonDisabled(false);
            if (!employee) {
                setEmployee({ ...quot.employee });
            }
        }
    }, [quot, currentStatus]);


    useEffect(() => {
    }, [currentCostcenter])

    useEffect(() => {
        const statusAcceptedOrAbove = [3, 4, 5, 8].includes(currentStatus);
        setFormDisabled(statusAcceptedOrAbove);
    }, [currentStatus]);

    async function getMyIP() {
        try {
            const ip = await publiceIp.v4();
            return ip;

        } catch {
            return 'ip tracking blocked by browser for security';
        }


    }

    function showCancelConfirm() {
        confirm({
            title: `${getLabel('reason for refusal', true, strings)}? *`,
            icon: <ExclamationCircleOutlined />,
            content: <textarea style={{ width: "90%", outline: "none", height: "200px" }} id="reasonTextarea"></textarea>,
            style: { top: '35%' },
            async onOk() {
                let description = document.getElementById("reasonTextarea").value;
                handleCancelOK(description);
            },
            okText: getLabel("afkeuren", true, strings),
            cancelText: getLabel("back", true, strings),
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    function showConfirm() {
        confirm({
            title: getLabel('Commentaar (optioneel)', true, strings),
            icon: <CheckCircleOutlined />,
            content: <textarea style={{ width: "90%", outline: "none", height: "200px" }} id="reasonTextarea"></textarea>,
            style: { top: '35%' },
            onOk() {
                let description = document.getElementById("reasonTextarea").value;
                handleConfirmOK(description);
            },
            okText: getLabel("goedkeuren", true, strings),
            cancelText: getLabel("back", true, strings),
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    async function handleCancelOK(description) {
        setLoading(true);
        setButtonDisabled(true);
        await updateOrderLog(description);
        refetchCallback();
        setModalVisible(false);
    }

    async function handleConfirmOK(description) {
        await handleFormSubmit(null, false);
        setLoading(true);
        setButtonDisabled(true)
        quot.order.confirmedOrder ? await updateOrderLog(description) : await createOrderLog(description);
        setModalVisible(false);
        refetchCallback();
    }

    const updateOrderLog = async (description) => {
        try {
            let ip = await getMyIP();
            await updateConfirmedOrder({
                variables: {
                    confirmedOrder: {
                        "id": quot.order.confirmedOrder ? quot.order.confirmedOrder.id : null,
                        "order_fk": quot.order.id,
                        "cancelled_by": userDetails.first_name + ' ' + userDetails.last_name,
                        "cancelled": true,
                        "canceller_ip": ip,
                        "canceller_mail": userDetails.email,
                        "cancelled_reason": description
                    },
                    reason: {
                        "description": description
                    }
                }
            });
            await updateDealPhase({
                variables: {
                    deal: {
                        id: quot.order.deal.id,
                    },
                    phase: {
                        id: 29775,
                        lost: true,
                        info: description
                    }
                }
            });
            await updateOrder({ variables: { order: { id: quot.order.id, order_status_fk: 8 } } })
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    const createOrderLog = async (description) => {
        try {
            let ip = await getMyIP();
            await createConfirmedOrder({
                variables: {
                    confirmedOrder: {
                        "order_fk": quot.order.id,
                        "confirmed_by": userDetails.first_name + ' ' + userDetails.last_name,
                        "cancelled": false,
                        "confirmer_ip": ip,
                        "confirmer_mail": userDetails.email,
                        "confirmed_reason": description,
                        "comment": description
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    const [updateQuotation] = useMutation(updateQuotationQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: queryRes => {
            if (queryRes.updateQuotation) {
                setQuot(queryRes.updateQuotation);
                setRefreshNeeded(true);
            }
            setLoading(false);
        }
    });

    const [updateDealCustomFields] = useMutation(QUERIES.UPDATE_DEAL_CUSTOM_FIELDS, {
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        setOkButtonDisabled(false)
        if (!!quot) {
            if (!quot.order.deal_status_fk) {
                setOkButtonDisabled(true);
            }
            else if (quot.order.deal_status_fk != 2) {
                setOkButtonDisabled(true);
            }
            else if (quot.order.confirmedOrder) {
                if (!!quot.order.confirmedOrder) {
                    setOkButtonDisabled(true)
                }
            }
        }
    }, [quot]);

    const submitEarliestDate = () => {
        return new Promise((resolve, reject) => {
            if (!!earliestDate) {
                if (earliestDate === 'null') {
                    updateQuotation({
                        variables: {
                            quotation: {
                                id: quot.id,
                                earliest_delivery_date: null
                            }
                        }
                    }).finally(() => {
                        if (quot.order.deal.custom_fields) {
                            const dealInput = {
                                id: quot.order.deal.id,
                                custom_fields: quot.order.deal.custom_fields.map(cf => {
                                    if (cf.definition.id == "5616ae6c-2f88-009a-8e5b-4634fbf61d39") cf.value = '';
                                    return {
                                        id: cf.definition.id,
                                        value: cf.value
                                    }
                                })
                            };
                            updateDealCustomFields({
                                variables: {
                                    deal: dealInput
                                }
                            }).finally(() => {
                                resolve();
                            });
                        } else resolve();
                    });
                }
                else {
                    updateQuotation({
                        variables: {
                            quotation: {
                                id: quot.id,
                                earliest_delivery_date: earliestDate.toISOString().slice(0, 19).replace('T', ' ')
                            }
                        }
                    }).finally(() => {
                        if (quot.order.deal.custom_fields) {
                            const dealInput = {
                                id: quot.order.deal.id,
                                custom_fields: quot.order.deal.custom_fields.map(cf => {
                                    if (cf.definition.id == "5616ae6c-2f88-009a-8e5b-4634fbf61d39") cf.value = new Date(earliestDate.toISOString()).toLocaleDateString('nl-be');
                                    return {
                                        id: cf.definition.id,
                                        value: cf.value
                                    }
                                })
                            };
                            updateDealCustomFields({
                                variables: {
                                    deal: dealInput
                                }
                            }).finally(() => {
                                resolve();
                            });
                        } else resolve();
                    });
                }
            } else resolve();
        });
    };

    const submitCostCenter = (costCenterData) => {
        return new Promise((resolve, reject) => {
            if (!!costCenterData.costCenter && !!costCenterData.quotation) {
                //Update the quotation's cc_fk
                updateQuotation({
                    variables: {
                        quotation: {
                            id: costCenterData.quotation.id,
                            costcenter: costCenterData.costCenter
                        }
                    }
                }).finally(() => {
                    //Update deal's cc cf
                    if (quot.order.deal.custom_fields) {
                        const dealInput = {
                            id: costCenterData.quotation.order.deal.id,
                            custom_fields: costCenterData.quotation.order.deal.custom_fields.map(cf => {
                                if (cf.definition.id == "8edfc8b9-da22-05df-985e-9b5a31d401d5") cf.value = costCenterData.costCenter;
                                return {
                                    id: cf.definition.id,
                                    value: cf.value
                                }
                            })
                        };
                        updateDealCustomFields({
                            variables: {
                                deal: dealInput
                            }
                        }).finally(() => {
                            resolve();
                        })
                    }
                });
            } else resolve();
        });
    }

    const handleConfirm = async () => {
        showConfirm();
    }

    const handleCancel = () => {
        showCancelConfirm();
    }

    const getCancelDisabled = order => {
        if (!!order.deal) {
            if (!!order.deal_status_fk) {
                if (order.deal_status_fk == 2) {
                    return false;
                }
                if (order.deal_status_fk == 3) {
                    return false;
                }
            }
        }
        return true;
    }

    const handleFormSubmit = async (e, shouldRefetch = true) => {
        if (!!e) e.preventDefault();
        if (formDisabled) return;

        setFormLoading(true);
        // let employeePromise = null;
        // let costCenterpromise = null;
        // let deliveryPromise = null;

        console.log(formChangeStatus);

        if (formChangeStatus.EMPLOYEE_NR) {
            console.log({ ...employee })
            try {
                await updateEmployee({ variables: { employee: { id: employee.id, employee_number: employee.employee_number } } });
                if (quot.order.deal.custom_fields) {
                    const dealInput = {
                        id: quot.order.deal.id,
                        custom_fields: quot.order.deal.custom_fields.map(cf => {
                            if (cf.definition.id === "13b18798-fdfb-0868-9353-a58addd28ed5") cf.value = employee.employee_number;
                            return {
                                id: cf.definition.id,
                                value: cf.value
                            }
                        })
                    };
                    updateDealCustomFields({
                        variables: {
                            deal: dealInput
                        }
                    })
                }
            }
            catch (err) {
                console.error('Could not update employee');
            }
        }

        if (formChangeStatus.DELIVERY_DATE) {
            console.log('here');
            try {
                await submitEarliestDate();
            }
            catch (err) {
                console.error('Could not update delivery date');
            }
        }

        if (formChangeStatus.COST_CENTER) {
            const costCenter = { costCenter: currentCostcenter, quotation: { ...quot, costcenter: currentCostcenter } };
            console.log(costCenter);
            try {
                await submitCostCenter(costCenter);
            }
            catch (err) {
                console.error('Could not update costcentre');
            }
        }

        // Promise.all([employeePromise, costCenterpromise, deliveryPromise]).finally(() => {
        // console.log('all is resolved');
        setFormLoading(false);
        if (shouldRefetch) {
            refetchCallback();
        }
        // });
    }

    const isBikeASpeedPedelec = quot?.bikeType?.name === "Speed pedelec"

    if (!!quot && !!employee) {
        return (
            <Form layout='inline' onSubmit={async (e) => {
                await handleFormSubmit(e);
                setModalVisible(false)
            }}>
                <Modal
                    title={quot.quotation_number}
                    visible={true}
                    destroyOnClose={true}
                    width="60%"
                    className='quotDetailModal'
                    onCancel={() => {
                        if (refreshNeeded) {
                            refetchCallback();
                        }
                        setModalVisible(false);
                    }}
                    footer={loading || formLoading ? [] : [
                        <Button className='button' key="btnOpenPDF" type="secondary" onClick={() => { setPdfModalVisible(true) }} style={{ float: 'left' }}>{getLabel('berekening tonen', true, strings)}</Button>,
                        <Button disabled={!servicepass} className='button' key="btnServicePassTransactions" type="secondary" onClick={() => { setShowTransactionsModal(true) }} style={{ float: 'left' }}>{getLabel('Service pass transacties', true, strings)}</Button>,
                        <Button className='button' key="btnDocuments" type="secondary" onClick={() => { setDocumentsModalVisible(true) }} style={{ float: 'left' }}>{getLabel('Documenten', true, strings)}</Button>,
                        !formDisabled ? [<Button className='button' type="primary" style={{ float: 'left' }} onClick={() => formSubmitRef.current.click()}><Icon type="save" /></Button>] : [],
                        (!!quot.order.confirmedOrder ? (
                            <Popconfirm
                                title={getLabel("Offerte is al goedgekeurd Toch afkeuren", true, strings)}
                                onConfirm={handleCancel}
                                okText={getLabel("afkeuren", true, strings)}
                                cancelText={getLabel("back", true, strings)}
                            >
                                <Button className='button' key="btnAfkeuren" type="danger" disabled={buttonDisabled || getCancelDisabled(quot.order)}>{getLabel('afkeuren', true, strings)}</Button>
                            </Popconfirm>
                        ) : <Button className='button' key="btnAfkeuren" type="danger" disabled={buttonDisabled || getCancelDisabled(quot.order)} onClick={handleCancel}>{getLabel('afkeuren', true, strings)}</Button>
                        ),
                        <Button className='button' key="btnGoedkeuren" type="primary" disabled={buttonDisabled || okButtonDisabled || !!quot.order.confirmedOrder} onClick={handleConfirm}>{getLabel('goedkeuren', true, strings)}</Button>,
                    ]}
                    closable={true}
                >
                    <div id="popup_screen">
                        <PdfModalPopup quot={quot} visible={pdfModalVisible} callback={(vis) => { setPdfModalVisible(vis) }} />
                        <DocumentsViewerPopup quot={quot} visible={documentsModalVisible} callbacks={{ setDocumentsModalVisible: setDocumentsModalVisible }} />
                    </div>
                    {loading || formLoading ? <><Spin /></> : <>
                        <div className='modalItems'>
                            <div className='modalContainer'>
                                <div>
                                    <p>{getLabel('quotation number', true, strings)}</p>
                                    <strong>{quot.quotation_number}</strong>
                                </div>
                                <div>
                                    <p>{getLabel('date', true, strings)}</p>
                                    <strong>{new Date(parseInt(quot.quotation_date)).toLocaleDateString('nl-be')}</strong>
                                </div>
                            </div>
                            <Divider />
                            <div className=' companyContainer'>
                                <div className='infoBox'>
                                    <p>{getLabel('employee', true, strings)}</p>
                                    <strong>{quot.employee.name}</strong>
                                </div>
                                <div className='company infoBox'>
                                    <div className='companyName'>
                                        <p>{getLabel('company', true, strings)}</p>
                                        <strong>{quot?.company?.name}</strong>
                                    </div>
                                    <img className='companyLogo' src={quot?.company?.logo_url} height='80px' />
                                </div>
                                <div className='infoBox'>
                                    <p>{getLabel('employee number', true, strings)}</p>
                                    <Row type='flex'>
                                        <Col>
                                            <FormItem>
                                                <Input
                                                    defaultValue={employee.employee_number}
                                                    disabled={formDisabled}
                                                    onChange={e => {
                                                        const val = e.target.value;
                                                        setEmployee({ ...employee, employee_number: val });
                                                        setFormChangeStatus({ ...formChangeStatus, EMPLOYEE_NR: true });
                                                    }}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='infoBox'>
                                    <p>{getLabel('Investeringswaarde excl BTW', true, strings)}</p>
                                    <strong>€{
                                        quot.order.deal ? (quot.order.deal.estimated_value ? `${quot.order.deal.estimated_value.amount}`.replace('.', ',') : '-') : '-'
                                    }</strong>
                                </div>
                                <div className='infoBox'>
                                    <p>{getLabel('Type', true, strings)}</p>
                                    <strong>{getLabel(quot.bikeType.name, true, strings)}</strong>
                                </div>
                                {isBikeASpeedPedelec &&
                                    <div className='infoBox'>
                                        <p>{getLabel('license number', true, strings)}</p>
                                        <strong>{getLabel(quot?.order?.bike?.license_number?.length > 0 ? quot?.order?.bike?.license_number : "Ongekend", true, strings)}</strong>
                                    </div>
                                }
                            </div>
                            <Divider />
                            <div className='modalContainer'>
                                <div>
                                    <p>{getLabel('Behandeld door', true, strings)}</p>
                                    {
                                        quot?.order?.confirmedOrder?.confirmer_mail ? <strong>
                                            <a type='email' href={`mailto:${quot.order.confirmedOrder ? (quot.order.confirmedOrder.confirmer_mail ? quot.order.confirmedOrder.confirmer_mail : "-") : "-"}`}>{quot.order.confirmedOrder ? (quot.order.confirmedOrder.confirmer_mail ? quot.order.confirmedOrder.confirmer_mail : "-") : "-"}</a>
                                        </strong> : <strong>
                                            {
                                                quot?.order?.confirmedOrder?.canceller_mail?.includes('b2bike') || quot?.order?.confirmedOrder?.canceller_mail?.includes('cogetix') ?
                                                    <a type='email' href={'mailto:offerte@b2bike.be'}>offerte@b2bike.be</a>
                                                    : <a type='email' href={`mailto:${quot?.order?.confirmedOrder?.canceller_mail ? (quot?.order?.confirmedOrder?.canceller_mail ? quot?.order?.confirmedOrder?.canceller_mail : "-") : "-"}`}>{quot?.order?.confirmedOrder?.canceller_mail ? (quot?.order?.confirmedOrder?.canceller_mail ? quot?.order?.confirmedOrder?.canceller_mail : "-") : "-"}</a>

                                            }
                                        </strong>
                                    }
                                    {
                                        quot?.order?.confirmedOrder?.canceller_mail && (
                                            <>
                                                <p>{getLabel('Geannuleerd door', true, strings)}</p>
                                                <strong id='canceller-label'>{quot?.order?.confirmedOrder?.canceller_mail?.includes('b2bike') ? 'B2Bike' : quot?.order?.confirmedOrder?.canceller_mail}</strong>
                                            </>
                                        )
                                    }

                                </div>
                                <Row type='flex' justify='space-between'>
                                    <div>
                                        <FormItem>
                                            <p>{getLabel('costcenter', true, strings)}</p>
                                            <AutoComplete
                                                disabled={(formDisabled)}
                                                defaultValue={quot?.costcenter}
                                                dataSource={quot?.company?.costcenters?.map(el => el.name)}
                                                onChange={val => {
                                                    setCurrentCostcenter(val);
                                                    setFormChangeStatus({ ...formChangeStatus, COST_CENTER: true });
                                                }}
                                                filterOption={(inputValue, option) =>
                                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }

                                            />
                                        </FormItem>
                                    </div>
                                    <div>
                                        <FormItem>
                                            <p>{getLabel('earliest delivery date', true, strings)}</p>
                                            <DatePicker disabled={(formDisabled)} value={earliestDate ? moment(new Date(Number(earliestDate)), dateFormat) : null}
                                                format={dateFormat} onChange={(date, dateString) => {
                                                    if (!!date) {
                                                        date.set('hours', 11);
                                                        setEarliestDate(date);
                                                        setFormChangeStatus({ ...formChangeStatus, DELIVERY_DATE: true });
                                                    }
                                                    else {
                                                        setEarliestDate('null');
                                                        setFormChangeStatus({ ...formChangeStatus, DELIVERY_DATE: true });
                                                    }
                                                }} />
                                        </FormItem>
                                    </div>
                                </Row>
                            </div>
                            <Divider />
                            <div className='statusContainer'>
                                {currentStatus == 6 ? (
                                    <Steps progressDot current={3} size='default' className='stepbar' status='error'>
                                        <Step title={getLabel("NEW", true, strings)} />
                                        <Step title={getLabel("signed", true, strings)} />
                                        <Step title={getLabel("send_to_decider", true, strings)} />
                                        <Step title={getLabel("refused", true, strings)} />
                                    </Steps>
                                ) :
                                    (<Steps progressDot current={currentStatus} size='default' className='stepbar' >
                                        <Step title={getLabel("NEW", true, strings)} />
                                        <Step title={getLabel("signed", true, strings)} />
                                        <Step title={getLabel("send_to_decider", true, strings)} />
                                        <Step title={getLabel("approved", true, strings)} />
                                        <Step title={getLabel("deliverable", true, strings)} />
                                        <Step title={getLabel("delivered", true, strings)} />
                                    </Steps>)}
                            </div>
                        </div>
                        <div className='modalButtons'>

                        </div>
                        <ServicePassTransactions
                            barcode={servicepass ? servicepass.barcode : null}
                            visible={showTransactionsModal}
                            setVisible={(val) => setShowTransactionsModal(val)}
                        />
                    </>}
                </Modal>
                <input type="submit" style={{ display: 'none' }} ref={formSubmitRef} />
            </Form>
        )
    } else {
        return (
            <div >

            </div>
        )
    }
}

export default QuotationDetailModal