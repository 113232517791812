import React, { useState, useContext } from 'react';
import { Table, Spin, Row, Col, Button, Input, Icon, Dropdown, Menu, Modal, message } from 'antd';
import Column from 'antd/lib/table/Column';
import { I18nContext } from '../../api/i18nService';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const LanguageSettings = props => {

  const langContext = useContext(I18nContext);
  const allLangArr = langContext.langStringsArr;
  const allLangCodesArr = langContext.langCodesArr;
  const [searchTerm, setSearchTerm] = useState('');
  const defaultLangCode = 1;
  const [currentLang, setCurrentLang] = useState(defaultLangCode);

  //Updates
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentStringToUpdate, setCurrentStringToUpdate] = useState(null);
  const [updateValue, setUpdateValue] = useState("");
  const updateStyle = {
    currentLanguageSpanStyle: {
      textDecoration: "underline",
      marginLeft: "5px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "primary"
    }
  }
  const handleSuccesfullUpdate=()=>{
    message.info(`Succesvol bewaard`);
    langContext.getAllLangStrings();
    resetAndCloseUpdate();
  };
  const handleFailedUpdate=()=>{
    message.error(`Er is iets mis gelopen`);
  };
  const resetAndCloseUpdate=()=>{
    setCurrentStringToUpdate(null);
    setUpdateValue("");
    setShowUpdateModal(false);
  };


  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchTerm(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchTerm('');
  };

  let searchInput = null;
  const searchColumn = dataIndex => (
    {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={'Zoeken'}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Zoeken
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        switch (dataIndex) {
          case "key":
            if(record[0].includes(value))
              return record[0];
            break;
          case "nl":
            if(record[1].includes(value))
              return record[0];
            break;
          case "fr":
            if(record[2].includes(value))
              return record[0];
            break;
          case "en":
            if(record[3].includes(value))
              return record[0];
            break;
          case "de":
            if(record[4].includes(value))
              return record[0];
            break;
        }
      },
    }
  );

  const langDropdownHandler=(code)=>{
    if(code>0)
      setCurrentLang(code);
  }

  const languageMenu = (
    <Menu>
      {allLangCodesArr.map((code, index)=>{
        if(index<1)
          return;
        return(
        <Menu.Item
        onClick={()=>langDropdownHandler(index)}>
          <a>{(code||"").toUpperCase()}</a>
        </Menu.Item>)
      })}
    </Menu>
  );

  if (!!allLangArr) {
    return (
      <div>
        <Row type='flex' justify='center'>
          <Table
            rowKey={record => record}
            style={{ width: '80vw', margin: 16 }}
            dataSource={allLangArr} >
            <Column
              title='Key'
              dataIndex='Key'
              render={ (text, record) => record[0]}
              {...searchColumn('key')}
            />
            <Column
              title='NL'
              dataIndex='NL'
              render={ (text, record) => {
                return(<>
                  {record[1]}
                  <Button
                        shape='circle'
                        type='default'
                        style={{ float: 'right' }}
                        onClick={()=>{
                          setCurrentStringToUpdate({stringKey: record[0], langCode: "nl"});
                          setUpdateValue(record[1]||"");
                          setShowUpdateModal(true);
                        }}
                    ><Icon type="edit"/></Button>
                  </>)
              }}
              {...searchColumn('nl')}
            />
            <Column
              title= {<Dropdown overlay={languageMenu}>
              <a className="ant-dropdown-link" href="#">
                {(allLangCodesArr[currentLang]||"").toUpperCase()} <Icon type="down" />
              </a>
            </Dropdown>}
              dataIndex={(allLangCodesArr[currentLang]||"").toUpperCase()}
              render={ (text, record) => {
                return (
                  <>
                    {record[currentLang+1]}
                    <Button
                      shape='circle'
                      type='default'
                      style={{ float: 'right' }}
                      onClick={()=>{
                        setCurrentStringToUpdate({stringKey: record[0], langCode: allLangCodesArr[currentLang]});
                        setUpdateValue(record[currentLang+1]||"");
                        setShowUpdateModal(true);
                      }}
                    ><Icon type="edit"/></Button>
                  </>
                )
              }}
              {...searchColumn(allLangCodesArr[currentLang])}
            />
          </Table>
        </Row>

        <Modal
          title="Vertaling bewerken"
          visible={showUpdateModal}
          okText={"Bewaren"}
          cancelText={"Annuleren"}
          onOk={()=>{
            langContext.updateTranslationString(currentStringToUpdate.langCode, currentStringToUpdate.stringKey, updateValue, handleSuccesfullUpdate, handleFailedUpdate);
          }}
          onCancel={()=>{
            resetAndCloseUpdate();
          }}
        >
          {currentStringToUpdate&&
            <>
              <p>Huidige taal: <span style={updateStyle.currentLanguageSpanStyle}>{currentStringToUpdate.langCode}</span></p>
              <Input
                style={{marginTop: "10px"}}
                value={updateValue}
                onChange={(e)=>{setUpdateValue(DOMPurify.sanitize(e.target.value))}}
              />
            </>
          }
        </Modal>
      </div>
    );
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col span={24}>
          <Spin size='large' />
        </Col>
      </Row>
    </div>
  );

};

export default LanguageSettings;