import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { QUERIES } from '../../api/queries';
import { useStateValue } from '../stateProvider/stateProvider';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import ServicePassUploader from './servicePassUploader';
import SearchEmployee from './searchEmployee';
import SearchDealer from './searchDealer';
import ServicePassActions from './servicePassActions'
import { Modal, Button, Table, Input, Icon, Row, Spin, message, Popover, Popconfirm, Empty } from 'antd';
import './servicePassManagement.css';
import axios from 'axios';
import DOMPurify from 'dompurify';
import ServicePassTransactions from './servicePassTransactions';
import {getServiceUrl} from '../../util/util';

const { TextArea } = Input;

const ServicePassManagement = props => {

  const [newBarcodes, setNewBarcodes] = useState(null);
  const [uploaderVisible, setUploaderVisible] = useState(false);
  const [employeeSelector, setEmployeeSelector] = useState(null);
  const [dealerSelector, setDealerSelector] = useState(null);
  const [loadedServicePasses, setLoadedServicePasses] = useState(null);
  const [queryOffset, setQueryOffset] = useState(1);
  const [queryOffsetForUI, setQueryOffsetForUI] = useState(1);
  const [updatesOnPage, setUpdatesOnPage] = useState([]);
  const [selectedServicePasses, setSelectedServicePasses] = useState([]);
  const [working, setWorking] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [queryFilter, setQueryFilter] = useState({});
  const [createServicePass] = useMutation(QUERIES.CREATE_SERVICE_PASS);
  const [updateServicePass] = useMutation(QUERIES.UPDATE_SERVICE_PASS);
  const [sortDirections, setSortDirections] = useState({});
  const [querySort, setQuerySort] = useState({ field: 'barcode', direction: 'asc' });
  const [{ userDetails, token }] = useStateValue();
  const [currentQuery, setCurrentQuery] = useState(QUERIES.SERVICE_PASSES);
  const [currentQueryVars, setCurrentQueryVars] = useState({ page: queryOffset, size: 100, filter: queryFilter, sort: querySort });
  const [actionsModalProps, setActionsModalProps] = useState({ visible: false, pass: null });
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);


  const [getPasses, { loading, error, data }] = useLazyQuery(currentQuery, {
    variables: currentQueryVars,
    fetchPolicy: 'network-only',
    onCompleted: res => {
      if (res.servicePasses)
        setLoadedServicePasses(res.servicePasses.data);
      else if (res.servicePassNextHundred)
        setLoadedServicePasses(res.servicePassNextHundred.data);
      else if (res.servicePassesByPack)
        setLoadedServicePasses(res.servicePassesByPack.data);
    }, 
    onError: res => {
      console.warn('Response not successful')
    }
  });
  useEffect(() => {
    getPasses();
  }, []);

  useEffect(() => {
    setNewBarcodes([]);
  }, [uploaderVisible]);

  useEffect(() => {
    setCurrentQueryVars((old) => {
      old.page = queryOffset;
      return { ...old };
    });
    getPasses();
  }, [queryOffset]);

  useEffect(() => {
    setCurrentQueryVars((old) => {
      old.sort = querySort;
      return { ...old };
    });
    getPasses();
  }, [querySort]);

  useEffect(() => {
    setCurrentQueryVars((old) => {
      old.filter = queryFilter;
      return { ...old };
    });
    getPasses();
  }, [queryFilter]);

  if (!userDetails.superUser) {
    return null;
  }

  let searchInput = null;
  const searchColumn = dataIndex => (currentQuery === QUERIES.SERVICE_PASS_NEXT_HUNDRED ? null : {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          value={selectedKeys}
          placeholder={'Zoeken'}
          onChange={e => setSelectedKeys(e.target.value ? [DOMPurify.sanitize(e.target.value)] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="filter"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          filter
          </Button>
        {updatesOnPage.length > 0 ? (
          <Popconfirm
            title='Er zijn wijzigingen op deze pagina! Resetten zonder op te slaan?'
            onConfirm={() => handleReset(clearFilters, dataIndex, () => { setSelectedKeys([]) })} size="small" style={{ width: 90 }}>
            <Button>
              Reset
            </Button>
          </Popconfirm>
        ) :
          (
            <Button
              onClick={() => handleReset(clearFilters, dataIndex, () => { setSelectedKeys([]) })} size="small" style={{ width: 90 }}
            >
              Reset
            </Button>
          )}
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return true
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const getFilterKey = (dataIndex) => {
    switch (dataIndex) {
      case 'employee':
        return 'employee_name';
      case 'dealer':
        return 'dealer_name';
      case 'barcode':
        return 'barcode';
      case 'quotation':
        return 'quotation_number';
      default:
        return null;
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let queryKey = getFilterKey(dataIndex);
    confirm();
    setQueryFilter(old => {
      if (!!queryKey) {
        old[queryKey] = selectedKeys[0];
        return old;
      }
    })
    getPasses();
  };

  const handleReset = (clearFilters, dataIndex, callback) => {
    clearFilters();
    setUpdatesOnPage([]);
    const dataKey = getFilterKey(dataIndex);
    setQueryFilter(old => {
      delete old[dataKey];
      return old;
    });
    getPasses();
    callback();
  };

  const globalReset = () => {
    setUpdatesOnPage([]);
    setQueryFilter({});
  }

  const pageInput = (
    updatesOnPage.length > 0 ? (
      <Row type='flex' justify='start'>
        {(
          <span style={{ marginLeft: '16px', display: 'inline-flex' }}>
            <Popconfirm
              title='Er zijn wijzigingen op deze pagina. Pagina verlaten zonder op te slaan?'
              onConfirm={() => {
                const offset = queryOffset - 1;
                setQueryOffset(offset);
                setQueryOffsetForUI(offset);
              }}
            >
              <Button
                disabled={queryOffset <= 1}
              >
                Vorige
          </Button>
            </Popconfirm>
            <Input style={{ margin: '0px  16px', width: '128px' }} type='Number'
              value={queryOffsetForUI}
              onKeyPress={
                e => {
                  if (e.key == 'Enter') {
                    const val = DOMPurify.sanitize(e.target.value);
                    if (val > 0) {
                      setQueryOffset(parseInt(val));
                      setQueryOffsetForUI(parseInt(val));
                    }
                  }
                }
              }
              onBlur={(e) => {
                setQueryOffset(parseInt(e.target.value));
              }}
              onChange={
                e => {
                  setQueryOffsetForUI(parseInt(e.target.value));
                }
              }
            ></Input>
            <Popconfirm
              title='Er zijn wijzigingen op deze pagina. Pagina verlaten zonder op te slaan?'
              onConfirm={() => {
                const offset = queryOffset + 1;
                setQueryOffset(offset);
                setQueryOffsetForUI(offset);
              }}
            >
              <Button
              >
                Volgende
          </Button>
            </Popconfirm>
          </span>
        )}
      </Row>
    )
      :
      (
        <Row type='flex' justify='start'>
          {(
            <span style={{ marginLeft: '16px', display: 'inline-flex' }}>
              <Button
                disabled={queryOffset <= 1}
                onClick={() => {
                  const offset = queryOffset - 1;
                  setQueryOffset(offset);
                  setQueryOffsetForUI(offset);
                }}
              >
                Vorige
          </Button>
              <Input style={{ margin: '0px  16px', width: '128px' }} type='Number'
                value={queryOffsetForUI}
                onKeyPress={
                  e => {
                    if (e.key == 'Enter') {
                      const val = DOMPurify.sanitize(e.target.value);
                      if (val > 0) {
                        setQueryOffset(parseInt(val));
                        setQueryOffsetForUI(parseInt(val));
                      }
                    }
                  }
                }
                onBlur={(e) => {
                  setQueryOffset(parseInt(e.target.value));
                }}
                onChange={
                  e => {
                    setQueryOffsetForUI(parseInt(e.target.value));
                  }
                }
              ></Input>
              <Button
                onClick={() => {
                  const offset = queryOffset + 1;
                  setQueryOffset(offset);
                  setQueryOffsetForUI(offset);
                }}
              >
                Volgende
          </Button>
            </span>
          )}
        </Row>
      )
  );

  const columns = [
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      sorter: currentQuery !== QUERIES.SERVICE_PASS_NEXT_HUNDRED,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sortDirections.barcode,
      ...searchColumn('barcode'),
      render: (text, record) => `${record.barcode.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}`,
      className: "barcodeCol",
      width: 190
    },
    // {
    //   render: record => {
    //     if (record.status === "blocked") {
    //       return (<Tag color="red">Geblokkeerd</Tag>)
    //     } else if (record.status === "normal") {
    //       return (<Tag color="green">Actief</Tag>)
    //     } else if (record.status == "inactive") {
    //       return (<Tag color="orange">Inactief</Tag>)
    //     }
    //   },
    //   width: 75
    // },
    {
      title: 'Dealer',
      dataIndex: 'dealer',
      sorter: currentQuery !== QUERIES.SERVICE_PASS_NEXT_HUNDRED,
      sortOrder: sortDirections.dealer,
      sortDirections: ['descend', 'ascend'],
      ...searchColumn('dealer'),
      render: (text, record) => (
        <Button
          style={{ width: '192px', overflowX: 'hidden' }}
          onClick={() => {
            setDealerSelector(<SearchDealer
              name={record.dealer ? record.dealer.name : record.dealer_name}
              onClose={() => setDealerSelector(null)}
              onSelect={({ dealer, dealerName }) => {
                if (dealer) {
                  record.dealer = dealer;
                  record.dealer_fk = dealer.id;
                  record.dealer_name = null;
                  record.modified = true;
                }
                else if (dealerName) {
                  record.dealer = null;
                  record.dealer_fk = null;
                  record.dealer_name = dealerName;
                  record.modified = true;
                }
                if (updatesOnPage.indexOf(record.id) < 0) {
                  setUpdatesOnPage(old => {
                    return [...old, record.id];
                  });
                }
              }}
            />);
          }}
        >
          {record.dealer ? `${record.dealer.name}` : record.dealer_name} <Icon type="search" />
        </Button>
      ),
      width: 200,
    },
    {
      title: 'Werknemer',
      dataIndex: 'employee',
      sorter: currentQuery !== QUERIES.SERVICE_PASS_NEXT_HUNDRED,
      sortOrder: sortDirections.employee,
      sortDirections: ['descend', 'ascend'],
      ...searchColumn('employee'),
      render: (text, record) => (
        <Button
          style={{ width: '192px', overflowX: 'hidden' }}
          onClick={() => {
            setEmployeeSelector(
              <SearchEmployee
                name={record.bike ? record.bike.employee.name : record.employee_name}
                visible={true}
                onClose={() => { setEmployeeSelector(null) }}
                onSelect={({ employee, employeeName, bike }) => {
                  record.employee_name = employeeName;
                  if (!!employee) {
                    record.bike = bike;
                    record.bike_fk = bike.id;
                    record.bike.employee = employee
                  }
                  if (!employee) {
                    record.bike = null;
                    record.bike_fk = null;
                  }
                  record.modified = true;
                  if (updatesOnPage.indexOf(record.id) < 0) {
                    setUpdatesOnPage(old => {
                      return [...old, record.id];
                    });
                  }
                  setEmployeeSelector(null);
                }}
              />
            )
          }}
        >{record.bike ? record.bike.employee.name : record.employee_name} <Icon type="search" /></Button>
      ),
      width: 200,
    },
    {
      title: 'Bedrijf',
      render: record => (record.bike ? <span
        style={{ width: '192px', overflowX: 'hidden' }}>{record.bike.employee.company.name}</span> : ''),
      className: "barcodeCol",
      width: 250,
    },
    {
      title: 'Facturatiedoel',
      render: record => (record.bike && record.bike.employee.company.invoice_target ? record.bike.employee.company.invoice_target.name : ''),
      width: 175
    },
    {
      title: 'Fiets',
      render: record => (record.bike && record.bike.bikeType ? `${record.bike.bikeType.name}` : ''),
      width: 175,
      className: "barcodeCol",
    },
    {
      title: 'Offerte',
      render: record => (record.bike && record.bike.order ? `Q${record.bike.order.quotation_fk}` : ''),
      ...searchColumn('quotation'),
      width: 100,
    },
    {
      title: 'Startbedrag',
      render: record => (
        <p>{`€\u00a0${record.starting_amount ? record.starting_amount.toFixed(2).replace('.', ',') : '0,00'}`}</p>
      ),
      width: 115,
    },
    {
      title: 'Gebruikt',
      render: record => (
        // <span>{`€\u00a0${record.used_amount ? record.used_amount.toFixed(2).replace('.', ',') : '0,00'}`}</span>
        <span>{`€\u00a0${(record.starting_amount && record.remaining_amount) ? (record.starting_amount - record.remaining_amount).toFixed(2).replace('.', ',') : '0,00'}`}</span>
      ),
      width: 115,
    },
    {
      title: 'Beschikbaar',
      render: record => (
        <p>{`€\u00a0${record.remaining_amount ? record.remaining_amount.toFixed(2).replace('.', ',') : '0,00'}`}</p>
      ),
      width: 115,
    },
    {
      render: record => {
        return (<Popover title='Commentaar' content={(
          <TextArea
            defaultValue={record.comment}
            onChange={e => {
              record.comment = e.target.value;
              record.modified = true;
              if (updatesOnPage.indexOf(record.id) < 0) {
                setUpdatesOnPage(old => {
                  return [...old, record.id];
                });
              }
            }}
          />
        )} trigger="hover">
          <Icon style={{ cursor: 'pointer', color: (!!record.comment ? '#7cbc20' : 'grey') }} type='info-circle' theme={!!record.comment ? 'filled' : 'outlined'} />
        </Popover>)
      },
      width: 25
    },
    {
      render: record => {
        return <Button
          shape='circle-outline'
          type='primary'
          onClick={async () => {
            setActionsModalProps({ visible: true, pass: { ...record } });
          }}>...</Button>
      },
      width: 50
    },
    {
      title: (updatesOnPage.length > 0 && <Button
        shape='circle'
        type='primary'
        onClick={async () => {
          setWorking(true);
          const pendingPasses = [...updatesOnPage];
          for (let updatedPass of updatesOnPage) {
            const pass = loadedServicePasses.find(el => el.id === updatedPass);
            const { dealer, bike, __typename, modified, emptied_at, ...spi } = pass;
            try {
              const updateRes = await updateServicePass({
                variables: { servicePass: spi }
              });
              message.success(`Servicepass ${updateRes.data.updateServicePass.barcode} geüpdated!`);
              pendingPasses.splice(pendingPasses.indexOf(updatedPass), 1);
            }
            catch (err) {
              console.error(err);
              message.error(`Fout bij updaten: ${err.message}`);
            }
          }
          setUpdatesOnPage(pendingPasses);
          setWorking(false);
        }}
      ><Icon type="save" /></Button>),
      render: record => ((record.modified && updatesOnPage.indexOf(record.id) >= 0) && (
        <Button
          shape='circle'
          type='primary'
          onClick={() => {
            const { dealer, bike, __typename, modified, emptied_at, ...spi } = record;
            setWorking(true);
            updateServicePass({
              variables: { servicePass: spi }
            })
              .then(res => {
                record.modified = false;
                setUpdatesOnPage(old => {
                  let temp = [...old];
                  temp.splice(old.indexOf(record.id), 1);
                  return temp;
                });
                message.success(`Servicepass ${res.data.updateServicePass.barcode} geüpdated!`);
              })
              .catch(err => {
                console.error(err);
                message.error(`Fout bij updaten: ${err.message}`, 10);
              })
              .finally(() => {
                setWorking(false);
              })
          }}
        ><Icon type="save" /></Button>
      )),
      width: 50
    },
    {
      title: (<Button
        hidden={currentQuery === QUERIES.SERVICE_PASSES}
        onClick={() => {
          setCurrentQuery(QUERIES.SERVICE_PASSES);
          setCurrentQueryVars({ page: queryOffset, size: 100, filter: queryFilter, sort: querySort });
        }}
      >
        Standaard zoeken
      </Button>),
      render: record => {
        return (<Button
          onClick={() => {
            if (!!record.first_in_pack) {
              setCurrentQuery(QUERIES.SERVICE_PASS_BY_PACK);
              setCurrentQueryVars({ firstInPack: record.first_in_pack });
            }
            else {
              message.warn('Geen pakket gevonden. 10 eerdere en 10 opvolgende passen worden getoond')
              setCurrentQuery(QUERIES.SERVICE_PASS_NEXT_HUNDRED);
              setCurrentQueryVars({ id: record.id });
            }
          }}
        >
          Toon opvolgenden
        </Button>)
      },
      width: 200
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedServicePasses(selectedRows);
    }
  };

  const handleSort = (sort) => {
    //Check if we really sorted (instead of filtering).
    if (Object.keys(sort).length === 0)
      return;

    const key = sort.columnKey;
    const API_KEY = getFilterKey(key);

    let order = sort.order;
    if (order == "ascend")
      order = "asc";
    else if (order == "descend")
      order = "desc";
    else if (order == null) {
      setQuerySort({ field: 'barcode', direction: 'asc' });
      setSortDirections({
        [key]: sort.order
      });
      return;
    }
    //Should be here
    setQuerySort(old => {
      return {
        ...old,
        field: API_KEY,
        direction: order
      }
    });
    setSortDirections({
      [key]: sort.order
    });
  }

  const collectCsv = async () => {
    setCsvLoading(true);
    try {
      const url = await getServiceUrl('servicePassGenerateCSV', token);
      console.log(url);
      const { data: { base64String } } = await axios.post(url, {
        env: process.env.NODE_ENV,
        user: {
          accessToken: token
        }
      });

      let csvContent = atob(base64String).replace('data:text/csv;charset=utf-8,', '\ufeff');
      var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `servicepasses-${new Date().toLocaleDateString()}.csv`;
      document.body.append(link);
      link.click();
      link.remove();
      setCsvLoading(false);
    } catch (error) {
      console.error(error);
      message.error(error.message);
      setCsvLoading(false);
    }
  };

  if (!!loadedServicePasses && !working) {
    return (
      <div style={{   maxWidth: '99vw', height:'90vh'}}>
        <div style={{ height: "40px", maxHeight: "40px !important"}}>
          <ServicePassActions
            visible={actionsModalProps.visible}
            pass={actionsModalProps.pass}
            onClose={() => setActionsModalProps({ visible: false, pass: null })}
            showTransactions={() => setShowTransactionsModal(true)}
          />
          <ServicePassTransactions
            barcode={actionsModalProps.pass ? actionsModalProps.pass.barcode : null}
            visible={showTransactionsModal}
            setVisible={(val) => setShowTransactionsModal(val)}
          />
          {pageInput}
        </div>
        <div style={{ margin: ".5em" }}>
            <Prompt
              when={updatesOnPage.length > 0}
              message='Er zijn wijzigingen op de pagina. Pagina verlaten zonder opslaan?'
            />
            <h1>Service Pass Beheer</h1>
            <Button
              onClick={() => setUploaderVisible(true)}
            >Service passes toevoegen</Button>
            <Button
              loading={csvLoading}
              style={{ marginLeft: '16px' }}
            >
              <a
                id="btnDownloadCsv"
                download={`servicepasses-${new Date().toLocaleDateString()}.csv`}
                onClick={() => collectCsv()}>Exporteer als CSV</a>
            </Button>
            <Button
              hidden={selectedServicePasses.length === 0}
              type='primary'
              style={{ marginLeft: '16px' }}
              onClick={() => {
                setDealerSelector(<SearchDealer
                  name={null}
                  onClose={() => setDealerSelector(null)}
                  onSelect={async ({ dealer, dealerName }) => {
                    const updates = selectedServicePasses.map(record => {
                      if (dealer) {
                        record.dealer = dealer;
                        record.dealer_fk = dealer.id;
                        record.dealer_name = null;
                        record.modified = true;
                      }
                      else if (dealerName) {
                        record.dealer = null;
                        record.dealer_fk = null;
                        record.dealer_name = dealerName;
                        record.modified = true;
                      }
                      return record;
                    });
                    for (const record of updates) {
                      const { dealer, bike, __typename, modified, emptied_at, ...spi } = record;
                      await updateServicePass({ variables: { servicePass: spi } });
                    }
                    message.success(`${updates.length} servicepasses toegewezen`);
                    setSelectedServicePasses([]);
                    getPasses();
                  }}
                />);
              }}
            >Bulk toewijzen</Button>
          </div>
          <Table
            loading={loading}
            onChange={(pag, filt, sort) => {
              handleSort(sort);
            }}
            columns={columns}
            dataSource={loadedServicePasses}
            rowSelection={rowSelection}
            rowKey={record => record.id}
            pagination={false}
            scroll={!!loadedServicePasses && loadedServicePasses.length > 0? {x: 100, y: 'calc(100vh - 340px)'}: {}}
            locale = {{
              emptyText: (<Empty description={
                <span>
                  'Geen servicepasses gevonden volgens de ingestelde filters.<br/>
                  'Probeer de filters manueel of in bulk (via de onderstaande knop) te herstellen.<br/>
  
                  <Button type="primary" onClick={globalReset} >RESET</Button>
                </span>
              }/>)
            }}
          ></Table>
        <Modal
            visible={uploaderVisible}
            destroyOnClose={true}
            onOk={() => {
              if (newBarcodes.length === 0) {
                return message.warn('Geen servicepassen toegevoegd. Gebruik de enter-toets alvorens op OK te klikken bij manuele ingave.');
              }
              setWorking(true);
              let first_bc = newBarcodes[0];
              for (let bc of newBarcodes) {
                let spi = { barcode: bc, first_in_pack: first_bc };
                createServicePass({
                  variables: { servicePass: spi },
                }).then(res => {
                  setLoadedServicePasses(old => [...old, res.data.createServicePass]);
                  message.success(`Service pass ${res.data.createServicePass.barcode} toegevoegd!`);
                }).catch(err => {
                  console.error(err);
                  message.error('Fout bij uploaden service passen.');
                })
              }
              setUploaderVisible(false);
              setWorking(false);
            }}
            onCancel={() => {
              setUploaderVisible(false);
            }}
            cancelText={'Annuleren'}
          >
            <ServicePassUploader
              onValueUpdate={(newVal => {
                setNewBarcodes(newVal);
              })}
            />
          </Modal>
          {employeeSelector}
          {dealerSelector}
      </div>
    );
  }
  else {
    return (<Spin size="large" style={{ textAlign: "center", margin: "auto", width: "100%", position: "absolute", top: "50%", transform: "translateY(-50%)" }} />);
  }
}

export default ServicePassManagement;