import React from "react";
import { DatePicker as AntDatePicker, Select, Input } from "antd";
import moment from "moment";
import ClickAwayListener from "react-click-away-listener";

import { noop } from "../utils";

const DatePicker = React.forwardRef(
  (
    {
      allowClear = true,
      autoFocus = true,
      disabled = false,
      placeholder = "",
      format = "DD/MM/YYYY",
      onChange = noop,
      onOk = noop,
      ...props
    },
    ref
  ) => {
    const date = props.value ? new Date(Number(props.value)) : null;

    const getDateFormat = (date) => {
      var d = moment(date, format);
      return date && d.isValid() ? d : null;
    };

    return (
      <ClickAwayListener
        onClickAway={() => {
          const DatePickerElement = document.getElementsByClassName(
            "ant-calendar-date-panel"
          );
          if (DatePickerElement.length === 0) {
            props.onClickAway();
          }
        }}
      >
        <AntDatePicker
          ref={ref}
          disabled={disabled}
          allowClear={allowClear}
          autoFocus={autoFocus}
          placeholder={placeholder}
          format={format}
          showToday={true}
          value={getDateFormat(date)}
          onChange={(e) => {
            props.onBlur(e);
          }}
          id="DatePicker"
        />
      </ClickAwayListener>
    );
  }
);

const Dropdown = React.forwardRef((props, ref) => {
  if (!props.menuType) {
    console.error(
      "You have not provided a menuType in the table configuration, PS: use camel case"
    );
  }

  const placeholderType = props.menuType.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  const menuItemsByType = props.menuItems.filter(
    (item) => item.__typename === props.menuType
  );

  return (
    <ClickAwayListener onClickAway={props.onClickAway}>
      <Select
        ref={ref}
        showSearch
        autoFocus
        allowClear
        defaultValue={
          menuItemsByType.find((x) => x.id === props.children[2])?.name ?? null
        }
        disabled={props.disabled}
        style={{ minWidth: 200 }}
        placeholder={`Select a ${placeholderType}`}
        optionFilterProp="children"
        onChange={(value) => {
          props.onBlur(menuItemsByType.find((x) => x.id === value));
        }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {menuItemsByType.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </ClickAwayListener>
  );
});

const TextArea = React.forwardRef(
  (
    {
      onPressEnter = noop,
      onBlur = noop,
      defaultValue,
      value,
      disabled = false,
      maxRows = 10,
    },
    ref
  ) => (
    <Input.TextArea
      ref={ref}
      onPressEnter={onPressEnter}
      onBlur={onBlur}
      rows={maxRows}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      autoFocus
    />
  )
);

const TextField = React.forwardRef(
  (
    { onBlur = noop, disabled = false, style = { minWidth: "auto" }, ...props },
    ref
  ) => (
    <Input
      ref={ref}
      onBlur={onBlur}
      style={style}
      defaultValue={props.value}
      disabled={disabled}
      onPressEnter={onBlur}
    />
  )
);

const cellComponents = {
  TextField,
  TextArea,
  DatePicker,
  Dropdown,
};

export default cellComponents;
