import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { postcodeValidator } from 'postcode-validator'
import DOMPurify from 'dompurify'
import { Select, Button, Form, Input, message, Row, Col, Spin } from 'antd'
import { Prompt } from 'react-router-dom'

import { QUERIES } from '../../api/queries'
import { useStateValue } from '../stateProvider/stateProvider'
import { getLabel, useI18nContext } from '../../api/i18nService'
import {
    validateEmail,
    differenceOfTwoArrays,
    checkForPhoneValidation,
    checkWebsiteLink,
    replaceMultipleItemsInString,
    isValidUrl,
    validCountryCodes
} from '../../util/util'

const { Option } = Select

const DealerInformation = () => {
    const { strings } = useI18nContext()
    const [{ userDetails, currentDealer }, dispatch] = useStateValue()

    const defaultDealerState = {
        official_registration_name: currentDealer?.official_registration_name,
        tel_backoffice: currentDealer?.tel_backoffice,
        website_url: checkWebsiteLink(currentDealer?.website_url ?? ''),
        email_customers: currentDealer?.email_customers,
        email: currentDealer?.email,
        name: currentDealer?.name,
        bikeBrands:
            currentDealer?.bikeBrands?.length > 0
                ? currentDealer?.bikeBrands?.map((bikeBrand) => bikeBrand.id)
                : [],
        tel: currentDealer?.tel,
        street: currentDealer?.street,
        city: currentDealer?.city,
        zip_code: currentDealer?.zip_code,
    }

    const [bikeBrands, setBikeBrands] = useState([])
    const [dealerHasBikeBrands, setDealerHasBikeBrands] = useState([])
    const [formInformation, setFormInformation] = useState(defaultDealerState)
    const [disabled, setDisabled] = useState(true)
    const [disabledCancel, setDisabledCancel] = useState(false)
    const [isFormDataIncomplete, setIsFormDataIncomplete] = useState(false)

    const [validation, setValidation] = useState({
        officialRegistrationName: {
            valid: true,
            validateStatus: '',
            message: getLabel('veld is leeg', true, strings),
        },
        name: {
            valid: true,
            validateStatus: '',
            message: getLabel('veld is leeg', true, strings),
        },
        street: {
            valid: true,
            validateStatus: '',
            message: getLabel('veld is leeg', true, strings),
        },
        city: {
            valid: true,
            validateStatus: '',
            message: getLabel('veld is leeg', true, strings),
        },
        email: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldig e-mail adres', true, strings),
        },
        emailCustomers: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldig e-mail adres', true, strings),
        },
        tel: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldig tel nummer', true, strings),
        },
        telBackoffice: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldig tel nummer', true, strings),
        },
        websiteUrl: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldige website url', true, strings),
        },
        zipCode: {
            valid: true,
            validateStatus: '',
            message: getLabel('ongeldige postcode', true, strings),
        },
    })

    // Redirect to home if you are not an admin
    if (!userDetails.admin) {
        return <Redirect to={'/'} />
    }

    if (!userDetails.admin) {
        return null
    }

    // ! API Queries
    const {
        loading: dealerHasBikeBrandsLoading,
        error: dealerHasBikeBrandsError,
    } = useQuery(QUERIES.DEALER_HAS_BIKE_BRANDS_BY_DEALER_ID, {
        variables: {
            dealerId: currentDealer?.id,
        },
        onCompleted: (dealerHasBikeBrandsRes) => {
            setDealerHasBikeBrands(dealerHasBikeBrandsRes.dealerHasBikeBrands)
        },
    })

    const { loading: bikeBrandsLoading, error: bikeBrandsError } = useQuery(
        QUERIES.BIKE_BRANDS_ALL,
        {
            onCompleted: (bikeBrandsRes) => {
                setBikeBrands(bikeBrandsRes.bikeBrands)
            },
        }
    )

    const { refetch } = useQuery(QUERIES.DEALER_ALL)

    const onMutationComplete = () => {
        refetch().then((res) => {
            dispatch({
                type: 'setAllDealers',
                dealers: res.data.dealers,
            })
        })
    }

    // ! API mutations
    const [
        createDealerHasBikeBrand,
        {
            loading: createDealerHasBikeBrandLoading,
            error: createDealerHasBikeBrandError,
        },
    ] = useMutation(QUERIES.CREATE_DEALER_HAS_BIKE_BRAND)
    const [
        deleteDealerHasBikeBrand,
        {
            loading: deleteDealerHasBikeBrandLoading,
            error: deleteDealerHasBikeBrandError,
        },
    ] = useMutation(QUERIES.DELETE_DEALER_HAS_BIKE_BRAND)
    const [
        updateDealer,
        { loading: updateDealerLoading, error: updateDealerError },
    ] = useMutation(QUERIES.UPDATE_DEALER)

    const formRowStyling = { marginBottom: 'unset' }

    const saveDealer = async () => {
        const {
            email,
            emailCustomers,
            tel,
            telBackoffice,
            websiteUrl,
            zipCode,
            officialRegistrationName,
            name,
            street,
            city,
        } = validation

        const checkForErrors =
            !email.valid ||
            !emailCustomers.valid ||
            !tel.valid ||
            !telBackoffice.valid ||
            !websiteUrl.valid ||
            !zipCode.valid ||
            !street.valid ||
            !city.valid ||
            !name.valid ||
            !officialRegistrationName.valid

        if (checkForErrors) {
            message.error(getLabel('form errors', true, strings))

            return
        }

        // ! handle bike brands for the dealer

        const dealerBikeBrandIds = dealerHasBikeBrands.map(
            (dealerHasBikeBrand) => dealerHasBikeBrand.bike_brand_fk
        )

        const difference = differenceOfTwoArrays(
            dealerBikeBrandIds,
            formInformation.bikeBrands
        )

        difference.forEach(async (id) => {
            if (dealerBikeBrandIds.includes(String(id))) {
                const dealerHasBikeBrand = dealerHasBikeBrands.find(
                    (dealerHasBikeBrand) =>
                        dealerHasBikeBrand.bike_brand_fk === String(id)
                )

                await deleteDealerHasBikeBrand({
                    variables: { id: dealerHasBikeBrand?.id },
                })
            } else {
                await createDealerHasBikeBrand({
                    variables: {
                        dealerHasBikeBrand: {
                            dealer_fk: currentDealer.id,
                            bike_brand_fk: String(id),
                        },
                    },
                })
            }
        })

        // ! handle the update of the information for the dealer

        await updateDealer({
            variables: {
                dealer: {
                    id: currentDealer.id,
                    name: formInformation?.name,
                    street: formInformation?.street,
                    zip_code: formInformation?.zip_code,
                    city: formInformation?.city, 
                    tel: formInformation?.tel,
                    email: formInformation?.email,
                    official_registration_name:
                        formInformation?.official_registration_name,
                    tel_backoffice: formInformation?.tel_backoffice,
                    website_url: checkWebsiteLink(formInformation?.website_url),
                    email_customers: formInformation?.email_customers,
                },
            },
        }).then((res) => {
            onMutationComplete()

            if (res?.data?.updateDealer?.id) {
                setDisabledCancel(true)
                message.success(getLabel('update dealer bericht', true, strings))
            } else {
                setDisabledCancel(false)
                message.success(getLabel('update error dealer bericht', true, strings))
            }
        })
    }

    const areBikeBrandsTheSame = differenceOfTwoArrays(
        (currentDealer?.bikeBrands ?? []).map((bikeBrand) => bikeBrand.id),
        formInformation.bikeBrands
    )

    const isDataEqualToOldData = () =>
        currentDealer?.official_registration_name ===
        formInformation.official_registration_name &&
        currentDealer?.tel_backoffice === formInformation.tel_backoffice &&
        checkWebsiteLink(currentDealer?.website_url ?? '') ===
        checkWebsiteLink(formInformation?.website_url ?? '') &&
        currentDealer?.email_customers === formInformation.email_customers &&
        currentDealer?.email === formInformation.email &&
        currentDealer?.name === formInformation.name &&
        currentDealer?.tel === formInformation.tel &&
        currentDealer?.street === formInformation.street &&
        currentDealer?.city === formInformation.city &&
        currentDealer?.zip_code === formInformation.zip_code &&
        areBikeBrandsTheSame.length === 0

    useEffect(() => {
        const IsDataSameAsOldData = isDataEqualToOldData()

        window.addEventListener('mousedown', () => {
            if (!IsDataSameAsOldData) {
                setIsFormDataIncomplete(true)
            } else {
                setIsFormDataIncomplete(false)
            }
        })

        return () => {
            window.removeEventListener('mousedown', () => {
                if (!IsDataSameAsOldData) {
                    setIsFormDataIncomplete(true)
                } else {
                    setIsFormDataIncomplete(false)
                }
            })
        }
    }, [formInformation])

    useEffect(() => {
        const checkForDisabledButton = isDataEqualToOldData()

        const areFormValuesValid =
            validation.email.valid &&
            validation.emailCustomers.valid &&
            validation.tel.valid &&
            validation.telBackoffice.valid &&
            validation.websiteUrl.valid &&
            validation.zipCode.valid &&
            validation.officialRegistrationName.valid &&
            validation.street.valid &&
            validation.city.valid &&
            validation.name.valid

        if (!areFormValuesValid || checkForDisabledButton) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [validation, formInformation])

    useEffect(() => {
        setFormInformation((old) => ({
            ...old,
            ...defaultDealerState,
        }))

        setValidation((old) => ({
            ...old,
            officialRegistrationName: {
                ...old.officialRegistrationName,
                validateStatus: currentDealer?.official_registration_name
                    ? Boolean(currentDealer?.official_registration_name)
                        ? 'success'
                        : 'error'
                    : '',
            },
            name: {
                ...old.name,
                validateStatus: currentDealer?.name
                    ? Boolean(currentDealer?.name)
                        ? 'success'
                        : 'error'
                    : '',
            },
            street: {
                ...old.street,
                validateStatus: currentDealer?.street
                    ? Boolean(currentDealer?.street)
                        ? 'success'
                        : 'error'
                    : '',
            },
            city:{
                ...old.city,
                validateStatus: currentDealer?.city
                    ? Boolean(currentDealer?.city)
                        ? 'success'
                        : 'error'
                    : '',
            },
            email: {
                ...old.email,
                validateStatus: currentDealer?.email
                    ? Boolean(currentDealer?.email)
                        ? 'success'
                        : 'error'
                    : '',
            },
            emailCustomers: {
                ...old.emailCustomers,
                validateStatus: currentDealer?.email_customers
                    ? Boolean(currentDealer?.email_customers)
                        ? 'success'
                        : 'error'
                    : '',
            },
            tel: {
                ...old.tel,
                validateStatus: currentDealer?.tel
                    ? Boolean(currentDealer?.tel)
                        ? 'success'
                        : 'error'
                    : '',
            },
            telBackoffice: {
                ...old.telBackoffice,
                validateStatus: currentDealer?.tel_backoffice
                    ? Boolean(currentDealer?.tel_backoffice)
                        ? 'success'
                        : 'error'
                    : '',
            },
            websiteUrl: {
                ...old.websiteUrl,
                validateStatus: currentDealer?.website_url
                    ? Boolean(currentDealer?.website_url)
                        ? 'success'
                        : 'error'
                    : '',
            },
            zipCode: {
                ...old.zipCode,
                validateStatus: currentDealer?.zip_code
                    ? Boolean(currentDealer?.zip_code)
                        ? 'success'
                        : 'error'
                    : '',
            },
        }))
    }, [currentDealer])

    const loading =
        bikeBrandsLoading ||
        dealerHasBikeBrandsLoading ||
        createDealerHasBikeBrandLoading ||
        deleteDealerHasBikeBrandLoading ||
        updateDealerLoading

    useEffect(() => {
        if (bikeBrandsError) {
            return message.error(getLabel('error ophalen fietsmerken', true, strings))
        }

        if (dealerHasBikeBrandsError) {
            return message.error(
                getLabel('error ophalen fietsmerken dealer', true, strings)
            )
        }

        if (
            createDealerHasBikeBrandError ||
            deleteDealerHasBikeBrandError ||
            updateDealerError
        ) {
            return message.error(getLabel('error update dealer', true, strings))
        }
    }, [
        bikeBrandsError,
        dealerHasBikeBrandsError,
        createDealerHasBikeBrandError,
        deleteDealerHasBikeBrandError,
        updateDealerError,
    ])

    return (
        <div style={{ overflowY: 'auto', overflowX: 'hidden', width: '100%' }}>
            <h1 style={{ marginBottom: 10 }}>
                {getLabel('menu item dealer info', true, strings)}
            </h1>

            <div
                style={{
                    position: 'fixed',
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'rgba(255, 255, 255, .8)',
                    display: loading ? 'block' : 'none',
                    zIndex: 128,
                }}
            >
                <Row type="flex" justify="center" style={{ marginTop: '256px' }}>
                    <Spin size="large"></Spin>
                </Row>
            </div>

            <Form
                onSubmit={async (e) => {
                    e.preventDefault()
                    await saveDealer()
                }}
            >
                <Row type="flex" justify="center" gutter={16}>
                    <Col span={12}>
                        <h3 style={{ marginBottom: 10 }}>
                            {getLabel('intern gebruik', true, strings)}
                        </h3>

                        <Form.Item
                            hasFeedback={validation.officialRegistrationName.valid}
                            validateStatus={
                                validation.officialRegistrationName.validateStatus
                            }
                            style={formRowStyling}
                            label={getLabel('officiele registratie naam', true, strings)}
                            {...(!validation.officialRegistrationName.valid && {
                                help: validation.officialRegistrationName.message,
                            })}
                        >
                            <Input
                                value={formInformation?.official_registration_name}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)

                                    const checkForValidation = (value ?? '').length > 0

                                    setValidation((old) => ({
                                        ...old,
                                        officialRegistrationName: {
                                            ...old.officialRegistrationName,
                                            valid: checkForValidation,
                                            validateStatus: checkForValidation ? 'success' : 'error',
                                        },
                                    }))

                                    setFormInformation((old) => ({
                                        ...old,
                                        official_registration_name: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback={validation.telBackoffice.valid}
                            validateStatus={validation.telBackoffice.validateStatus}
                            style={formRowStyling}
                            label={getLabel('tel backoffice', true, strings)}
                            {...(!validation.telBackoffice.valid && {
                                help: validation.telBackoffice.message,
                            })}
                        >
                            <Input
                                value={formInformation?.tel_backoffice}
                                onChange={(e) => {
                                    const value = replaceMultipleItemsInString(
                                        DOMPurify.sanitize(e.target.value)
                                    )

                                    setValidation((old) => ({
                                        ...old,
                                        telBackoffice: {
                                            ...old.telBackoffice,
                                            valid: checkForPhoneValidation(value),
                                            validateStatus: checkForPhoneValidation(value)
                                                ? 'success'
                                                : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        tel_backoffice: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('email backoffice', true, strings)}
                            hasFeedback={validation.email.valid}
                            validateStatus={validation.email.validateStatus}
                            {...(!validation.email.valid && {
                                help: validation.email.message,
                            })}
                        >
                            <Input
                                value={formInformation?.email}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)
                                    setValidation((old) => ({
                                        ...old,
                                        email: {
                                            ...old.email,
                                            valid: validateEmail(value),
                                            validateStatus: validateEmail(value)
                                                ? 'success'
                                                : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        email: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <h3 style={{ marginBottom: 10 }}>
                            {getLabel('extern gebruik', true, strings)}
                        </h3>

                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('winkelnaam google', true, strings)}
                            hasFeedback={validation.name.valid}
                            validateStatus={validation.name.validateStatus}
                            {...(!validation.name.valid && {
                                help: validation.name.message,
                            })}
                        >
                            <Input
                                value={formInformation?.name}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)

                                    const checkForValidation = (value ?? '').length > 0

                                    setValidation((old) => ({
                                        ...old,
                                        name: {
                                            ...old.name,
                                            valid: checkForValidation,
                                            validateStatus: checkForValidation ? 'success' : 'error',
                                        },
                                    }))

                                    setFormInformation((old) => ({
                                        ...old,
                                        name: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('tel klanten', true, strings)}
                            hasFeedback={validation.tel.valid}
                            validateStatus={validation.tel.validateStatus}
                            {...(!validation.tel.valid && { help: validation.tel.message })}
                        >
                            <Input
                                value={formInformation?.tel}
                                onChange={(e) => {
                                    const value = replaceMultipleItemsInString(
                                        DOMPurify.sanitize(e.target.value)
                                    )

                                    setValidation((old) => ({
                                        ...old,
                                        tel: {
                                            ...old.tel,
                                            valid: checkForPhoneValidation(value),
                                            validateStatus: checkForPhoneValidation(value)
                                                ? 'success'
                                                : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        tel: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('email klanten', true, strings)}
                            hasFeedback={validation.emailCustomers.valid}
                            validateStatus={validation.emailCustomers.validateStatus}
                            {...(!validation.emailCustomers.valid && {
                                help: validation.emailCustomers.message,
                            })}
                        >
                            <Input
                                value={formInformation?.email_customers}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)
                                    setValidation((old) => ({
                                        ...old,
                                        emailCustomers: {
                                            ...old.emailCustomers,
                                            valid: validateEmail(value),
                                            validateStatus: validateEmail(value)
                                                ? 'success'
                                                : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        email_customers: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('adres postcode', true, strings)}
                            hasFeedback={validation.zipCode.valid}
                            validateStatus={validation.zipCode.validateStatus}
                            {...(!validation.zipCode.valid && {
                                help: validation.zipCode.message,
                            })}
                        >
                            <Input
                                value={formInformation?.zip_code}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)
                                    // Is postcode valid for Belgium, Germany or The Netherlands
                                    const resultValidation = validCountryCodes.map(code => postcodeValidator(value, code))

                                    setValidation((old) => ({
                                        ...old,
                                        zipCode: {
                                            ...old.zipCode,
                                            valid: resultValidation.some(x => x),
                                            validateStatus:
                                                resultValidation.some(x => x)
                                                    ? 'success'
                                                    : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        zip_code: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('adres straat', true, strings)}
                            hasFeedback={validation.street.valid}
                            validateStatus={validation.street.validateStatus}
                            {...(!validation.street.valid && {
                                help: validation.street.message,
                            })}
                        >
                            <Input
                                value={formInformation?.street}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)

                                    const checkForValidation = (value ?? '').length > 0

                                    setValidation((old) => ({
                                        ...old,
                                        street: {
                                            ...old.street,
                                            valid: checkForValidation,
                                            validateStatus: checkForValidation ? 'success' : 'error',
                                        },
                                    }))

                                    setFormInformation((old) => ({
                                        ...old,
                                        street: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('Adres Woonplaats', true, strings)}
                            hasFeedback={validation.city.valid}
                            validateStatus={validation.city.validateStatus}
                            {...(!validation.city.valid && {
                                help: validation.city.message,
                            })}
                        >
                            <Input
                                value={formInformation?.city}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)

                                    const checkForValidation = (value ?? '').length > 0

                                    setValidation((old) => ({
                                        ...old,
                                        city: {
                                            ...old.city,
                                            valid: checkForValidation,
                                            validateStatus: checkForValidation ? 'success' : 'error',
                                        },
                                    }))

                                    setFormInformation((old) => ({
                                        ...old,
                                        city: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('website url', true, strings)}
                            hasFeedback={validation.websiteUrl.valid}
                            validateStatus={validation.websiteUrl.validateStatus}
                            {...(!validation.websiteUrl.valid && {
                                help: validation.websiteUrl.message,
                            })}
                        >
                            <Input
                                value={formInformation?.website_url}
                                onChange={(e) => {
                                    const value = DOMPurify.sanitize(e.target.value)
                                    setValidation((old) => ({
                                        ...old,
                                        websiteUrl: {
                                            ...old.websiteUrl,
                                            valid: isValidUrl('https://' + value),
                                            validateStatus: isValidUrl('https://' + value)
                                                ? 'success'
                                                : 'error',
                                        },
                                    }))
                                    setFormInformation((old) => ({
                                        ...old,
                                        website_url: value,
                                    }))
                                    setDisabledCancel(false)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={formRowStyling}
                            label={getLabel('brands', true, strings)}
                        >
                            <Select
                                value={formInformation?.bikeBrands}
                                showSearch
                                mode="multiple"
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setDisabledCancel(false)
                                    setFormInformation((old) => ({ ...old, bikeBrands: val }))
                                }}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {bikeBrands.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            padding: '25px 0',
                        }}
                        span={24}
                    >
                        <Button
                            style={{ marginRight: '10px' }}
                            type="primary"
                            disabled={disabledCancel}
                            onClick={() => {
                                setFormInformation((old) => ({
                                    ...old,
                                    ...defaultDealerState,
                                }))

                                setValidation((old) => ({
                                    ...old,
                                    officialRegistrationName: {
                                        ...old.officialRegistrationName,
                                        valid: true,
                                        validateStatus: currentDealer?.official_registration_name
                                            ? Boolean(currentDealer?.official_registration_name)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    name: {
                                        ...old.name,
                                        valid: true,
                                        validateStatus: currentDealer?.name
                                            ? Boolean(currentDealer?.name)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    street: {
                                        ...old.street,
                                        valid: true,
                                        validateStatus: currentDealer?.street
                                            ? Boolean(currentDealer?.street)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    city: {
                                        ...old.city,
                                        valid: true,
                                        validateStatus: currentDealer?.city
                                            ? Boolean(currentDealer?.city)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    email: {
                                        ...old.email,
                                        valid: true,
                                        validateStatus: currentDealer?.email
                                            ? Boolean(currentDealer?.email)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    emailCustomers: {
                                        ...old.emailCustomers,
                                        valid: true,
                                        validateStatus: currentDealer?.email_customers
                                            ? Boolean(currentDealer?.email_customers)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    tel: {
                                        ...old.tel,
                                        valid: true,
                                        validateStatus: currentDealer?.tel
                                            ? Boolean(currentDealer?.tel)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    telBackoffice: {
                                        ...old.telBackoffice,
                                        valid: true,
                                        validateStatus: currentDealer?.tel_backoffice
                                            ? Boolean(currentDealer?.tel_backoffice)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    websiteUrl: {
                                        ...old.websiteUrl,
                                        valid: true,
                                        validateStatus: currentDealer?.website_url
                                            ? Boolean(currentDealer?.website_url)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                    zipCode: {
                                        ...old.zipCode,
                                        valid: true,
                                        validateStatus: currentDealer?.zip_code
                                            ? Boolean(currentDealer?.zip_code)
                                                ? 'success'
                                                : 'error'
                                            : '',
                                    },
                                }))

                                setDisabledCancel(true)
                            }}
                        >
                            {getLabel('cancel', true, strings)}
                        </Button>
                        <Button
                            style={{ marginRight: '10px' }}
                            type="primary"
                            htmlType="submit"
                            disabled={disabled}
                        >
                            {getLabel('save', true, strings)}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Prompt
                when={isFormDataIncomplete}
                message={getLabel(
                    'Verlaten pagina met niet opgeslagen data',
                    true,
                    strings
                )}
            />
        </div>
    )
}

export default DealerInformation
