import React from 'react';
import { Card, Row, Col } from 'antd';
import './employeeCard.css'

/**
 * employeeCard.js
 * Small component that shows a user's name and employer.
 */

const EmployeeCard = props => {

  return props.employee ? (<Card>
    <Row type='flex' gutter={4} justify='center'>
      <Col span={props.shouldRenderLogo ? 16 : 24}>
        <h2>{props.employee.first_name} {props.employee.last_name}</h2>
        <h3>{props.employee.company.name}</h3>
      </Col>
    </Row>
  </Card>) : null;
}

export default EmployeeCard;