import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: 'da0192907906cfe80244220637687a06',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV? process.env.REACT_APP_ENV: 'dev'
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);


ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
