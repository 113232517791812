import React, { useEffect, useState } from 'react';
import { notification, Icon, Card, Row, Col, Statistic, Avatar, Button } from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf'



const EmployeeAccessResult = ({quotation, postData, dealer, company, name, mail}) => {

   

    const { strings } = useI18nContext();

    const style = {
        hr: {
            width: "30%",
            maxWidth: "300px",
            margin: "auto",
            marginTop: "25px",
            marginBottom: "25px",
            border: "0.5px solid lightGray"
        }
    }

    useEffect(()=>{
       var resultCARD = document.getElementById('resultCard');
       resultCARD.scrollIntoView({behavior: "smooth"});
      
      if(quotation.warning_message != null && quotation.warning_message != ""){
          createWarningNotification(quotation.warning_message);
      }
    },[quotation])

      const createWarningNotification=(msg)=>{
        notification.open({
            message: getLabel('Waarschuwing', true, strings),
            description: msg,
            icon: <Icon type="exclamation-circle-o" />,
        })
      }

      const createPDF=()=>{
        html2canvas(document.getElementById("resultCard")).then(canvas => {
          const imgData = canvas.toDataURL('image/png');

          const imgWidth = 210; 
          const pageHeight = 295;  
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
          const doc = new jsPDF('p', 'mm');
          let position = 0;

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save(`Quotation_${new Date().toJSON().slice(0,10).split('-').reverse().join('/')}.pdf`);
      });
      }

    return (
        <>

        <hr style={{...style.hr, marginTop: "100px"}} />
        
        <div id="resultCard" style={{margin: "auto", marginTop: "50px", marginBottom: "50px", width: "8.3in"}}>
            <Card title={getLabel('Berekening', true, strings)} bordered={true} style={{ width: "100%", maxWidth: "1250px", margin: "auto" }}>
            
            <div style={{width:"100%", overflow: "auto"}}>
                <div style={{float: "left"}}>
                {company.logo_url ?
                    <img src={company.logo_url} style={{maxWidth: '128px', maxHeight: '128px', marginBottom:"25px"}} />
                    :
                    <Avatar size='large' style={{ maxWidth: '128px', maxHeight: '128px', marginBottom: '25px' }}>{company.name}</Avatar>
                }

                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="team" style={{marginRight: 10}}/>
                    {company && company.name? company.name:"/"}</p>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="mail" style={{marginRight: 10}}/>
                    {company && company.email? company.email:"/"}</p>
                </div>

                <div style={{float: "right"}}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="user" style={{marginRight: 10}}/>
                    {name?name:"/"}</p>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="mail" style={{marginRight: 10}}/>
                    {mail?mail:"/"}</p>
                </div>
            </div>

            <hr style={style.hr} />

            <h4 style={{marginBottom:"25px"}}>{getLabel('Dealer', true, strings)}:</h4>
            <div style={{margin: "auto"}}>
                <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="team" style={{marginRight: 10}}/>
                {dealer && dealer.name? dealer.name:"/"}</p>
                <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="environment-o" style={{marginRight: 10}}/>
                {dealer ? `${dealer.street? dealer.street:"/"} - ${dealer.zip_code? dealer.zip_code:"/"} -  ${dealer.city? dealer.city:"/"}`:"/"}</p>
                <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="mail" style={{marginRight: 10}}/>
                {dealer && dealer.email? dealer.email:"/"}</p>
                <p style={{whiteSpace: "nowrap", overflow: "hidden"}}><Icon type="phone" style={{marginRight: 10}}/>
                {dealer && dealer.tel? dealer.tel:"/"}</p>
            </div>

            {postData.fields.length >0 &&
              <div>
              <hr style={style.hr} />
            <h4 style={{marginBottom:"25px"}}>{getLabel('Aanvullende informatie', true, strings)}:</h4>
                  {postData.fields.map((field, i)=>{
                    return (
                    <p key={`${i}_InfoTxt`}><span style={{textDecoration: "underline"}}>
                      {getLabel(field.name.toLowerCase().replace(/\./g, ''), true, strings)}:</span> {
                      field.code == "NUMBER" ?
                      field.value?`€ ${field.value}`:`€ ${0}` : 
                      field.value?field.value: "/"
                      }</p>
                    )
                  })
                  }
              </div>
            }


            <hr style={style.hr} />

            <div>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center', fontSize: '4pt' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('lease excl vat', true, strings)}</span>} prefix='€' value={quotation.lease_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('lease incl vat', true, strings)}</span>} prefix='€' value={quotation.lease_amount_vat} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('salary swap', true, strings)}</span>} prefix='€' value={quotation.salary_swap} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('insurance', true, strings)}</span>} prefix='€' value={quotation.insurance_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('maintenance', true, strings)}</span>} prefix='€' value={quotation.maintenance_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('assistance', true, strings)}</span>} prefix='€' value={quotation.assistance_amount} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('warranty grant', true, strings)}</span>} prefix='€' value={quotation.warranty_grant} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('total excl vat', true, strings)}</span>} prefix='€' value={quotation.total_vat_excluded} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('total incl vat', true, strings)}</span>} prefix='€' value={quotation.total_vat_included} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('residual value', true, strings)}</span>} prefix='€' value={quotation.residual_value} /></Col>
            </Row>
            </div>
            
            </Card>
        </div>


        <div style={{margin: "auto", width: "8.3in"}}>
          <Button style={{float: "right", marginBottom:"25px"}} onClick={createPDF}>{getLabel('Bewaar als PDF', true, strings)}</Button>
        </div>

        </>
    )
}

export default EmployeeAccessResult;