import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStateValue } from '../stateProvider/stateProvider';
import { useQuery } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { Row, Spin, Card, Col, Icon, Button, Result, Avatar } from 'antd';
import { getLabel, useI18nContext } from '../../api/i18nService';

/**
 * companyInfoCard.js
 * React component that displays the details (info fiche, invoice target etc.) of a company.
 * @param (props) : company - The company (as object, id is required)
 * Optional @param (props) : showNextButton - Whether or not a next button should be shown
 * Optional @param (props) : nextRoute - Where the next button leads to
 * Optional @param (props) : setCompany - Whether or not to set this company in the global state
 */

const CompanyInfoCard = props => {
  const { strings } = useI18nContext();
  let content = null;
  const [{ company: companyContext }, dispatch] = useStateValue();
  const [company, setCompany] = useState(null);
  const { loading, error, data } = useQuery(QUERIES.COMPANY_INFO_FICHE, {
    variables: {
      id: props.company.id
    },
    onCompleted: res => {
      setCompany(res.company);
    }
  });

  if (error) {
    console.error('companyInfoCard - ', error)
    content = (
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  }

  if (loading) {
    content = (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    )
  }

  else if (data && company) {
    content = (
      <Card style={{ marginTop: '16px' }} title={<h1 style={{ textAlign: 'center' }}>{props.company.name}</h1>}
      >
        <h4 style={{ color: '#a1a1a1' }}>{company.invoice_target.name}</h4>
        <Row type='flex' justify='center'>
          <Col span={24}>
            {company.logo_url ?
            <img style={{ maxWidth: '128px', maxHeight: '128px', marginBottom: '16px' }} src={company.logo_url} alt={props.company.name} />
          : <Avatar size='large' style={{ maxWidth: '128px', maxHeight: '128px', marginBottom: '16px' }}>{company.name[0]}</Avatar>}
          </Col>
          <Col span={24}>
            <iframe style={{ width: '100%', height: '100%' }} srcDoc={company.info} title='company info' />
          </Col>
        </Row>
        {(props.showNextButton && props.nextRoute) &&
          <Row type='flex' justify='end'>
            <Link to={props.nextRoute}>
              <Button size='large' shape='circle'
                type='primary' style={{ marginTop: '16px' }}
                onClick={() => {
                  if (props.setCompany) {
                    dispatch({
                      type: 'changeCompany',
                      newCompany: company
                    });
                  }
                }
                }>
                <Icon type='right' />
              </Button>
            </Link>
          </Row>
        }
      </Card>
    )
  }
  return (
    <div>{content}</div>
  )
}

export default CompanyInfoCard;