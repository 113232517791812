import { message } from 'antd'
import copy from 'copy-to-clipboard'
import React from "react";

import Icon from './icon'

const labelStyling = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const CopyRow = ({ content }) => {
  const value = content ?? '/'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <p style={labelStyling}>{value}</p>
      {value !== '/' && (
        <Icon
          onClick={() => {
            const copiedToClipboard = copy(content)
            if (copiedToClipboard) {
              message.success(
                `"${content}" has been successfully copied to the clipboard`
              )
            }
          }}
          type="copy"
          style={{ cursor: 'pointer', marginTop: '3px' }}
        />
      )}
    </div>
  )
}

export default CopyRow
