import React, { useState, useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table, Button, Icon, Modal, Input, Form, Spin, Row, Col, message, Popconfirm } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const VariantSettings = props => {

  const variantModel = {
    code: '',
    name: '',
    isNew: true
  };

  const [variants, setVariants] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [inputModalVisible, setInputModalVisible] = useState(false);
  const [working, setWorking] = useState(false);

  const [createVariant] = useMutation(QUERIES.CREATE_VARIANT);
  const [updateVariant] = useMutation(QUERIES.UPDATE_VARIANT);
  const [deleteVariant] = useMutation(QUERIES.DELETE_VARIANT);

  const { loading, error, refetch } = useQuery(QUERIES.VARIANT_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      // console.log(res);
      setVariants(res.variants);
    }
  });

  const onMutationDone = data => {
    refetch()
      .then(res => {
        setVariants(res.data.variants)
        setInputModalVisible(false);
        setWorking(false);
        message.success(`Wijzigingen opgeslagen`);
      })
  }

  const onMutationFailed = err => {
    setInputModalVisible(false);
    setWorking(false);
    console.error(err);
    message.error(`Bewerking mislukt`);
  }

  const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      render: (text, record) => record.name,
      width: '30%',
      onFilter: (value, record) => record.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter: (a, b) => a.name > b.name ? -1 : 1,
      sortDirections: ['descend', 'ascend'],
      // ...searchColumn('name')
    },
    {
      title: 'Code',
      render: record => record.code,
      width: '30%',
      sorter: (a, b) => a.code > b.code ? -1 : 1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      render: record => {
        return (
          <span>
            <Button
              shape='circle'
              style={{ marginRight: '8px' }}
              onClick={() => {
                // const {__typename, ...strippedVariant} = record;
                // console.log(strippedVariant);
                setSelectedVariant(record);
                setInputModalVisible(true);
              }}
            ><Icon type="edit" /></Button>
            <Popconfirm
            title={`Variant ${record.name} verwijderen?`}
            onConfirm={() => {
              setWorking(true);
                deleteVariant({variables: {id: record.Id}})
                .then(res => onMutationDone(res))
                .catch(err => onMutationFailed(err));
            }}
            >
            <Button
              shape='circle'
              type='danger'
              style={{ marginRight: '8px' }}
              
            ><Icon type="delete" /></Button>
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  if (loading || working) {
    return (
      <div>
        <Row type='flex' justify='center'>
          <Col span={8}>
            <Spin size='large' />
          </Col>
        </Row>
      </div>
    )
  };
  return (
    <div>
      <Button
        type='primary'
        onClick={() => {
          setSelectedVariant({ ...variantModel });
          setInputModalVisible(true);
        }}><Icon type='plus' /> Nieuw
        </Button>
      <Row type='flex' justify='center'>
        <Table
          rowKey={record => record.Id}
          style={{ width: '80vw', margin: 16 }}
          columns={columns}
          dataSource={variants}
        />
      </Row>
      <Modal
        visible={inputModalVisible}
        destroyOnClose={true}
        onOk={() => {
          // TODO Persist changes
          setWorking(true);
          if (selectedVariant.isNew) {
            const { isNew, ...rest } = selectedVariant;
            // selectedVariant = rest;
            createVariant({ variables: { variant: rest } })
              .then(res => {
                // setVariants(old => [...old, selectedVariant]);
                return onMutationDone(res.createVariant);
              })
              .catch(err => {
                return onMutationFailed(err);
              })
          }
          else {
            const { __typename, ...rest } = selectedVariant;
            updateVariant({ variables: { variant: rest } })
              .then(res => {
                return onMutationDone(res.updateVariant);
              })
              .catch(err => {
                return onMutationFailed(err);
              })
          }
        }}
        onCancel={() => {
          setInputModalVisible(false);
        }}
        cancelText={'Annuleren'}
      >
        {selectedVariant && (
          <Form>
            <Form.Item required={true} label='Naam'>
              <Input
                defaultValue={selectedVariant.name}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setSelectedVariant(old => {
                    return { ...old, name: val };
                  });
                }} />
            </Form.Item>
            <Form.Item required={true} label='Code'>
              <Input
                defaultValue={selectedVariant.code}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setSelectedVariant(old => {
                    return { ...old, code: val };
                  });
                }} />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default VariantSettings;