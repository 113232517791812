import React from "react";
import { Button as AntButton } from "antd";

import Tooltip from "./tooltip";

const Button = ({
  type = "primary",
  size = "large",
  loading = false,
  ...props
}) => (
  <Tooltip title={props.tooltipTitle}>
    <AntButton
      type={type}
      style={props.style}
      onClick={props.onClick}
      icon={props.icon}
      size={size}
      disabled={props.disabled}
      loading={loading}
    >
      {props.children}
    </AntButton>
  </Tooltip>
);

export default Button;
