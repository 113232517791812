import React, { useState, useEffect, useRef } from 'react';
import CompanySearch from '../companySearch/companySearch';
import { QUERIES } from '../../api/queries';
import {
  Modal, Button, Icon, Form, Row, Col, List,
  Input, Select, Checkbox, Spin, Card, Table, InputNumber, message, Tooltip, Popconfirm
} from 'antd';
import Column from 'antd/lib/table/Column';
import { useQuery, useMutation } from '@apollo/react-hooks';
import FileUploader from '../fileUploader/fileUploader';
import JoditEditor from "jodit-react";
import { reducer } from '../stateProvider/stateProvider';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
import { useI18nContext, getLabel } from '../../api/i18nService';

const companyModel = {
  id: 'new',
  name: '',
  company_parent_fk: null,
  language_fk: null,
  variant_fk: null,
  contact: '',
  email: '',
  tel: '',
  invoice_target_fk: '',
  invoice_target: '',
  calculation_sheet: null,
  comment_public: '',
  comment_private: '',
  disclaimer_calculation: '',
  disclaimer_receipt: '',
  logo_id: '',
  logo_url: '',
  folder_id: '',
  form_url: '',
  form_id: '',
  form_responses_id: '',
  info: '',
  hide: false,
  fip: false,
  customer_id: '',
  costcenters: [],
  test: false,
  fields: [],
  employeeStatutes: [],
  employee_access_code: ''
}

const { Option } = Select;
const { TextArea } = Input;

const CompanySettings = props => {

  const [selectedCompany, setSelectedCompany] = useState({ ...companyModel });
  const [showCompanyMutationModal, setShowCompanyMutationModal] = useState(false);
  const [showCompanySelector, setShowCompanySelector] = useState(false);
  const [showIncoiceTargetSelector, setShowInvoiceTargetSelector] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [currentTab, setCurrentTab] = useState('Algemeen');
  const [originalFields, setOriginalFields] = useState(null);
  const [originalCostcenters, setOriginalCostcenters] = useState(null);
  const [originalStatutes, setOriginalStatutes] = useState(null);
  const [allVariants, setAllVariants] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [saving, setSaving] = useState(false);
  // const [makeSheet, setMakeSheet] = useState(true);
  const accessCodeInputRef = useRef(null);
  const [clearSheetLoading, setClearSheetLoading] = useState(false);
  const [companyDeletionLoading, setCompanyDeletionLoading] = useState(false);
  const [companyList, setCompanyList] = useState((
    <CompanySearch
      customQuery={QUERIES.COMPANIES_ALL_FOR_SETTINGS}
      showHidden={true}
      settingsSource={true}
      callback={comp => {
        setSelectedCompany({ ...comp });
        setOriginalFields(null);
        setOriginalCostcenters(null);
        setOriginalStatutes(null);
        setShowCompanyMutationModal(true);
      }}
    />
  ))

  const [createCompany] = useMutation(QUERIES.CREATE_COMPANY);
  const [updateCompany] = useMutation(QUERIES.UPDATE_COMPANY);
  const [createField] = useMutation(QUERIES.CREATE_FIELD);
  const [updateField] = useMutation(QUERIES.UPDATE_FIELD);
  const [createFieldValue] = useMutation(QUERIES.CREATE_FIELD_VALUE);
  const [addStatute] = useMutation(QUERIES.CREATE_COMPANY_HAS_EMPLOYEE_STATUTE);
  const [deleteStatute] = useMutation(QUERIES.DELETE_COMPANY_HAS_EMPLOYEE_STATUTE);
  const [createCostcenter] = useMutation(QUERIES.CREATE_COST_CENTER);
  const [updateCostcenter] = useMutation(QUERIES.UPDATE_COST_CENTER);
  const [deleteCostcenter] = useMutation(QUERIES.DELETE_COST_CENTER);
  const [deleteCompanyCalculationSheet] = useMutation(QUERIES.DELETE_COMPANY_CALCULATION_SHEET);
  const [deleteCompany] = useMutation(QUERIES.DELETE_COMPANY);



  // const { error, loading, data: invoiceTargetData } = useQuery(QUERIES.ENTERPRISE_ALL);
  const { error: variantError, loading: variantLoading, data: variantData } = useQuery(QUERIES.VARIANT_SETTINGS, {
    onCompleted: res => {
      // console.log(res);
      setAllVariants(res.variants);
    }
  });
  const { error: langError, loading: langLoading, data: langData } = useQuery(QUERIES.LANGUAGES, {
    onCompleted: res => {
      setAllLanguages(res.languages);
    }
  });

  const tabList = [
    {
      key: 'Algemeen',
      tab: 'Algemeen'
    },
    {
      key: 'Velden',
      tab: 'Velden'
    },
    {
      key: 'Kostenplaatsen',
      tab: 'Kostenplaatsen'
    },
    {
      key: 'Statuten',
      tab: 'Statuten'
    },
    {
      key: 'VerplichteVelden',
      tab: 'Verplichte velden'
    }
  ];

  /*********************
   * START ONSAVE
   */

  const resetCompanyList = () => {
    setCompanyList((
      <CompanySearch
        customQuery={QUERIES.COMPANIES_ALL_FOR_SETTINGS}
        showHidden={true}
        settingsSource={true}
        callback={comp => {
          setSelectedCompany(comp);
          setOriginalFields(null);
          setOriginalCostcenters(null);
          setOriginalStatutes(null);
          setShowCompanyMutationModal(true);
        }}
      />
    ));
  }

  const onSave = async (company, orFields, orCostcenters, orStatutes, regenerateSheetBool, isDeletion) => {
    if (!company.name || !company.invoice_target || !company.language_fk || !company.variant_fk) {
      message.error('Gelieve de verplichte velden in te vullen.');
      return;
    }

    setSaving(true);

    const { employeeStatutes, costcenters, fields,
      invoice_target, __typename, parentCompany, children, ...companyBase } = company;
    let fieldsToDelete = [];
    let fieldsToUpdate = [];
    let newFields = [];
    let costcentersToDelete = [];
    let costcentersToAdd = [];
    let statutesToDelete = [];
    let statutesToAdd = [];
    let fieldValuesToAdd = [];
    let fieldValuesToDelete = [];

    // console.log(companyBase);
    // console.log(logoFile);

    if (!!orFields) {
      fieldsToDelete = orFields.filter(el => {
        return !company.fields.find(f => el.id === f.id);
      })
      fieldsToUpdate = company.fields.filter(el => {
        return el.updated && !!el.id;
      });
      newFields = company.fields.filter(el => {
        return el.isNew;
      })
      // console.log('fieldsToDelete: ', fieldsToDelete);
      // console.log('fieldsToUpdate: ', fieldsToUpdate)
      // console.log('newFields: ', newFields);

      for (let f of fieldsToDelete) {
        if (!!f.fieldValues) {
          fieldValuesToDelete = fieldValuesToDelete.concat(f.fieldValues);
          // console.log('Should delete fieldValues (field deleted): ', f.fieldValues);
        }
      }
      for (let f of fieldsToUpdate) {
        if (!!f.valuesToDelete) {
          fieldValuesToDelete = fieldValuesToDelete.concat(f.valuesToDelete);
        }
      }
      // console.log('fieldValuesToDelete: ', fieldValuesToDelete);


      for (let f of newFields) {
        if (!!f.fieldValues) {
          fieldValuesToAdd = fieldValuesToAdd.concat(f.fieldValues);
        }
      }
      for (let f of fieldsToUpdate) {
        if (!!f.fieldValues) {
          fieldValuesToAdd = fieldValuesToAdd.concat(f.fieldValues.filter(el => !el.id));
        }
      }

      // console.log('fieldValuesToAdd: ', fieldValuesToAdd);
    }

    if (!!orCostcenters) {
      costcentersToDelete = orCostcenters.filter(el => {
        return !company.costcenters.find(f => el.id === f.id);
      });
      costcentersToAdd = company.costcenters.filter(el => !el.id);

      // console.log('costcentersToAdd: ', costcentersToAdd);
      // console.log('costcentersToDelete: ', costcentersToDelete);
    }

    if (!!orStatutes) {
      statutesToDelete = orStatutes.filter(el => {
        return !company.employeeStatutes.find(f => el.id === f.id);
      });

      statutesToAdd = company.employeeStatutes.filter(el => {
        return !orStatutes.find(f => el.id === f.id);
      });
      // console.log('statutesToDelete: ', statutesToDelete);
      // console.log('statutesToAdd: ', statutesToAdd);
    }

    // ACUTAL MUTATIONS
    try {
      let companyRes = null;
      let companyId = null;
      if (selectedCompany.id === 'new') {
        const { id, ...finalCompany } = companyBase;
        // console.log("finalCompany", finalCompany);
        // console.log("selectedCompany",selectedCompany);
        const variables = { company: finalCompany };
        if (!!logoFile) {
          variables.logo = logoFile;
        }
        variables.make_sheet = !company.company_parent_fk;
        // variables.regenerate_sheet = regenerateSheetBool
        companyRes = await createCompany({ variables: variables });
        companyId = companyRes.data.createCompany.id;
        message.info('Bedrijfsgegevens aangemaakt');
      }
      else {
        const variables = { company: companyBase, regenerate_sheet: regenerateSheetBool };
        if (!!logoFile) {
          variables.logo = logoFile;
        }
        companyRes = await updateCompany({ variables: variables });
        companyId = companyBase.id;
      }

      if (regenerateSheetBool) {
        return
      } else {

        // LET THE LOOPING COMMENCE!!!!

        // New Fields and FieldValues
        for (let f of newFields) {
          try {
            const { isNew, fieldType, fieldValues, updated, ...fBase } = f;
            if (fBase.company_fk === 'new') {
              fBase.company_fk = companyId;
            }
            const fieldRes = await createField({ variables: { field: fBase } });
            message.info(`Veld ${f.name} aangemaakt`);
            if (!!f.fieldValues) {
              for (let fv of f.fieldValues) {
                try {
                  let value = { ...fv };
                  value.field_fk = fieldRes.data.createField.id;
                  const fvRes = await createFieldValue({ variables: { fieldValue: value } });
                  message.info(`Optie ${fv.value} voor ${f.name} aangemaakt`);
                }
                catch (fvErr) {
                  console.error(fvErr);
                  message.error(`Kon optie ${fv.value} niet toevoegen aan ${f.name}`);
                }
              }
            }
          }
          catch (err) {
            message.error(`Kon veld ${f.name} niet opslaan.`);
            console.error(err);
          }
        }

        // Update Fields & FieldValues
        for (let f of fieldsToUpdate) {
          const { fieldType, __typename, updated, fieldValues, valuesToDelete, ...fBase } = f;
          try {
            delete fBase.hasQuotationFields
            const fRes = await updateField({ variables: { field: fBase } });
            message.info(`Veld ${f.name} geüpdatet`)
          }
          catch (fErr) {
            console.error(fErr);
            message.error(`Kon veld ${f.name} niet updaten`);
          }
        }
        for (let fv of fieldValuesToAdd) {
          if (fv.field_fk) {
            try {
              const fvRes = await createFieldValue({ variables: { fieldValue: fv } });
              message.info(`Optie ${fv.value} geüpdatet`);
            }
            catch (fvErr) {
              console.error(fvErr);
              message.error(`Kon optie ${fv.value} niet aanmaken.`);
            }
          }
        }

        // Create, update and delete CostCenters
        for (let c of costcentersToAdd) {
          if (c.company_fk === 'new') {
            c.company_fk = companyId;
          }
          try {
            const cRes = await createCostcenter({ variables: { costcenter: c } });
            message.info(`Kostenplaats ${c.name} aangemaakt`);
          }
          catch (cErr) {
            console.error(cErr);
            message.error(`Kan kostenplaats ${c.name} niet aanmaken`);
          }
        }
        for (let c of costcentersToDelete) {
          try {
            const cRes = await deleteCostcenter({ variables: { id: c.id } });
            message.info(`Kostenplaats ${c.name} verwijderd`);
          }
          catch (cErr) {
            console.error(cErr);
            message.error(`Kan kostenplaats ${c.name} niet verwijderen`);
          }
        }

        // Add & remove Statutes
        for (let s of statutesToAdd) {
          try {
            const statuteRes = await addStatute({ variables: { companyHasEmployeeStatute: { company_fk: companyId, employee_statute_fk: s.id } } });
            message.info(`Statuut ${s.name} toegevoegd`);
          }
          catch (sErr) {
            console.error(sErr);
            message.error(`Kon statuut ${s.name} niet toevoegen.`)
          }
        }
        for (let s of statutesToDelete) {
          if (s.relation_fk) {
            try {
              const deletedId = await deleteStatute({ variables: { id: s.relation_fk } });
              message.info(`Statuut ${s.name} verwijderd`);
            }
            catch (sErr) {
              console.error(sErr);
              message.error(`Kon statuut ${s.name} niet verwijderen`);
            }
          }
        }
      }
    }
    catch (err) {
      //Don't show saving err when it was a company deletion
      if (!isDeletion) {
        console.error(err);
        message.error('Er ging iets mis bij het opslaan van het bedrijf');
      }
    }

    finally {
      // company = null
      setShowCompanyMutationModal(false);
      setSelectedCompany({ ...companyModel });
      // setSelectedCompany(null);
      setSaving(false);
      resetCompanyList();

      //Reset new & updates after saving
      if (company && company.fields) {
        company.fields.map((el) => {
          if (el.isNew)
            el.isNew = false;
          if (el.updated)
            el.updated = false;
        });
      }
    }
  }
  /***********************
    END OF ONSAVE
  */

  const checkCanCreateSheet = (selectedCompany) => {
    // console.log('CompanyCalcsheet: ', selectedCompany)
    //Check if child comp w/ sheet
    if (!selectedCompany.calculation_sheet) {
      return true;
    }
    if (!!selectedCompany.parentCompany && !!selectedCompany.parentCompany.calculation_sheet) {
      //if same as parent => open else locked
      return (selectedCompany.calculation_sheet === selectedCompany.parentCompany.calculation_sheet);
    }
    return false;
  }

  const tabContents = {
    Algemeen: (<Form>
      <Row type='flex' justify='center' gutter={16}>
        <Col span={12}>
          <Form.Item
            label='Naam'
            required={true}
          >
            <Input
              defaultValue={selectedCompany.name}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setSelectedCompany(old => {
                  return { ...old, name: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Facturatiedoel'
            required={true}
          >
            <Button
              onClick={() => setShowInvoiceTargetSelector(true)}
            >
              {selectedCompany.invoice_target ? selectedCompany.invoice_target.name : 'Selecteer...'}
            </Button>
          </Form.Item>
          <Form.Item
            label='Contact'
          >
            <Input
              defaultValue={selectedCompany.contact}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setSelectedCompany(old => {
                  return { ...old, contact: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Telefoon'
          >
            <Input
              defaultValue={selectedCompany.tel}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setSelectedCompany(old => {
                  return { ...old, tel: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='E-mail'
          >
            <Input
              defaultValue={selectedCompany.email}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setSelectedCompany(old => {
                  return { ...old, email: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Hoofdbedrijf'
          >
            <Button onClick={() => { setShowCompanySelector(true) }}>{selectedCompany.parentCompany ? selectedCompany.parentCompany.name : 'Selecteer'}</Button>
            <Modal
              visible={showCompanySelector}
              width={720}
              footer={null}
              cancelText='Annuleren'
              onCancel={() => {
                setShowCompanySelector(false);
              }}
              destroyOnClose={true}>
              <CompanySearch
                showHidden={true}
                settingsSource={true}
                callback={comp => {
                  // console.log(comp);
                  setSelectedCompany(old => {
                    return { ...old, company_parent_fk: comp.id, parentCompany: { id: comp.id, name: comp.name } }
                  })
                  setShowCompanySelector(false);
                }} />
            </Modal>
          </Form.Item>
          <Form.Item
            label='Customer Id'
          >
            <Input
              defaultValue={selectedCompany.customer_id}
              onChange={e => {
                const val = DOMPurify.sanitize(e.target.value);
                setSelectedCompany(old => {
                  return { ...old, customer_id: val };
                })
              }}
            />
          </Form.Item>
          {/* <Form.Item>
            <Checkbox
              disabled={selectedCompany.id =='new'? false : true}
              defaultChecked={makeSheet}
              onChange={e => {
                const val = e.target.checked;
                setMakeSheet(val);
              }}
            >calculation sheet aanmaken?</Checkbox>
          </Form.Item> */}
          {/* style={{display: (selectedCompany.calculation_sheet? 'none': 'block')}} Style of Form.item below this line */}
          {selectedCompany.id != 'new' && (
            <>
              <Form.Item
                label='Calculatiesheet genereren'
                style={{ display: checkCanCreateSheet(selectedCompany) ? 'block' : 'none' }}
              >
                <Button
                  onClick={async () => {
                    // onSave = (company,             originalFields,  originalCostcenters, originalStatutes, regenerateSheetBool)
                    onSave({ ...selectedCompany }, null, null, null, true);
                  }}
                >
                  Genereer sheet
                </Button>
              </Form.Item>
              <Form.Item
                label='Calculatie sheet'
                style={{ display: selectedCompany.calculation_sheet ? 'block' : 'none' }}>
                <Button
                  onClick={() => window.open("https://docs.google.com/spreadsheets/d/" + selectedCompany.calculation_sheet + "/edit#gid=0", "_blank")}
                  disabled={!selectedCompany.calculation_sheet}
                >
                  Ga naar calculation sheet
                </Button>
                <Popconfirm
                  title="Calculatie sheet verwijderen?"
                  onConfirm={() => {
                    setClearSheetLoading(true);
                    deleteCompanyCalculationSheet({ variables: { id: selectedCompany.id } })
                      .then(res => {
                        //setSelectedCompany(old=>{return {...old, calculation_sheet: null}});
                        onSave({ ...selectedCompany, calculation_sheet: null }, originalFields, originalCostcenters, originalStatutes, false);
                        message.info("Sheet succesvol verwijderd.");
                      })
                      .catch(err => {
                        message.error("Er ging iets mis tijdens het verwijderen van de sheet.");
                      }).finally(() => {
                        setClearSheetLoading(false);
                      });
                  }}
                  okText="Ja"
                  cancelText="Nee"
                >
                  <Button
                    shape='circle'
                    type='danger'
                    disabled={clearSheetLoading}
                    loading={clearSheetLoading}
                    style={{ marginLeft: '10px' }}
                  ><Icon type="delete" /></Button>
                </Popconfirm>
              </Form.Item>
              <Form.Item
                label='Folder ID'>
                <Button
                  onClick={() => window.open("https://drive.google.com/drive/folders/" + selectedCompany.folder_id, "_blank")}
                  disabled={!selectedCompany.folder_id}
                >
                  Ga naar de bedrijfsfolder
                </Button>
              </Form.Item>
              <Form.Item
                label='Employee access code'>
                <Button
                  style={{ width: "5%", float: "left", maxWidth: "50px" }}
                  onClick={() => {
                    accessCodeInputRef.current.select();
                    document.execCommand('copy');
                    message.info("Gekopieerd naar het klembord.");
                  }}
                ><Icon type='copy' /></Button>
                <Input
                  style={{ width: "93%", float: "right" }}
                  ref={accessCodeInputRef}
                  placeholder={selectedCompany.employee_access_code ? 'Code voor employee access' : 'Wordt bij aanmaak automatisch ingevuld'}
                  defaultValue={selectedCompany.employee_access_code}
                  value={selectedCompany.employee_access_code}
                  onKeyPressCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Checkbox
              defaultChecked={selectedCompany.fip}
              onChange={e => {
                const val = e.target.checked;
                setSelectedCompany(old => {
                  return { ...old, fip: val };
                })
              }}
            ><b>FIP</b></Checkbox>
            <Checkbox
              defaultChecked={selectedCompany.hide}
              onChange={e => {
                const val = e.target.checked;
                setSelectedCompany(old => {
                  return { ...old, hide: val };
                })
              }}
            >Hide</Checkbox>
            <Popconfirm
              title={`${selectedCompany.name} verwijderen?`}
              cancelText="Annuleren"
              okText="Verwijderen"
              onConfirm={() => {
                setCompanyDeletionLoading(true);
                deleteCompany({ variables: { id: selectedCompany.id } }).then(() => {
                  message.info(`${selectedCompany.name} succesvol verwijderd.`);
                  onSave(selectedCompany, null, null, null, null, true);
                }).catch(err => {
                  const errMsg = (!!err && !!err.graphQLErrors && !!err.graphQLErrors.length > 0) ? err.graphQLErrors[0].message : "Er ging iets mis bij het verwijderen.";
                  message.warning(errMsg);
                }).finally(() => setCompanyDeletionLoading(false));
              }}>
              <Button
                type='danger'
                disabled={companyDeletionLoading}
                loading={companyDeletionLoading}
                style={{ marginLeft: 10 }}>
                Bedrijf verwijderen</Button>
            </Popconfirm>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required={true}
            label='Taal'
          >
            <Select
              defaultValue={selectedCompany.language_fk}
              onSelect={val => {
                setSelectedCompany(old => {
                  return { ...old, language_fk: val };
                })
              }}
            >
              {allLanguages.map(el => {
                return (
                  <Option
                    value={el.Id}
                    key={el.Id}
                  >
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            required={true}
            label='Template Variant'
          >
            <Select
              defaultValue={selectedCompany.variant_fk}
              onSelect={val => {
                setSelectedCompany(old => {
                  return { ...old, variant_fk: val };
                })
              }}
            >
              {allVariants.map((el, index) => {
                return (
                  <Option
                    value={el.Id}
                    key={'variant-' + el.Id}
                  >{el.name}</Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label='Memo Openbaar'
          >
            <TextArea rows={3} autoSize={true}
              defaultValue={selectedCompany.comment_public}
              onChange={e => {
                const val = e.target.value;
                setSelectedCompany(old => {
                  return { ...old, comment_public: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Memo B2Bike'
          >
            <TextArea rows={3} autoSize={true}
              defaultValue={selectedCompany.comment_private}
              onChange={e => {
                const val = e.target.value;
                setSelectedCompany(old => {
                  return { ...old, comment_private: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Clausule Calculatie'
          >
            <TextArea rows={3} autoSize={true}
              defaultValue={selectedCompany.disclaimer_calculation}
              onChange={e => {
                const val = e.target.value;
                setSelectedCompany(old => {
                  return { ...old, disclaimer_calculation: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Clausule Ontvangstbewijs'
          >
            <TextArea rows={3} autoSize={true}
              defaultValue={selectedCompany.disclaimer_receipt}
              onChange={e => {
                const val = e.target.value;
                setSelectedCompany(old => {
                  return { ...old, disclaimer_receipt: val };
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Info'
          >
            <JoditEditor
              value={selectedCompany.info || ''}
              onChange={val => setSelectedCompany(old => {
                return { ...old, info: val };
              })}
            />
            {/* <TextArea rows={3} autoSize={true}
              defaultValue={selectedCompany.info}
            /> */}
          </Form.Item>
          <FileUploader acceptedFileTypes={['image/*']} customDocType={{ name: 'Logo Bedrijf' }} locked={true} onFileChanged={val => {
            if (!!val.file) {
              // console.log(val.file);
              if (Object.keys(val.file).length > 0 && val.file.constructor === Object) {
                // console.log(val.file.getFileEncodeBase64String());
                setLogoFile(val.file.getFileEncodeBase64String());
              }
            }
          }} />
          {selectedCompany.logo_url && <img alt="logo" src={selectedCompany.logo_url} style={{ maxWidth: '256px', maxHeight: '256px' }} />}
          <Popconfirm
            title="Logo verwijderen?"
            onConfirm={() => {
              setLogoFile(null);
              onSave({ ...selectedCompany, logo_url: null }, originalFields, originalCostcenters, originalStatutes, false);
              message.info("Logo succesvol verwijderd.");
            }}
            okText="Ja"
            cancelText="Nee"
          >
            <Button
              shape='circle'
              type='danger'
              style={{ marginLeft: '10px' }}
              hidden={!selectedCompany.logo_url}
            ><Icon type="delete" /></Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form>),
    Velden: (<FieldTab
      company={selectedCompany}
      dataCallback={data => {
        if (originalFields === null) {
          setOriginalFields(data);
        }
        setSelectedCompany(old => {
          return { ...old, fields: data };
        })
      }}
    />),
    Kostenplaatsen: (<CostCenterTab
      company={selectedCompany}
      dataCallback={data => {
        if (originalCostcenters === null) {
          setOriginalCostcenters(data);
        }
        setSelectedCompany(old => {
          return { ...old, costcenters: data }
        })
      }}
    />),
    Statuten: (
      <StatutesTab
        company={selectedCompany}
        dataCallback={data => {
          if (originalStatutes === null) {
            setOriginalStatutes(data);
          }
          setSelectedCompany(old => {
            return { ...old, employeeStatutes: data }
          })
        }}
      />
    ),
    VerplichteVelden: (
      <RequiredFieldsTab
        company={selectedCompany}
        dataCallback={data => setSelectedCompany(old => {return {...old, employee_required_fields: data}})}
        />
    )
  };

  useEffect(() => {
    // console.log('CompanySettings - selectedCompany: ', selectedCompany);
    setLogoFile(null);
  }, [selectedCompany]);

  if (variantLoading) {
    return (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    );
  }

  if (langError || variantError) {
    console.error(langError || variantError)
    return (<div><h2>Er ging iets mis</h2></div>);
  }

  if (saving) {
    return (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    );
  }

  if (!!allVariants && !!allLanguages && !saving) {
    // console.log('CompanySettings - invoiceTargetData: ', invoiceTargetData);
    // console.log('CompanySettings - allVariants: ', allVariants);
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            setSelectedCompany({ ...companyModel });
            setOriginalFields(null);
            setOriginalCostcenters(null);
            setOriginalStatutes(null);
            setShowCompanyMutationModal(true);
          }}>
          <Icon type='plus' /> Nieuw
        </Button>
        {companyList}
        <Modal
          width={'90vw'}
          visible={showCompanyMutationModal}
          destroyOnClose={true}
          onCancel={() => setShowCompanyMutationModal(false)}
          cancelText='Annuleren'
          onOk={() => {
            // console.log('companySettings - ready to submit: ', selectedCompany);
            if (!saving)
              onSave({ ...selectedCompany }, originalFields, originalCostcenters, originalStatutes, false);
          }}
        >
          <Card
            tabList={tabList}
            activeTabKey={currentTab}
            onTabChange={key => {
              setCurrentTab(key);
            }}
          >
            {tabContents[currentTab]}
          </Card>
        </Modal>
        <Modal
          width={'50vw'}
          visible={showIncoiceTargetSelector}
          destroyOnClose={true}
          footer={null}
          cancelText='Annuleren'
          onCancel={() => setShowInvoiceTargetSelector(false)}>
          <InvoiceTargetSelector callback={enterprise => {
            setSelectedCompany(old => {
              setShowInvoiceTargetSelector(false);
              return ({ ...old, invoice_target: enterprise, invoice_target_fk: enterprise.id });
            })
          }} />
        </Modal>
      </div>
    );
  }

  return ('Er ging iets mis')
};

const FieldTab = ({ company, dataCallback }) => {
  const [fields, setFields] = useState(null);
  const [selectedFieldForDeletion, setSelectedFieldForDeletion] = useState();
  const [selectedFieldValueForDeletion, setSelectedFieldValueForDeletion] = useState();

  const [showFieldTypeSelector, setShowFieldTypeSelector] = useState(false);
  // const fieldTypeSelect = useRef(null)

  const [deleteField, { loading: deleteFieldLoading, error: deleteFieldError }] = useMutation(QUERIES.DELETE_FIELD);
  const [deleteFieldValue, { loading: deleteFieldValueLoading, error: deleteFieldValueError }] = useMutation(QUERIES.DELETE_FIELD_VALUE);
  const [updateFieldValue, { loading: updateFieldValueLoading, error: updateFieldValueError }] = useMutation(QUERIES.UPDATE_FIELD_VALUE)

  const onQueryComplete = res => {
    if (!company.fields && !!res.company) {
      setFields(res.company.fields);
    }
    else if (!!company.fields) {
      setFields(company.fields);
    }
    else {
      setFields([]);
    }
  }

  const fieldModel = {
    name: "",
    company_fk: company.id,
    description: "",
    field_type_fk: 1,
    index: 0
  }

  useEffect(() => {
    if (!!fields) {
      fields.sort((a, b) => a.index - b.index);
    }
    dataCallback(fields);
  }, [fields]);

  const { error, loading, data, refetch } = useQuery(QUERIES.COMPANY_FIELDS_AND_FIELDS_TYPES, {
    variables: { id: company.id },
    fetchPolicy: 'network-only',
    onCompleted: res => onQueryComplete(res)
  });

  useEffect(() => {
    if (company.id === 'new' && !!company.fields) {
      if (company.fields.length > 0) {
        setFields(company.fields);
      }
      else {
        company.fields = null;
      }
    }
    if (!!data && company.id === 'new' && !company.fields && !fields) {
      company.fields = [];
      data.fieldTypes.forEach(el => {
        if (el.is_default) {
          // defaultFields[el.code] = el;
          let desc = el.name;
          switch (el.code) {
            case 'BIKE_VALUE':
              desc = 'Incl BTW. Toebehoren zoals stuurlint, pedalen, ART2 slot, ...';
              break
            case 'LOOSE_PARTS_VALUE':
              desc = 'Incl BTW. Accessoires zoals kinderstoel, GPS, fietstas, helm, drinkbus, ...';
              break;
            case 'CLOTHING':
              desc = 'Incl BTW. Kledij zoals regenkledij, handschoenen, schoenen, ...';
              break;

          }
          company.fields.push({ ...fieldModel, name: el.name, description: desc, field_type_fk: el.id, fieldType: el.code, isNew: true, index: getDefaultFieldIndex(el.code) });
        }
      });
      //console.log(company.fields);
      setFields(company.fields);
      // dataCallback(company.fields);
    }
  }, [data]);

  const getDefaultFieldIndex = fieldCode => {
    switch (fieldCode) {
      case 'BIKE_DESCRIPTION':
        return 1;
      case 'BIKE_VALUE':
        return 2;
      case 'PARTS_DESCRIPTION':
        return 3;
      case 'LOOSE_PARTS_VALUE':
        return 4;
      case 'CLOTHING_DESCRIPTION':
        return 5;
      case 'CLOTHING':
        return 6;
      case 'MAINTENANCE':
        return 7;
      default:
        return null;
    }
  }

  if (fields && data) {
    return (
      <div>
        <Table
          dataSource={fields}
          rowKey={record => fields.indexOf(record)}
        >
          <Column
            title="Naam"
            dataIndex="name"
            render={(text, record) => {
              return (<Input
                key={`field_name_${record.index}`}
                defaultValue={record.name}
                // value={record.name}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setFields(old => {
                    old[old.indexOf(record)].name = val;
                    old[old.indexOf(record)].updated = true;
                    return [...old];
                  });
                }}
              />)
            }}
          />
          <Column
            title="Omschrijving"
            dataIndex="description"
            render={(text, record) => {
              return (<Input
                defaultValue={record.description}
                key={`field_description_${record.index}`}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  // record.name = val;
                  setFields(old => {
                    old[old.indexOf(record)].description = val;
                    old[old.indexOf(record)].updated = true;
                    return [...old];
                  });
                }}
              />)
            }}
          />
          <Column
            title="Index"
            dataIndex="index"
            render={(text, record) => {
              return (<InputNumber
                defaultValue={record.index}
                key={`field_index_${record.index}`}
                onChange={val => {
                  //const val = e.target.value;
                  // record.name = val;
                  if (!!val) {
                    setFields(old => {
                      old[old.indexOf(record)].index = DOMPurify.sanitize(val);
                      old[old.indexOf(record)].updated = true;
                      old.sort((a, b) => a.index - b.index)
                      return [...old];
                    });
                  }
                }}
              />)
            }}
          />
          <Column
            title="Opties"
            dataIndex=""
            render={(text, record) => {
              // console.log(record.is_multivalue)
              if (record.fieldType.is_multivalue) {
                return (<List
                  dataSource={record.fieldValues}
                  renderItem={(item, index) => {
                    return (<List.Item>
                      <Input
                        id={item.id ? `fv-${item.id}` : `fv-${item.value}`}
                        style={{ width: '256px' }}
                        value={item.value}
                        // defaultValue={item.value}
                        onChange={e => {
                          // if (!item.id) {
                          // is a new item
                          if (item.id) {
                            item.update = true;
                          }
                          const val = DOMPurify.sanitize(e.target.value);
                          setFields(old => {
                            const currentField = { ...old[old.indexOf(record)] }
                            currentField.fieldValues[index].value = val;
                            const updatedFields = [...old];
                            updatedFields[updatedFields.indexOf(record)] = currentField;
                            return updatedFields;
                          })
                        }} />
                      <Tooltip
                        placement="topLeft"
                        title={"Optie verwijderen"}
                        mouseEnterDelay={1.5}
                      >
                        <Button
                          disabled={false}
                          style={{ marginLeft: '8px' }}
                          size='small'
                          shape='circle-outline'
                          type='danger'
                          onClick={async () => {
                            setSelectedFieldValueForDeletion(item.id)
                            const itemCopy = { ...item };
                            if (!!item.id) {
                              const deletedId = await deleteFieldValue({ variables: { id: item.id } });
                            }
                            setFields(old => {
                              const currentField = { ...old[old.indexOf(record)] }
                              currentField.fieldValues.splice(index, 1);
                              const updatedFields = [...old];
                              updatedFields[updatedFields.indexOf(record)] = currentField;
                              return updatedFields;
                            })
                            // record.fieldValues.splice(record.fieldValues.indexOf(item), 1);
                            // record.updated = true
                            // if (!record.valuesToDelete) {
                            //   record.valuesToDelete = [];
                            // }
                            // record.valuesToDelete.push(item);
                            // dataCallback(company.fields);
                            message.info(`Optie '${itemCopy.value}' is verwijderd`);
                          }}
                        >
                          <Icon type={deleteFieldValueLoading && item.id == selectedFieldValueForDeletion ? 'loading' : 'delete'} />
                        </Button>
                      </Tooltip>
                      <Button
                        hidden={!(!!item.id && !!item.update)}
                        style={{ marginLeft: '8px' }}
                        size='small'
                        shape='circle-outline'
                        onClick={async () => {
                          const { __typename, update, ...fvForUpdate } = item
                          // fvForUpdate.value = document.getElementById(`fv-${item.id}`).value;
                          try {
                            const updateRes = await updateFieldValue({ variables: { fieldValue: fvForUpdate } });
                            if (!!updateRes.data.updateFieldValue) {
                              setFields(old => {
                                const currentField = { ...old[old.indexOf(record)] }
                                currentField.fieldValues[index].update = false;
                                const updatedFields = [...old];
                                updatedFields[updatedFields.indexOf(record)] = currentField;
                                return updatedFields;
                              })
                            }
                            message.info(`Optie '${item.value}' is geüpdatet`);
                          }
                          catch (err) {
                            message.error(`Er ging iets mis...`);
                          }
                        }}
                      >
                        <Icon type={updateFieldValueLoading ? 'loading' : 'save'} />
                      </Button>
                    </List.Item>)
                  }}
                  loadMore={(
                    // FIXME: I can't get this to clear after submit...
                    // <Input
                    //   placeholder='Enter om toe te voegen'
                    //   onPressEnter={e => {
                    //     console.log(e.currentTarget);
                    //     const val = {
                    //       value: e.target.value,
                    //       field_fk: record.id || null
                    //     };
                    //     if (record.fieldValues) {
                    //       record.fieldValues.push(val)
                    //     }
                    //     else {
                    //       record.fieldValues = [val];
                    //     }
                    //     record.updated = true
                    //     dataCallback(company.fields);
                    //   }}
                    // />
                    <FieldValueInput
                      callback={name => {
                        const val = {
                          value: name,
                          field_fk: record.id || null
                        };
                        if (record.fieldValues) {
                          record.fieldValues.push(val)
                        }
                        else {
                          record.fieldValues = [val];
                        }
                        record.updated = true
                        dataCallback(company.fields);
                      }}
                    />
                  )
                  }
                >
                </List>)
              }
            }}
          />
          <Column
            title=""
            dataIndex="delete"
            render={(text, record) => {
              return (<Tooltip
                placement="topLeft"
                title={record.hasQuotationFields ? "Heeft reeds quotation fields" : "Veld verwijderen"}
                mouseEnterDelay={1.5}
              >
                <Button
                  disabled={!record.id || record.hasQuotationFields}
                  onClick={async () => {
                    setSelectedFieldForDeletion(record.id)
                    const deletedId = await deleteField({ variables: { id: record.id } });
                    const newFields = [...fields];
                    newFields.splice(fields.indexOf(record), 1);
                    setFields(newFields);
                    message.info(`Veld ${record.name} is verwijderd`);
                    // fields.splice(fields.indexOf(record), 1);
                    // const { fields, ...rest } = company;
                    // fields.splice(fields.indexOf(record), 1);
                    // dataCallback(fields);
                  }}
                  type="danger"
                  shape="circle"
                ><Icon type={deleteFieldLoading && record.id == selectedFieldForDeletion ? 'loading' : 'delete'} />
                </Button>
              </Tooltip>)
            }}
          />
        </Table>
        <Button onClick={() => {
          setShowFieldTypeSelector(true);
        }}><Icon type='plus' /></Button>

        <Modal
          visible={showFieldTypeSelector}
          destroyOnClose={true}
          title='Veld type'
          footer={null}
          cancelText='Annuleren'
          onCancel={() => setShowFieldTypeSelector(false)}
        // onOk={() => {
        //   // console.log(fieldTypeSelect.current)
        //   // company.fields.push({...fieldModel, fieldType: {...fieldType}});
        //   dataCallback(company.fields);
        //   setShowFieldTypeSelector(false);
        // }}
        // onCancel={() => {setShowFieldTypeSelector(false)}}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            onSelect={val => {
              const ft = data.fieldTypes.find(el => el.id === val);
              const newIndex = company.fields ? company.fields.length + 1 : 0;
              company.fields.push({ ...fieldModel, name: ft.name, field_type_fk: val, fieldType: ft, isNew: true, index: newIndex });
              dataCallback(company.fields);
              setShowFieldTypeSelector(false);

            }}
          // ref={fieldTypeSelect}
          >
            {data.fieldTypes.sort((a, b) => b.name - a.name).map((el) => {
              return (
                <Option
                  value={el.id}
                  key={el.id}
                >{el.name}</Option>
              )
            })}
          </Select>
        </Modal>
      </div>
    );
  }

  if (error) {
    console.error(error);
    return 'Er ging iets mis';
  }
  return (
    <Row type='flex' justify='center'>
      <Spin size="large" style={{ marginTop: 128 }} />
    </Row>
  )
}

const RequiredFieldsTab = ({ company, dataCallback }) => {
  const { strings } = useI18nContext();
  const employeeFields = [
    {label: 'Titel', field: 'employee_title_fk'},
    {label: 'tel', field: 'tel'},
    {label: 'employee number', field: 'employee_number'},
    {label: 'national register', field: 'national_register'},
    {label: 'comment public', field: 'comment_public'},
    {label: 'drivers license date', field: 'drivers_license_date'}
  ];
  const [companyRequiredFields, setCompanyRequiredFields] = useState(company.employee_required_fields ? JSON.parse(company.employee_required_fields) : []);

  useEffect(() => {
    dataCallback(JSON.stringify(companyRequiredFields));
  }, [companyRequiredFields])

  return (
    <div>
      <Table dataSource={employeeFields} rowKey={record => `rf-${record.field}`} key={record => `rf-${record.field}`}>
        <Column key={record => `${record.field}-field`} title='Veld' render={(record, index) => (<p>{getLabel(record.label, true, strings)}</p>)} />
        <Column key={record => `${record.field}-req`} title='Verplicht?' render={(record, index) => (
          <Checkbox checked={companyRequiredFields.includes(record.field)}
          onChange={(e) => {
            const val = e.target.checked;
            if (!!val && !companyRequiredFields.includes(record.field)) {
              setCompanyRequiredFields(old => [...old, record.field]);
            }
            else if (!val && companyRequiredFields.includes(record.field)) {
              setCompanyRequiredFields(old => old.filter(el => el !== record.field))
            }
          }}
          />)} />
      </Table>
    </div>
  )
}

const CostCenterTab = ({ company, dataCallback }) => {
  const [costcenters, setCostcenters] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newCostcenter, setNewCostcenter] = useState({ name: '', company_fk: company.id })

  useEffect(() => {
    if (company.costcenters) {
      setCostcenters(company.costcenters);
    }
  }, []);

  useEffect(() => {
    if (costcenters) {
      dataCallback(costcenters);
    }
  }, [costcenters])

  const { error, loading, data } = useQuery(QUERIES.COSTCENTERS_FOR_COMPANY, {
    variables: { id: company.id },
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (!company.costcenters) {
        setCostcenters(res.company.costcenters);
        dataCallback(costcenters);
      }
    }
  });

  if (error) {
    return (<h4>Er ging iets mis</h4>)
  }

  if (!!costcenters && data) {
    return (
      <div>
        <Table
          dataSource={costcenters}
          rowKey={record => costcenters.indexOf(record)}
        >
          <Column
            title='Naam'
            dataIndex="name"
            render={(text, record) => (record.name)}
          />
          <Column
            dataIndex="delete"
            render={(text, record) => (<Button type="danger"
              shape="circle"
              onClick={() => {
                const list = [...costcenters];
                list.splice(list.indexOf(record), 1);
                setCostcenters(list);
                //  dataCallback(list);
                // console.log(costcenters.indexOf(record), costcenters[costcenters.indexOf(record)])
              }}
            ><Icon type="delete" /></Button>)}
          />
        </Table>
        <Button onClick={() => {
          setShowAddModal(true);
        }}><Icon type='plus' /></Button>
        <Modal
          title='Nieuwe kostenplaats'
          visible={showAddModal}
          destroyOnClose={true}
          cancelText='Annuleren'
          onCancel={() => {
            setShowAddModal(false);
            setNewCostcenter({ name: '', company_fk: company.id });
          }}
          onOk={() => {
            setCostcenters(old => {
              old.push(newCostcenter);
              return old;
            });
            setNewCostcenter({ name: '', company_fk: company.id });
            setShowAddModal(false);
          }}
        >
          <Input
            onChange={e => {
              const val = DOMPurify.sanitize(e.target.value);
              setNewCostcenter(old => ({ ...old, name: val }));
            }}
          />
        </Modal>
      </div>
    )
  }

  return (
    <Row type='flex' justify='center'>
      <Spin size="large" style={{ marginTop: 128 }} />
    </Row>
  )
}

const StatutesTab = ({ company, dataCallback }) => {

  const [companyStatutes, setCompanyStatutes] = useState(null);

  useEffect(() => {
    if (company.employeeStatutes) {
      setCompanyStatutes([...company.employeeStatutes]);
    }
  }, []);

  useEffect(() => {
    dataCallback(companyStatutes);
  }, [companyStatutes])

  const { error, loading, data } = useQuery(QUERIES.STATUTES_FOR_COMPANY_AND_STATUTES, {
    variables: { id: company.id, page: 1, size: 999 },
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (!company.employeeStatutes && !!res.company.employeeStatutes) {
        const appendedStatutes = res.company.employeeStatutes.map(el => {
          const relationObject = (res.companyHasEmployeeStatutesByCompanyId.find(rel => {
            return rel.employee_statute_fk === el.id;
          }));
          return ({ ...el, relation_fk: relationObject.id });
        });
        setCompanyStatutes(appendedStatutes);
      }
    }
  })

  if (data && companyStatutes) {
    return (
      <List
        dataSource={data.employeeStatutes}
        rowKey={item => item.id}
        renderItem={item => (
          <List.Item>
            <Checkbox
              style={{ marginRight: '32px' }}
              defaultChecked={!!companyStatutes.find(el => el.code === item.code)}
              onChange={e => {
                const checked = e.target.checked;
                if (checked) {
                  setCompanyStatutes(old => {
                    return [...old, item];
                  })
                }
                else {
                  let newStatutes = [...companyStatutes];
                  for (const [i, value] of companyStatutes.entries()) {
                    if (value.id === item.id) {
                      newStatutes.splice(i, 1);
                      setCompanyStatutes(newStatutes);
                    }
                  }
                }
              }}
            />
            <h4>{item.name}</h4>
          </List.Item>
        )}
      />
    )
  }

  if (error) {
    return (<h4>Er ging iets mis...</h4>)
  }

  return (
    <Row type='flex' justify='center'>
      <Spin size="large" style={{ marginTop: 128 }} />
    </Row>
  )
};

const InvoiceTargetSelector = ({ callback }) => {
  const [term, setTerm] = useState(null);
  let content = (<p></p>)
  const { error, loading, data } = useQuery(QUERIES.INVOICE_TARGETS_BY_NAME, {
    variables: { filter: term?{name: term}: {} },
    fetchPolicy: 'no-cache',
  });

  if (error) {
    console.error(error);
    content = (<h4>Er ging iets mis...</h4>);
  }

  if (loading) {
    content = (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ marginTop: 128 }} />
      </Row>
    )
  }

  if (data) {
    content = (
      <List
        dataSource={data.invoiceTargets}
        rowKey={item => item.id}
        renderItem={item => (
          <List.Item>
            <Button
              type='link'
              onClick={() => {
                callback(item);
              }}
            >
              {item.name}
            </Button>
          </List.Item>
        )}
      />
    );
  }

  return (
    <div>
      <Input.Search
        placeholder='Naam facturatiedoel'
        onSearch={val => {
          if (!!val) {
            setTerm(val);
          }
          else {
            setTerm('a');
          }
        }}
      />
      {content}
    </div>
  )

}

const FieldValueInput = ({ callback }) => {
  const [text, setText] = useState('');
  const onSubmit = () => {
    if (!!text) {
      callback(text);
      setText('');
    }
  }

  return (
    <Row type='flex' gutter={8} style={{ marginTop: '8px' }}>
      <Col span={16}>
        <Input
          value={text}
          onChange={e => {
            const val = DOMPurify.sanitize(e.target.value);
            setText(val);
          }}
          onPressEnter={() => {
            onSubmit();
          }}
        />
      </Col>
      <Col span={4}>
        <Button
          onClick={() => {
            onSubmit();
          }}
        >OK</Button>
      </Col>
    </Row>
  );
}



export default CompanySettings;