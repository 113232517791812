import React, { createContext, useContext, useReducer } from 'react';
import Bugsnag from '@bugsnag/js'

export const StateContext = createContext();

export const backofficeDealer = { name: 'BACKOFFICE', id: -1 };

// Initial state and reducer for StateProvider (app wide global state).
export const initialState = {
  company: null,
  requestedRoute: '/',
  allDealers: null,
  dealerIndex: 0,
  currentDealer: null,
  currentUser: null,
  userDetails: { dealerIds: [] },
  userDealers: [],
  token: null,
  tempQuotation: null,
  tempQuotationFields: null,
  idToken: null,
  currentEmployersPage: 'inbox'
};

export const reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case 'logout':
      return { ...initialState }
    case 'changeCompany':
      return {
        ...state,
        company: action.newCompany
      };
    case 'changeRequestedRoute':
      return {
        ...state,
        requestedRoute: action.newRoute
      }
    case 'setAllDealers':
      return {
        ...state,
        allDealers: action.dealers
      }
    case 'setUserDealers':
      return {
        ...state,
        userDealers: action.dealers
      }
    case 'setDealerIndex':
      localStorage.setItem('dealerIndex', action.newIndex);
      return {
        ...state,
        dealerIndex: action.newIndex
      }
    case 'setCurrentDealer':
      return {
        ...state,
        currentDealer: action.newDealer
      }
    case 'setUserDetails':
      return {
        ...state,
        userDetails: action.userDetails
      }
    case 'setToken':
      localStorage.setItem('sessionToken', action.newToken);
      return {
        ...state,
        token: action.newToken
      }
    case 'setIdToken':
      return {
        ...state,
        idToken: action.newToken
      }
    case 'setCurrentUser':
      Bugsnag.addOnError(event => {
        event.setUser({email: action.currentUser.email});
      });
      return {
        ...state,
        currentUser: action.currentUser
      }
    case 'setTempQuotation':
      return {
        ...state,
        tempQuotation: action.tempQuotation
      }
    case 'setTempQuotationFields':
      return {
        ...state,
        tempQuotationFields: action.tempQuotationFields
      }
    case 'setCurrentEmployersPage':
      return {
        ...state,
        currentEmployersPage: action.page
      }
    default:
      return state;
  }
};

// export const StateProvider = ({reducer, initialState, children}) =>(
export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);