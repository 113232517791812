import React, { useState, useEffect } from 'react'
import { Table, Form } from 'antd'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

import './table.css'
import {
  overwriteColumnsProps,
  sendEmail,
  sendFBUsersEmail,
  getSortOrder,
  replaceValuesInStringByObject,
  templatesLicenseNumberSignedup,
  templatesLicenseNumberSignedupSubject,
} from './utils'
import { columns } from './tableConfig'
import { useStateValue } from '../stateProvider/stateProvider'
import { QUERIES } from '../../api/queries'

import Error from './components/wrappedComponents/error'
import ModalForm from './components/wrappedComponents/modalForm'
import EditableCell from './components/editableCell'
import Alert from './components/wrappedComponents/alert'
import Pagination from './components/pagination'
import { openNotificationWithIcon } from './components/wrappedComponents/notification'
import Button from './components/wrappedComponents/button'
import Modal from './components/wrappedComponents/modal'
import CopyRow from './components/wrappedComponents/copyRow'

const initialQueryVariables = (currentDealer) => ({
  id: currentDealer?.name ?? null,
  page: 1,
  size: 50,
  filter: { license_plate_request_statuses: ['1', '2', '3', '4', '7'] },
})

const isNotPurchaseTarget = (invoiceTargetTLIdV2) => {
  const listOfInvoiceTargetsToIgnoreMail = [
    "95ebbec7-8297-06ee-b76d-eba69b146b1d", //Alphabet
    "2c42df82-525c-069b-af7d-38c462451b9d", //B2Bike - Alphabet
    "4a182c11-bee3-0677-9068-faf3f4146a80", //Axus
    "21b1945c-1b98-00b2-b968-d864c3146b7f", //B2Bike lease
    "24934579-d7ee-070c-a461-0810a5b9abee", //Dieteren Lease
    "d1d83194-0152-0965-ad6d-dae63b146adb", //JenT Autolease NV
    "923cea14-549e-043d-9f7f-c8da6301a5e5", //B2Bike - Europcar
    "3c08032a-8a41-08eb-a372-27c6b301a5d9" //B2Bike - Athlon
  ]

  console.log(listOfInvoiceTargetsToIgnoreMail.includes(invoiceTargetTLIdV2));

  return listOfInvoiceTargetsToIgnoreMail.includes(invoiceTargetTLIdV2);
}

export const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

const LicensePlates = (mainProps) => {
  const modalFieldKey = 'comment'
  // Everything of state
  const [{ userDetails, token, currentDealer }] = useStateValue()
  const [isModalFormVisible, setIsModalFormVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [bikeInfo, setBikeInfo] = useState({})
  const [rowData, setRowData] = useState({})
  const [licensePlateRequests, setLicensePlateRequests] = useState([])
  const [
    licensePlateRegistrationTypes,
    setLicensePlateRegistrationTypes,
  ] = useState([])
  const [
    licensePlateRequestStatuses,
    setLicensePlateRequestStatuses,
  ] = useState([])
  const [queryVariables, setQueryVariables] = useState(
    initialQueryVariables(currentDealer)
  )
  const [previousLicensePlateStatus, setPreviousLicensePlateStatus] = useState(
    []
  )

  // ref's
  let modalRef = React.useRef(null)

  // Queries
  const [
    fetchLicensePlateRequestsInfo,
    { loading, error: fetchLicensePlateRequestsInfoError },
  ] = useLazyQuery(QUERIES.LICENSE_PLATE_REQUESTS_INFO, {
    variables: queryVariables,
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      setLicensePlateRequests(res.licensePlateRequests)
      setLicensePlateRegistrationTypes(res.licensePlateRegistrationTypes)
      setLicensePlateRequestStatuses(res.licensePlateRequestStatuses)
      res?.licensePlateRequests?.map((element) => {
        const statusObject = {
          quotationId: element?.bike?.order?.quotation?.id,
          licenseNumber: element?.bike?.license_number,
        }
        setPreviousLicensePlateStatus((previousLicensePlateStatus) => [
          ...previousLicensePlateStatus,
          statusObject,
        ])
      })
    },
  })

  // Mutations
  const [updateBike, { error: updateBikeError }] = useMutation(
    QUERIES.UPDATE_BIKE
  )
  const [
    updateLicensePlateRequest,
    { error: updateLicensePlateRequestError },
  ] = useMutation(QUERIES.UPDATE_LICENSE_PLATE_REQUEST)

  useEffect(() => {
    fetchLicensePlateRequestsInfo()
  }, [])

  // errors or no authentication
  if (!userDetails.superUser) {
    return (
      <Error
        info="license plates view"
        error="Er is iets misgegaan met de authenticatie."
      />
    )
  }

  if (fetchLicensePlateRequestsInfoError) {
    return (
      <Error
        info="license plates fetching data"
        error={fetchLicensePlateRequestsInfoError}
      />
    )
  }

  const sendLicenseMail = async ({
    quotationId,
    emailDealer,
    licenseNumber,
    language,
    companyId,
    invoiceTargetTLIdV2
  }) => {
    if (
      quotationId &&
      emailDealer &&
      licenseNumber &&
      language &&
      companyId &&
      invoiceTargetTLIdV2
    ) {
      const config = (isDealer) => ({
        subject: replaceValuesInStringByObject({
          stringToReplace: templatesLicenseNumberSignedupSubject[language],
          dataToChange: {
            quotation_Id: quotationId,
          },
        }),
        html: replaceValuesInStringByObject({
          stringToReplace: templatesLicenseNumberSignedup(isDealer)[language],
          dataToChange: {
            quotation_Id: quotationId,
            license_number: licenseNumber,
          },
        }),
        headers: { authorization: token },
      })

      if (isNotPurchaseTarget(invoiceTargetTLIdV2)) {
        console.log('Sending to dealer')
        await sendEmail({
          to: emailDealer,
          ...config(true),
        })
      }
      else {
        console.log('Sending to employer emails')
        await sendFBUsersEmail({
          ...config(false),
          token,
          companyId,
        })
      }
    } else {
      openNotificationWithIcon({
        type: 'error',
        message:
          'Er ontbreken gegevens bij de verzending, probeer het later terug aub.',
        description: 'Error',
        duration: 0,
      })
    }
  }

  const handleSave = async (
    row,
    skipUpdateBike = false,
    commentSendToDealer = false
  ) => {
    setSaveLoading(true)

    if (commentSendToDealer) {
      row.license_plate_request_status_fk = '7' // status => In afwachting
    }

    const findPreviousQuotationObject = previousLicensePlateStatus?.find(
      (e) => e.quotationId === row?.bike?.order?.quotation?.id
    )

    // Check if license plate is changed
    if (
      findPreviousQuotationObject?.licenseNumber !== row?.bike?.license_number
    ) {
      const licenseMailObject = {
        quotationId: row?.bike?.order?.quotation?.id,
        emailDealer: row?.bike?.order?.quotation?.dealer?.email,
        licenseNumber: row?.bike?.license_number,
        language: row?.bike?.order?.quotation?.dealer?.language,
        companyId: row?.bike?.employee?.company?.id,
        invoiceTargetTLIdV2: row?.bike?.employee?.company?.invoice_target?.tl_id_v2,
      }
      row.license_plate_request_status_fk = '4' // status -> 'Nummerplaat aangevraagd'
      await sendLicenseMail(licenseMailObject) // Email wordt verzonden als status op 'nummerplaat aangevraagd' word verzet
    }

    if (skipUpdateBike === false) {
      // handle update for Bike request through license_plate_request
      try {
        await updateBike({
          variables: {
            bike: {
              id: row.bike.id,
              frame_number: row.bike.frame_number ?? undefined,
              control_number: row.bike.control_number ?? undefined,
              license_number: row.bike.license_number ?? undefined,
              barcode_x12: row.bike.barcode_x12 ?? null,
              order_fk: row.bike.order_fk ?? undefined,
            },
          },
        })
      } catch (err) {
        console.error(err)
        setSaveLoading(false)
        return
      }
    }

    const newRegistrationDate = new Date(Number(row.registration_date))
    const newLicensePlateSendToDealerDate = new Date(
      Number(row.license_plate_sent_to_dealer_date)
    )

    const handleDateWhenStatusIsUpdated = (statusCodes) =>
      statusCodes.includes(row.license_plate_request_status_fk)
        ? new Date().toLocaleDateString('zh-Hans-CN')
        : null

    // handle update for the license plate request
    // "zh-Hans-CN" -> The API expects it in this format "2023-03-30", otherwise it returns an error ...
    try {
      await updateLicensePlateRequest({
        variables: {
          licensePlateRequest: {
            id: row.id,
            registration_date: row.registration_date
              ? newRegistrationDate.toLocaleDateString('zh-Hans-CN')
              : handleDateWhenStatusIsUpdated(['4']), // status => Nummerplaat aangevraagd
            license_plate_sent_to_dealer_date: row.license_plate_sent_to_dealer_date
              ? newLicensePlateSendToDealerDate.toLocaleDateString('zh-Hans-CN')
              : handleDateWhenStatusIsUpdated(['5', '6']), // status => 5 -> Nummerplaat verzonden / 6 -> Aankoopdossier
            comment: row.comment,
            license_plate_registration_type_fk:
              row.license_plate_registration_type_fk,
            license_plate_request_status_fk:
              row.license_plate_request_status_fk,
          },
        },
      })
    } catch (err) {
      console.error(err)
      setSaveLoading(false)
      return
    }

    fetchLicensePlateRequestsInfo()

    if (!updateBikeError || !updateLicensePlateRequestError) {
      openNotificationWithIcon({
        type: 'success',
        message: 'De update was succesvol.',
      })
    }

    setSaveLoading(false)
  }

  const saveFormRef = (formRef) => {
    modalRef = formRef
  }

  const handleModalCancel = () => {
    setIsModalFormVisible(false)
  }

  const handleModalSave = (commentSendToDealer = false) => {
    const { form } = modalRef.props

    form.validateFields((err, values) => {
      if (err) {
        return
      }

      setIsModalFormVisible(false)
      handleSave({ ...rowData, ...values }, true, commentSendToDealer)
      form.resetFields()
    })
  }

  return (
    <>
      {(updateBikeError || updateLicensePlateRequestError) && (
        <Alert
          message="Er was een probleem met de update"
          type="error"
          description={
            updateBikeError?.message || updateLicensePlateRequestError?.message
          }
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <h1 style={{ margin: '10px 50px 10px 0' }}>License plates aanvragen</h1>
        <Pagination
          onPageChange={(page) =>
            setQueryVariables((old) => ({
              ...old,
              page,
            }))
          }
          onSizeChange={(size) =>
            setQueryVariables((old) => ({
              ...old,
              size,
            }))
          }
          onNextChange={() =>
            setQueryVariables((old) => ({
              ...old,
              page: old.page + 1,
            }))
          }
          onPreviousChange={() =>
            setQueryVariables((old) => ({
              ...old,
              page: old.page > 1 ? old.page - 1 : 1,
            }))
          }
          page={queryVariables.page}
          pageSize={queryVariables.size}
          currentPageLength={licensePlateRequests?.length ?? 0}
        />
      </div>
      <Table
        components={{
          body: {
            row: EditableFormRow,
            cell: EditableCell,
          },
        }}
        loading={loading || saveLoading || emailLoading}
        rowKey={(record) => record.id}
        pagination={false}
        columns={overwriteColumnsProps({
          columns: columns(
            {
              fetchTableData: () => fetchLicensePlateRequestsInfo(),
              emailLoading,
              setEmailLoading: (loading) => setEmailLoading(loading),
            },
            mainProps
          ),
          handleSave,
          menuItems: [
            ...licensePlateRegistrationTypes,
            ...licensePlateRequestStatuses,
          ],
          setIsCommentModalVisible: (props) => {
            setModalContent(props.rowData[props.dataIndex])

            if (props?.rowData) {
              setRowData(props.rowData)
            }

            if (props?.rowData?.bike) {
              setBikeInfo(props.rowData.bike)
            }

            const { form } = modalRef.props
            form.resetFields()

            setIsModalFormVisible(true)
          },
          handleSearch: (filter) => {
            setQueryVariables((old) => {
              return {
                ...old,
                filter: {
                  ...old.filter,
                  ...filter,
                },
                page: 1,
              }
            })

            fetchLicensePlateRequestsInfo()
          },
          resetFilter: (filterKey) => {
            setQueryVariables((old) => {
              if (filterKey) {
                delete old.filter[filterKey]
              }

              return old
            })

            fetchLicensePlateRequestsInfo()
          },
          filterVariables: queryVariables.filter,
          setIsUserInfoModalVisible: (props) => {
            setModalContent(props.rowData?.bike?.employee)

            setIsModalVisible(true)
          },
        })}
        dataSource={licensePlateRequests}
        className="table-striped-rows"
        bordered
        onChange={(_pagination, _filters, sorter, _extra) => {
          // handle sorting
          if (sorter?.column && sorter?.order) {
            setQueryVariables((old) => {
              return {
                ...old,
                sort: {
                  field: sorter?.columnKey,
                  direction: getSortOrder[sorter.order],
                },
              }
            })
          }

          fetchLicensePlateRequestsInfo()
        }}
      />
      <ModalForm
        title="Commentaar"
        formField={modalFieldKey}
        value={modalContent}
        onCancel={handleModalCancel}
        visible={isModalFormVisible}
        wrappedComponentRef={saveFormRef}
        footer={
          <>
            <Button
              key="cancel"
              onClick={() => {
                setIsModalFormVisible(false)
              }}
            >
              Annuleren
            </Button>
            <Button
              tooltipTitle="Update de commentaar's value en verzend het naar de dealer"
              key="sendToDealer"
              onClick={async () => {
                const quotationNumber =
                  bikeInfo?.order?.quotation?.quotation_number ??
                  `Q${bikeInfo?.order?.quotation?.id}`

                const employeeName =
                  bikeInfo?.employee?.name ??
                  `${bikeInfo?.employee?.first_name} ${bikeInfo?.employee?.last_name}`

                if (!bikeInfo.order?.quotation?.dealer?.email) {
                  console.error("There is no dealer's email found!")
                  openNotificationWithIcon({
                    type: 'error',
                    message: 'Er is geen email adres van de dealer gevonden!',
                    description:
                      'Zorg ervoor dat er een e-mailadres beschikbaar is voor de dealer.',
                    duration: 0,
                  })

                  return
                }

                handleModalSave(true)

                try {
                  await sendEmail({
                    to: bikeInfo.order.quotation.dealer.email,
                    subject: `B2Bike - Nummerplaat ${quotationNumber} - ${employeeName}`,
                    text:
                      document.getElementById('custom_modal_comment')?.value ??
                      undefined,
                    headers: { authorization: token },
                  })
                } catch (err) {
                  console.error(err)
                  openNotificationWithIcon({
                    type: 'error',
                    message:
                      'Er was een probleem met de verzending, probeer het later terug aub.',
                    description: err.message,
                    duration: 0,
                  })
                }
              }}
            >
              Verzend naar dealer
            </Button>
            <Button
              key="save"
              onClick={() => {
                handleModalSave(false)
                setIsModalFormVisible(false)
              }}
            >
              Update
            </Button>
          </>
        }
      />
      <Modal
        title="Werknemer's informatie"
        onCancel={() => {
          setIsModalVisible(false)
        }}
        visible={isModalVisible}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '30%' }}
            >
              <p>Naam:</p>
              <p>E-mail adres:</p>
              <p>Telefoonnummer:</p>
              <p>Adres:</p>
              <p>Geboorte datum:</p>
              <p>Rijksregisternummer:</p>
              <p>Moedertaal:</p>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '70%' }}
            >
              <CopyRow
                content={
                  modalContent?.name ??
                  `${modalContent?.first_name} ${modalContent?.last_name}`
                }
              />
              <CopyRow content={modalContent?.email} />
              <CopyRow content={modalContent?.tel} />
              <CopyRow
                content={
                  modalContent?.address ??
                  `${modalContent?.street} ${modalContent?.house_number} ${modalContent?.city}`
                }
              />
              <CopyRow
                content={
                  modalContent?.date_of_birth === null
                    ? '/'
                    : new Date(
                      Number(modalContent?.date_of_birth)
                    ).toLocaleDateString('nl-BE')
                }
              />
              <CopyRow content={modalContent?.national_register} />
              <CopyRow content={modalContent?.language?.name} />
            </div>
          </div>
        }
      />
    </>
  )
}

export default LicensePlates
