import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, message, Button, Popconfirm } from 'antd';
import axios from 'axios';
import { useStateValue } from '../stateProvider/stateProvider';
import { QUERIES } from '../../api/queries';
import { useMutation } from '@apollo/react-hooks';

export const givacarApiUrlBase = 'https://europe-west1-b2bike-253312.cloudfunctions.net/givacardApi';

const ServicePassActions = (props) => {

    const [working, setWorking] = useState(false);
    // const { token } = useStateValue();

    const [updateServicePass] = useMutation(QUERIES.UPDATE_SERVICE_PASS);
    const [givacardData, setGivacardData] = useState(null);
    const [hasCardError, setHasCardError] = useState(true);
    

    useEffect(() => {
        if (!!props.pass) {
            const getCardInfo = async () => {
                const infoRes = await axios({
                    method: 'post',
                    url: givacarApiUrlBase + '/getCardInfo',
                    data: { barcode: props.pass.barcode, id: props.pass.id },
                    headers: {
                        authorization: localStorage.getItem('sessionToken'),
                        env: process.env.REACT_APP_ENV || 'dev'
                    }
                });
                if (!['0', '103'].includes(infoRes.data.ResultCode[0])) {
                    setHasCardError(true);
                    message.warning(`Info uit givacard: ${infoRes.data.ResultDescription[0]}`);
                }
                else {
                    setHasCardError(false);
                    try {
                        const activationRes = await axios({
                        method: 'post',
                        url: givacarApiUrlBase + '/getActivationDetails',
                        data: { barcode: props.pass.barcode, id: props.pass.id },
                        headers: {
                            authorization: localStorage.getItem('sessionToken'),
                            env: process.env.REACT_APP_ENV || 'dev'
                        }
                    });
                    setGivacardData({
                        status: infoRes.data.Card[0].Status[0],
                        balance: Number(infoRes.data.Card[0].Balance[0]) * 0.01,
                        starting_amount: Number(activationRes.data[0].Value[0]) * 0.01,
                        activation_time: new Date(infoRes.data.Card[0].IssueTime[0])
                    })
                    }
                    catch(err) {
                        console.error(err);
                        message.error('Kon activatiedetails niet ophalen.')
                    }
                }
            }
            getCardInfo();
        }
    }, [props]);


    const emptyCard = async () => {
        setWorking(true);
        try {
            // console.log(token);
            const result = await axios({
                method: 'post',
                url: givacarApiUrlBase + '/emptyCard',
                data: { barcode: props.pass.barcode, id: props.pass.id },
                headers: {
                    authorization: localStorage.getItem('sessionToken'),
                    env: process.env.REACT_APP_ENV || 'dev'
                }
            });
            console.log(result);
            message.success('Op nul gezet!')
            setWorking(false);
            props.onClose();
        }
        catch (err) {
            console.error(err);
            message.error('Er ging iets mis');
        }
        finally {
            setWorking(false);
        }
    }

    const checkStartAmount = async () => {
        setWorking(true);
        try {
            const activationDetails = await axios({
                method: 'post',
                    url: givacarApiUrlBase + '/getActivationDetails',
                    data: { barcode: props.pass.barcode },
                    headers: {
                        authorization: localStorage.getItem('sessionToken')
                    }
            });
            // console.log(activationDetails);
            const givacardValue = Number(activationDetails.data[0].Value[0]) / 100;
            if (givacardValue === props.pass.starting_amount) {
                message.success('Bedragen komen overeen!');
            }
            else {
                message.warn(<p>Startbedrag komt niet overeen! <br/> Givacard: € {givacardValue} <br/> Database: € {props.pass.starting_amount}</p>)
            }
        }
        catch(err) {
            console.error(err);
            message.error('Er ging iets mis');
        }
        finally {
            setWorking(false);
        }
    }

    const blockCard = async () => {
        setWorking(true);
        try {
            const blockRes = await axios({
                method: 'post',
                url: givacarApiUrlBase + '/blockCard',
                data: { barcode: props.pass.barcode },
                headers: {
                    authorization: localStorage.getItem('sessionToken')
                }
            });
            console.log('blockRes: ', blockRes.data.ResultCode[0]);
            const result = blockRes.data.ResultCode[0];
            if (result === '0') {
                await updateServicePass({
                    variables: { servicePass: {
                        id: props.pass.id,
                        blocked_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
                    } }
                });
    
                message.success('Kaart succesvol geblokkeerd!')
                setWorking(false);
                props.onClose();
            }
            else if (result === '103') {
                message.warning('Kaart is reeds geblokkeerd');
                setWorking(false);
                props.onClose();
            }
            else {
                throw `Blocking card failed. Givacard resultcode: ${result}`;
            }
        }
        catch (err) {
            console.error(err);
            message.error('Er ging iets mis');
        }
        finally {
            setWorking(false);
        }
    }

    const unBlockCard = async () => {
        setWorking(true);
        try {
            const unBlockRes = await axios({
                method: 'post',
                url: givacarApiUrlBase + '/unblock',
                data: { barcode: props.pass.barcode },
                headers: {
                    authorization: localStorage.getItem('sessionToken')
                }
            });
            console.log('unBlockRes: ', unBlockRes.data.ResultCode[0]);
            const result = unBlockRes.data.ResultCode[0];
            if (result === '0') {
                await updateServicePass({
                    variables: { servicePass: {
                        id: props.pass.id,
                        blocked_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
                    } }
                });
    
                message.success('Kaart succesvol gedeblokkeerd!')
                setWorking(false);
                props.onClose();
            }
            else {
                message.warning(`Kaart is kan niet gedeblokkeerd worden of is reeds actief. Code: ${result}`);
                setWorking(false);
                props.onClose();
            }
        }
        catch (err) {
            console.error(err);
            message.error('Er ging iets mis');
        }
        finally {
            setWorking(false);
        }
    }

    if (!props.pass) {
        return <></>
    }


    return (
        <Modal
            style={{marginTop: '20vh'}}
            zIndex={1}
            visible={props.visible}
            destroyOnClose={true}
            onCancel={() => {
                setGivacardData(null);
                props.onClose();
            }}
            closable={false}
            okButtonProps={{ style: { display: 'none' } }}
            cancelText='Annuleren'
            cancelButtonProps={{ disabled: !!working }}
            title={`Details bij ${props.pass.barcode}`}>
                {!!givacardData && (<Row>
                    <Col>
                    <p><b>Status: </b> {givacardData.status}</p>
                    </Col>
                    <Col>
                    <p><b>Startbedrag: </b>€{givacardData.starting_amount.toFixed(2)}</p>
                    </Col>
                    <Col>
                    <p><b>Huidig saldo: </b>€{givacardData.balance.toFixed(2)}</p>
                    </Col>
                    <Col>
                    <p><b>Datum activatie: </b>{givacardData.activation_time.toLocaleDateString('nl-be')}</p>
                    </Col>
                </Row>)}
                <Row style={{marginBottom: '8px'}} >
                <Popconfirm
            title={`Ben je zeker dat je pas ${props.pass.barcode} op nul wil zetten?`}
            onConfirm={() => emptyCard()}
            okText='Ja'
            cancelText='Nee'>
                <Button
                disabled={!!working}>
                    Op nul zetten
                </Button>
            </Popconfirm>
                </Row>
                <Row style={{marginBottom: '8px'}} >
                <Button
            disabled={!!working}
            onClick={() => checkStartAmount()}>
                Startbedrag controleren
            </Button>
                </Row>
                <Row style={{marginBottom: '8px'}} >
                <Popconfirm
            title={`Ben je zeker dat je pas ${props.pass.barcode} wil blokkeren?`}
            onConfirm={() => blockCard()}
            okText='Ja'
            cancelText='Nee'>
                <Button
                disabled={!!working}>
                    Blokkeren
                </Button>
            </Popconfirm>
                </Row>
                <Row style={{marginBottom: '8px'}} >
                <Popconfirm
            title={`Ben je zeker dat je pas ${props.pass.barcode} wil heractiveren?`}
            onConfirm={() => unBlockCard()}
            okText='Ja'
            cancelText='Nee'>
                <Button
                disabled={!!working}>
                    Heractiveren
                </Button>
            </Popconfirm>
                </Row>
                <Row style={{marginBottom: '8px'}} >
                <Button
            disabled={!!working}
            onClick={() => props.showTransactions()}>
                Transacties bekijken
            </Button>
                </Row>
        </Modal>
    );
}

export default ServicePassActions;