import React from "react";
import { Result } from "antd";
import { Link } from "react-router-dom";

import { getLabel, useI18nContext } from "../../../../api/i18nService";
import Icon from "./icon";
import Button from "./button";

const ErrorMessage = ({ info, error }) => {
  const { strings } = useI18nContext();

  console.error(`${info} - `, error);

  return (
    <Result
      status="error"
      title={getLabel("something went wrong", true, strings)}
      extra={
        <Link to="/">
          <Button type="primary" key="console">
            <Icon type="home" />
          </Button>
        </Link>
      }
    />
  );
};

export default ErrorMessage;
