import React, { useState, useEffect } from 'react';
import { QUERIES } from '../../api/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table, Button, Icon, Modal, Input, message, Form, Spin, Row, Col, Popconfirm } from 'antd';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()

const BikeBrandSettings = props => {

  const bikeBrandModel = {
    name: '',
    code: '',
    isNew: true,
  };

  const [bikeBrands, setBikeBrands] = useState(null);
  const [selectedBikeBrand, setSelectedBikeBrand] = useState(null);
  const [inputModalVisible, setInputModalVisible] = useState(false);
  const [working, setWorking] = useState(false);

  const [createBikeBrand] = useMutation(QUERIES.CREATE_BIKE_BRAND);
  const [updateBikeBrand] = useMutation(QUERIES.UPDATE_BIKE_BRAND);
  const [deleteBikeBrand] = useMutation(QUERIES.DELETE_BIKE_BRAND);

  const { loading, error, refetch } = useQuery(QUERIES.BIKE_BRAND_SETTINGS, {
    onCompleted: res => {
      setBikeBrands(res.bikeBrands);
    }
  });

  const onMutationComplete = () => {
    message.success('Wijzigingen opgeslagen!');
    refetch()
      .then(res => {
        setBikeBrands(res.data.bikeBrands);
        setInputModalVisible(false);
        setWorking(false);
      })
  }

  const onMutationFailed = err => {
    message.error('Er ging iets mis');
    setInputModalVisible(false);
    setWorking(false);
    console.error(err);
  }

  const columns = [
    {
      title: 'Naam',
      key: record => record.id,
      render: record => record.name
    },
    {
      title: 'Code',
      render: record => record.code
    },
    {
      render: record => {
        return (
          <span>
            <Popconfirm
              title={record?.hidden_public_website ? "Merk tonen (publieke website)" : "Merk verbergen (publieke website)?"}
              onConfirm={() => {
                setWorking(true);
                updateBikeBrand({ variables: { bikeBrand: {id: record.id, hidden_public_website: !record?.hidden_public_website} } })
                  .then(res => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }}
              okText="Ja"
              cancelText="Nee"
            >
              <Button
                shape='circle'
                style={{ marginRight: '8px' }}
              ><Icon type={record?.hidden_public_website ? "eye-invisible" : "eye"} /></Button>
            </Popconfirm>
            <Button
              shape='circle'
              style={{ marginRight: '8px' }}
              onClick={() => {
                // const {__typename, ...strippedVariant} = record;
                // console.log(strippedVariant);
                setSelectedBikeBrand(record);
                setInputModalVisible(true);
              }}
            ><Icon type="edit" /></Button>
            <Popconfirm
              title="Merk verwijderen?"
              onConfirm={() => {
                setWorking(true);
                deleteBikeBrand({ variables: { id: record.id } })
                  .then(res => {
                    onMutationComplete();
                  })
                  .catch(err => {
                    onMutationFailed(err);
                  })
              }}
              okText="Ja"
              cancelText="Nee"
            >
              <Button
                shape='circle'
                type='danger'
                style={{ marginRight: '8px' }}
              ><Icon type="delete" /></Button>
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  if (!loading && !error && !!bikeBrands && !working) {
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            setSelectedBikeBrand({ ...bikeBrandModel });
            setInputModalVisible(true);
          }}><Icon type='plus' /> Nieuw
        </Button>
        <Row type='flex' justify='center'>
          <Table
            rowKey={record => record.id}
            style={{ width: '80vw', margin: 16 }}
            dataSource={bikeBrands}
            columns={columns}
          />
        </Row>
        <Modal
          visible={inputModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setWorking(true);
            if (!!selectedBikeBrand.isNew) {
              const { isNew, ...rest } = selectedBikeBrand;
              createBikeBrand({ variables: { bikeBrand: rest } })
                .then(res => {
                  onMutationComplete();
                })
                .catch(err => {
                  onMutationFailed(err);
                })
            }
            else {
              const { __typename, ...rest } = selectedBikeBrand;
              updateBikeBrand({ variables: { bikeBrand: rest } })
                .then(res => {
                  onMutationComplete();
                })
                .catch(err => {
                  onMutationFailed(err);
                })
            }
          }}
          onCancel={() => {
            setInputModalVisible(false);
          }}
          cancelText={'Annuleren'}
        >
          {selectedBikeBrand && (
            <Form>
              <Form.Item required={true} label='Naam'>
                <Input
                  defaultValue={selectedBikeBrand.name}
                  onChange={e => {
                    const val = DOMPurify.sanitize(e.target.value);
                    setSelectedBikeBrand(old => {
                      return { ...old, name: val };
                    });
                  }} />
              </Form.Item>
              <Form.Item required={true} label='Code'>
                <Input
                  defaultValue={selectedBikeBrand.code}
                  onChange={e => {
                    const val = DOMPurify.sanitize(e.target.value);
                    setSelectedBikeBrand(old => {
                      return { ...old, code: val };
                    });
                  }} />
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>
    );
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col span={24}>
          <Spin size='large' />
        </Col>
      </Row>
    </div>
  );

};

export default BikeBrandSettings;