import React, { useContext } from "react";
import { Route, Redirect } from 'react-router-dom';
import { useStateValue } from '../components/stateProvider/stateProvider';

/**
 * privateRoute.js
 * Wrapper for react Route component.
 * Redirects to the login component when there is no currentUser (and hence no auth).
 * Updates the global state via reducer to keep a reference of the requested route,
 * so the user will be redirected there when he ultimately logs in.
 */

const PrivateRoute = ({ component: RouteComponent, location: requestedPath, ...rest }) => {
  const [{ currentUser, requestedRoute: route }, dispatch] = useStateValue();
  /**
   * Quotationlist should not be manually refreshed, hence it is routed to / below
   */
  !currentUser && dispatch({
    type: 'changeRequestedRoute',
    newRoute: requestedPath.pathname === '/quotations'? {...requestedPath, pathname: '/'}: requestedPath
  });

  return (
    <Route {...rest} render={routerProps => !!currentUser ? (
      <RouteComponent {...routerProps} />
    ) : (
        <Redirect to={'/login'} />
      )} />
  );
};

export default PrivateRoute;