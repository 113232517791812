import React from "react";

import Button from "./wrappedComponents/button";
import Icon from "./wrappedComponents/icon";
import TextField from "./wrappedComponents/textfield";
import Dropdown from "./wrappedComponents/dropdown";

import { openNotificationWithIcon } from "./wrappedComponents/notification";

const marginRight = "10px";

const Pagination = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      margin: "auto 0",
    }}
  >
    <Dropdown
      tooltipTitle="Selecteer hoeveel rijen u wilt zien per pagina"
      style={{ width: "100%", marginRight }}
      defaultValue={props.pageSize}
      onChange={(value) => {
        props.onPageChange(1);
        props.onSizeChange(value);
      }}
      options={[
        { key: 1, value: 1 },
        { key: 2, value: 2 },
        { key: 5, value: 5 },
        { key: 10, value: 10 },
        { key: 20, value: 20 },
        { key: 50, value: 50 },
      ]}
    />
    <Button
      onClick={() => {
        props.onPreviousChange();
      }}
      style={{ margin: "auto", marginRight }}
      disabled={props.page === 1}
    >
      <Icon type="left-circle" />
      Vorige
    </Button>
    <TextField
      tooltipTitle="Selecteer de pagina"
      value={props.page}
      style={{ marginRight }}
      min={1}
      max={1000}
      step={1}
      type="Number"
      disabled
      onChange={(e) => {
        if (!e.target.value) {
          openNotificationWithIcon({
            type: "error",
            message:
              "De minimum waarde voor de pagina is 1, het verwijderen hiervan is niet mogelijk!",
          });
          return;
        }

        props.onPageChange(Number(e.target.value));
      }}
    />
    <Button
      onClick={() => {
        props.onNextChange();
      }}
      style={{ margin: "auto" }}
      disabled={props.currentPageLength < props.pageSize}
    >
      <Icon type="right-circle" />
      Volgende
    </Button>
  </div>
);

export default Pagination;
