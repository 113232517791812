import React, { useState, useEffect } from 'react';
import { Select, List, Button, Modal, Form, Input, Checkbox, message, Row, Col, Spin, Icon, Card, Tabs } from 'antd';
import { MODAL_TYPE_ENUM } from './userModal';
const { Option } = Select;


const DealerTab=(props)=>{
    const { userDetails, currentDealer, onDealerChange, userDealers, selectedDealer, setEditMode, setNewUser, userModel, setCreateNewUserVisible, filteredUsers, deleteUser, setCurrentModalType } = props;

    return(
        <Row justify='center' type='flex' gutter={8}>
          <Col span={6}>
            <Select
              disabled={!userDetails.superUser}
              defaultValue={currentDealer.id}
              showSearch
              style={{ width: 500 }}
              placeholder="Selecteer winkel"
              optionFilterProp="children"
              onChange={onDealerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {userDealers.map((el, index) => {
                return <Option key={index} value={el.id}>{el.name}</Option>
              })}
            </Select>
          </Col>
          <Col style={{display: selectedDealer.id === -1? 'none' : 'block'}}>
            {/* {console.log(currentDealer.id)} */}
            <List header={<div>Gebruikers bij dealer <Button
              style={{ margin: '16px' }}
              type='primary' shape='circle'
              onClick={() => {
                setEditMode(false);
                setNewUser({ ...userModel, language: selectedDealer.language, dealerIds: [parseInt(selectedDealer.id)] });
                setCurrentModalType(MODAL_TYPE_ENUM.DEALER);
                setCreateNewUserVisible(true);
              }}>
              <Icon type='plus' /></Button>
            </div>}>
              {filteredUsers.map((el, index) => {
                return (
                  <List.Item key={index}>
                    {/* <List.Item.Meta
                    title={`${el.first_name} ${el.last_name}`}
                    description={<p>{el.email ? el.email : ''}</p>}
                  /> */}
                    <Card
                      style={{ width: '300px' }}
                      actions={[
                        <Button shape='circle'
                          onClick={() => {
                            setNewUser(el);
                            setEditMode(true);
                            setCurrentModalType(MODAL_TYPE_ENUM.DEALER);
                            setCreateNewUserVisible(true);
                          }}
                        ><Icon type="edit" /></Button>,
                        <Button shape='circle' type='danger'
                          disabled={el.email === userDetails.email}
                          onClick={() => {
                            //console.log(el);
                            deleteUser({ ...el, dealerIds: [parseInt(selectedDealer.id)], companyIds: [] });
                          }}
                        ><Icon type="delete" /></Button>
                      ]}
                    >
                      <Card.Meta
                        title={`${el.first_name} ${el.last_name}`}
                        description={<p>{el.email ? el.email : ''}</p>}
                      />
                    </Card>
                    {/* <Row type='flex' justify='center'>
                    <Col span={8}>
                      <Button shape='circle'><Icon type="edit" /></Button>
                      <Button shape='circle' type='danger'><Icon type="delete"/></Button>
                    </Col>
                  </Row> */}
                  </List.Item>
                );
              })}
              {!!selectedDealer && (!filteredUsers.find(el => {
                return el.email === selectedDealer.email;
              }) && (<List.Item><Button type='link' onClick={() => {
                setEditMode(false);
                setNewUser({ ...userModel, email: selectedDealer.email, language: selectedDealer.language, dealerIds: [parseInt(selectedDealer.id)] });
                setCurrentModalType(MODAL_TYPE_ENUM.DEALER);
                setCreateNewUserVisible(true);
              }}>
                Nieuwe gebruiker voor {selectedDealer.email}
              </Button></List.Item>))}
            </List>
          </Col>
      </Row>
    )
  }

  export default DealerTab;