import React, { useState, useEffect, useRef } from 'react';
import { Row, Button, Col, Modal, Icon, Menu, Dropdown, Select, Divider } from 'antd';
import { Link } from 'react-router-dom';
import fbApp from '../../firebase';
import { useStateValue, backofficeDealer } from '../stateProvider/stateProvider';
import CompanyInfoCard from '../companyInfoCard/companyInfoCard';
import { getLabel, useI18nContext } from '../../api/i18nService';
import { QUERIES } from '../../api/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { LogoutOutlined, SmileOutlined, ContainerOutlined, BellOutlined, BookOutlined, DatabaseOutlined, RightCircleOutlined, LeftCircleOutlined, UserOutlined } from '@ant-design/icons';
import './employersPortalNavigation.css';



const EmployersPortalNavigation = props => {
  const { strings } = useI18nContext();
  const [{ currentUser, userDetails, company: companyContext, token, allDealers, dealerIndex, userDealers, currentEmployersPage }, dispatch] = useStateValue();
  const [showModal, setShowModal] = useState(false);
  const [collapsed, setCollapsed] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([0]);

  const signOut = async evt => {
    await fbApp.auth().signOut();
    dispatch({ type: 'logout' });
    setTimeout(() => {
      window.location.replace('/login');
    }, 30)
  };

  useEffect(() => {
    if (collapsed != null) {
      document.getElementById('topMenuRow').classList.toggle('collapsed')
    }
  }, [collapsed])

  useEffect(() => {
    const pageMapping = {
      "inbox": '0',
      "quotations": '1',
      "costcenters": '2',
      "dashboard": '3',
      "userManagement": '4'
    }
    setSelectedKeys([pageMapping[currentEmployersPage]]);
  }, [currentEmployersPage])

  const content = innerElements => {

    return (
      <div>
        <Row id='topMenuRow' type='flex' >
          {innerElements}
        </Row>
      </div>

    )
  }


  if (!currentUser || !userDetails) {
    return (
      null
    )
  }


  if (!window.location.href.includes('/employersportal')) {
    return null
  }

  return (
    content(
      collapsed ? (
        <div>
          <Menu style={{ border: 'none', padding: 0, margin: 0 }} theme='dark' style={{ background: '#333' }} selectedKeys={selectedKeys}>
            <Menu.Item key="0"  >
              <Link to='/employersportal'><BellOutlined className='MenuIcon' /></Link>
            </Menu.Item>
            <Menu.Item key="1" >
              <Link to='/employersportal/quotations'><DatabaseOutlined className='MenuIcon' /></Link>
            </Menu.Item>
            <Menu.Item key="2"  >
              <Link to='/employersportal/costcenters'><BookOutlined className='MenuIcon' /></Link>
            </Menu.Item>
            <Menu.Item key="3"  >
              <Link to='/employersportal/dashboard'><ContainerOutlined className='MenuIcon' /></Link>
            </Menu.Item>
            {(userDetails.superUser || userDetails.admin) && (
              <Menu.Item key="4">
                <Link to='/employersportal/user-management'><UserOutlined className='MenuIcon' /></Link>
              </Menu.Item>
            )}
          </Menu>

          <div style={{ border: 'none' }} theme='dark' style={{ background: '#333' }}>
            <a onClick={() => { setCollapsed(false) }} style={{ position: 'fixed', top: '50%', transform: 'translateX(-60px)' }}><LeftCircleOutlined style={{ fontSize: '24px', color: '#A9A9A9' }} /></a>
          </div>

          <div className='accountmenu' style={{
            position: 'fixed',
            bottom: '2em'
          }}>
            <Menu style={{ border: 'none' }} theme='dark' style={{ background: '#333' }} selectable={false}>
              <Menu.Item key="4" >
                <Link to='/my-account'><SmileOutlined className='MenuIcon' /></Link>
              </Menu.Item>
              <Menu.Item key="5"  >
                <a style={{ height: '4vh' }} onClick={evt => signOut(evt)}><LogoutOutlined className='MenuIcon' /></a>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      ) : (
        <div>
          <Menu style={{ border: 'none' }} theme='dark' style={{ background: '#333' }} selectedKeys={selectedKeys}>
            <Menu.Item key="0"  >
              <Link to='/employersportal'><BellOutlined className='MenuIcon' />{getLabel('Inbox', true, strings)}</Link>
            </Menu.Item>
            <Menu.Item key="1" >
              <Link to='/employersportal/quotations'><DatabaseOutlined className='MenuIcon' />{getLabel('alle offertes', true, strings)}</Link>
            </Menu.Item>
            <Menu.Item key="2"  >
              <Link to='/employersportal/costcenters'><BookOutlined className='MenuIcon' />{getLabel('costcenters', true, strings)}</Link>
            </Menu.Item>
            <Menu.Item key="3"  >
              <Link to='/employersportal/dashboard'><ContainerOutlined className='MenuIcon' />{getLabel('Dashboard', true, strings)}</Link>
            </Menu.Item>
            {(userDetails.superUser || userDetails.admin) && (
              <Menu.Item key="4">
                <Link to='/employersportal/user-management'><UserOutlined className='MenuIcon' />{getLabel('Gebruikersbeheer', true, strings)}</Link>
              </Menu.Item>
            )}
          </Menu>

          <div style={{ border: 'none' }} theme='dark' style={{ background: '#333' }}>
            <a onClick={() => { setCollapsed(true) }} style={{ position: 'fixed', top: '50%', transform: 'translateX(-60px)' }}><RightCircleOutlined style={{ fontSize: '24px', color: '#A9A9A9' }} /></a>
          </div>

          <div style={{
            position: 'fixed',
            bottom: '2em'
          }}>
            <Menu style={{ border: 'none' }} theme='dark' style={{ background: '#333' }} selectable={false}>
              <Menu.Item key="4" >
                <Link to='/employersportal/my-account'><SmileOutlined className='MenuIcon' />{getLabel('my account', true, strings)}</Link>
              </Menu.Item>
              <Menu.Item key="5"  >
                <a style={{ height: '4vh' }} onClick={evt => signOut(evt)}><LogoutOutlined className='MenuIcon' />{getLabel('log out', true, strings)}</a>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      )
    )
  )
}

export default EmployersPortalNavigation;