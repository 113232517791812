import React, { useEffect, useState } from 'react'
import {Row, Col, message, Button, Icon, Divider} from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import * as firebase from 'firebase/app';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';


const MfaResetPage = props => {
    const history = useHistory();
    const { strings } = useI18nContext();
    const auth = firebase.auth();
    const historyDelay = 10000;

    const [loading, setLoading] = useState(false);
    const [actionCode, setActionCode] = useState(null);
    const [userTel, setUserTel] = useState(null);
    const [userMail, setUserMail] = useState(null);
    const [enrollTime, setEnrollTime] = useState(null);
    const [isUnEnrolled, setIsUnEnrolled] = useState(false);

    useEffect(()=>{
        setLoading(true);
        const queryString = props.resetProps.location.search.replace("?", "");
        const { mode, oobCode } = QueryString.parse(queryString);
        if(!mode || !oobCode || mode!="revertSecondFactorAddition"){
            history.push("/");
        }

        auth.checkActionCode(oobCode).then((info)=>{
            if(info["operation"] != "REVERT_SECOND_FACTOR_ADDITION")
                history.push('/');

            setActionCode(oobCode);
            setUserTel(info['data']['multiFactorInfo']['phoneNumber']);
            setUserMail(info['data']['email']);
            setEnrollTime(info['data']["multiFactorInfo"]["enrollmentTime"]);
        }).catch((err)=> history.push("/"))
        .finally(()=> setLoading(false));
    }, []);

    const disableMfa=()=>{
        setLoading(true);
        auth.applyActionCode(actionCode).then(()=>{
            setIsUnEnrolled(true);
            message.success(`${userTel} succesvol verwijderd als multi factor.`);
            message.info(`Indien dit geen eigen actie was raden we je aan uw paswoord te herstellen.`);
            setTimeout(function() { history.push("/"); }, historyDelay);
        }).catch((err)=>{
            message.warning(`Fout bij het uitschakelen van de multi factor. Probeer opnieuw of neem contact op.`);
        }).finally(()=> setLoading(false));
    }

    return (
        <div>
            <h2>MFA uitschrijving</h2>
            {!!actionCode && userTel && userMail && <Row type='flex' justify='center'>
                <Col span={8}>
                    <p style={{marginBottom: 50}}>Via deze pagina kan u de zonet ingestelde multi factor weer uitschakelen.</p>
                    <Divider />
                    <p style={{marginBottom: 20, fontWeight: 'bold'}}>Multi factor overzicht:</p>

                    <p><span style={{textDecoration: "underline", fontWeight: 'bold'}}>Tel:</span> {userTel} <span style={{fontWeight: "bold", marginLeft: 15, color: "red"}}><Icon style={{marginRight: 5}} type="exclamation-circle-o" />Bent u dit niet?</span> We raden je aan je paswoord te herstellen.</p>
                    <p><span style={{textDecoration: "underline", fontWeight: 'bold'}}>Email:</span> {userMail}</p>
                    <p><span style={{textDecoration: "underline", fontWeight: 'bold'}}>Enrollment:</span> {enrollTime}</p>

                    <Button style={{marginTop: 30}} type="primary" htmlType="submit" disabled={loading || isUnEnrolled} loading={loading} onClick={()=>disableMfa()}>
                        MFA uitschakelen
                    </Button>

                    {isUnEnrolled &&
                        <p style={{marginTop: 15}}><Icon type="clock-circle-o" /> {historyDelay/1000}s. We verbinden je door naar de startpagina. <Button style={{marginLeft: 15}} type="primary" onClick={()=>history.push("/")}><Icon type="home" /></Button></p>
                    }
                </Col>
            </Row>}
        </div>
    );
}

export default MfaResetPage;