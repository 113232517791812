import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Icon, Button, Typography, message, Modal } from 'antd';
import fbApp from '../../firebase';
import * as firebase from 'firebase/app';

import { Link} from 'react-router-dom';
import { useStateValue } from '../stateProvider/stateProvider';
import {useI18nContext, getLabel} from '../../api/i18nService';
import {showInfoToast} from '../../util/util';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
import axios from 'axios';

const Login = ({ history }) => {

  const [{ requestedRoute, currentUser, token }, dispatch] = useStateValue();
  // const [{ requestedRoute, currentUser, company}, dispatch] = useReducer(reducer, initialState);
  const {strings} = useI18nContext()

  const {Title, Text} = Typography;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationIdFromFirebase, setVerificationIdFromFirebase] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [isVerificationModalVisible, setIsVerificationModalVisible] = useState(false);
  const [resolver, setResolver] = useState(null);

  const handleLogin = async event => {
    event.preventDefault();
    setIsAuthenticating(true);
    try {
      const loginRes = await fbApp.auth().signInWithEmailAndPassword(email, password);
      setTimeout(() => {
        history.push(requestedRoute);
      }, 1000)
    } catch (e) {
      if (e.code == 'auth/too-many-requests'){
        showInfoToast(e.message)
      } else if (e.code == 'auth/multi-factor-auth-required'){
        // The user is a multi-factor user. Second factor challenge is required.
        setResolver(e.resolver);
        var phoneInfoOptions = {
          multiFactorHint: e.resolver.hints[0],
          session: e.resolver.session
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then(function(verificationId) {
            // Ask user for the SMS verification code.
            setVerificationIdFromFirebase(verificationId);
            setIsVerificationModalVisible(true)
            
          })
          
      }else{
        showInfoToast('Wrong email/password combination or email is not known');
      }
    }
    finally {
      setIsAuthenticating(false);
    }
  };

  const resetPassword = () => {
    if (!email) {
      return message.warning(getLabel('To reset your password, please enter email adress field', true, strings));
    }
    else {
      axios.post(process.env.REACT_APP_ENV === 'production' ? 'https://europe-west1-b2bike-253312.cloudfunctions.net/passwordResetHandler': 'https://europe-west1-b2bike-253312.cloudfunctions.net/passwordResetHandlerStaging', {
            email: email,
            type: 'reset'
      }, {
        headers: {env: process.env.REACT_APP_ENV}
      })
      .then(() => {
        return message.success(getLabel('password reset mail sent', true, strings));
      })
      .catch((err) => {
        return message.success(getLabel('password reset mail sent', true, strings));
      });
    }
  };

  // const { currentUser } = useAuthContext();
  useEffect(() => {
    if (currentUser && token) {
      history.push(requestedRoute);
    }
  }, [currentUser, token]);

  useEffect(()=>{
    setRecaptchaVerifier(new firebase.auth.RecaptchaVerifier('login-button', {
      'size': 'invisible',
      'callback': function(response) {
        
      }
     })
    )
  },[])


  const handleVerificationOk = () => {
    var cred = firebase.auth.PhoneAuthProvider.credential(
      verificationIdFromFirebase, verificationCode);
    var multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    
     let send = (resolver.resolveSignIn(multiFactorAssertion).then(async (res)=>{
        await fbApp.database().ref(`users/${res.user.uid}`).update({loginTime: new Date()});
        setTimeout(() => {
          setIsVerificationModalVisible(false);
          history.push(requestedRoute);
        }, 1000)
      })).catch(e => {
        if (e.code == 'auth/invalid-verification-code') {
          message.warning(getLabel('Code does not match', true, strings));
        }else{
          console.log('error', e)
          message.warning('Fout bij inloggen. Probeer het opnieuw of contacteer support');
        }
      })
    
  };

  const handleVerificationCancel = () => {
    setIsVerificationModalVisible(false);
  };
  
  return (
    <div>
      <Row type="flex" justify="space-around" gutter={16} style={{padding: '16px', height: '85vh'}}>
        <Col span={10}>
          {/* <img src='/res/img/login-img.jpg' style={{width: '100%', height: '100%'}} alt=''/> */}
          <div style={{height: '100%', width: '100%', 
          backgroundImage: 'url(/res/img/login_img.jpg)',
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center'}}></div>
        </Col>
        <Col span={10}>
          <Title level={3}>{getLabel('log in', true, strings)}</Title>
          <Form onSubmit={event => handleLogin(event)}>
            <Form.Item>
             <Text strong>{getLabel('email', true, strings)}</Text>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={getLabel('email', true, strings)}
              value={email}
              onChange={e => setEmail(DOMPurify.sanitize(e.target.value))}
            />
            </Form.Item>
            <Form.Item>
            <Text strong>{getLabel('password', true, strings)}</Text>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={getLabel('password', true, strings)}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            </Form.Item>
            <Button type='primary' htmlType='submit' loading={isAuthenticating} 
              disabled={(email === '') || (password === '')} style={{float: 'right'}} id='login-button'>
                {getLabel('log in', true, strings)}
              </Button>
          </Form>
          <h4 style={{textAlign: 'center'}}><Button type='link' onClick={() => {resetPassword()}}>{getLabel('forgot password', true, strings)}?</Button></h4>
          
          <hr style={{marginTop: '25px', width: '15%'}}/>
          <h5 style={{textAlign: 'center',marginTop: '25px'}}><Link to='/employee-access'>{getLabel('Werknemer? Maak hier je berekening', true, strings)}</Link></h5>
        </Col>
      </Row>
      <Modal title="Verificatie" visible={isVerificationModalVisible} onOk={handleVerificationOk} onCancel={handleVerificationCancel} >
    
        <Input type="text" placeholder='Sms code' onChange={(e)=>{setVerificationCode(e.target.value)}} onPressEnter={() =>{handleVerificationOk()}}></Input>

      </Modal>
    </div>
    
  )
}

export default Login;