import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { QUERIES } from '../../api/queries';
import { useAuthContext } from "../../auth/auth";
import { Spin, Input, Row, Col, Icon, Button, Result, Tooltip, Typography, Avatar, notification } from 'antd';
import EmployeeCard from '../employeeCard/employeeCard';
import { getLabel, useI18nContext } from '../../api/i18nService';
import { useStateValue } from '../stateProvider/stateProvider';
const { Search } = Input;
const { Title } = Typography;
/**
 * employeeSearch.js
 * React component for searching employees
 * Provides employees for one company if a company id is provided (default behaviour)
 * If no company id is provided it can search across companies
 */
const EmployeeSearch = props => {
  const dummyTerm = props.match.params.companyId ? '' : 'dùmmy$e@rch';
  const [company, setCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState(dummyTerm);
  const [canSearch, setCanSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [employees, setEmployees] = useState([]);
  let content = (<div></div>);
  // const [token, setToken] = useState('');
  // const { currentUser } = useAuthContext();
  // if (currentUser) {
  //   currentUser.getIdToken(true).then(userToken => {
  //     setToken(userToken);
  //   });
  // }
  const [{ token, currentUser, userDetails }] = useStateValue();

  const { strings } = useI18nContext();

  useEffect(() => {
    if (!userDetails) {
      props.history.push('/');
    }
    else if (!userDetails.superUser && !props.match.params.companyId) {
      props.history.push('/');
    }
  }, []);

  // Define the graphQL query based on whether a company id is provided
  const query = () => {
    if (props.match.params.companyId) {
      return QUERIES.EMPLOYEES_FOR_COMPANY;
    }
    else return QUERIES.EMPLOYEE_SEARCH;
  };

  // Define query options depending on whether a company id is present
  const queryOptions = () => {
    if (props.match.params.companyId) {
      return {
        variables: { id: props.match.params.companyId },
        fetchPolicy: 'cache-and-network',
        onCompleted: res => {
          if (res) {
            const companyStripped = { ...res.company };
            delete companyStripped.employees;
            setCompany(companyStripped);
            res.company.employees.forEach(emp => {
              emp.company = companyStripped;
            });
            setResults([]);
            setEmployees(res.company.employees);
          }
        }
      };
    }
    else return {
      variables: { search: searchTerm },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data) {
          setResults(data.searchEmployees);
        }
      }
    };
  };


  const { loading, error, data, refetch } = useQuery(query(), queryOptions());

  const filterText = text => {
    if (props.match.params.companyId) {
      setSearchTerm(text.trim())
      if (text.trim() === '') {
        setResults([]);
      }
      else {
        let filterResults = [];
        const regexStr = '(?=.*' + text.toLowerCase().split(/\,|\s/).join(')(?=.*') + ')';
        const searchRegEx = new RegExp(regexStr, 'gi');
        employees.forEach(item => {
          if (`${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}`.match(searchRegEx)) {
            filterResults.push(item);
          }
        });
        setResults(filterResults);
      }
    }
    else {
      text === '' ? setSearchTerm(dummyTerm) : setSearchTerm(text);
      refetch();
    }
  }

  if (!!userDetails.companyIds){
    return null
  }
  
  if (loading) {
    content = (
      <Row type='flex' justify='center' gutter={16}>
        <Col style={{ marginTop: '128px' }} span={1}>
          <Spin size='large'></Spin>
        </Col>
      </Row>
    );
  }
  else if (error) {
    console.error(error);
    content = (
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
        extra={
          <Link to='/'>
            <Button type="primary" key="console">
              <Icon type='home' />
            </Button>
          </Link>
        }
      />
    );
  }

  else if (results) {
    content = props.match.params.companyId ? (
      <Row style={{ marginTop: '16px' }} type='flex' gutter={16} justify={(results.length > 0) ? 'start' : 'center'}>
        {(results.length > 0 ? results.map((el, index) => (
          <Col span={8} key={index}>
            <Link to={'/employee/' + el.id + '/' + props.match.params.companyId}>
              <EmployeeCard shouldRenderLogo={results.length < 100 && !props.match.params.companyId} employee={el} fbToken={token} />
            </Link>
          </Col>
        )) : (<Col span={8}>
          <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{searchTerm === '' ? (`${employees.length} ${getLabel('known employees', false, strings)}`) : getLabel('employee not found', true, strings)}</h1>
        </Col>))}
      </Row>
    ) : (
        <Row style={{ marginTop: '16px' }} type='flex' gutter={16} justify={(searchTerm !== dummyTerm && results.length > 0) ? 'start' : 'center'}>
          {searchTerm === dummyTerm ? (<Col span={1}>
            <Icon type='search' style={{ fontSize: '72px', marginTop: '128px' }} />
          </Col>) : (results.length > 0 ? results.map((el, index) => (
            <Col span={8} key={index}>
              <Link to={'/employee/' + el.id + '/' + el.company_fk}>
                <EmployeeCard shouldRenderLogo={results.length < 100} employee={el} fbToken={token} />
              </Link>
            </Col>
          )) : (<Col span={8}>
            <h1 style={{ textAlign: 'center', marginTop: '128px' }}>{getLabel('employee not found', true, strings)}</h1>
          </Col>))}
        </Row>
      )
  }

  return (<div>
    <Row type='flex' justify='space-between'>
      <Link to='/'>
        <Button><Icon type='left' /></Button>
      </Link>
    </Row>
    {(props.match.params.companyId && company) && (
      <Row type='flex' justify='space-around' style={{ maxWidth: '35vw', margin: '14px auto' }}>
        <Col span={20}><h2 style={{ textAlign: 'left' }}>{company.name}</h2></Col>
        <Col span={4}> {company.logo_url ? 
        <img className='image' src={company.logo_url} alt='logo' style={{ maxHeight: '50px', maxWidth: '100px' }} /> 
        : <Avatar size='large'>{company.name[0]}</Avatar>}</Col>
      </Row>
    )
    }
    <Row type='flex' justify="center" gutter={16}>
      <Col span={8}>
        <Tooltip title={getLabel('search employee', true, strings)}>
          <Search
            onSearch={val => { 
              if(!!canSearch)
                filterText(val);
              else notification["warning"]({
                message: getLabel('Search input', true, strings),
                description: getLabel('Input should be at least 5 characters long', true, strings),
              });
            }}
            enterButton
            size='large'
            onChange={e=> setCanSearch(e.target.value.trim().length>=5)}
            placeholder={getLabel('employee', true, strings)} />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip title={getLabel('new employee', true, strings)}>
          <Button size='large' type='primary'><Link to={props.match.params.companyId ? `/employee/new/${props.match.params.companyId}` : '/employee/new'}>
            <Icon type='plus' /></Link></Button>
        </Tooltip>
      </Col>
    </Row>
    {content}
  </div>)
}

export default EmployeeSearch