import React, { useState, useEffect, useRef } from 'react';
import { List, Button, InputNumber, Icon, message } from 'antd';

const ServicePassUploader = props => {
  const [barcodes, setBarcodes] = useState([]);
  const barcodeInput = useRef(null);

  useEffect(() => {
    props.onValueUpdate(barcodes);
  }, [barcodes]);

  return (
    <div>
      <span><h2>Nieuwe Service Passes</h2><h4>Aantal: {barcodes.length}</h4></span>
      <List
      style={{ height: '50vh', overflowY: 'scroll' }}
      >
        {barcodes.map((el, index) => {
          return (
            <List.Item key={index}>
              <h3>{el}</h3><Button
                style={{ marginLeft: '32px' }}
                type='danger'
                shape='circle'
                size='small'
                onClick={() => {
                  const newArr = [...barcodes];
                  newArr.splice(index, 1)
                  setBarcodes(newArr);
                }}
              ><Icon type="delete" /></Button>
            </List.Item>
          );
        })}
      </List>
      <h3>Barcode</h3>
      <InputNumber
        ref={barcodeInput}
        style={{ width: '100%' }}
        placeholder='19 cijfers'
        maxLength={19}
        onPressEnter={() => {
          if (!!barcodeInput.current) {
            if (!!barcodeInput.current.inputNumberRef.state.inputValue) {
              if ((barcodeInput.current.inputNumberRef.state.inputValue + '').length === 19) {
                if (!(barcodeInput.current.inputNumberRef.state.inputValue + '').match(/^\d+$/)) {
                  message.warn('Enkel cijfers toegelaten!');
                  return;
                }
                setBarcodes(old => {
                  return [...old, barcodeInput.current.inputNumberRef.state.inputValue];
                });
                barcodeInput.current.inputNumberRef.state.inputValue = null;
              }
            }
          }
        }}
      />
    </div>
  );

}

export default ServicePassUploader;