import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { QUERIES } from '../../api/queries';
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks';
import FileUploader, { fileOptions } from '../fileUploader/fileUploader';
import { Link } from 'react-router-dom';
import { compareObjects } from '../../util/util';
import { getServiceUrl } from '../../util/util';
import {
  Card, Spin, Row, Col, Checkbox, Button, Icon, message, Collapse,
  Statistic, Divider, Timeline, Select, Tooltip, Result, Tag,
  DatePicker,
  Form
} from 'antd';
import { secureFetchFromDrive } from '../../api/driveFetch';
import { useAuthContext } from '../../auth/auth';
import { useI18nContext, getLabel } from '../../api/i18nService';
import { useStateValue } from '../stateProvider/stateProvider';
import {
  PopupStatusNew, PopupStatusDeliverable, PopUpCompleteBike, FIPQuoteUploadModal,
  PopUpShowDocuments, PopUpShowWebhookAttempts, PopUpFileUploader, PopupEditOrderStatus,
  PopUpSpeedPedelecUploader, PopupEditDealLink, PopUpChangeCompany
} from './quotationPopups';
import { bugsnagApiKey } from '../../api/apiUrls';
import Axios from 'axios';
import Bugsnag from '@bugsnag/js';
const { Option } = Select;
const { Panel } = Collapse;
const dateFormat = 'DD/MM/YYYY';

const firstCompatibleDate = new Date('2019/12/30');

// const relevantInvoiceTargetsForLicensePlates = [
//   1338141, // Alphabet
//   1337984, // Axus
//   1338239, // B2Bike Lease
//   38083485 // Alphabet Broker (Alphabet - B2Bike)
// ]

// const shouldHandleLicensePlate = (invoiceTarget) => {
//   return relevantInvoiceTargetsForLicensePlates.indexOf(Number(invoiceTarget)) >= 0
// }

const completeBikeDisabled = (orderlogs, bike, invoiceTarget) => {
  if (!bike) return true;
  const bikeType = bike.bikeType.id;
  if (bikeType != 1) return false; // No SP? No worries
  if (!!invoiceTarget.license_plate_auto_request) { // Only for certain invoice targets
    if (!orderlogs.find(el => el.order_log_type_fk == 8) || !orderlogs.find(el => el.order_log_type_fk == 9)) { // only if the docs are missing
      return true
    }
  }
  return false;
}

export default function QuotationDetail(props) {

  const { strings } = useI18nContext();
  const [webhookAttemptsVisible, setWebhookAttemptsVisible] = useState(false);
  const [uploadFromNewVisible, setUploadFromNewVisible] = useState(false);
  const [uploadFromDeliverableVisible, setUploadFromDeliverableVisible] = useState(false);
  const [quotation, setQuotation] = useState(null);
  const [showDocumentsVisible, setShowDocumentsVisible] = useState(false);
  const [showChangeCompanyModal, setShowChangeCompanyModal] = useState(false)
  const [uploadExtraDocumentVisible, setUploadExtraDocumentVisible] = useState(false);
  const [uploadSpeedpedelecDocumentsVisible, setUploadSpeedpedelecDocumentsVisible] = useState(false);
  const [extraDocLoading, setExtraDocLoading] = useState(false);
  const [speedPedDocsLoading, setSpeedPedDocsLoading] = useState(false);
  const [editOrderStatusVisible, setEditOrderStatusVisible] = useState(false);
  const [editOrderStatusLoading, setEditOrderStatusLoading] = useState(true);
  const [editLinkedDealVisible, setEditLinkedDealVisible] = useState(false);
  const [editLinkedDealLoading, setEditLinkedDealLoading] = useState(true);
  const [savingBike, setSavingBike] = useState(false);
  const [orderLogTypes, setOrderLogTypes] = useState(null);
  const [showBikeModal, setShowBikeModal] = useState(false);
  const [showFipUploadQuoteModal, setShowFipQuoteUploadModal] = useState(false);
  //const [orderStatuses, setOrderStatuses] = useState(null);
  // const { currentUser, userDealers } = useAuthContext();
  const [createOrder, { loading: orderMutationLoading }] = useMutation(QUERIES.CREATE_ORDER);
  const [createLicensePlateRequest] = useMutation(QUERIES.CREATE_LICENSE_PLATE_REQUEST);
  const [createBike] = useMutation(QUERIES.CREATE_BIKE);
  const [createOrderLog, { loading: orderLogMutationLoading }] = useMutation(QUERIES.CREATE_ORDER_LOG);
  const [updateQuotation, { loading: updateQuotationLoading }] = useMutation(QUERIES.UPDATE_QUOTATION);
  const [updateDeal] = useMutation(QUERIES.UPDATE_DEAL);
  const [deleteOrder] = useMutation(QUERIES.DELETE_ORDER);
  const [updateOrder] = useMutation(QUERIES.UPDATE_ORDER);
  const [updateBike] = useMutation(QUERIES.UPDATE_BIKE);
  const [working, setWorking] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [{ currentUser, userDetails, token }, dispatch] = useStateValue();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [currentWebhookAttemptsQuotationId, setCurrentWebhookAttemptsQuotationId] = useState(null);

  const [updateDealCustomFields] = useMutation(QUERIES.UPDATE_DEAL_CUSTOM_FIELDS, {
    fetchPolicy: 'no-cache'
  });


  const updateOrderStatus = async (statusFK) => {
    try {
      const updateRes = await updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: statusFK } } });
      if (updateRes.error) {
        throw new Error("Update order call failed");
      }
      const refetchRes = await refetch();
      onQueryComplete(refetchRes.data);
      return updateRes;
    } catch (err) {
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - updateOrderStatus';
      });
      message.warning(getLabel('something went wrong', true, strings));
      return null;
    }
  }

  const updateEarliestDeliveryDate = async (date) => {
    const updateRes = await updateQuotation({
      variables: {
        quotation: { id: props.id, earliest_delivery_date: date ? date.toISOString().slice(0, 19).replace('T', ' ') : null }
      }
    });
    const refetchRes = await refetch();
    onQueryComplete(refetchRes.data);
    const dealInput = {
      id: quotation.order.deal.id,
      custom_fields: quotation.order.deal.custom_fields.map(cf => {
        if (cf.definition.id == "5616ae6c-2f88-009a-8e5b-4634fbf61d39") cf.value = date ? new Date(date.toISOString()).toLocaleDateString() : '';
        return {
          id: cf.definition.id,
          value: cf.value
        }
      })
    };
    updateDealCustomFields({
      variables: {
        deal: dealInput
      }
    });
    return updateRes;
  }

  const canDuplicate = (quotation) => {
    return (new Date(parseInt(quotation.quotation_date)) > firstCompatibleDate) && !quotation.company.hide
  }

  // console.log(currentUser.getIdToken());

  const uploadQuoteFip = async (quoteDoc) => {
    setShowFipQuoteUploadModal(false);
    setUploading(true);
    const ol = {
      order_log_type_fk: quoteDoc.type.id,
      order_fk: quotation.order.id,
      description: quoteDoc.type.name
    }
    try {
      const docRes = await createOrderLog({ variables: { orderLog: ol, document: quoteDoc.file.getFileEncodeBase64String() } });
      if (docRes.error) {
        throw new Error(docRes.error);
      }
      const refetchData = await refetch();
      onQueryComplete(refetchData.data);
      // console.log('refetchData: ', refetchData);
      if (refetchData.data.quotation.order.orderLogs.find(el => el.order_log_type_fk === '2')) {
        // We have a signed calc doc!
        const updateRes = await updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 2 } } });
        await handleCustomFields()
        if (updateRes.error) {
          throw new Error(updateRes.error);
        }
        // console.log('[quotationCard] - orderSigned after fip - res: ', updateRes);
        const finalData = await refetch();
        onQueryComplete(finalData.data);
      }
      message.success(getLabel('quotation', true, strings) + ' ' + getLabel('modified', false, strings));
    }
    catch (err) {
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - uploadQuoteFip';
      });
      console.error(err);
      message.error(getLabel('something went wrong', true, strings));
      setShowFipQuoteUploadModal(true);
    }
    finally {
      setUploading(false);
    }
  }

  const sendQuotationDoc = async () => {
    try {
      const sendQuotationDocUrl = await getServiceUrl('mailCalculationDocument', token);
      const mailRes = await Axios.post(sendQuotationDocUrl, {
        quotationId: quotation.id,
        toEmail: quotation.employee.email,
        env: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development',
        user: { accessToken: token },
        language: quotation?.employee?.language?.code?.toLowerCase() ?? "nl"
      });
      message.success('Berekening verzonden!');
    }
    catch (err) {
      console.error(err);
      message.error('E-mail error')
    }
  }

  // const sendStatusEmail = async () => {
  //   try {
  //     const res = await Axios.post(statusEmailUrl, {
  //       user: { id: currentUser.uid, email: currentUser.email, accessToken: token },
  //       order: { id: quotation.order.id }
  //     }, {
  //       headers: {
  //         'Authorization': token
  //       }
  //     });
  //     message.success('Status URL verzonden!');
  //     setIsSendingEmail(false);
  //     return;
  //   }
  //   catch (err) {
  //     message.error('Kon e-mail niet verzenden.');
  //     setIsSendingEmail(false);
  //     console.error(err);
  //     return
  //   }
  // }

  const handleCustomFields = async () => {
    const refetchRes = await refetch();

    for (let fields of refetchRes?.data?.quotation?.order?.deal?.custom_fields) {
      switch (fields?.definition?.id) {
        case "24054410-f8a2-03b6-9251-78c4ed06dbb8":
          const result = refetchRes?.data?.quotation?.quotationFields?.find((quotationField) => quotationField?.field?.fieldType?.code === 'DURATIONMONTHS');
          const monthCheck = result?.value ? `${result?.value} maanden` : "36 maanden"
          fields.value = monthCheck; // Veld 22 looptijd
          break;
        case "c05572d8-ca1b-0165-a85f-b92528416a37":
          fields.value = refetchRes?.data?.quotation?.quotation_number // Veld 06 quotation_number
          break;
        case "062d44fe-b61a-07f9-9d5a-90e98a86c399":
          fields.value = refetchRes?.data?.quotation?.employee?.employeeStatute?.name // Veld 20 employeeStatute
          break;
        case "9ccf62e0-6947-033f-8e56-6ed44f66c39c":
          fields.value = refetchRes?.data?.quotation?.lease_amount_vat?.toString() // Veld 21 lease_amount_vat
          break;
        default:
          break;
      }
    }
    const dealInput = {
      id: refetchRes?.data?.quotation?.order?.deal_id_v2? refetchRes?.data?.quotation?.order?.deal_id_v2: refetchRes?.data?.quotation?.order?.deal_id,
      custom_fields: refetchRes?.data?.quotation?.order?.deal?.custom_fields.map(cf => {
        return {
          id: cf.definition.id,
          value: cf.value
        }
      })
    };
    const updateDealRes = await updateDealCustomFields({
      variables: {
        deal: dealInput
      }
    });
    if (updateDealRes?.error) {
      throw new Error(updateDealRes?.error);
    }
  }

  const uploadFromNew = async (calcDocSigned, quotationDoc) => {
    setUploadFromNewVisible(false);
    setUploading(true);
    // TODO: Check file types and upload files
    // console.log('CalcDoc base64: ', calcDocSigned.file.getFileEncodeBase64String());
    const calcOrderLog = {
      order_log_type_fk: calcDocSigned.type.id,
      order_fk: quotation.order.id,
      description: calcDocSigned.type.name
    };

    const quotationOrderLog = {
      order_log_type_fk: quotationDoc.type.id,
      order_fk: quotation.order.id,
      description: quotationDoc.type.name
    };

    delete quotationDoc.__typename;
    try {
      const calcDocRes = await createOrderLog({ variables: { orderLog: calcOrderLog, document: calcDocSigned.file.getFileEncodeBase64String() } });
      if (calcDocRes.error) {
        throw new Error(calcDocRes.error);
      }
      const quotDocRes = await createOrderLog({ variables: { orderLog: quotationOrderLog, document: quotationDoc.file.getFileEncodeBase64String() } });
      if (quotDocRes.error) {
        throw new Error(quotDocRes.error);
      }
      const updateRes = await updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 2 } } });
      if (updateRes.error) {
        throw new Error(updateRes.error);
      }

      await handleCustomFields()
    }
    catch (err) {
      console.error(err);
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - uploadFromNew';
      });
      message.error(getLabel('something went wrong', true, strings));
    }
    finally {
      const refetchData = await refetch();
      onQueryComplete(refetchData.data);
      setUploading(false);
    }

    // createOrderLog({ variables: { orderLog: calcOrderLog, document: calcDocSigned.file.getFileEncodeBase64String() } })
    //   .then(res => {
    //     // console.log('quotationCard - res from orderLog (calcDoc): ', res);
    //     if (!res.data.createOrderLog.id || !res.data.createOrderLog.file_id) {
    //       throw { err: 'no FileId' };
    //     }
    //     if (res.data.createOrderLog.id && res.data.createOrderLog.file_id) {
    //       return createOrderLog({ variables: { orderLog: quotationOrderLog, document: quotationDoc.file.getFileEncodeBase64String() } })
    //         .then(quotDocRes => {
    //           // console.log('quotationCard - res from orderLog (quotationDoc): ', quotDocRes);
    //           if (!quotDocRes.data.createOrderLog.id || !quotDocRes.data.createOrderLog.file_id) {
    //             throw { err: 'no FileId' };
    //           }
    //           if (quotDocRes.data.createOrderLog.id && quotDocRes.data.createOrderLog.file_id) {
    //             return updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 2 } } })
    //               .then(orderRes => {
    //                 // console.log('quotationCard - res from order update(new -> signed): ', orderRes);
    //                 return refetch()
    //                   .then(rfRef => {
    //                     message.success(getLabel('quotation', true, strings) + ' ' + getLabel('modified', false, strings));
    //                     setUploading(false);
    //                     return setQuotation(rfRef.data.quotation);
    //                   }
    //                   );
    //               })
    //           }
    //         })
    //     }
    //   })
    //   .catch(err => {
    //     console.error('QuotationCard error uploading form new: ', err);
    //     message.error(getLabel('something went wrong', true, strings));
    //     setUploading(false);
    //   })
    // console.log('Uploaded: ', calcDocSigned, quotationDoc);
  }

  const uploadFromExtraDocument = async (document, description) => {
    setExtraDocLoading(true);

    const orderLog = {
      order_log_type_fk: document.type.id,
      order_fk: quotation.order.id,
      description: description //document.type.name
    };

    try {
      const COLRes = await createOrderLog({ variables: { orderLog: orderLog, document: document.file.getFileEncodeBase64String() } });
      if (COLRes.error) {
        throw new Error(COLRes.error);
      } else {
        //Success
        setUploadExtraDocumentVisible(false);
      }
    }
    catch (err) {
      console.error(err);
      message.error(getLabel('something went wrong', true, strings));
    }
    finally {
      const refetchRes = await refetch();
      onQueryComplete(refetchRes.data);
      setSavingBike(false);
      setUploading(false);
      setExtraDocLoading(false);
    }
  }

  const uploadFromSpeedPedDocuments = async (cocDoc, descriptionCOC, doc705, description705, bike, quotationId) => {
    setSpeedPedDocsLoading(true);

    const orderLogCOC = {
      order_log_type_fk: cocDoc.type.id,
      order_fk: quotation.order.id,
      description: descriptionCOC
    };

    const orderLog705 = {
      order_log_type_fk: doc705.type.id,
      order_fk: quotation.order.id,
      description: description705
    };

    try {
      await updateBike({
        variables: {
          bike: {
            id: bike.id,
            frame_number: bike.frame_number,
            sticker_number: bike.sticker_number,
            order_fk: bike.order_fk,
            control_number: bike.control_number,
            barcode_x12: bike.barcode_x12?? null
          }
        }
      });

      const COLResCOC = await createOrderLog({ variables: { orderLog: orderLogCOC, document: cocDoc.file.getFileEncodeBase64String() } });
      if (COLResCOC.error) {
        throw new Error(COLResCOC.error);
      } else {
        //Success
      }

      const COLRes705 = await createOrderLog({ variables: { orderLog: orderLog705, document: doc705.file.getFileEncodeBase64String() } });
      if (COLRes705.error) {
        throw new Error(COLRes705.error);
      } else {
        //2nd Success
        //setUploadSpeedpedelecDocumentsVisible(false);
        const ordUrl = await getServiceUrl('sendOrdBikeAtShop', token);
        if (!!ordUrl) {
          await Axios.post(ordUrl, {
            quotationId: quotationId,
            user: { accessToken: token },
            env: process.env.REACT_APP_ENV
          });
        }
      }

      console.log("COLRes705", COLRes705)
      console.log("COLResCOC", COLResCOC)

      const checkIf705AndCOCExists = Boolean(COLRes705?.data?.createOrderLog?.file_id && COLResCOC?.data?.createOrderLog?.file_id)

    const isAld = quotation.employee.company.invoice_target.tl_id_v1 == 1337984

      console.log("condition creation of license plate request", {checkIf705AndCOCExists, isAld})

      if(checkIf705AndCOCExists && !isAld){
        console.log("in creation of license plate request")
        const licensePlateRequest = await createLicensePlateRequest({
          variables: {
            licensePlateRequest: {
              license_plate_registration_type_fk: '2',
              license_plate_request_status_fk: '1',
              bike_fk: bike.id
            }
          }
        });

        console.log("licensePlateRequest result", licensePlateRequest)

        if (licensePlateRequest.error) {
          throw new Error(licensePlateRequest.error);
        }
      }
    }
    catch (err) {
      console.error(err);
      message.error(getLabel('something went wrong', true, strings));
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - uploadFromSpeedPedDocuments';
      });
    }
    finally {
      const refetchRes = await refetch();
      onQueryComplete(refetchRes.data);
      setSpeedPedDocsLoading(false);
      setUploadSpeedpedelecDocumentsVisible(false);
    }
  }

  const uploadFromDeliverable = async (invoiceDoc, receiptDocSigned, startDate) => {
    console.log(startDate);
    setUploadFromDeliverableVisible(false);
    setUploading(true);
    // console.log('receiptDocSigned base64: ', receiptDocSigned.file.getFileEncodeBase64String());
    // console.log('invoiceDoc base64: ', invoiceDoc.file.getFileEncodeBase64String());
    const invoiceOrderLog = {
      order_log_type_fk: invoiceDoc.type.id,
      order_fk: quotation.order.id,
      description: invoiceDoc.type.name
    };

    const receiptSignedOrderLog = {
      order_log_type_fk: receiptDocSigned.type.id,
      order_fk: quotation.order.id,
      description: receiptDocSigned.type.name
    };
    try {
      const dealUpdateRes = await updateDeal({
        variables: {
          deal: {
            id: quotation.order.deal.id, custom_fields: [
              { id: '7944d14a-9797-0ef8-ad5c-d9ebfd11e866', value: `${startDate.format('YYYY-MM-DD')}` }
            ]
          }
        }
      });
      // console.log('dealUpdateRes: ', dealUpdateRes);
      const invoiceRes = await createOrderLog({ variables: { orderLog: invoiceOrderLog, document: invoiceDoc.file.getFileEncodeBase64String() } });
      if (invoiceRes.error) {
        throw new Error(invoiceRes.error);
      }
      const signedReceptRes = await createOrderLog({ variables: { orderLog: receiptSignedOrderLog, document: receiptDocSigned.file.getFileEncodeBase64String() } });
      if (signedReceptRes.error) {
        throw new Error(signedReceptRes.error);
      }
      const updateOrderRes = await updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 5 }, deliveryDate: startDate.unix() } });
      if (updateOrderRes.error) {
        throw new Error(updateOrderRes.error);
      }
    }
    catch (err) {
      console.error(err);
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - uploadFromDeliverable';
      });
      message.error(getLabel('something went wrong', true, strings));
    }
    finally {
      const refetchRes = await refetch();
      onQueryComplete(refetchRes.data);
      setSavingBike(false);
      setUploading(false);
    }
    // createOrderLog({ variables: { orderLog: invoiceOrderLog, document: invoiceDoc.file.getFileEncodeBase64String() } })
    //   .then(res => {
    //     console.log('quotationCard - res from orderLog (invoice): ', res);
    //     if (!res.data.createOrderLog.id || !res.data.createOrderLog.file_id) {
    //       throw { err: 'no FileId' };
    //     }
    //     if (res.data.createOrderLog.id && res.data.createOrderLog.file_id) {
    //       // console.log('receipt doc: ', receiptSignedOrderLog, receiptDocSigned.file.getFileEncodeBase64String());
    //       createOrderLog({ variables: { orderLog: receiptSignedOrderLog, document: receiptDocSigned.file.getFileEncodeBase64String() } })
    //         .then(receiptRes => {
    //           //console.log('quotationCard - res from orderLog (quotationDoc): ', receiptRes);

    //           if (!receiptRes.data.createOrderLog.id || !receiptRes.data.createOrderLog.file_id) {
    //             throw { err: 'no FileId' };
    //           }
    //           if (receiptRes.data.createOrderLog.id && receiptRes.data.createOrderLog.file_id) {
    //             return updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 5 } } })
    //               .then(orderRes => {
    //                 // console.log('quotationCard - res from order approved(new -> deliverable): ', orderRes);
    //                 return refetch()
    //                   .then(rfRef => {
    //                     message.success(getLabel('quotation', true, strings) + ' ' + getLabel('modified', false, strings));
    //                     setUploading(false);
    //                     return setQuotation(rfRef.data.quotation);
    //                   }
    //                   );
    //               })
    //           }
    //         })
    //         .catch(err => {
    //           console.error(err);
    //         })
    //     }

    //   })
    //   .catch(err => {
    //     console.error('QuotationCard error uploading form new: ', err);
    //     message.error(getLabel('something went wrong', true, strings));
    //     setUploading(false);
    //   })
  }

  const saveBike = async (bike, callback = null) => {
    // if (!quotation.order.bike) {
    //   const bikeRes = await createBike({bike});
    //   console.log('bikeRes', bikeRes)
    //   if (bikeRes.data.createBike.id) {
    //     bike.id = bikeRes.data.createBike.id;
    //   }
    // }

    bike.b2bike_servicepas = bike.b2bike_servicepas.replace(/\s/g, ''); //Make sure servicepass got rid of spaces
    setSavingBike(true);
    // console.log('QuotationCard - Saving bike...', bike, quotation.order.bike);
    if (!!quotation.order.bike) {
      updateBike({ variables: { bike: bike } }).then(res => {
        // console.log('saved bike: ', res);
        try {
          return refetch()
            .then(rfRef => {
              message.success(getLabel('quotation', true, strings) + ' ' + getLabel('modified', false, strings));
              setQuotation(rfRef.data.quotation);
              if (callback) {
                return callback();
              }
              else {
                setSavingBike(false);
              }
            })
            .catch(err => {
              console.error('Error saving bike: ', err);
              message.error(getLabel('something went wrong', true, strings));
              setSavingBike(false);
            })
        } catch (e) {
          //Do nothing
        }
      })
    }
  }

  const refreshDeal = async () => {
    const newData = await refetch();
      onQueryComplete(newData.data);
  }

  const createReceipt = async () => {
    try {
      const olRes = await createOrderLog({
        variables: {
          orderLog: {
            order_log_type_fk: 3,
            order_fk: quotation.order.id,
            description: 'Ontvangstbewijs'
          }
        }
      });
      if (olRes.error) {
        throw new Error(olRes.error);
      }
      const orderUpdateRes = await updateOrder({
        variables: {
          order: {
            id: quotation.order.id,
            order_status_fk: 4
          }
        }
      });
      if (orderUpdateRes.error) {
        throw new Error(orderUpdateRes.error);
      }
      if (!!quotation.order.bike && !!quotation.order.bike.b2bike_servicepas) {
        await updateDeal({
          variables: {
            deal: {
              id: quotation.order.deal.id, custom_fields: [
                { id: 'e91d1ba5-3160-026c-b25f-22bcead59d05', value: `${quotation.order.bike.b2bike_servicepas}` }
              ]
            }
          }
        });
      }
    }
    catch (err) {
      console.error('could not create receipt... ', err);
      Bugsnag.notify(err, e => {
        e.context = 'quotationOverview - createReceipt';
      });
      message.error(getLabel('something went wrong', true, strings));
      return err;
    }
    finally {
      const newData = await refetch();
      onQueryComplete(newData.data);
      setSavingBike(false);
    }
    // return createOrderLog({
    //   variables: {
    //     orderLog: {
    //       order_log_type_fk: 3,
    //       order_fk: quotation.order.id,
    //       description: 'Ontvangstbewijs'
    //     }
    //   }
    // })
    //   .then(res => {
    //     console.log('Receipt created: ', res);
    //     if (res.error) {
    //       throw new Error(res.error);
    //     }
    //     refetch()
    //     .then(data => {
    //       onQueryComplete(data.data);
    //     })
    //     return;
    //   })
    //   .catch(err => {
    //     console.error('could not create receipt... ', err);
    //     getLabel('something went wrong', true, strings);
    //     return err;
    //   })
  }


  const teamleaderButton = () => (
    <>
    <a hidden={!quotation.order.deal_id || !!quotation.order.deal_id_v2} href={`https://app.teamleader.eu/sale_detail.php?id=${quotation.order.deal_id}`} target='_blank'>
      <img style={{ height: '32px', width: '32px' }} src='/res/img/Teamleader-icon.png' />
    </a>
    <a hidden={!quotation.order.deal_id_v2} href={`https://focus.teamleader.eu/web/deals/${quotation.order.deal_id_v2}`} target='_blank'>
      <img style={{ height: '32px', width: '32px' }} src='/res/img/Teamleader-icon.png' />
    </a>
    </>
  );

  const statusEmailButton = () => (
    <Button
      style={{ margin: '8px' }}
      loading={isSendingEmail}
      hidden={!quotation}
      onClick={async () => {
        setIsSendingEmail(true);
        // const token = await currentUser.getIdToken();
        try {
          const statusEmailUrl = await getServiceUrl('sendOrderStatusEmail', token);
          const res = await Axios.post(statusEmailUrl, {
            user: { id: currentUser.uid, email: currentUser.email, accessToken: token },
            order: { id: quotation.order.id }
          }, {
            headers: {
              'Authorization': token
            }
          });
          message.success('Mail verzonden!');
          setIsSendingEmail(false);
          return;
        }
        catch (err) {
          message.error('Kon e-mail niet verzenden.');
          setIsSendingEmail(false);
          console.error(err);
          return
        }
        // console.log('order: ', quotation.order);
      }}
    >
      Nieuwe status e-mail sturen
    </Button>
  );

  const webhookAttemptsButton = (quotationId) => {
    setCurrentWebhookAttemptsQuotationId(quotationId);
    setWebhookAttemptsVisible(true);
  }

  const onQueryComplete = queryRes => {
    if (queryRes.quotation) {
      // if (data.quotation.id === '18036') {
      //   console.log('quotationCard - query onCompleted: ', data.quotation)
      // }
      // console.log(data.quotation);
      let q = { ...queryRes.quotation };
      q.employee.company = props.company;
      setQuotation(q);
      // console.log(q);
      setOrderLogTypes(queryRes.orderLogTypes);
      // startPolling(5000);
    }
  }


  const { loading, error, data, refetch, startPolling } = useQuery(QUERIES.QUOTATION_BY_ID, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
    onCompleted: (data => {
      onQueryComplete(data);
      // setOrderStatuses(data.orderStatuses);
    })
  });

  // useEffect(() => {
  //   if (data) {
  //     if (data.quotation) {
  //       if (data.quotation.id === '18036') {
  //         console.log('order status: ', data.quotation.order.order_status_fk);
  //         if (data.quotation.order.order_status_fk != 3) {
  //           console.log('Incorrect status');
  //         }
  //         // console.log('quotationCard - query onCompleted: ', data.quotation)
  //       }
  //       let q = {...data.quotation};
  //       q.employee.company = props.company;
  //       setQuotation(data.quotation);
  //     }
  //   }
  // }, [data])



  /*useSubscription(quotationSub, {
    onSubscriptionData: data => {
      let d = null;
      if (data.subscriptionData.data.quotationUpdated) {
        // console.log('production sub fired!');
        d = data.subscriptionData.data.quotationUpdated;
      }
      else if (data.subscriptionData.data.quotationUpdatedTest) {
        // console.log('TEST sub fired!');
        d = data.subscriptionData.data.quotationUpdatedTest;
      }
      else return;
      if (d.id === props.id) {
        refetch().then(res => {
          // console.log('QuotationCard - quotation updated: ', data.subscriptionData.data, new Date().toISOString());
          setQuotation(res.data.quotation);
        })
      }
    }
  }); */

  /*
  useSubscription(orderUpdatedSub, {
    onSubscriptionData: data => {
      let d = null;
      if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'staging') {
        if (data.subscriptionData.data.orderUpdatedTest) {
          // console.log('TEST sub fired!');
          d = data.subscriptionData.data.orderUpdatedTest;
        }
        else return;
      }
      else if (process.env.REACT_APP_ENV === 'production') {
        if (data.subscriptionData.data.orderUpdated) {
          // console.log('production sub fired!');
          d = data.subscriptionData.data.orderUpdated;
        }
        else return;
      }
      else return;
      if (d.quotation_fk === props.id) {
        // console.log('QuotationCard - order updated: ', d, new Date().toISOString());
        refetch().then(res => {
          setQuotation(res.data.quotation);
        });
      }
    }
  });

  useSubscription(orderLogUpdatedSub, {
    onSubscriptionData: subData => {
      // console.log(subData.subscriptionData.data.orderLogUpdated);
      if (quotation) {
        // console.log('QuotationCard - orderLogUpdated: check 01')
        if (quotation.order) {
          // console.log('QuotationCard - orderLogUpdated: check 02', subData);
          let d = subData.subscriptionData.data.orderLogUpdated || subData.subscriptionData.data.orderLogUpdatedTest;
          if (d.order_fk === quotation.order.id) {
            if (d.file_id.indexOf('ERROR') >= 0) {
              message.error(getLabel('something went wrong', true, strings));
            }
            // console.log(d);
            if (d.order_log_type_fk == 3) {
              // console.log(d);
              setSavingBike(false);
            }
            // console.log('QuotationCard - orderLogUpdated: ', d, new Date().toISOString());
            refetch().then(res => {
              setQuotation(res.data.quotation);
            });
          }
        }
      }
    }
  })
  */

  // if (!calculationSubscriptionLoading) {
  //   if (calculationSubscriptionData) {
  //     console.log('QuotationCard - subscription fired: ', calculationSubscriptionData);
  //     refetch();
  //   }
  // }

  if (error) {
    console.error(error);
    return (
      <Result
        status="error"
        title={getLabel('something went wrong', true, strings)}
      />
    );
  }
  if (loading) {
    return (
      <Row type='flex' justify='center'>
        <Spin size="large" style={{ margin: 128 }} />
      </Row>
    )
  }
  if (quotation) {

    const statuscard = () => {
      if (!quotation.order) {
        return (
          <Card>
            {/* {console.log('quotationCard - quotation at render - ' , quotation)} */}
            <Row type='flex' justify='space-between'>
              <div style={{ display: quotation.warning_message ? 'block' : 'none' }}>
                <Row type='flex' justify='center'>
                  <Icon type="close-circle" theme="twoTone" twoToneColor="#f5222d" style={{ fontSize: '32pt' }} />
                </Row>
                <Row type='flex' justify='center'>
                  <h3 style={{ textAlign: 'center', color: '#f5222d' }}>{quotation.warning_message}</h3>
                </Row>
              </div>
              <Button disabled={quotation.warning_message} style={{ float: 'right' }} size='large' type='primary' loading={working}
                onClick={async () => {
                  setWorking(true);
                  // console.log('QuotationCard - CreatingOrder...')
                  let orderId = null;
                  try {
                    const orderRes = await createOrder({ variables: { order: { quotation_fk: props.id, date: new Date().toISOString(), order_status_fk: 1 } } });
                    if (!orderRes.data && !!orderRes.error) {
                      throw new Error(orderRes.error);
                    }
                    orderId = orderRes.data.createOrder.id;
                    const orderLogRes = await createOrderLog({
                      variables: {
                        orderLog: {
                          order_fk: orderRes.data.createOrder.id, order_log_type_fk: orderLogTypes.find(el => {
                            return el.code = 'CALCULATIONDOC';
                          }).id, description: 'Berekening'
                        }
                      }
                    });
                    if (!orderLogRes.data && !!orderLogRes.error) {
                      throw new Error(orderLogRes.error);
                    }
                    if (quotation.employee.company.fip) {
                      setShowFipQuoteUploadModal(true)
                      sendQuotationDoc();
                      // sendStatusEmail();
                    }
                  }
                  catch (err) {
                    console.error('QuotationCard - Error executing order: ', err);
                    message.error(getLabel('something went wrong', true, strings));
                    if (orderId) {
                      await deleteOrder({ variables: { id: orderId } });
                    }
                  }
                  finally {
                    const newData = await refetch();
                    onQueryComplete(newData.data);
                    setWorking(false);
                  }
                }}
              >{getLabel('execute', true, strings)}</Button>
            </Row>
          </Card>
        )
      }
      if (!quotation.order.orderStatus) {
        // TODO: should show error
        return null;
      }
      const actionCard = () => {
        if (quotation.order.orderStatus.index === 1 && !quotation.employee.company.fip) {
          return (
            <Card>
              <Row type='flex' justify='space-between'>
                <Tooltip title={getLabel('upload', true, strings)}>
                  {(!quotation.order.orderLogs.find(el => el.order_log_type_fk == 2) ||
                    !quotation.order.orderLogs.find(el => el.order_log_type_fk == 5)) ? (
                    <Button
                      style={{ float: 'right', marginLeft: '16px' }}
                      size='large'
                      type="primary" onClick={() => {
                        setUploadFromNewVisible(true);
                      }}
                      // disabled={false}><Icon type="upload" /> {getLabel('uploading documents', true, strings)}</Button>
                      disabled={uploading} shape='circle-outline'
                      loading={uploading}>{!uploading && <Icon type="upload" />}</Button>
                  ) :
                    <Button
                      style={{ float: 'right', marginLeft: '16px' }}
                      size='large'
                      type="danger" onClick={async () => {
                        setUploading(true);
                        try {
                          const updateRes = await updateOrder({ variables: { order: { id: quotation.order.id, order_status_fk: 2 } } });
                          if (updateRes.error) {
                            throw new Error(updateRes.error);
                          }
                        }
                        catch (err) {
                          console.error(err);
                          Bugsnag.notify(err, e => {
                            e.context = 'quotationOverview - actionCard - btn reload';
                          });
                          message.error(getLabel('something went wrong', true, strings));
                        }
                        finally {
                          const refetchData = await refetch();
                          onQueryComplete(refetchData.data);
                          setUploading(false);
                        }

                      }}
                      // disabled={false}><Icon type="upload" /> {getLabel('uploading documents', true, strings)}</Button>
                      disabled={uploading}
                      loading={uploading}>{!uploading &&
                        <Icon type="reload" />}{getLabel("Fout bij uploaden, klik om opnieuw te proberen", true, strings)}</Button>
                  }

                </Tooltip>
                <Tooltip title={getLabel('documenten', true, strings)}>
                  <Button
                    shape='circle-outline'
                    size='large'
                    style={{ float: 'right' }}
                    type="primary" onClick={() => {
                      setShowDocumentsVisible(true);
                    }}
                    disabled={false}><Icon type="file-text" /></Button>
                </Tooltip>
                {userDetails.superUser && teamleaderButton()}
                {userDetails.superUser && statusEmailButton()}
              </Row>
            </Card>
          )
        }
        if ((quotation.order.orderStatus.index === 1 || quotation.order.orderStatus.index === 2 || quotation.order.orderStatus.index === 3)
          && !!quotation.employee.company.fip) {
          return (
            <Card>
              <Row type='flex' justify='space-between'>
                <Tooltip title={getLabel('upload', true, strings)}>
                  {!quotation.order.orderLogs.find(el => el.order_log_type_fk == 5) && (
                    <Button
                      style={{ float: 'right', marginLeft: '16px' }}
                      size='large'
                      type="primary" onClick={() => {
                        setShowFipQuoteUploadModal(true);
                      }}
                      // disabled={false}><Icon type="upload" /> {getLabel('uploading documents', true, strings)}</Button>
                      disabled={uploading} shape='circle-outline'
                      loading={uploading}>{!uploading && <Icon type="upload" />}</Button>
                  )}

                </Tooltip>
                <Tooltip title={getLabel('documenten', true, strings)}>
                  <Button
                    shape='circle-outline'
                    size='large'
                    style={{ float: 'right' }}
                    type="primary" onClick={() => {
                      setShowDocumentsVisible(true);
                    }}
                    disabled={false}><Icon type="file-text" /></Button>
                </Tooltip>
                {userDetails.superUser && teamleaderButton()}
                {userDetails.superUser && statusEmailButton()}
              </Row>
            </Card>
          )
        }
        if (quotation.order.orderStatus.index === 2 || quotation.order.orderStatus.index === 3) {
          return (
            <Card>
              <Tooltip title={getLabel('documenten', true, strings)}>
                <Button
                  shape='circle-outline'
                  size='large'
                  style={{ float: 'right' }}
                  type="primary" onClick={() => {
                    setShowDocumentsVisible(true);
                  }}
                  disabled={false}><Icon type="file-text" /></Button>
              </Tooltip>
              {userDetails.superUser && teamleaderButton()}
              {userDetails.superUser && statusEmailButton()}
            </Card>
          )
        }
        if (quotation.order.orderStatus.index === 4 || quotation.order.orderStatus.index === 6) {
          return (
            <Card>
              <Row type='flex' justify='space-between'>
                <Tooltip title={getLabel('upload', true, strings)}>
                  {(!quotation.order.orderLogs.find(el => el.order_log_type_fk == 8) && 
                  quotation.order.bike.bikeType.id == 1) && 
                  !!quotation.employee.company.invoice_target.license_plate_auto_request && (
                    <Button
                      style={{ float: 'right', marginLeft: '16px' }}
                      size='large'
                      type="primary" onClick={() => {
                        setUploadSpeedpedelecDocumentsVisible(true);
                      }}
                      // disabled={false}><Icon type="upload" /> {getLabel('uploading documents', true, strings)}</Button>
                      disabled={uploading}
                      loading={uploading}>{!uploading && getLabel('nummerplaat aanvragen', true, strings)}</Button>
                  )}

                </Tooltip>
                <Button
                  // shape='circle-outline'
                  loading={savingBike}
                  size='large'
                  style={{ float: 'right', marginLeft: '16px' }}
                  type="primary" onClick={() => {
                    setShowBikeModal(true);
                  }}
                  disabled={savingBike || completeBikeDisabled(quotation.order.orderLogs, quotation.order.bike, quotation.employee.company.invoice_target)}>
                    <Icon type="link" /> {getLabel('complete bike info', true, strings)}</Button>
                <Tooltip title={getLabel('documenten', true, strings)}>
                  <Button
                    shape='circle-outline'
                    size='large'
                    style={{ float: 'right', marginLeft: '16px' }}
                    type="primary" onClick={() => {
                      setShowDocumentsVisible(true);
                    }}
                    disabled={false}><Icon type="file-text" /></Button>
                </Tooltip>
                {userDetails.superUser && teamleaderButton()}
                {userDetails.superUser && statusEmailButton()}
              </Row>
            </Card>
          )
        }
        if (quotation.order.orderStatus.index === 5) {
          return (
            <Card>
              <Row type='flex' justify='space-between'>
                <Tooltip title={getLabel('upload', true, strings)}>
                  <Button
                    style={{ float: 'right' }}
                    size='large'
                    type="primary" onClick={() => {
                      setUploadFromDeliverableVisible(true);
                    }}
                    // disabled={false}><Icon type="upload" /> {getLabel('uploading documents', true, strings)}</Button>
                    disabled={uploading} shape='circle-outline'
                    loading={uploading}>{!uploading && <Icon type="upload" />}</Button>
                </Tooltip>
                <Button
                  loading={savingBike}
                  // shape='circle-outline'
                  size='large'
                  style={{ float: 'right', marginLeft: '16px' }}
                  type="primary" onClick={() => {
                    setShowBikeModal(true);
                  }}
                  disabled={savingBike || completeBikeDisabled(quotation.order.orderLogs, quotation.order.bike, quotation.employee.company.invoice_target)}>
                    <Icon type="link" /> {getLabel('complete bike info', true, strings)}</Button>
                <Tooltip title={getLabel('documenten', true, strings)}>
                  <Button
                    shape='circle-outline'
                    size='large'
                    style={{ float: 'right', marginLeft: '16px' }}
                    type="primary" onClick={() => {
                      setShowDocumentsVisible(true);
                    }}
                    disabled={false}><Icon type="file-text" /></Button>
                </Tooltip>
                {userDetails.superUser && teamleaderButton()}
                {userDetails.superUser && statusEmailButton()}
              </Row>
            </Card>
          )
        }
        return null;
      }

      return (
        <div>
          {showFipUploadQuoteModal && <FIPQuoteUploadModal
            onLaterClicked={() => setShowFipQuoteUploadModal(false)}
            onSubmitClicked={file => uploadQuoteFip(file)}
          />}
          {webhookAttemptsVisible && <PopUpShowWebhookAttempts quotId={currentWebhookAttemptsQuotationId} callbacks={{ setWebhookAttemptsVisible: setWebhookAttemptsVisible }} />}
          {uploadFromNewVisible && <PopupStatusNew callbacks={{ uploadFromNew: uploadFromNew, setUploadFromNewVisible: setUploadFromNewVisible }} />}
          {uploadFromDeliverableVisible && <PopupStatusDeliverable callbacks={{ uploadFromDeliverable: uploadFromDeliverable, setUploadFromDeliverableVisible: setUploadFromDeliverableVisible, setWorking: setWorking }} />}
          {(quotation.order && showDocumentsVisible) && <PopUpShowDocuments currentUser={currentUser}
            quotation={quotation} callbacks={{ setShowDocumentsVisible: setShowDocumentsVisible, setQuotation: setQuotation, setUploadExtraDocumentVisible: setUploadExtraDocumentVisible }} />}
          {(quotation.order && showDocumentsVisible && uploadExtraDocumentVisible) && <PopUpFileUploader currentUser={currentUser}
            quotation={quotation} extraDocLoading={extraDocLoading} callbacks={{ setUploadExtraDocumentVisible: setUploadExtraDocumentVisible, uploadFromExtraDocument: uploadFromExtraDocument }} />}
          {(quotation.order && editOrderStatusVisible) && <PopupEditOrderStatus
            quotation={quotation} editOrderStatusLoading={editOrderStatusLoading} callbacks={{ setEditOrderStatusVisible, setEditOrderStatusLoading, updateOrderStatus }} />}
          {(quotation.order && editLinkedDealVisible) && <PopupEditDealLink
            quotation={quotation} editLinkedDealVisible={editLinkedDealVisible} editLinkedDealLoading={editLinkedDealLoading} token={token} callbacks={{ setEditLinkedDealVisible, setEditLinkedDealLoading, refreshDeal }} />}
          {(quotation.order && showBikeModal) && <PopUpCompleteBike
            quotation={quotation} bike={quotation.order.bike} userDetails={userDetails}
            callbacks={{ setShowBikeModal: setShowBikeModal, setSavingBike: setSavingBike, saveBike: saveBike, createReceipt: createReceipt, setUploadSpeedpedelecDocumentsVisible: setUploadSpeedpedelecDocumentsVisible }} />}
          {(quotation.order && showChangeCompanyModal) && <PopUpChangeCompany currentUser={currentUser} quotation={quotation} callbacks={{ setQuotation: setQuotation, setShowChangeCompanyModal: setShowChangeCompanyModal }} />}


          {(quotation.order && uploadSpeedpedelecDocumentsVisible) && <PopUpSpeedPedelecUploader currentUser={currentUser}
            quotation={quotation} speedPedDocsLoading={speedPedDocsLoading} callbacks={{ setUploadSpeedpedelecDocumentsVisible: setUploadSpeedpedelecDocumentsVisible, uploadFromSpeedPedDocuments: uploadFromSpeedPedDocuments }} />}
          <Card>
            {quotation.order.orderStatus.index > 0 ? (
              <Timeline>
                <Timeline.Item color='green'>{getLabel('new', true, strings)}</Timeline.Item>
                <Timeline.Item color={quotation.order.orderStatus.index >= 2 ? 'green' : 'gray'}>{getLabel('signed', true, strings)}</Timeline.Item>
                <Timeline.Item color={quotation.order.orderStatus.index >= 3 ? 'green' : 'gray'}>{getLabel('sent to decision maker', true, strings)}</Timeline.Item>
                {quotation.order.orderStatus.index >= 3 && !!quotation.order.confirmedOrder && <Timeline.Item color='green'>{getLabel('signed online', true, strings)}</Timeline.Item>}
                <Timeline.Item color={quotation.order.orderStatus.index >= 4 ? 'green' : 'gray'}>{getLabel('approved', true, strings)}</Timeline.Item>
                <Timeline.Item color={quotation.order.orderStatus.index >= 5 ? 'green' : 'gray'}>{getLabel('deliverable', true, strings)}</Timeline.Item>
                <Timeline.Item color={quotation.order.orderStatus.index >= 6 ? 'green' : 'gray'}>{getLabel('delivered', true, strings)}</Timeline.Item>
              </Timeline>
            ) : (
              <div>
                <Row type='flex' justify='center'>
                  <Icon type="close-circle" theme="twoTone" twoToneColor="#f5222d" style={{ fontSize: '48pt' }} />
                </Row>
                <Row type='flex' justify='center'>
                  <h2 style={{ textAlign: 'center', color: '#f5222d' }}>{quotation.order.orderStatus.index === -1 ? getLabel('refused', true, strings) : 'TEST'}</h2>
                </Row>
              </div>
            )}
          </Card>
          {actionCard()}
        </div>
      );
    }

    return (<Card loading={loading} style={props.highlight ? { backgroundColor: '#edfad9', width: '30vw', marginBottom: '16px' } : { width: '30vw', marginBottom: '16px' }}>
      {!!quotation.test &&
        <Tag style={{ float: 'right' }} color="blue">{getLabel('test', true, strings).toUpperCase()}</Tag>
      }
      {!!quotation.company.fip &&
        <Tag style={{ float: 'right' }} color="blue">{getLabel('fip', true, strings).toUpperCase()}</Tag>
      }
      {userDetails.superUser && (
        <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}>
          <Col span={24}><p>Dealer: <i>{quotation.dealer ? quotation.dealer.name : ''}</i></p></Col>
        </Row>
      )}

      <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}>
        <Col span={8}><h3><b>{quotation.quotation_number}</b></h3></Col>
        <Col span={8}><Link to={`/employee/${quotation.employee ? quotation.employee.id : '0'}/${quotation.employee ? quotation.employee.company.id : '0'}`}>{quotation.employee ? quotation.employee.first_name : '0'} {quotation.employee ? quotation.employee.last_name : '0'}</Link></Col>
        <Col span={8}><p>{quotation.company?.name}</p></Col>
      </Row>
      <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}>
        <Col span={8}><p>{new Date(parseInt(quotation.quotation_date)).toLocaleDateString('nl-BE')}</p></Col>
        <Col span={8}><Checkbox onChange={evt => {
          // setQuotation(old => {
          //   return { ...old, active: evt.target.checked };
          // });
          updateQuotation({ variables: { quotation: { id: props.id, active: evt.target.checked } } })
            .then(res => {
              // console.log('QuotationCard - changed active', res);
              setQuotation(old => {
                return { ...old, active: evt.target.checked };
              });
            })
            .then(err => {
              console.error('QuotationCard - error changing active... ', err)
            })
        }} checked={quotation.active}>{getLabel('active', true, strings)}</Checkbox></Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Tooltip placement="topLeft" title={!canDuplicate(quotation) ? 'Kan niet dupliceren' : 'Dupliceren'} arrowPointAtCenter>
            <Button
              disabled={!canDuplicate(quotation)}
              onClick={() => {
                // console.log('quotationCard - onDuplicate', quotation.quotaionFields);
                props.onDuplicate(quotation.quotationFields, {
                  deliverability: quotation.deliverability,
                  bike_type_fk: quotation.bike_type_fk,
                  bikeType: quotation.bikeType,
                  bike_brand_fk: quotation.bike_brand_fk
                });
              }}><Icon size='large' type="copy" /></Button>
          </Tooltip>
        </Col>
      </Row>
      <Divider dashed />
      <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
        <Col span={8}><Statistic style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('bike type', true, strings)}</span>} value={getLabel(quotation.bikeType.name, true, strings)} /></Col>
        <Col span={8}><Statistic style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('deliverability', true, strings)}</span>} value={quotation.deliverability} /></Col>
        <Col span={8}><Statistic style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} groupSeparator='' title={<span style={{ fontSize: '9pt' }}>{getLabel('invoice target', true, strings)}</span>} value={quotation.employee.company.invoice_target.name} /></Col>
        {userDetails.superUser && quotation.order && quotation.order.confirmedOrder && quotation.order.confirmedOrder.confirmed_by && <Col span={24}><Statistic style={{ textAlign: 'center', marginTop: 5 }} valueStyle={{ fontSize: '12pt' }} groupSeparator='' title={<span style={{ fontSize: '9pt' }}>{getLabel('bevestigd door', true, strings)}</span>} value={quotation.order.confirmedOrder.confirmed_by} /></Col>}
      </Row>
      {(quotation.lease_amount !== null) ? (
        <div>
          <Card type='inner'>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center', fontSize: '4pt' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('lease excl vat', true, strings)}</span>} prefix='€' value={quotation.lease_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('lease incl vat', true, strings)}</span>} prefix='€' value={quotation.lease_amount_vat} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('salary swap', true, strings)}</span>} prefix='€' value={quotation.salary_swap} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('insurance', true, strings)}</span>} prefix='€' value={quotation.insurance_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('maintenance', true, strings)}</span>} prefix='€' value={quotation.maintenance_amount} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('assistance', true, strings)}</span>} prefix='€' value={quotation.assistance_amount} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='start' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('warranty grant', true, strings)}</span>} prefix='€' value={quotation.warranty_grant} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('total excl vat', true, strings)}</span>} prefix='€' value={quotation.total_vat_excluded} /></Col>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('total incl vat', true, strings)}</span>} prefix='€' value={quotation.total_vat_included} /></Col>
            </Row>
            <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}>
              <Col span={8}><Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>{getLabel('residual value', true, strings)}</span>} prefix='€' value={quotation.residual_value} /></Col>
            </Row>
            <Divider dashed />
            {/* <Row style={{ marginBottom: '8px' }} type='flex' justify='center' gutter={8}> */}
            <Collapse defaultActiveKey={['1']}>
              <Panel header='Details'>
                <Row type='flex' justify='center' gutter={4}>
                  {quotation.quotationFields.map((el, index) => {
                    // console.log(el);
                    if (!el.field || !el.value) {
                      return null;
                    }
                    return (<Col key={index} span={8}>
                      <Statistic groupSeparator=' ' decimalSeparator=',' style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }} title={<span style={{ fontSize: '9pt' }}>
                        {getLabel(el.field.name.toLowerCase().replace(/\./g, ''), true, strings)}</span>} prefix={el.field.fieldType.dataType.code == 'NUMBER' ? '€' : ''} value={getLabel(el.value, true, strings)} />
                    </Col>)
                  })}
                </Row>
                {userDetails.superUser && (
                  <Button onClick={() => {
                    window.open(`https://docs.google.com/spreadsheets/d/${quotation.calculation_sheet}`);
                  }}
                    style={{ margin: "2em 0 .5em 0" }}>Open Calculatie sheet</Button>
                )}
              </Panel>
            </Collapse>
            {/* </Row> */}
          </Card>
          {statuscard()}
        </div>
      ) :
        (<Row type='flex' justify="center">
          <Spin size="large" style={{ marginTop: 64, marginBottom: 64 }} />
        </Row>)

      }
      <Row type='flex' justify='space-between' align='middle' gutter={8}>
        {!!userDetails.superUser && <Button style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => webhookAttemptsButton(quotation.id)}>{getLabel('webhook attempts', true, strings)}</Button>}
        {!!userDetails.superUser && <Button style={{ marginTop: "20px", marginBottom: "20px"  }} onClick={() => setShowChangeCompanyModal(true)}>{getLabel('Wijzig bedrijf', true, strings)}</Button>} {/* webhookAttemptsButton(quotation.id)  */}
      </Row>
      <Row type='flex' justify='space-between' align='middle' gutter={8}>
        {quotation.order && quotation.order.generated_id &&
          <Col>
            <p style={{ marginTop: 25, display: "block" }}>
              <a target="_blank" href={`https://order.b2bike.be/?id=${quotation.order.generated_id}`}>Order status</a>
              {!!userDetails.superUser &&
                <Button
                  type='primary'
                  shape='circle'
                  size='small'
                  style={{ marginLeft: 10 }}
                  onClick={() => setEditOrderStatusVisible(true)}
                >
                  <Icon type="edit" />
                </Button>
              }
            </p>
          </Col>
        }
        {userDetails.superUser ? (
          <Col>
            <Form>
              <Form.Item label='Vroegste leverdatum'>
                <DatePicker
                  value={quotation.earliest_delivery_date ? moment(new Date(Number(quotation.earliest_delivery_date)), dateFormat) : null}
                  format={dateFormat} onChange={(date, dateString) => {
                    console.log(date);
                    if (!!date) {
                      date.set('hours', 11);
                      updateEarliestDeliveryDate(date);
                      return;
                    }
                    updateEarliestDeliveryDate(null)
                    // else {setEarliestDate('null')}
                  }} />
              </Form.Item>
            </Form>
          </Col>
        ) : (
          <Col hidden={!quotation.earliest_delivery_date}>
            <Statistic style={{ textAlign: 'center' }} valueStyle={{ fontSize: '12pt' }}
              title={<span style={{ fontSize: '9pt' }}>{getLabel('earliest delivery date', true, strings)}</span>}
              value={new Date(Number(quotation.earliest_delivery_date)).toLocaleDateString('nl-be')} />
          </Col>
        )}
      </Row>
      {!!userDetails.superUser && !!quotation && !!quotation.order && !!quotation.order.deal_id_v2 &&
        <Col>
          <p style={{ marginTop: 25, display: "block" }}>
            <a target="_blank" href={`https://focus.teamleader.eu/web/deals/${quotation.order.deal_id_v2}`}>Deal link</a>
            <Button
              type='primary'
              shape='circle'
              size='small'
              style={{ marginLeft: 10 }}
              onClick={() => setEditLinkedDealVisible(true)/*console.log(quotation.quotation_number, userDetails)*/}
            >
              <Icon type="edit" />
            </Button>
          </p>
        </Col>
      }
    </Card>)
  }
  return null;
}