import React, { useState, useEffect } from 'react'
import get from 'lodash.get'

import Button from './wrappedComponents/button'
import Dropdown from './wrappedComponents/dropdown'
import TextField from './wrappedComponents/textfield'

const Filter = (props) => {
  const [filterValue, setFilterValue] = useState({})

  useEffect(() => {
    const defaultFilterValues = get(props.filterVariables, props.filterKey, [])

    if (defaultFilterValues.length > 0) {
      props.setSelectedKeys(defaultFilterValues)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        justifyContent: 'center',
        width: '200px',
      }}
    >
      {props.filterComponent === 'TextField' && (
        <TextField
          placeholder={`Filter op ${props.title}`}
          value={props.selectedKeys[0]}
          onChange={(e) => {
            props.setSelectedKeys(e.target.value ? [e.target.value] : [])
            setFilterValue({ [props.filterKey]: e?.target?.value })
          }}
          onPressEnter={() => {
            props.confirm()

            props.handleSearch(filterValue)
          }}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
      )}
      {props.filterComponent === 'MultiSelect' && (
        <Dropdown
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Selecteer alstublieft"
          value={props.selectedKeys}
          onChange={(value) => {
            props.setSelectedKeys(value ? value : [])
            setFilterValue({ [props.filterKey]: value ? value : [] })
          }}
          options={props.menuItems.map((menuItem) => ({
            key: menuItem?.id,
            value: menuItem?.id,
            name: menuItem?.name,
          }))}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          justifyContent: 'space-around',
        }}
      >
        <Button
          onClick={() => {
            props.confirm()

            props.handleSearch(filterValue)
          }}
        >
          Apply
        </Button>
        <Button
          onClick={() => {
            props.clearFilters()

            setFilterValue({})
            props.resetFilter(props.filterKey)
          }}
        >
          Clear
        </Button>
      </div>
    </div>
  )
}

export default Filter
