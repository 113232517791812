import React, { useState, useEffect } from 'react';
import { Select, List, Button, Row, Col, Icon, Card, Tag, Switch } from 'antd';
import { QUERIES } from '../../api/queries';
import axios from 'axios';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { MODAL_TYPE_ENUM } from './userModal';
const { Option } = Select;


const CompanyTab=(props)=>{
    const { userDetails, selectedDealer, setEditMode, setNewUser, userModel, setCreateNewUserVisible, deleteUser, allUsers, setCurrentModalType } = props;

    const [userCompanies, setUserCompanies] = useState([]);
    const [originalUserCompanies, setOriginalUserCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({id: -1});
    const [portalUserFilterBool, setPortalUserFilterBool] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
      if(!!portalUserFilterBool && !!originalUserCompanies && originalUserCompanies.length > 0) {
        const portalUserCompanies = originalUserCompanies.filter((item) => {
          return item.portal_user == true;
        });
        setUserCompanies(portalUserCompanies);
      } else setUserCompanies(originalUserCompanies);
    }, [portalUserFilterBool, originalUserCompanies]);

    useEffect(() => {
      if(!!currentCompany && currentCompany.id != -1) onCompanyChange(currentCompany.id);
    }, [originalUserCompanies]);

    useEffect(() => {
      if(!!currentCompany && currentCompany.id != -1 && !!allUsers) {
        if (!!userDetails.superUser) {
          getAllCompanies();
        }
        else {
          getCompaniesById();
        }
        onCompanyChange(currentCompany.id)
      };
    }, [allUsers]);

    const [getCompaniesById, {loading: loadingById}] = useLazyQuery(QUERIES.COMPANIES_BY_ID, {
      fetchPolicy: 'network-only',
      variables: {filter: {company_ids: userDetails.companyIds}},
      onCompleted: queryRes => {
        setUserCompanies([...queryRes.companiesById]);
        setOriginalUserCompanies([...queryRes.companiesById]);
      }
    });

    const [getAllCompanies, {loading: loadingAll}] = useLazyQuery(QUERIES.COMPANIES_ALL, {
      variables: {size: 9999},
      fetchPolicy: 'network-only',
      onCompleted: queryRes => {
        setUserCompanies([...queryRes.companies]);
        setOriginalUserCompanies([...queryRes.companies]);
      }
    });

    useEffect(() => {
      if (!!userDetails) {
        if (!!userDetails.superUser) {
          getAllCompanies();
        }
        else {
          getCompaniesById();
        }
      }
    }, [userDetails]);

    // useEffect(() => {
    //   if (!!currentCompany && !!currentCompany.id && currentCompany.id>0) {
    //     onCompanyChange(currentCompany.id);
    //   } getAllCompanies();
    // }, [allUsers]);

    const onCompanyChange=(compId)=>{
        setCurrentCompany(userCompanies.find(el => {
            return el.id === compId;
        }));
        const fu = allUsers.filter(el => {
        if (!el.companyIds) {
            return false;
        }
        if (el.email === 'werkgever@b2bike.com' && !userDetails.superUser) return false
        return el.companyIds.indexOf(parseInt(compId)) >= 0;
        });
        setFilteredUsers(fu);
    }

    return(
        <Row justify='center' type='flex' gutter={8}>
          {!!userDetails.superUser && (
            <Col style={{display: "flex", flex: "0 0 100%", justifyContent: "center", alignItems: "center", marginBottom: 10}} >
            <Switch defaultChecked={false} checked={portalUserFilterBool} checkedChildren="Portal users" unCheckedChildren="Alle bedrijven" onChange={(val)=>setPortalUserFilterBool(val)} />
          </Col>
          )}
          <Col span={6}>
            <Select
              disabled={loadingById || loadingAll}
              loading={loadingById || loadingAll}
              showSearch
              style={{ width: 500 }}
              placeholder="Selecteer bedrijf"
              optionFilterProp="children"
              onChange={onCompanyChange}
              filterOption={(input, option) =>
                option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={
                <div style={{width: 455}}>
                  {!!currentCompany && !!currentCompany.name ? currentCompany.name : ""}
                  {!!currentCompany && currentCompany.id != -1 && !! userDetails.superUser &&
                    <Tag color={!!currentCompany.portal_user ? "green" : "red"} style={{float: "right", marginTop: 4, color: !!currentCompany.portal_user ? "green" : "red" }}><Icon style={{color: !!currentCompany.portal_user ? "green" : "red"}} type={!!currentCompany.portal_user ? "check" : "close"} /> Portal user</Tag>
                  }
                </div>
              }
            >
              {userCompanies.map((el, index) => {
                return (
                <Option key={index} value={el.id}>
                    {el.name}
                    {userDetails.superUser && (
                      <Tag color={!!el.portal_user ? "green" : "red"} style={{float: "right", marginTop: 4, color: !!el.portal_user ? "green" : "red" }}><Icon style={{color: !!el.portal_user ? "green" : "red"}} type={!!el.portal_user ? "check" : "close"} /> Portal user</Tag>
                    )}
                </Option>)
              })}
            </Select>
          </Col>


          <Col style={{display: currentCompany.id === -1? 'none' : 'block', marginTop: 15}}>
            <List header={<div>Gebruikers bij bedrijf <Button
              style={{ margin: '16px' }}
              type='primary' shape='circle'
              onClick={() => {
                setEditMode(false);
                setNewUser({ ...userModel, language: currentCompany.language? currentCompany.language.code.toLowerCase(): 'nl', companyIds: [parseInt(currentCompany.id)], isPortaluser: filteredUsers.length == 3 });
                setCurrentModalType(MODAL_TYPE_ENUM.COMPANY);
                setCreateNewUserVisible(true);
              }}>
              <Icon type='plus' /></Button>
            </div>}>


              {filteredUsers.map((el, index) => {
                return (
                  <List.Item key={index}>
                    {/* <List.Item.Meta
                    title={`${el.first_name} ${el.last_name}`}
                    description={<p>{el.email ? el.email : ''}</p>}
                  /> */}
                    <Card
                      style={{ width: '300px' }}
                      actions={[
                        <Button shape='circle'
                          onClick={() => {
                            setNewUser(el);
                            setEditMode(true);
                            setCurrentModalType(MODAL_TYPE_ENUM.COMPANY);
                            setCreateNewUserVisible(true);
                          }}
                        ><Icon type="edit" /></Button>,
                        <Button shape='circle' type='danger'
                          disabled={el.email === userDetails.email}
                          onClick={async () => {
                            await deleteUser({ ...el, companyIds: [parseInt(currentCompany.id)], dealerIds: [], isPortaluser: filteredUsers.length <= 1 });
                            if (!!userDetails.superUser) {
                              getAllCompanies();
                            }
                            else {
                              getCompaniesById();
                            }
                            setCurrentCompany({...currentCompany, portal_user: false });
                          }}
                        ><Icon type="delete" /></Button>
                      ]}
                    >
                      <Card.Meta
                        title={`${el.first_name} ${el.last_name}`}
                        description={
                        <>
                          <p>{el.email ? el.email : ''}</p>
                        </>}
                      />
                    </Card>
                    {/* <Row type='flex' justify='center'>
                    <Col span={8}>
                      <Button shape='circle'><Icon type="edit" /></Button>
                      <Button shape='circle' type='danger'><Icon type="delete"/></Button>
                    </Col>
                  </Row> */}
                  </List.Item>
                );
              })}
            </List>
          </Col>



      </Row>
    )
  }

  export default CompanyTab;