import React, { useState, useEffect } from 'react'
import { Row, Col, Input, message, Form, Button, Icon, Popover, Modal, Menu, Dropdown, Divider } from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import * as firebase from 'firebase/app';
import { useStateValue } from '../stateProvider/stateProvider';
import axios from 'axios';
// import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
import { DownOutlined } from '@ant-design/icons';
import fbApp from '../../firebase';
const strengthStyle = {
  lineHeight: 0,
  marginLeft: 25
}


const MyAccount = props => {
  const [{ currentUser, userDetails }] = useStateValue();
  const [oldPassword, setOldPAssword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { strings } = useI18nContext();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVerificationModalVisible, setIsVerificationModalVisible] = useState(false);
  const [phone, setPhone] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [MFAPassword, setMFAPassword] = useState(null);
  const [countryCode, setCountryCode] = useState('+32');
  const [verificationIdFromFirebase, setVerificationIdFromFirebase] = useState(null);
  const [MfaActivated, setMfaActivated] = useState(false);

  let pwContent = null;
  let addModal = null;
  let removeModal = null;



  const checkPasswordIsAvailable = async () => {
    try {
      const { data } = await axios.post((process.env.REACT_APP_ENV === 'production' ? "https://europe-west1-b2bike-253312.cloudfunctions.net/hashPw" : "https://europe-west1-b2bike-253312.cloudfunctions.net/hashPwStaging"), {
        candidate: newPassword
      }, {
        headers: {
          authorization: await currentUser.getIdToken(true)
        }
      });

      return data.result == "ok";
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    setRecaptchaVerifier(new firebase.auth.RecaptchaVerifier('enroll-button', {
      'size': 'invisible',
      'callback': function (response) {
        console.log(response);
        console.log('z solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...)');
      },
      'error-callback': function (res) {
        console.log(res);
        console.log('error occured in error-callback');
      },
      'errorCallback': function (res) {
        console.log(res);
        console.log('error occured in errorCallback');
      }
    }, fbApp)
    )
  }, [])

  useEffect(() => {
    //MFA activation check
    setMfaActivated(currentUser.multiFactor.enrolledFactors.length >= 1);
  }, [currentUser]);

  const updatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword !== newPasswordConfirmation) {
      setLoading(false);
      return message.warning('Nieuw wachtwoord komt niet overeen met bevestiging.');
    }
    if (!newPassword.match(/^(?=.*\d)(?=.*[!@#$%^&]).{8,}$/) || !newPassword.match(/^(?=.*[A-Z])/)) {
      setLoading(false);
      return message.warning('Nieuw wachtwoord voldoet niet aan de voorwaarden.');
    }
    if (!await checkPasswordIsAvailable()) {
      setLoading(false);
      return message.warning('Nieuw wachtwoord is reeds eerder gebruikt.');
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      oldPassword
    );
    currentUser.reauthenticateWithCredential(credential)
      .then(() => {
        return currentUser.updatePassword(newPassword)
          .then(() => {
            message.success(getLabel('password', true, strings) + ' ' + getLabel('modified', false, strings));
            setOldPAssword('');
            setNewPassword('');
            setNewPasswordConfirmation('');
            setPasswordChanged(true);
          })
      })
      .catch(err => {
        console.error('myAccount - Change password failed: ', err);
        message.error(getLabel('error', true, strings));
      }).finally(() => {
        setLoading(false);
      });
  };

  // ALl handles for add phone num modal 
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        MFAPassword
      );
      
      const reAuthRes = await currentUser.reauthenticateWithCredential(credential)
      await fbApp.database().ref(`users/${reAuthRes.user.uid}`).update({ loginTime: new Date() });
      const multiFactorSession = await currentUser.multiFactor.getSession()

        // Specify the phone number and pass the MFA session.
        var phoneInfoOptions = {
          // phoneNumber: phone,
          phoneNumber: countryCode + phone,
          session: multiFactorSession
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        console.log(recaptchaVerifier);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions, recaptchaVerifier);
          // Ask user for the verification code.
          setVerificationIdFromFirebase(verificationId);
          setIsVerificationModalVisible(true)
    }
    catch (err) {
      setIsModalVisible(false);
      // console.error('MFA - reauthenticate password failed: ', err);
      message.error(getLabel('error', true, strings));
      console.error(err);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // all handles for enter verificationcode modals

  const handleVerificationOk = () => {
    var cred = firebase.auth.PhoneAuthProvider.credential(verificationIdFromFirebase, verificationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    // Complete enrollment.
    currentUser.multiFactor.enroll(multiFactorAssertion).then(() => {
      message.info('Succes');
      setIsVerificationModalVisible(false);
      setMfaActivated(true);
    }).catch(e => {
      message.warning(getLabel('Code does not match', true, strings));
      return
    });

  };

  const handleVerificationCancel = () => {
    setIsVerificationModalVisible(false);
  };

  // all handles for remove verification modals

  const handleRemoveOk = () => {
    setIsModalVisible(false);
  };

  const handleRemoveCancel = () => {
    setIsModalVisible(false);
  };
  const specialCharContent = (
    <div>
      <p style={{ textAlign: "center" }}>{"!@#$%^&".split('').join(' ')}</p>
    </div>
  );
  let countryArr = [
    {
      country: 'BE',
      code: '+32',
      key: 'country_32'
    },
    {
      country: 'NL',
      code: '+31',
      key: 'country_31'
    },
    {
      country: 'FR',
      code: '+33',
      key: 'country_33'
    },
    {
      country: 'DE',
      code: '+49',
      key: 'country_49'
    },
    {
      country: 'LUX',
      code: '+352',
      key: 'country_352'
    },
  ];

  const menu = (
    <Menu>
      {countryArr.map((c) =>
      (<Menu.Item onClick={() => { setCountryCode(c.code) }} key="menu_item_country_code">
        <p>
          {c.country + ' ' + c.code}
        </p>
      </Menu.Item>)
      )}
    </Menu>
  );

  addModal = (
    <Modal title={getLabel("Twofactor auth toevoegen", true, strings)} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <p>{getLabel('Stuurt een SMS bericht voor de verificatie Standaard tarieven zijn van toepassing', true, strings)}</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Dropdown overlay={menu} defaultValue="+32">
          <Button>
            {countryCode ? countryCode : 'Code'}<DownOutlined />
          </Button>

        </Dropdown>
        <Input style={{
          width: '80%',
          marginLeft: 10
        }} type="tel" placeholder='Gsm nummer' onChange={(e) => { setPhone(e.target.value) }}></Input>
      </div>

      <Input type="password" style={{ marginTop: '1em' }} placeholder='Huidig wachtwoord' onChange={(e) => { setMFAPassword(e.target.value) }}></Input>
    </Modal>
  )
  removeModal = (
    <Modal title="Verwijder Two-factor auth" visible={isModalVisible} onOk={handleRemoveOk} onCancel={handleRemoveCancel}>

      <Input type="password" placeholder='Huidig wachtwoord'></Input>

    </Modal>
  )


  if (!passwordChanged) {
    pwContent = (<div style={{ marginTop: 25 }}>
      <Row type='flex' justify='center'>
        <Col span={8}>
          <h3 style={{ marginBottom: 20, marginLeft: -25 }}>{getLabel('Wachtwoord wijzigen', true, strings)}</h3>

          <Form onSubmit={updatePassword}>
            <Form.Item label='Huidig wachtwoord' required={true}>
              <Input.Password onChange={(e) => {
                const val = e.target.value;
                setOldPAssword(val);
              }} />
            </Form.Item>
            <Form.Item label='Nieuw wachtwoord' required={true}>
              <p style={{ ...strengthStyle, color: newPassword.match(/^.{8,}$/) ? "Green" : "Red" }}>{newPassword.match(/^.{8,}$/) ? <Icon type="check" /> : <Icon type="close" />} Bevat minstens 8 karakters</p>
              <p style={{ ...strengthStyle, color: newPassword.match(/^(?=.*\d)/) ? "Green" : "Red" }}>{newPassword.match(/^(?=.*\d)/) ? <Icon type="check" /> : <Icon type="close" />} Bevat minstens één nummer</p>
              <p style={{ ...strengthStyle, color: newPassword.match(/^(?=.*[!@#$%^&])/) ? "Green" : "Red" }}>{newPassword.match(/^(?=.*[!@#$%^&])/) ? <Icon type="check" /> : <Icon type="close" />} Bevat minstens één speciaal karakter <Popover content={specialCharContent} title="Speciale karakters"><Icon style={{ color: "#000000", marginLeft: 5 }} type="info-circle" /></Popover></p>
              <p style={{ ...strengthStyle, color: newPassword.match(/^(?=.*[A-Z])/) ? "Green" : "Red" }}>{newPassword.match(/^(?=.*[A-Z])/) ? <Icon type="check" /> : <Icon type="close" />} Bevat minstens één hoofdletter</p>
              <Input.Password onChange={(e) => {
                const val = e.target.value;
                setNewPassword(val);
              }} />
            </Form.Item>
            <Form.Item label='Bevestig nieuw wachtwoord' required={true}>
              <Input.Password onChange={(e) => {
                const val = e.target.value;
                setNewPasswordConfirmation(val);
              }} />
            </Form.Item>
            <Button type="primary" htmlType="submit" disabled={!oldPassword || !newPassword || !newPasswordConfirmation || loading} loading={loading}>
              {getLabel('Wachtwoord wijzigen', true, strings)}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>)
  }

  else if (passwordChanged) {
    pwContent = (<Row type='flex' justify='center'>
      <Col span={12}>
        <h2 style={{ textAlign: 'center' }}>{getLabel('password', true, strings) + ' ' + getLabel('modified', false, strings)}</h2>
      </Col>
    </Row>)
  }

  const mfaContent = (
    <Row type='flex' justify='center' style={{ marginTop: 25, marginBottom: 25 }}>
      <Col span={8}>
        <Divider />
        <h3 style={{ marginBottom: 20, marginLeft: -25 }}>{getLabel('multifactor-label', true, strings)}</h3>

        <p><Icon type="info-circle" style={{ marginRight: 15 }} />{getLabel('multifactor-info', true, strings)}</p>

        {!MfaActivated ?
          <Button id='enroll-button' type="primary" onClick={showModal} style={{ marginTop: '1em' }}>
            {!currentUser.phoneNumber ? getLabel('2FA instellen', true, strings) : '2FA uitschakelen'}
          </Button > :
          <><Icon type="safety" style={{ marginRight: 15, fontSize: 30, color: "green", verticalAlign: "middle" }} /><Button disabled={MfaActivated} style={{ marginTop: '1em' }}>
            {getLabel('2FA Actief', true, strings)}
          </Button ></>
        }

        {!currentUser.phoneNumber ? addModal : removeModal}
        <Modal title={getLabel("Verificatie", true, strings)} visible={isVerificationModalVisible} onOk={handleVerificationOk} onCancel={handleVerificationCancel}>

          <Input type="text" placeholder={getLabel('Sms code', true, strings)} onChange={(e) => { setVerificationCode(e.target.value) }}></Input>

        </Modal>
      </Col>
    </Row>
  );


  return (<div>
    <h2>Account</h2>
    {pwContent}
    {mfaContent}
  </div>)
}

export default MyAccount;