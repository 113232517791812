import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import get from 'lodash.get'

import Icon from './wrappedComponents/icon'
import Button from './wrappedComponents/button'
import {
  sendEmail,
  templatesPreRegistration,
  templatesPreRegistrationOlderThenTwoYears,
  replaceValuesInStringByObject,
  subjectPreregistration,
  subjectPreregistrationOlderThanTwoYears,
  immatMail,
} from '../utils'
import { useStateValue } from '../../stateProvider/stateProvider'
import { QUERIES } from '../../../api/queries'
import { openNotificationWithIcon } from './wrappedComponents/notification'
import Badge from './wrappedComponents/badge'
import hooks from '../hooks'

const marginLeft = 10

const ActionCell = (props) => {
  const [{ token }] = useStateValue()
  const [
    amountOfEmailsPreRegistration,
    setAmountOfEmailsPreRegistration,
  ] = hooks.useLocalStorage(
    `amountOfEmailsPreRegistration_${props.rowData.id}`,
    0
  )
  const [
    amountOfEmailsPreRegistrationTwoYears,
    setAmountOfEmailsPreRegistrationTwoYears,
  ] = hooks.useLocalStorage(
    `amountOfEmailsPreRegistrationTwoYears_${props.rowData.id}`,
    0
  )

  // mutation
  const [updateLicensePlateRequest] = useMutation(
    QUERIES.UPDATE_LICENSE_PLATE_REQUEST
  )

  const orderLogs = props.rowData?.bike?.order?.orderLogs ?? []

  const fileCOCData = orderLogs.find((log) => log.order_log_type_fk === '8')
  const file705Data = orderLogs.find((log) => log.order_log_type_fk === '9')

  const frameNumber = get(props.rowData, 'bike.frame_number', undefined)
  const dealerEmail = get(
    props.rowData,
    'bike.order.quotation.dealer.email',
    undefined
  )
  const language = get(
    props.rowData,
    'bike.order.quotation.dealer.language',
    'nl'
  )

  const handleSendEmail = async (isOlderThenTwoYears) => {
    props.setEmailLoading(true)

    // feedback to the user when there is no frame number found
    if (!frameNumber) {
      props.setEmailLoading(false)
      openNotificationWithIcon({
        type: 'error',
        message:
          'Er is geen framenummer toegewezen aan de bike, dit zorgt ervoor dat de mail niet kan worden verzonden. Wanneer u het framenummer hebt aangepast, kunt u opnieuw proberen.',
        duration: 0,
      })

      return
    }

    // feedback to the user when there is no dealer's email found
    if (!dealerEmail) {
      props.setEmailLoading(false)
      openNotificationWithIcon({
        type: 'error',
        message:
          'Er is geen email toegewezen aan de dealer, dit zorgt ervoor dat de mail niet kan worden verzonden. Wanneer de dealer een email heeft, kunt u opnieuw proberen.',
        duration: 0,
      })

      return
    }

    const todaysDate = new Date()

    try {
      await sendEmail({
        to: dealerEmail,
        subject: replaceValuesInStringByObject({
          stringToReplace: isOlderThenTwoYears
            ? subjectPreregistrationOlderThanTwoYears[language]
            : subjectPreregistration[language],
          dataToChange: {
            quotationId: props.rowData?.bike?.order?.quotation?.id ?? '',
          },
        }),
        html: replaceValuesInStringByObject({
          stringToReplace: isOlderThenTwoYears
            ? templatesPreRegistrationOlderThenTwoYears[language]
            : templatesPreRegistration[language],
          dataToChange: {
            frame_number: frameNumber,
            immat_mail: immatMail,
          },
        }),
        headers: { authorization: token },
      })

      // update the amount of pre registrations trough local storage
      if (isOlderThenTwoYears) {
        setAmountOfEmailsPreRegistrationTwoYears(
          !amountOfEmailsPreRegistrationTwoYears
            ? 1
            : JSON.parse(amountOfEmailsPreRegistrationTwoYears) + 1
        )
      } else {
        setAmountOfEmailsPreRegistration(
          !amountOfEmailsPreRegistration
            ? 1
            : JSON.parse(amountOfEmailsPreRegistration) + 1
        )
      }

      await updateLicensePlateRequest({
        variables: {
          licensePlateRequest: {
            id: props.rowData.id,
            license_plate_request_status_fk: isOlderThenTwoYears ? '3' : '2',
            comment: `${props.rowData.comment ?? ''}\nPre registratie mail${
              isOlderThenTwoYears ? ' ouder dan 2 jaar ' : ' '
            }is verzonden op ${todaysDate.toLocaleDateString('nl-BE')}`,
          },
        },
      })

      await props.fetchTableData()

      props.setEmailLoading(false)
    } catch (err) {
      console.error(err)
      props.setEmailLoading(false)
      openNotificationWithIcon({
        type: 'error',
        message:
          'Er was een probleem tijdens het verzenden van de email voor de pre registratie. Probeer het later nog eens aub.',
        duration: 0,
      })
      return
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {Boolean(fileCOCData) && (
        <Button
          tooltipTitle="Ga naar de file: COC"
          style={{ marginLeft }}
          type="primary"
          onClick={() => {
            window.open(
              `https://drive.google.com/file/d/${fileCOCData.file_id}`,
              'blank'
            )
          }}
        >
          <Icon type="file" />
        </Button>
      )}

      {Boolean(file705Data) && (
        <Button
          tooltipTitle="Ga naar de file: 705"
          style={{ marginLeft }}
          type="primary"
          onClick={() => {
            window.open(
              `https://drive.google.com/file/d/${file705Data.file_id}`,
              'blank'
            )
          }}
        >
          <Icon type="file-exclamation" />
        </Button>
      )}

      <Badge
        count={amountOfEmailsPreRegistration}
        title="Aantal verzonden e-mails"
        showZero={false}
      >
        <Button
          tooltipTitle="Zend verzoek tot pre registratie naar de dealer"
          style={{ marginLeft }}
          type="primary"
          icon="inbox"
          onClick={() => {
            handleSendEmail(false)
          }}
        />
      </Badge>

      <Badge
        count={amountOfEmailsPreRegistrationTwoYears}
        title="Aantal verzonden e-mails"
        showZero={false}
      >
        <Button
          tooltipTitle="Zend verzoek tot pre-registratie/fiets (ouder dan 2 jaar) naar de dealer"
          style={{ marginLeft }}
          type="primary"
          icon="inbox"
          onClick={() => {
            handleSendEmail(true)
          }}
        />
      </Badge>
    </div>
  )
}

export default ActionCell
