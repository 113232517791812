import React from 'react';
import { Modal, Row, Col, Button, Icon, Divider } from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import { secureFetchFromDrive } from '../../api/driveFetch';
import { useStateValue } from '../stateProvider/stateProvider';

const htmlSpinner = `
<head>
<style>   

iframe {
  position: absolute;
  top: 0px;
  left: 0px;
}

.lds-ripple {
  display: block;
  margin: 128px auto;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #999;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

</style>
</head>
<body>
<div class="lds-ripple"><div></div><div></div></div>
</body>
`;

function DocumentsViewerPopup({ quot, visible, callbacks }) {
    const { strings } = useI18nContext();
    const [{ token }] = useStateValue();

    const { setDocumentsModalVisible } = callbacks;

    const onOpenDoc = async (item) => {
        const pdfWindow = window.open('', '_blank');
        pdfWindow.document.write(htmlSpinner);
        let res = await secureFetchFromDrive(item.file_id, token);
        // const res = fetch(FetchFromDrive(item.file_id, token));
        if (!res){
            res = await secureFetchFromDrive(item.file_id, token);
        }
        if (!!res) {
            const blob = new Blob([res.data], { type: "application/pdf" });
            // console.log(blob);
            if(pdfWindow.URL){
            const uri = pdfWindow.URL.createObjectURL(blob);
            // pdfWindow.location = uri;
            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + uri + "'/>")
            // pdfWindow.document.write("<object width='100%' height='100%' data='" + uri + "'></object>")
            // window.open(uri, '_blank');
            }
        }
        else {
            console.warn(`Could not collect file (res == ${res})`);
        }
    }

    const collectFilteredOrderLogs = () => {
      const signed = quot.order.orderLogs.filter(item => {
        return item.orderLogType.code.includes("_SIGNED");
      }).map(item => {
        return item.orderLogType.code.split("_")[0];
      });
      
      const res = quot.order.orderLogs.filter(item => {
        return !(signed.indexOf(item.orderLogType.code) > -1);
      });
      return res;
    }

    return (
        <div >
            <Modal
                title={getLabel('Documenten', true, strings)}
                visible={visible}
                destroyOnClose={true}
                cancelText={getLabel('Terug', true, strings)}
                onCancel={() => setDocumentsModalVisible(false)}
                footer={null}
            >
                <Row type='flex' justify='space-between' style={{ marginBottom: '16px' }}>
                    <Col span={12} style={{textAlign:"left", fontWeight:"bold"}}>{getLabel("Beschrijving", true, strings)}</Col>
                    <Col span={4} style={{textAlign:"right", fontWeight:"bold"}}>{getLabel("Verborgen", true, strings)}</Col>
                    <Col span={3} style={{textAlign:"center", fontWeight:"bold"}}>{<Icon type="eye"/>}</Col>
                </Row>
                {/* Enkel de orderLogs met de prop hidden op true zijn eruit gehaald, false of null worden doorgelaten */}
                {collectFilteredOrderLogs().filter(orderLog => !orderLog.hidden).map((item, index) => 
                    (<div key={index}>
                        <Row type='flex' justify='space-between' style={{ marginBottom: '16px' }}>
                            <Col span={12} style={{textAlign:"left"}}>{item.description}</Col>
                            <Col span={4} style={{textAlign:"center"}}>{<Button disabled={true} shape='circle' type='dashed'><Icon type={item.hidden? "check": "close"}/></Button>}</Col>
                            <Col span={3} style={{textAlign:"center"}}>{<Button shape='circle' type='primary' onClick={() => onOpenDoc(item)}><Icon type="eye"/></Button>}</Col>
                        </Row>
                        <Divider style={{marginBottom: 10, marginTop: 10}} />
                    </div>)
                )}
            </Modal>
        </div>
    )
}

export default DocumentsViewerPopup;