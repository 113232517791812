import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Row, Col, Form, Select, Input, Button, Spin, Icon, DatePicker, Skeleton, message, Table, Switch, Divider, Popover, Tag, Popconfirm, Alert, Tooltip } from 'antd';
import { useI18nContext, getLabel } from '../../api/i18nService';
import FileUploader from '../fileUploader/fileUploader';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { QUERIES } from '../../api/queries';
import { secureFetchFromDrive } from '../../api/driveFetch';
import { useStateValue } from '../stateProvider/stateProvider';
import { downloadFileFromBlob, getDateString, getDestinationName } from '../../util/util';
import moment from 'moment';
import DOMPurify from 'dompurify'; //DOMPurify.sanitize()
import axios from 'axios';
import FormItem from 'antd/lib/form/FormItem';
import CompanySearch from '../companySearch/companySearch';

// import { doc } from 'prettier';
const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const htmlSpinner = `
<head>
<style>   

iframe {
  position: absolute;
  top: 0px;
  left: 0px;
}

.lds-ripple {
  display: block;
  margin: 128px auto;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #999;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

</style>
</head>
<body>
<div class="lds-ripple"><div></div><div></div></div>
</body>
`;

const getPrefix = (invTarget) => {
  return 'BIKE';
};

const getInvTargetName = invTarget => {
  // switch (invTarget) {
  //   case '1337984' || '4a182c11-bee3-0677-9068-faf3f4146a80':
  //     return 'Axus / ALD';
  //   case '1338141' || '95ebbec7-8297-06ee-b76d-eba69b146b1d':
  //     return 'Alphabet';
  //   case '38083485':
  //     return 'Alphabet';
  //   case '20656606' || 'b9ffd675-8aeb-08a7-9870-12f4c13b31de':
  //     return 'Arval';
  //   case '12168174' || '24934579-d7ee-070c-a461-0810a5b9abee':
  //     return 'Dieteren Lease';
  //   case '1338075' || 'd1d83194-0152-0965-ad6d-dae63b146adb':
  //     return 'J&T';
  //   case '4300823' || '4fbe9ee3-ae6d-0d2d-ae6e-e9fe1c41a017':
  //     return 'Directlease';
  //   case '37713835':
  //     return 'Westlease';
  //   default:
  //    return 'B2Bike';
  // }
  return 'B2Bike';
};

const minimumLengthAccuNumber = 4

/**
  * Inner component - Modal for file upload from status new
  */
export const PopupStatusNew = (props) => {

  const { strings } = useI18nContext();

  const { uploadFromNew, setUploadFromNewVisible } = props.callbacks;

  const [calculationDocSigned, setCalculationDocSigned] = useState({ type: 'CALCULATIONDOC_SIGNED', file: {} });
  const [quotationDoc, setQuotationDoc] = useState({ type: 'QUOTATIONDOC', file: {} });
  // useEffect(() => {
  //   console.log(calculationDocSigned, invoiceDoc);
  // }, [calculationDocSigned, invoiceDoc]);
  return (
    <Modal
      title={getLabel('uploading documents', true, strings)}
      // visible={uploadFromNewVisible}
      visible={true}
      // footer={null}
      onOk={() => {
        uploadFromNew(calculationDocSigned, quotationDoc);
      }}
      okButtonProps={{
        disabled: (calculationDocSigned.file && quotationDoc.file) ?
          ((calculationDocSigned.file.status === 2 && quotationDoc.file.status === 2) ? false : true) : true
      }}
      onCancel={() => {
        // console.log('cancel');
        setCalculationDocSigned({});
        setQuotationDoc({});
        setUploadFromNewVisible(false);
      }}>
      <Row type='flex' justify='center'>
        <Col>
          <FileUploader acceptedFileTypes={['application/pdf']} option={'CALCULATIONDOC_SIGNED'} locked={true} onFileChanged={val => setCalculationDocSigned(val)} />
          <FileUploader acceptedFileTypes={['application/pdf']} option={'QUOTATIONDOC'} locked={true} onFileChanged={val => setQuotationDoc(val)} />
          {/* <Button type='primary'>Bestanden opladen</Button> */}
        </Col>
      </Row>
    </Modal>
  )
};

/**
* Inner component - Modal for status deliverable
*/
export const PopupStatusDeliverable = (props) => {
  const { strings } = useI18nContext();

  const { uploadFromDeliverable, setUploadFromDeliverableVisible, setWorking } = props.callbacks;

  const [invoiceDoc, setInvoiceDoc] = useState({ type: 'INVOICEDOC', file: {} });
  const [receiptDocSigned, setReceiptDocSigned] = useState({ type: 'RECEIPTDOC_SIGNED', file: {} });
  const [deliveryDate, setDeliveryDate] = useState(null);
  // useEffect(() => {
  //   console.log(calculationDocSigned, invoiceDoc);
  // }, [calculationDocSigned, invoiceDoc]);
  return (
    <Modal
      title={getLabel('uploading documents', true, strings)}
      visible={true}
      // footer={null}
      onOk={() => {
        setWorking(true);
        uploadFromDeliverable(invoiceDoc, receiptDocSigned, deliveryDate);
      }}
      okButtonProps={{
        disabled: (invoiceDoc.file && receiptDocSigned.file) ?
          ((invoiceDoc.file.status === 2 && receiptDocSigned.file.status === 2 && !!deliveryDate) ? false : true) : true
      }}
      onCancel={() => {
        // console.log('cancel');
        setInvoiceDoc({});
        setReceiptDocSigned({});
        setUploadFromDeliverableVisible(false);
      }}>
      <Row type='flex' justify='center'>
        <Col>
          <Form>
            <Form.Item label={getLabel('datum ophaling door werknemer', true, strings)} required={true}>
              <DatePicker value={!!deliveryDate ? moment(deliveryDate, dateFormat) : null} format={dateFormat} onChange={(date, dateString) => {
                setDeliveryDate(date);
              }} />
            </Form.Item>
            <FileUploader acceptedFileTypes={['application/pdf']} option={'INVOICEDOC'} locked={true} onFileChanged={val => setInvoiceDoc(val)} />
            <FileUploader acceptedFileTypes={['application/pdf']} option={'RECEIPTDOC_SIGNED'} locked={true} onFileChanged={val => setReceiptDocSigned(val)} />
            {/* <Button type='primary'>Bestanden opladen</Button> */}
          </Form>
        </Col>
      </Row>
    </Modal>
  )
};

/**
* Inner component - Modal for completing bike info 
*/
export const PopUpCompleteBike = props => {
  // useEffect(() => {
  //   console.log('bikeModal props: ', props);
  // }, []);
  const { strings } = useI18nContext();
  const { setShowBikeModal, setSavingBike, saveBike, createReceipt, setUploadSpeedpedelecDocumentsVisible } = props.callbacks;
  const quotation = props.quotation;
  const userDetails = props.userDetails;
  const [isSpeedped, setIsSpeedPed] = useState(false);
  const [hasSpeedPedDocs, setHasSpeedPedDocs] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const savingBike = props.savingBike;
  const [brands, setBrands] = useState(null);
  const [bike, setBike] = useState({
    id: props.bike ? props.bike.id : null,
    // bike_type_fk: props.bike ? props.bike.bikeType.id : null,
    bike_type_fk: props.quotation.bike_type_fk,
    order_fk: quotation.order.id || null,
    employee_fk: quotation.employee.id,
    bike_brand_fk: props.bike ? props.bike.bikeBrand.id : null,
    model: props.bike ? (props.bike.model ? props.bike.model : '') : '',
    comment: props.bike ? (props.bike.comment ? props.bike.comment : '') : '',
    license_number: props.bike ? (props.bike.license_number ? props.bike.license_number : '') : '',
    frame_number: props.bike ? (props.bike.frame_number ? props.bike.frame_number : '') : '',
    control_number: props.bike ? (props.bike.control_number ? props.bike.control_number : '') : '',
    sticker_number: props.bike ? (props.bike.sticker_number ? props.bike.sticker_number : '') : '',
    key_number_1: props.bike ? (props.bike.key_number_1 ? props.bike.key_number_1 : '') : '',
    key_number_2: props.bike ? (props.bike.key_number_2 ? props.bike.key_number_2 : '') : '',
    accu_number: props.bike ? (props.bike.accu_number ? props.bike.accu_number : '') : '',
    b2bike_servicepas: (props.bike ? (props.bike.b2bike_servicepas ? props.bike.b2bike_servicepas : '') : '').replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
  });
  const [originalBike, setOriginalBike] = useState({ ...bike });

  const [frameNumberChanged, setFrameNumberChanged] = useState(false);


  useEffect(() => {
    let sticker = bike.sticker_number;
    sticker = sticker.replace(getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1), '');
    setBike(old => { return { ...old, sticker_number: sticker } });
  }, []);

  useEffect(() => {
    //Bike type check
    if (quotation && quotation.bikeType && quotation.bikeType.id == 1) {
      setIsSpeedPed(true);

      //docs check, already uploaded?
      if (quotation.order.orderLogs && quotation.order && quotation.order.orderLogs) {
        const hasCOC = quotation.order.orderLogs.find((item) => item.order_log_type_fk == 8) ? true : false;
        const has705 = quotation.order.orderLogs.find((item) => item.order_log_type_fk == 9) ? true : false;
        console.log(hasCOC, has705);
        if (hasCOC && has705) {
          setHasSpeedPedDocs(true);
        } else {
          setHasSpeedPedDocs(false);
        }
      }
    } else {
      setIsSpeedPed(false);
      setHasSpeedPedDocs(false);
    }

    //Deal status check for locking
    setIsLocked((quotation && quotation.order && quotation.order.deal_status_fk && quotation.order.deal_status_fk == 6) && !userDetails.superUser);
  }, [quotation])

  const { loading: brandLoading, error: brandError, data: brandData } = useQuery(QUERIES.BIKE_BRANDS_ALL, {
    onCompleted: brandRes => {
      setBrands(brandRes.bikeBrands);
    }
  });

  const checkValidations = () => {
    let result = true;

    const showError = (msg, extra) => {
      message.error(`${getLabel(msg, true, strings)} ${extra ? extra : ""}`);
      result = false;
    }

    //Service pass
    if (!!quotation.maintenance_amount && quotation.maintenance_amount > 0) {
      const barcode = bike.b2bike_servicepas.replace(/\s/g, ''); //Remove spaces for tests
      if (barcode.length != 19) showError("Servicepas moet bestaan uit 19 karakters", `(=${barcode.length})`);
      if (barcode.substring(0, 4) != "3120") showError("Servicepas moet beginnen met 3120");
    }

    return result;
  }

  let bikeContent = null;

  const [reachedStickerLimit, setReachedStickerLimit] = useState(false);
  const STICKER_CHAR_LIMIT = 8;

  const isBikeElectricBike = bike.bike_type_fk === '5'
  const isBikeSpeedPedelec = bike.bike_type_fk === '1'

  const isBikeElectricOrSpeed = isBikeElectricBike || isBikeSpeedPedelec

  const isSaveButtonDisabled = !!quotation.maintenance_amount && quotation.maintenance_amount > 0 ? ((!bike.bike_brand_fk || !bike.key_number_1 || !bike.frame_number || !bike.sticker_number || bike.sticker_number?.length !== 8) || isLocked || !bike.b2bike_servicepas || bike.b2bike_servicepas.length !== 23) : ((!bike.bike_brand_fk || !bike.key_number_1 || !bike.frame_number || !bike.sticker_number || bike.sticker_number?.length !== 8) || isLocked)

  if (brandData && brands) {
    bikeContent = (
      <Form>
        <Row type='flex' justify='center' gutter={16}>
          <Col span={12}>
            <Form.Item label={getLabel('bike brand', true, strings)} required={true}>
              <Select
                value={bike.bike_brand_fk}
                showSearch
                optionFilterProp='children'
                disabled={isLocked}
                onChange={
                  val => {
                    setBike(old => {
                      return { ...old, bike_brand_fk: val };
                    })
                  }
                }
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {brands.map((item, index) => {
                  return (<Option key={index} value={item.id}>{item.name}</Option>);
                })}
              </Select>
            </Form.Item>
            <Form.Item label={getLabel('model', true, strings)}>
              <Input defaultValue={bike.model}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, model: val };
                  })
                }} />
            </Form.Item>
            <Form.Item label={getLabel('license plate', true, strings)} required={isBikeSpeedPedelec}>
              <Input value={bike.license_number}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  // console.log(val.replace(/[^a-zA-Z0-9]/g, ''));
                  setBike(old => {
                    return { ...old, license_number: val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() };
                  })
                }} />
            </Form.Item>
            <Form.Item label={getLabel('frame number', true, strings)} required={true}
              validateStatus={isBikeSpeedPedelec ? (bike.frame_number ? (bike.frame_number.length === 17 ? 'success' : 'error') : 'error') : null}
              help={isBikeSpeedPedelec ? getLabel('17 tekens', false, strings) : ''}>
              <Input value={bike.frame_number}
                disabled={isLocked}
                onChange={e => {
                  setFrameNumberChanged(true);

                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, frame_number: val.toUpperCase() };
                  })
                }} />
            </Form.Item>
            {bike.bike_type_fk == 1 && <Form.Item label={getLabel('control number (modulo97)', true, strings)} required={false}
              validateStatus={bike.control_number ? (bike.control_number.match(/^\d{3}$/) ? 'success' : 'error') : null}>
              <Input defaultValue={bike.control_number}
                disabled={isLocked}
                onChange={e => {
                  const val = e.target.value;
                  setBike(old => {
                    return { ...old, control_number: val };
                  })
                }} />
            </Form.Item>}
            <Form.Item type='number' validateStatus={bike.sticker_number?.length < 8 ? 'error' : 'succes'}
              help={bike.sticker_number?.length !== 8 ? getLabel('Exact 8 numerieke tekens', false, strings) : ''}
              label={`${getLabel('sticker number', true, strings)} ${getInvTargetName(props.quotation.employee.company.invoice_target.tl_id_v1)}`} required={true}>
              <Alert message={<p style={{ margin: 0, padding: 0 }} >{`${getLabel('Opgelet: enkel BIKE STICKER gebruiken', true, strings)}`}</p>} type="info" showIcon style={{ margin: 0, marginBottom: 2 }} />
              <Tooltip><Input defaultValue={bike.sticker_number}
                disabled={isLocked} //! stickernumbrrrr
                maxLength={STICKER_CHAR_LIMIT}
                onInput={e => { e.target.value = e?.target?.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1') }}
                addonBefore={getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1)}
                onKeyDown={e => {
                  if (e.target.value.length >= STICKER_CHAR_LIMIT && /^.$/u.test(e.key)) {
                    setReachedStickerLimit(true)
                    setTimeout(function () {
                      setReachedStickerLimit(false)
                    }, 2000)
                  }
                }}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, sticker_number: val };
                  })
                }} /></Tooltip>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={getLabel('key number 1', true, strings)} required={true}>
              <Input value={bike.key_number_1}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, key_number_1: val.toUpperCase() };
                  })
                }} />
            </Form.Item>
            <Form.Item label={getLabel('key number 2', true, strings)}>
              <Input value={bike.key_number_2}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, key_number_2: val.toUpperCase() };
                  })
                }} />
            </Form.Item>
            <Form.Item
              label={getLabel('battery number', true, strings)} //! accuuu weg
              required={isBikeElectricOrSpeed}
              validateStatus={isBikeElectricOrSpeed ? (bike.accu_number ? (bike.accu_number.length >= minimumLengthAccuNumber ? 'success' : 'error') : 'error') : null}
              help={isBikeElectricOrSpeed ? getLabel('minimum 4 tekens', false, strings) : ''}
            >
              <Input defaultValue={bike.accu_number}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, accu_number: val };
                  })
                }} />
            </Form.Item>
            {!!quotation.maintenance_amount && quotation.maintenance_amount > 0 && <Form.Item label={getLabel('b2bike service pass', true, strings)} validateStatus={bike.b2bike_servicepas ? (bike.b2bike_servicepas ? (bike.b2bike_servicepas.length === 23 ? 'success' : 'error') : 'error') : null}
              help={bike.b2bike_servicepas ? getLabel('Exact 19 tekens', false, strings) : ''} required={quotation.maintenance_amount > 0}>
              <Input value={bike.b2bike_servicepas}
                disabled={isLocked}
                onChange={e => {
                  e.target.value = DOMPurify.sanitize(e.target.value);
                  let target = e.target, position = target.selectionEnd, length = target.value.length;
                  target.value = target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                  target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);
                  setBike(old => {
                    return { ...old, b2bike_servicepas: target.value };
                  })
                }} />
            </Form.Item>}
            <Form.Item label={getLabel('comment', true, strings)}>
              <Input defaultValue={bike.comment}
                disabled={isLocked}
                onChange={e => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setBike(old => {
                    return { ...old, comment: val };
                  })
                }} />
            </Form.Item>
          </Col>
          <Row type='flex' justify='space-between' gutter={16}>
            <Button disabled={savingBike} onClick={() => { setShowBikeModal(false) }}>{getLabel('cancel', true, strings)}</Button>
            <Button loading={savingBike} style={{ marginLeft: '16px' }} type='primary'
              disabled={isSaveButtonDisabled}
              onClick={() => {
                setShowBikeModal(false);
                setSavingBike(true);
                if (frameNumberChanged && isSpeedped) {
                  message.warn(getLabel('Nieuw framenummer! Gelieve de inschrijvingsdocumenten op te laden', true, strings))
                }
                let sticker = bike.sticker_number;
                if (bike.sticker_number.indexOf(getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1)) < 0) {
                  sticker = getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1) + bike.sticker_number
                }
                saveBike({ ...bike, sticker_number: sticker });
              }}
            >{getLabel('save', true, strings)}</Button>
            {/* {isSpeedped &&
              <Button style={{ marginLeft: '16px' }} type='primary'
                disabled={hasSpeedPedDocs || isLocked}
                onClick={() => {
                  setUploadSpeedpedelecDocumentsVisible(true);
                }}
              >{
                hasSpeedPedDocs?getLabel('Already uploaded', true, strings):getLabel('Speed pedelec documents', true, strings)
              }</Button>
            } */}
            <Button loading={savingBike} style={{ marginLeft: '16px' }} type='primary'
              disabled={!bike.bike_brand_fk ||
                (isBikeSpeedPedelec && bike.license_number.trim() === '') ||
                bike.frame_number.trim() === '' ||
                (bike.frame_number.trim().length !== 17 && isSpeedped) ||
                bike.sticker_number.trim() === '' ||
                bike.key_number_1.trim() === '' ||
                (isBikeSpeedPedelec && bike.accu_number.trim() === '') ||
                (isBikeElectricBike && bike.accu_number.trim() === '') ||
                (bike.accu_number.trim().length < minimumLengthAccuNumber && isBikeElectricOrSpeed) ||
                //  ||compareObjects(bike, originalBike)
                //(!hasSpeedPedDocs && isSpeedped) ||
                ((!!quotation.maintenance_amount && !!quotation.maintenance_amount > 0) ? bike.b2bike_servicepas.trim() === '' : false) ||
                isLocked
              }
              onClick={() => {
                //Bike validation
                if (!!checkValidations()) {
                  if (frameNumberChanged && isSpeedped) {
                    message.warn(getLabel('Nieuw framenummer! Gelieve de inschrijvingsdocumenten op te laden', true, strings))
                  }
                }

                //Deliveri date validation & warning system
                if (quotation.earliest_delivery_date) {
                  const today = new Date();
                  today.setHours(13, 0, 0, 0);
                  const dday = new Date(Number(quotation.earliest_delivery_date));
                  dday.setHours(13, 0, 0, 0);
                  if (today < dday) {
                    //Show modal to confirm it's visibility (guarantied)
                    Modal.warning({
                      title: getLabel('Vroegste leverdatum', true, strings),
                      okText: getLabel('Ok', true, strings),
                      content: (
                        <p>{`${getLabel('OPGELET: deze fiets mag pas uitgeleverd worden vanaf', true, strings)} `}<span style={{ fontWeight: 'bold' }}>{`${dday.toLocaleDateString()}`}</span></p>
                      )
                    });
                  }
                }

                setShowBikeModal(false);
                setSavingBike(true);
                saveBike({ ...bike, sticker_number: getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1) + bike.sticker_number }, createReceipt);
              }}
            >{getLabel('create receipt', true, strings)}</Button>
          </Row>
        </Row>
      </Form>
    );
  }
  if (brandLoading) {
    bikeContent = (
      <div>
        <Spin />
      </div>
    )
  }
  return (
    <Modal width={720}
      visible={true}
      footer={null}
      closable={false}
      destroyOnClose={true}>
      {bikeContent}
    </Modal>
  );
}


/**
* Inner component - Modal for uploading speedpedelec documents
*/
export const PopUpSpeedPedelecUploader = (props) => {

  const removePrefix = (stickerNumber) => {
    const prefix = getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1);
    if (!stickerNumber) return '';
    return (stickerNumber.replace(prefix, ''));
  }

  const { strings } = useI18nContext();
  const { speedPedDocsLoading } = props;
  const { setUploadSpeedpedelecDocumentsVisible, uploadFromSpeedPedDocuments } = props.callbacks;
  const [bike, setBike] = useState({ ...props.quotation.order.bike });
  const [rawSticker, setRawStiker] = useState(removePrefix(bike.sticker_number));

  const isBikeElectricBike = bike.bike_type_fk === '5'
  const isBikeSpeedPedelec = bike.bike_type_fk === '1'

  const [documentCOC, setDocumentCOC] = useState({ type: 'QUOTATIONDOC', file: {} });
  const [document705, setDocument705] = useState({ type: 'QUOTATIONDOC', file: {} });

  const docChange = (val, type) => {
    switch (type) {
      case "COC":
        setDocumentCOC(val);
        break;
      case "705":
        setDocument705(val);
        break;
    }
  }

  const onDocSubmit = () => {
    uploadFromSpeedPedDocuments(documentCOC, "COC Document", document705, "Document 705", { ...bike, sticker_number: getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1) + rawSticker, order_fk: props.quotation.order.id }, props.quotation.id)
  }
  const validateX12 = () => {
    if (!bike.barcode_x12) {
      return 'error'
    }
    else if (bike.barcode_x12.match(/^\d{9}$/)) {
      return 'success'
    }
    return 'error'
  }

  return (
    <Modal
      title='Speedped docs'
      visible={true}
      footer={null}
      onCancel={
        () => {
          setUploadSpeedpedelecDocumentsVisible(false);
        }
      }>
      <Alert style={{ marginBottom: 10 }} message={
        `${getLabel(props.quotation.employee.company.invoice_target.license_plate_auto_request ? "license plate handled msg" : "license plate not handled msg", true, strings)}.`} type="info" showIcon banner={false} />
      <Form>
        <Row type='flex' justify='center'>
          <Col>
            <Form.Item label={`${getLabel('frame number', true, strings)} (${getLabel('17 tekens', false, strings)})`} required={true}
              validateStatus={(bike.frame_number ? (bike.frame_number.length === 17 ? 'success' : 'error') : null)}
              help={isBikeSpeedPedelec ? getLabel('17 tekens', false, strings) : ''}>
              <Input value={bike.frame_number}
                disabled={speedPedDocsLoading}
                onChange={e => {
                  const val = e.target.value;
                  setBike(old => { return { ...old, frame_number: val.toUpperCase() } })
                }} />
            </Form.Item>
            <Form.Item label={getLabel('control number (modulo97)', true, strings)} required={true}
              validateStatus={bike.control_number ? (bike.control_number.match(/^\d{3}$/) ? 'success' : 'error') : null}>
              <Input value={bike.control_number}
                disabled={speedPedDocsLoading}
                onChange={e => {
                  const val = e.target.value;
                  setBike(old => {
                    return { ...old, control_number: val };
                  })
                }} />
            </Form.Item>
            <Form.Item label={getLabel('sticker number', true, strings)} required={false}>
              <Input
                disabled={speedPedDocsLoading}
                addonBefore={getPrefix(props.quotation.employee.company.invoice_target.tl_id_v1)}
                value={rawSticker}
                onChange={e => {
                  const val = e.target.value;
                  setRawStiker(val)
                }}
              />
            </Form.Item>
            <Form.Item
              label={getLabel('Refertenummer (vak X12)(9 cijfer)', true, strings)}
              validateStatus={validateX12()}
              required={true}>
              <Input
                help='(9 cijfer)'
                disabled={speedPedDocsLoading}
                value={bike.barcode_x12}
                onChange={e => {
                  const val = e.target.value;
                  setBike(old => {
                    return { ...old, barcode_x12: val }
                  })
                }}
              />
            </Form.Item>
            <FileUploader acceptedFileTypes={['application/pdf']} option={'COCDOC'} locked={true} onFileChanged={val => docChange(val, "COC")} />
            <FileUploader acceptedFileTypes={['application/pdf']} option={'DOC705'} locked={true} onFileChanged={val => docChange(val, "705")} />
          </Col>
        </Row>
      </Form>

      <Button
        type="primary"
        loading={speedPedDocsLoading}
        disabled={(documentCOC?.file ? (documentCOC?.file?.status === 2 ? false : true) : true)
          || (document705?.file ? (document705?.file?.status === 2 ? false : true) : true)
          || speedPedDocsLoading
          || bike?.frame_number?.length !== 17
          || bike.accu_number?.length < minimumLengthAccuNumber
          || (bike?.control_number?.length !== 3)
          || (validateX12() === 'error')
        }
        style={{ marginTop: "25px" }}
        onClick={() => onDocSubmit()}
      >
        {getLabel("OK", true, strings)}
      </Button>
    </Modal>
  );
}


/**
* Inner component - Modal for uploading extra document
*/
export const PopUpFileUploader = (props) => {
  const { strings } = useI18nContext();
  const { extraDocLoading } = props;
  const { setUploadExtraDocumentVisible, uploadFromExtraDocument } = props.callbacks;
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState({ type: 'QUOTATIONDOC', file: {} });

  const docChange = (val) => {
    setDocument(val);
  }

  const onDocSubmit = () => {
    uploadFromExtraDocument(document, description);
  }

  return (
    <Modal
      title='Extra document'
      visible={true}
      footer={null}
      onCancel={
        () => {
          setUploadExtraDocumentVisible(false);
        }
      }>
      <Alert style={{ marginBottom: 10 }} message={`${getLabel(`geen getekende berekening, offerte, factuur, ontvangstbewijs, coc of document 705`, true, strings)}`} type='warning' showIcon banner={false} />
      <Input style={{ margin: "auto", marginBottom: "25px" }} placeholder={getLabel("Titel", true, strings)} onChange={(evt) => setDescription(DOMPurify.sanitize(evt.target.value))} />
      <FileUploader acceptedFileTypes={['application/pdf']} option={'OTHER'} locked={true} onFileChanged={val => docChange(val)} />
      <Button
        type="primary"
        loading={extraDocLoading}
        disabled={(document.file ? (document.file.status === 2 ? false : true) : true) || (description == "") || extraDocLoading}
        style={{ marginTop: "25px" }}
        onClick={() => onDocSubmit()}
      >
        <Icon style={{ marginLeft: "15px" }} type="file-add" />{getLabel("Bewaar document", true, strings)}
      </Button>
    </Modal>
  );
}

export const PopUpChangeCompany = (props) => {
  const { strings } = useI18nContext();

  const [updateQuotation] = useMutation(QUERIES.UPDATE_QUOTATION);
  const quotation = props.quotation;
  const { setShowChangeCompanyModal, setQuotation } = props.callbacks

  const [showCompanySelector, setShowCompanySelector] = useState(false)
  const [newCompany, setNewCompany] = useState();

  const updateCompanyQuotation = async () => {
    const quotationId = quotation?.id
    const quotationCompanyId = quotation?.company?.id

    const newCompanyId = newCompany?.id
    const newCompanyName = newCompany?.name
    const newCompanyFip = newCompany?.fip
    const newCompanyHide = newCompany?.hide

    if (!newCompanyId || newCompanyId === quotationCompanyId) {
      setShowChangeCompanyModal(false);
      return
    }

    await updateQuotation({variables: {quotation: {id: quotationId, company_fk: newCompanyId}}})
      .then(res => {
        setQuotation((old) => {
          old.company.id = newCompanyId;
          old.company.name = newCompanyName;
          old.company.fip = newCompanyFip;
          old.company.hide = newCompanyHide;
          return { ...old };
        });
      })
      .catch(err => {
        console.error(err);
        message.error('Er ging iets mis...');
      })

    setShowChangeCompanyModal(false);
  }

  return (
    <Modal
      title={getLabel("Wijzig bedrijf", true, strings)}
      visible={true}
      footer={null}
      onCancel={
        () => {
          setShowChangeCompanyModal(false);
        }
      }>
      <Row type='flex' justify='space-between' gutter={8} style={{ marginBottom: '16px' }}>
        <Col span={10} style={{ textAlign: "center", fontWeight: "bold" }}>{getLabel("Bedrijf", true, strings)}</Col>
        <Col span={10} style={{ textAlign: "center", fontWeight: "bold" }}>{getLabel("Nieuw bedrijf", true, strings)}</Col>
      </Row>
      <Row type='flex' justify='space-between' gutter={8} style={{ marginBottom: '16px' }}>
        <Col span={10} style={{ textAlign: "center" }}>{quotation?.company?.name}</Col>
        <Col span={10} style={{ textAlign: "center", fontWeight: "bold" }}>
          <Button onClick={() => { setShowCompanySelector(true) }}>{newCompany?.name || getLabel('Bedrijf selecteren', true, strings)}</Button>
          <Modal
            visible={showCompanySelector}
            width={720}
            footer={null}
            onCancel={() => {
              setShowCompanySelector(false);
            }}
            destroyOnClose={true}>
            <CompanySearch callback={newComp => {
              console.log(newComp)
              setNewCompany(newComp);
              setShowCompanySelector(false);
            }} />
          </Modal>
        </Col>
      </Row>
      <Row type='flex' justify='space-between' gutter={8} style={{ marginBottom: '16px' }}>
        <Col span={10} style={{ textAlign: "center", fontWeight: "bold" }} />
        <Col span={10} style={{ textAlign: "center", fontWeight: "bold" }}>
          <Button
            style={{ marginTop: "30px" }}
            type="primary"
            disabled={!newCompany?.id || newCompany?.id === quotation?.company?.id }
            onClick={() => updateCompanyQuotation()}>
            {getLabel("Updaten", true, strings)}
          </Button>
        </Col>  
      </Row>
    </Modal>
  )
}

/**
* Inner component - Modal for showing documents
*/
export const PopUpShowDocuments = (props) => {
  const { strings } = useI18nContext();
  const currentUser = props.currentUser;
  // const showDocumentsVisible = props.showDocumentsVisible;
  const quotation = props.quotation;
  const { setShowDocumentsVisible, setUploadExtraDocumentVisible, setQuotation } = props.callbacks
  const [{ token }] = useStateValue();
  const [content, setContent] = useState(null);
  //Hidden switches
  const [globalHidden, setGlobalHidden] = useState(false);
  const [updateOrderLogMutation] = useMutation(QUERIES.UPDATE_ORDER_LOG);
  const [switchUpdateLoading, setSwitchUpdateLoading] = useState(false);
  // currentUser.getIdToken(true).then(userToken => {
  //   //console.log(userDealers);
  //   //console.log(userToken);
  //   setToken(userToken);
  // });

  const updateOrderLog = async (orderLog, index, state) => {
    try {
      setSwitchUpdateLoading(true);
      const orderLogRes = await updateOrderLogMutation({
        variables: {
          orderLog: {
            id: orderLog.id,
            hidden: state
          }
        }
      });
      setQuotation((old) => {
        old.order.orderLogs[index].hidden = state;
        return { ...old };
      });
      setSwitchUpdateLoading(false);
    } catch (error) {
      console.error(error);
      setSwitchUpdateLoading(false);
      message.error(getLabel('something went wrong', true, strings));
    }
  }

  return (
    <Modal
      title={getLabel("documenten", true, strings)}
      visible={true}
      footer={null}
      onCancel={
        () => {
          setShowDocumentsVisible(false);
        }
      }>
      <p>{getLabel("Verborgen documenten", true, strings)} ({
        quotation.order.orderLogs.filter((log) => {
          return log.hidden == true
        }).length
      }): <Switch defaultChecked={false} checked={globalHidden} onChange={(val) => setGlobalHidden(val)} /></p>
      <Divider dashed={true} />
      {quotation.order.orderLogs.length > 0 &&
        <Row type='flex' justify='space-between' gutter={8} style={{ marginBottom: '16px' }}>
          <Col span={15} style={{ textAlign: "left", fontWeight: "bold" }}>{getLabel("Beschrijving", true, strings)}</Col>
          <Col span={9} style={{ textAlign: "right", fontWeight: "bold" }}>{getLabel("Verborgen", true, strings)}</Col>
        </Row>}
      {quotation.order.orderLogs.map((item, index) => {
        if ((!(item.hidden || false)) || globalHidden)
          return (<DocumentLine item={item} index={index} key={item.id} quotation={quotation} token={token} updateOrderLog={updateOrderLog} switchUpdateLoading={switchUpdateLoading} />)
      })}
      {content}
      <Popconfirm
        title={getLabel(`geen getekende berekening, offerte, factuur, ontvangstbewijs, coc of document 705`, true, strings)}
        cancelButtonProps={{ style: { display: 'none' } }}
        onConfirm={() => setUploadExtraDocumentVisible(true)}
      >
        <Button
          style={{ marginTop: "30px" }}
          type="primary"
        >
          <Icon type="plus" />{getLabel("Upload extra document", true, strings)}
        </Button>
      </Popconfirm>
    </Modal>
  )
}

const DocumentLine = ({ quotation, item, index, token, updateOrderLog, switchUpdateLoading }) => {
  const { strings } = useI18nContext();
  const [downloading, setDownloading] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const downloadingDisabled = (quotation.bike_type_fk == 1) && (item.order_log_type_fk == 3) && (!quotation.order.bike.license_number);
  return (
    <Row type='flex' justify='space-between' gutter={8} key={index} style={{ marginBottom: '16px' }}>
      <Col span={15}>{item.description ? getLabel(item.description.toLowerCase(), true, strings) : <Skeleton paragraph={false} active />}</Col>
      <Col span={3}>
        <Button
          type='primary'
          shape='circle'
          size='large'
          loading={!item.file_id}
          onClick={async () => {
            const pdfWindow = window.open('', '_blank');
            pdfWindow.document.write(htmlSpinner);
            let res = await secureFetchFromDrive(item.file_id, token);
            // const res = fetch(FetchFromDrive(item.file_id, token));
            if (!res) {
              res = await secureFetchFromDrive(item.file_id, token);
            }
            if (!!res) {
              const blob = new Blob([res.data], { type: "application/pdf" });
              // console.log(blob);
              if (pdfWindow.URL) {
                const uri = pdfWindow.URL.createObjectURL(blob);
                // pdfWindow.location = uri;
                pdfWindow.document.write("<iframe width='100%' height='100%' src='" + uri + "'/>")
                // pdfWindow.document.write("<object width='100%' height='100%' data='" + uri + "'></object>")
                // window.open(uri, '_blank');
              }
            }
            else {
              console.warn(`Could not collect file (res == ${res})`);
            }
          }}
        >
          {item.file_id && <Icon type="eye" />}</Button>
      </Col>
      <Col span={3}>
        <Popover
          content={getLabel("Provide license plate to download", true, strings)}
          title={<><Tag color="blue"><Icon type="info" /></Tag> {getLabel("Download", true, strings)}</>}
          visible={popOverVisible}
          onVisibleChange={(val) => {
            if (downloadingDisabled)
              setPopOverVisible(val);
            else
              setPopOverVisible(false);
          }}>
          <Button
            type='primary'
            shape='circle'
            size='large'
            loading={!item.file_id || downloading}
            disabled={downloadingDisabled}
            onClick={async () => {
              try {
                setDownloading(true);
                const res = await secureFetchFromDrive(item.file_id, token);
                // const res = fetch(FetchFromDrive(item.file_id, token));
                const blob = new Blob([res.data], { type: "application/pdf" });
                console.log(quotation, item);
                downloadFileFromBlob(blob, `Q${quotation.id}-${item.description}.pdf`);
              }
              catch (err) {
                message.error('Er ging iets mis');
                console.error(err);
              }
              finally {
                setDownloading(false);
              }
            }}
          >
            {(item.file_id && !downloading) && <Icon type="cloud-download" />}
          </Button>
        </Popover>
      </Col>
      <Col span={3}>
        <Switch loading={switchUpdateLoading} style={{ marginTop: "10px" }} defaultChecked={false} checked={item.hidden ? item.hidden : false} onChange={(val) => updateOrderLog(item, index, val)} />
      </Col>
    </Row>
  )
}

/**
* Inner component - Modal for webhook attempts table
*/
export const PopUpShowWebhookAttempts = (props) => {

  let quotationId = props.quotId;
  const [attempts, setAttempts] = useState([]);
  const { setWebhookAttemptsVisible } = props.callbacks

  const { loading, error, data } = useQuery(QUERIES.WEBHOOK_ATTEMPTS_BY_QUOTATION_ID, {
    variables: { id: quotationId },
    onCompleted: attemptsRes => {
      setAttempts(attemptsRes.webhookAttemptsByQuotationId);
    }
  })

  const columns = [{
    title: 'webhook',
    key: (record) => `webhook_${record.id}`,
    render: (record) => (
      getDestinationName(record.url)
    )
  }, {
    title: 'status code',
    key: (record) => `status_${record.id}`,
    render: (record) => (
      record.status_code == "200" ? <span style={{ color: "green" }}>Ok</span> : <span style={{ color: "red" }}>Niet ok</span>
    )
  }, {
    title: 'response',
    key: (record) => `response_${record.id}`,
    render: (record) => (
      <span>{record.response}</span>
    )
  }, {
    title: 'date',
    key: (record) => `date_${record.id}`,
    render: (record) => (
      getDateString(record.date)
    )
  }];

  return (
    <Modal
      title='Webhook attempts'
      visible={true}
      footer={null}
      onCancel={
        () => {
          setWebhookAttemptsVisible(false);
        }
      }>
      <Table loading={loading} pagination={{ pageSize: 5 }} dataSource={attempts} columns={columns} />
    </Modal>
  )
}

export const FIPQuoteUploadModal = props => {
  const { strings } = useI18nContext();
  const [quoteDoc, setQuoteDoc] = useState({ file: null });
  return (
    <Modal
      title={getLabel('FIP warning', false, strings)}
      visible={true}
      closable={false}
      footer={[
        <Button key="back" onClick={() => props.onLaterClicked()}>
          {getLabel('later uploaden', true, strings)}
        </Button>,
        <Button
          type='primary'
          disabled={quoteDoc.file ? quoteDoc.file.status !== 2 : true}
          key="submit" onClick={() => props.onSubmitClicked(quoteDoc)}>
          {getLabel('upload', true, strings)}
        </Button>
      ]}>
      <p>{getLabel('FIP warning enkel offerte', true, strings)}</p>
      <FileUploader acceptedFileTypes={['application/pdf']} option={'QUOTATIONDOC'} locked={true} onFileChanged={val => setQuoteDoc(val)} />
      {props.children}
    </Modal>
  )
}

/**
* Inner component - Modal for editing order status
*/
export const PopupEditOrderStatus = (props) => {

  const { strings } = useI18nContext();

  let { quotation, editOrderStatusLoading } = props;
  const [statuses, setStatuses] = useState([]);
  const [currentStatusName, setCurrentStatusName] = useState(null);
  const [currentStatusValue, setCurrentStatusValue] = useState(null);
  const { setEditOrderStatusVisible, setEditOrderStatusLoading, updateOrderStatus } = props.callbacks

  useEffect(() => {
    const statusName = quotation.order.orderStatus.name;
    setCurrentStatusName(statusName);
    setCurrentStatusValue(statusName ? getLabel(statusName, true, strings) : "");
  }, [quotation]);

  useQuery(QUERIES.ORDER_STATUSES, {
    onCompleted: statussesRes => {
      const sortedRes = statussesRes.orderStatuses.sort((a, b) => {
        return a.index - b.index;
      });
      setStatuses(sortedRes);
      setEditOrderStatusLoading(false);
    }
  });

  const handleEditSave = async () => {
    const originalStatus = statuses.find((status) => { return status.name == currentStatusName });
    if (Number(currentStatusValue) > 0 && originalStatus && originalStatus.id != currentStatusValue) {
      setEditOrderStatusLoading(true);
      await updateOrderStatus(currentStatusValue);
      setEditOrderStatusLoading(false);
      setEditOrderStatusVisible(false);
    } else {
      message.warning(getLabel("Select different status", true, strings));
    }
  }

  return (
    <Modal
      title={getLabel("Edit order status", true, strings)}
      visible={true}
      footer={null}
      onCancel={
        () => {
          //if(!editOrderStatusLoading)
          setEditOrderStatusVisible(false);
        }
      }
    >
      <p><span style={{ textDecoration: "underline", fontWeight: "bold" }}>{getLabel("Original status", true, strings)}</span>: {currentStatusName ? getLabel(currentStatusName, true, strings) : ""}</p>
      <Select
        style={{ width: 300, display: "block", marginTop: 25 }}
        defaultValue={currentStatusValue}
        onChange={(val) => setCurrentStatusValue(val)}
      >
        {statuses.map((object, i) => {
          return <Option key={`editOrderStatusOption_${i}`} value={object.id}>{getLabel(object.name, true, strings)}</Option>
        })
        }
      </Select>
      <Button
        style={{ marginTop: 30, display: "block" }}
        type="primary"
        loading={editOrderStatusLoading}
        disabled={editOrderStatusLoading}
        onClick={() => handleEditSave()}
      >
        {getLabel("Adjust status", true, strings)}
      </Button>
    </Modal>
  )
}

/**
* Inner component - Modal for linking teamleader deal
*/
export const PopupEditDealLink = (props) => {

  const { strings } = useI18nContext();

  const { quotation, editLinkedDealLoading, editLinkedDealVisible } = props;
  const { setEditLinkedDealVisible, setEditLinkedDealLoading, refreshDeal } = props.callbacks;
  const token = props.token;

  const [collectedDeals, setCollectedDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState();
  const [updateOrder] = useMutation(QUERIES.UPDATE_ORDER);

  const collectDeals = async () => {
    try {
      setEditLinkedDealLoading(true);
      const url = ['prod', 'production'].includes(process.env.REACT_APP_ENV)? 'https://europe-west1-b2bike-253312.cloudfunctions.net/searchDealsToLink' : 'https://europe-west1-b2bike-253312.cloudfunctions.net/searchDealsToLinkStaging';
      const deals = await axios.post(url, {
        "user": {
          "accessToken": token
        },
        "quotation_number": quotation.quotation_number
      });
      if (!!deals || !!deals.data || deals.data.length > 0) {
        setCollectedDeals(deals.data);
      }
      setEditLinkedDealLoading(false);
    } catch (err) {
      setEditLinkedDealVisible(false);
      setEditLinkedDealLoading(false);
      message.error("Couldn't collect deals");
    }
  }

  useEffect(() => {
    collectDeals();
  }, []);

  const linkDeal = async () => {
    if (!!selectedDeal && !!selectedDeal.id) {
      setEditLinkedDealLoading(true);
      try {
        await updateOrder({
          variables: {
            order: {
              id: quotation.order.id,
              deal_id_v2: `${selectedDeal.id}`,
              order_status_fk: quotation.order.order_status_fk,
              deal_number: `${selectedDeal.quotation_nr}`
            }
          }
        });
        setEditLinkedDealLoading(false);
        setEditLinkedDealVisible(false);
        message.success("Deal succesvol verbonden.");
        quotation.order.deal_id_v2 = selectedDeal;
        refreshDeal();
      } catch (err) {
        message.error("Kon deal niet verbinden.");
        setEditLinkedDealLoading(false);
        refreshDeal();
      }
    } else {
      message.info("Geen deal gekozen.");
    }
  }

  return (
    <Modal
      title={getLabel("Deal manueel linken", true, strings)}
      visible={true}
      footer={null}
      onCancel={
        () => {
          setEditLinkedDealVisible(false);
        }
      }
    >
      <p><span style={{ fontWeight: "bold" }}>Huidige deal: </span> <a href={`https://focus.teamleader.eu/web/deals/${quotation.order.deal_id_v2}`}>{`https://focus.teamleader.eu/web/deals/${quotation.order.deal_id_v2}`}</a></p>
      <Select
        style={{ width: "90%", display: "block", marginTop: 25 }}
        onChange={(val) => setSelectedDeal(JSON.parse(val))}
        loading={editLinkedDealLoading}
        disabled={editLinkedDealLoading}
      >
        {collectedDeals.map((object, i) => {
          return <Option key={`editOrdersDeal_${object.id}`} value={JSON.stringify(object)}>{object.title}</Option>
        })
        }
      </Select>

      <Button
        style={{ marginTop: 30, display: "block" }}
        type="primary"
        loading={editLinkedDealLoading}
        disabled={!!editLinkedDealVisible && !!editLinkedDealLoading}
        onClick={() => linkDeal()}
      >
        {getLabel("save", true, strings)}
      </Button>
    </Modal>
  )
}