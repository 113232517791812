import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Table, Spin, Input, Icon, Button, Row, Select, Empty, Tooltip } from 'antd';
import { useStateValue } from '../stateProvider/stateProvider';
import Column from 'antd/lib/table/Column';
import { QUERIES } from '../../api/queries';
import moment from 'moment';
import './employersPage.css';
import QuotationDetailModal from './quotationDetailModal'
import { useI18nContext, getLabel } from '../../api/i18nService';
import DOMPurify from 'dompurify';
import { flattenObj } from '../../util/util';

function Quotations(props) {
    moment.locale('nl');

    const { strings } = useI18nContext();
    const { Option } = Select;
    const [{ userDetails }, dispatch] = useStateValue();

    const mandatoryFilters = { exclude_order_status_codes: ['NEW', 'TESTPURPOSES'], ignoreDefaultActive: true }
    const [queryVariables, setQueryVariables] = useState({ filter: { ...mandatoryFilters } });
    const [queryOffset, setQueryOffset] = useState(1);

    const [currentStatus, setCurrentStatus] = useState(null);

    const [quotations, setQuotations] = useState([]);

    const [quot, setQuot] = useState('')
    const [modalVisible, setModalVisible] = useState(true);

    const [searchTerm, setSearchTerm] = useState({});
    const [pageLoading, setPageLoading] = useState(true)

    const [orderStatuses, setOrderStatuses] = useState([]);

    const [companies, setCompanies] = useState([]);
    const [filterValues, setFilterValues] = useState({})

    const history = useHistory();

    const [getCompanies] = useLazyQuery(QUERIES.COMPANIES_BY_ID, {
        variables: { filter: { company_ids: userDetails.companyIds } },
        onCompleted: (d => {
            if (!!d.companiesById) {
                const sortedCompanies = [...d.companiesById].sort((companyA, companyB) => companyA.name.localeCompare(companyB.name))
                setCompanies(sortedCompanies)
            }
        })
    })

    const [getQuotations, { loading }] = useLazyQuery(QUERIES.QUOTATIONS_FOR_COMPANY_AND_ORDER_STATUS, {
        variables: { ...queryVariables, filter: { ...queryVariables.filter, ...mandatoryFilters } },
        fetchPolicy: 'no-cache',
        onCompleted: queryRes => {
            onQueryComplete({ ...queryRes })
        }
    });

    const [getQuotationsForExport, quotsForExport] = useLazyQuery(QUERIES.QUOTATIONS_FOR_COMPANY_AND_ORDER_STATUS_FOR_EXPORT, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setPageLoading(false)
    }, [quotations])

    useEffect(() => {
        if (!!quotsForExport.data) {
            const flatArray = quotsForExport.data?.quotations.map(el => flattenObj(el));
            const cleanArray = flatArray.map(el => {
                const relevantKeys = Object.keys(el).filter(key => key.indexOf('__typename') < 0);
                const newEl = {};
                for (const key of relevantKeys) {
                    newEl[key] = el[key];
                }
                return newEl
            });
            cleanArray.forEach(quotation => {
                Object.keys(quotation).forEach(key => {
                    if (key.indexOf('date') >= 0) {
                        if (!!quotation[key]) {
                            quotation[key] = new Date(Number(quotation[key])).toLocaleDateString('nl-BE');
                        }
                    }
                })
            })
            const longestObject = cleanArray.sort((a, b) => Object.keys(a).length - Object.keys(b).length).reverse()[0];
            const allKeys = Object.keys(longestObject);
            let csvString = `${allKeys.map(el => el)}\n`;
            cleanArray.forEach(el => {
                allKeys.forEach(key => csvString += `${el[key] || ''},`);
                csvString += '\n';
            })
            const element = document.createElement("a");
            const file = new Blob([csvString], { type: 'text/csv' });
            element.href = URL.createObjectURL(file);
            element.download = `export-${new Date().getTime()}.csv`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }, [quotsForExport.data]);

    useEffect(() => {
        // Clearing the session storage, because it used to be incorrectly used here
        // and now we need to clean it up
        sessionStorage.clear();
        dispatch({ type: 'setCurrentEmployersPage', page: 'quotations' });
    }, [])

    useEffect(() => {
        setModalVisible(true);
    }, [quot])

    useEffect(() => {
        if (!queryVariables.filter.company_ids) {
            if (!!props.match.params.quotationId) {
                setSearchTerm({ quotation_number: `Q${props.match.params.quotationId}` })
                setQueryVariables(old => { return { ...old, filter: { ...old.filter, company_ids: userDetails.companyIds, quotation_number: `Q${props.match.params.quotationId}` } } });
            }
            else {
                setQueryVariables(old => { return { ...old, filter: { ...old.filter, company_ids: userDetails.companyIds } } });
            }
            getCompanies();
        }
    }, [userDetails])

    useEffect(() => {
        getQuotations();
    }, [queryVariables])

    useEffect(() => {
        setQueryVariables(old => {
            return { ...old, page: queryOffset, filter: { ...old.filter } };
        });
    }, [queryOffset])

    const { loading: orderStatusesLoading } = useQuery(QUERIES.ORDER_STATUSES,
        {
            fetchPolicy: 'cache-and-network',
            onCompleted: queryRes => {
                if (queryRes.orderStatuses && queryRes.orderStatuses.length > 0);
                //Remove new & testpurposes from list if available
                const filteredStatuses = queryRes.orderStatuses.filter(status => {
                    return status.code != "NEW" && status.code != "TESTPURPOSES";
                });
                setOrderStatuses(filteredStatuses);
            }
        },
    );

    const getFilterKey = (dataIndex) => {
        switch (dataIndex) {
            case "order_status":
                return "order_status_name";
            case "company":
                return "company_name";
            case "quotation_number":
                return "quotation_number";
            case "employee":
                return "employee_name";
            default:
                return dataIndex;
        }
    }

    const getAPISearchKey = (key) => {
        switch (key) {
            case "employee":
                return "employee.name";
            case "company":
                return "company.name";
            case "quotation_number":
                return "id";
            case "order_status":
                return "order_status.name";
            case "quotation_date":
                return "quotation_date";
        }
    }

    const getEndDate = (record) => {
        if (record.order?.start_date) {
            const relevantField = record.quotationFields.find(el => el.field.fieldType.code === 'DURATIONMONTHS');
            const duration = relevantField?.value ? parseInt(relevantField.value) : 36;
            const startDate = new Date(parseInt(record.order.start_date));
            startDate.setMonth(startDate.getMonth() + duration);
            startDate.setDate(startDate.getDate() -1);
            return new Date(startDate).toLocaleDateString('nl-BE')
        }
        return '/'

    }

    const handleTableChange = (pagination, filters, sort) => {
        let newFilter = {}
        if (filters) {
            for (let key of Object.keys(filters)) {
                let value = filters[key][0]
                if (key === 'quotation_number') {
                    if (value && value?.toLowerCase()[0] !== 'q') {
                        value = `Q${value}`;
                    }
                }
                newFilter[getFilterKey(key)] = value
            }

            let newSort;
            if (Object.keys(sort).length > 0) {
                const key = sort.columnKey;
                const API_KEY = getAPISearchKey(key);

                let order = sort.order;
                if (order === "ascend")
                    newSort = {
                        field: API_KEY,
                        direction: 'asc'
                    }
                else if (order === "descend")
                    newSort = {
                        field: API_KEY,
                        direction: 'desc'
                    }
            }

            setQueryVariables(old => {
                return { ...old, sort: newSort, filter: { ...newFilter, company_ids: userDetails.companyIds } }
            })
        }
    }

    let searchInput = null
    const searchColumn = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                {dataIndex == 'order_status' ? (
                    <Select
                        loading={orderStatusesLoading}
                        disabled={orderStatusesLoading}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        onChange={(val) => {
                            setSelectedKeys(val ? [val] : [])
                            setFilterValues((old) => ({ ...old, order_status: val }))
                        }}
                        value={filterValues?.['order_status'] ?? null}
                    >
                        {orderStatuses.map((item) => {
                            return (
                                <Option value={item.name} key={`status_option_${item.index}`}>
                                    {getLabel(item.code.toLowerCase(), true, strings)}
                                </Option>
                            )
                        })}
                    </Select>
                ) : dataIndex == 'company' ? (
                    <Select
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        showSearch
                        onChange={(val, option) => {
                            setSelectedKeys(
                                option?.props?.children ? [option.props.children] : []
                            )
                            setFilterValues((old) => ({
                                ...old,
                                company: option?.props?.children,
                            }))
                        }}
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                            option.props.parent === null && option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        value={filterValues?.['company'] ?? null}
                    >
                        {companies.map((item) => (
                            <Option parent={item?.company_parent_fk ?? null} value={item?.id}>{item?.name}</Option>
                        ))}
                    </Select>
                ) : (
                    <Input
                        ref={(node) => {
                            searchInput = node
                        }}
                        placeholder={getLabel('Zoeken', true, strings)}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                )}
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon="filter"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    {getLabel('filter', true, strings)}
                </Button>
                <Button
                    onClick={() => {
                        if (searchTerm.quotation_number) {
                            history.replace('/employersportal/quotations/')
                            handleNoQuotationsReset()
                        }
                        clearFilters()
                        if (searchInput) {
                            searchInput.state.value = null
                        }
                        setFilterValues({})
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <Icon
                type="filter"
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && searchInput) {
                setTimeout(() => searchInput.select())
            }
        },
    })

    const onQueryComplete = queryRes => {
        if (queryRes.quotations) {
            setQuotations(queryRes.quotations);
        } else {
            console.error('No quotations in res?');
        }
    };

    const handleNoQuotationsReset = () => {
        setPageLoading(true);
        setQueryVariables(old => {
            return { filter: { ...mandatoryFilters, company_ids: userDetails.companyIds } }
        })
    }

    const pagination = (
        <>
            <Row type='flex' justify='start' style={{ float: "left" }}>
                {(
                    <span style={{ marginLeft: '16px', display: 'inline-flex' }}>
                        <Button
                            disabled={queryOffset <= 1}
                            onClick={() => {
                                const offset = queryOffset - 1;
                                setQueryOffset(offset);
                            }}
                        >
                            {getLabel('previous', true, strings)}
                        </Button>
                        <Input style={{ margin: '0px  16px', width: '128px' }} type='Number'
                            value={queryOffset}
                            onKeyPress={
                                e => {
                                    if (e.key == 'Enter') {
                                        const val = DOMPurify.sanitize(e.target.value);
                                        if (val > 0) {
                                            setQueryOffset(parseInt(val));
                                        }
                                    }
                                }
                            }
                            onBlur={(e) => {
                                setQueryOffset(parseInt(e.target.value));
                            }}
                        ></Input>
                        <Button
                            onClick={() => {
                                const offset = queryOffset + 1;
                                setQueryOffset(offset);
                            }}
                        >
                            {getLabel('next', true, strings)}
                        </Button>
                    </span>
                )}
            </Row>
            <Row type='flex' justify='end' style={{ float: 'right', marginRight: '10%' }}>
                <Button
                    loading={quotsForExport.loading}
                    onClick={() => {
                        getQuotationsForExport({
                            variables: { ...queryVariables, limit: 99999, page: 1 },
                        });
                    }}
                >Export</Button>
            </Row>
        </>
    );

    const openModal = (record) => {
        switch (record.order.orderStatus.name) {
            case 'Nieuw':
                setCurrentStatus(0)
                break;
            case 'Getekend':
                setCurrentStatus(1)
                break;
            case 'Verzonden naar beslisser':
                setCurrentStatus(2)
                break;
            case 'Goedgekeurd':
                setCurrentStatus(3)
                break;
            case 'Klaar voor levering':
                setCurrentStatus(4)
                break;
            case 'Geleverd':
                setCurrentStatus(5)
                break;
            case 'Geweigerd':
                setCurrentStatus(6)
                break;
            default:
                break;
        }
        setQuot({ ...record });
    }

    if (pageLoading) {
        return (
            <Row type='flex' justify='center'>
                <Spin size="large" style={{ margin: 128 }} />
            </Row>
        )
    }

    return (
        <div className="main">
            {modalVisible && (
                <div id="popup_screen">
                    <QuotationDetailModal quotation={quot} setModalVisible={setModalVisible} refetchCallback={() => getQuotations()} currentStatus={currentStatus} />
                </div>
            )}
            <h1>{getLabel('Alle offertes', true, strings)}</h1>
            {pagination}
            <Row type='flex' justify='center' style={{ width: "100%" }}>
                <Table
                    onChange={(page, filter, sort) => {
                        handleTableChange(page, filter, sort);
                    }}
                    locale={{
                        emptyText: (<Empty description={
                            <span>
                                {getLabel('Geen quotations gevonden volgens de ingestelde filters', true, strings)}.<br />

                                {getLabel('Probeer de filters manueel of in bulk (via de onderstaande knop) te herstellen', true, strings)}.<br />

                                <Button type="primary" onClick={handleNoQuotationsReset} >RESET</Button>
                            </span>
                        } />)
                    }}
                    loading={loading}
                    pagination={false}
                    dataSource={quotations}
                    style={{ width: '80vw', margin: 16, cursor: 'pointer' }}
                    rowKey={record => record.id}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { openModal(record) }, // click row
                        };
                    }}
                >
                    <Column
                        title={getLabel("quotation number", true, strings)}
                        dataIndex="quotation_number"
                        render={(text, record) => (
                            <p>{record.quotation_number}</p>
                        )}
                        sorter={true}
                        {...searchColumn('quotation_number')}
                    />
                    <Column
                        title={getLabel("employee", true, strings)}
                        dataIndex="employee"
                        render={(text, record) => (
                            <p>{record.employee.name}</p>
                        )}
                        {...searchColumn('employee')}
                    />
                    <Column
                        title={getLabel("order status", true, strings)}
                        dataIndex="order_status"
                        render={(text, record) => (
                            <p>{getLabel(record.order.orderStatus.code.toLowerCase(), true, strings)}</p>
                        )}
                        {...searchColumn('order_status')}
                    />
                    <Column
                        title={getLabel("company", true, strings)}
                        dataIndex="company"
                        render={(text, record) => (
                            <p>{record.company.name}</p>
                        )}
                        {...searchColumn('company')}
                    />
                    <Column
                        title={getLabel("license number", true, strings)}
                        dataIndex=""
                        render={(text, record) => {
                            const isBikeASpeedPedelec = record?.bikeType.name === "Speed pedelec"

                            return (
                                <p>{isBikeASpeedPedelec ?
                                    (record?.order?.bike?.license_number?.length > 0 ? record?.order?.bike?.license_number : getLabel("Ongekend", true, strings))
                                    : getLabel("Niet van toepassing", true, strings)
                                }
                                </p>
                            )
                        }}
                    />
                    <Column
                        title={getLabel("costcenter", true, strings)}
                        dataIndex=""
                        render={record => (
                            <p>{record.costcenter ? record.costcenter : '-'}</p>
                        )}
                    />
                    <Column
                        title={getLabel("date", true, strings)}
                        dataIndex=""
                        render={record => (
                            <p>{new Date(parseInt(record.quotation_date)).toLocaleDateString('nl-BE')}</p>
                        )}
                    />
                    <Column
                        title={getLabel("start date", true, strings)}
                        dataIndex=""
                        render={record => (
                            <p>{record.order?.start_date ? new Date(parseInt(record.order.start_date)).toLocaleDateString('nl-BE') : '/'}</p>
                        )}
                    />
                    <Column
                        title={getLabel("end date", true, strings)}
                        dataIndex=""
                        render={record => (
                            //<p>{new Date(parseInt(record.quotation_date)).toLocaleDateString('nl-BE')}</p>
                            <p>{getEndDate(record)}</p>
                        )}
                    />
                    {/* <Column
                        title={getLabel("Investeringswaarde excl BTW", true, strings)}
                        dataIndex=""
                        render={record => (
                            <p>{record.order.deal.estimated_value ? ('€ ' + record.order.deal.estimated_value.amount).replace('.', ',') : getLabel('ongekend', true, strings)}</p>
                        )}
                    /> */}
                    <Column
                        title={getLabel("lease incl vat", true, strings)}
                        dataIndex=""
                        render={record => (
                            <p>{record.lease_amount_vat ? ('€ ' + record.lease_amount_vat).replace('.', ',') : getLabel('ongekend', true, strings)}</p>
                        )}
                    />
                    <Column
                        title={`${getLabel("approved", true, strings)}?`}
                        dataIndex=""
                        render={record => {
                            if (record.order.confirmedOrder) {
                                return (
                                    (record.order.confirmedOrder.canceller_mail || record.order.orderStatus.code === 'CANCELLED') ? (
                                        <Tooltip title={record.order.confirmedOrder.cancelled_reason || 'Geweigerd'}>
                                            <Icon type="close-circle" style={{ color: '#f5222d', fontSize: '32px' }} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={record.order.confirmedOrder.confirmed_reason}>
                                            <Icon type="check-circle" style={{ color: '#7BBC1F', fontSize: '32px' }} />
                                        </Tooltip>
                                    )
                                )
                            }
                            else if (record.order.orderStatus.code === 'CANCELLED') {
                                return (
                                    <Tooltip title={getLabel('Geweigerd', true, strings)}>
                                        <Icon type="close-circle" style={{ color: '#f5222d', fontSize: '32px' }} />
                                    </Tooltip>
                                )
                            }
                        }
                        }
                    />
                </Table>
            </Row>
        </div>
    )
}

export default Quotations
